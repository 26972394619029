export const faktorial = [
  0, 0.0, 0.69315, 1.79176, 3.17805, 4.78749, 6.57925, 8.52516, 10.6046,
  12.80183, 15.10441, 17.50231, 19.98721, 22.55216, 25.19122, 27.89927,
  30.67186, 33.50507, 36.39545, 39.33988, 42.33562, 45.38014, 48.47118,
  51.60668, 54.78473, 58.00361, 61.2617, 64.55754, 67.88974, 71.25704, 74.65824,
  78.09222, 81.55796, 85.05447, 88.58083, 92.13618, 95.71969, 99.33061,
  102.9682, 106.63176, 110.32064, 114.03421, 117.77188, 121.53308, 125.31727,
  129.12393, 132.95258, 136.80272, 140.67392, 144.56574, 148.47777, 152.40959,
  156.36084, 160.33113, 164.32011, 168.32745, 172.3528, 176.39585, 180.45629,
  184.53383, 188.62817, 192.73905, 196.86618, 201.00932, 205.1682, 209.34259,
  213.53224, 217.73693, 221.95644, 226.19055, 230.43904, 234.70172, 238.97839,
  243.26885, 247.57291, 251.8904, 256.22114, 260.56494, 264.92165, 269.2911,
  273.67312, 278.06757, 282.47429, 286.89313, 291.32395, 295.7666, 300.22095,
  304.68686, 309.16419, 313.65283, 318.15264, 322.6635, 327.18529, 331.71789,
  336.26118, 340.81506, 345.37941, 349.95412, 354.53909, 359.13421, 363.73938,
];
