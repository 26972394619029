export const xData_522 = [
  -5, -4.9749, -4.9499, -4.9248, -4.8997, -4.8747, -4.8496, -4.8246, -4.7995,
  -4.7744, -4.7494, -4.7243, -4.6992, -4.6742, -4.6491, -4.6241, -4.599,
  -4.5739, -4.5489, -4.5238, -4.4987, -4.4737, -4.4486, -4.4236, -4.3985,
  -4.3734, -4.3484, -4.3233, -4.2982, -4.2732, -4.2481, -4.2231, -4.198,
  -4.1729, -4.1479, -4.1228, -4.0977, -4.0727, -4.0476, -4.0226, -3.9975,
  -3.9724, -3.9474, -3.9223, -3.8972, -3.8722, -3.8471, -3.8221, -3.797,
  -3.7719, -3.7469, -3.7218, -3.6967, -3.6717, -3.6466, -3.6216, -3.5965,
  -3.5714, -3.5464, -3.5213, -3.4962, -3.4712, -3.4461, -3.4211, -3.396,
  -3.3709, -3.3459, -3.3208, -3.2957, -3.2707, -3.2456, -3.2206, -3.1955,
  -3.1704, -3.1454, -3.1203, -3.0952, -3.0702, -3.0451, -3.0201, -2.995,
  -2.9699, -2.9449, -2.9198, -2.8947, -2.8697, -2.8446, -2.8195, -2.7945,
  -2.7694, -2.7444, -2.7193, -2.6942, -2.6692, -2.6441, -2.619, -2.594, -2.5689,
  -2.5439, -2.5188, -2.4937, -2.4687, -2.4436, -2.4185, -2.3935, -2.3684,
  -2.3434, -2.3183, -2.2932, -2.2682, -2.2431, -2.218, -2.193, -2.1679, -2.1429,
  -2.1178, -2.0927, -2.0677, -2.0426, -2.0175, -1.9925, -1.9674, -1.9424,
  -1.9173, -1.8922, -1.8672, -1.8421, -1.817, -1.792, -1.7669, -1.7419, -1.7168,
  -1.6917, -1.6667, -1.6416, -1.6165, -1.5915, -1.5664, -1.5414, -1.5163,
  -1.4912, -1.4662, -1.4411, -1.416, -1.391, -1.3659, -1.3409, -1.3158, -1.2907,
  -1.2657, -1.2406, -1.2155, -1.1905, -1.1654, -1.1404, -1.1153, -1.0902,
  -1.0652, -1.0401, -1.015, -0.99, -0.9649, -0.9398, -0.9148, -0.8897, -0.8647,
  -0.8396, -0.8145, -0.7895, -0.7644, -0.7393, -0.7143, -0.6892, -0.6642,
  -0.6391, -0.614, -0.589, -0.5639, -0.5388, -0.5138, -0.4887, -0.4637, -0.4386,
  -0.4135, -0.3885, -0.3634, -0.3383, -0.3133, -0.2882, -0.2632, -0.2381,
  -0.213, -0.188, -0.1629, -0.1378, -0.1128, -0.0877, -0.0627, -0.0376, -0.0125,
  0.0125, 0.0376, 0.0627, 0.0877, 0.1128, 0.1378, 0.1629, 0.188, 0.213, 0.2381,
  0.2632, 0.2882, 0.3133, 0.3383, 0.3634, 0.3885, 0.4135, 0.4386, 0.4637,
  0.4887, 0.5138, 0.5388, 0.5639, 0.589, 0.614, 0.6391, 0.6642, 0.6892, 0.7143,
  0.7393, 0.7644, 0.7895, 0.8145, 0.8396, 0.8647, 0.8897, 0.9148, 0.9398,
  0.9649, 0.99, 1.015, 1.0401, 1.0652, 1.0902, 1.1153, 1.1404, 1.1654, 1.1905,
  1.2155, 1.2406, 1.2657, 1.2907, 1.3158, 1.3409, 1.3659, 1.391, 1.416, 1.4411,
  1.4662, 1.4912, 1.5163, 1.5414, 1.5664, 1.5915, 1.6165, 1.6416, 1.6667,
  1.6917, 1.7168, 1.7419, 1.7669, 1.792, 1.817, 1.8421, 1.8672, 1.8922, 1.9173,
  1.9424, 1.9674, 1.9925, 2.0175, 2.0426, 2.0677, 2.0927, 2.1178, 2.1429,
  2.1679, 2.193, 2.218, 2.2431, 2.2682, 2.2932, 2.3183, 2.3434, 2.3684, 2.3935,
  2.4185, 2.4436, 2.4687, 2.4937, 2.5188, 2.5439, 2.5689, 2.594, 2.619, 2.6441,
  2.6692, 2.6942, 2.7193, 2.7444, 2.7694, 2.7945, 2.8195, 2.8446, 2.8697,
  2.8947, 2.9198, 2.9449, 2.9699, 2.995, 3.0201, 3.0451, 3.0702, 3.0952, 3.1203,
  3.1454, 3.1704, 3.1955, 3.2206, 3.2456, 3.2707, 3.2957, 3.3208, 3.3459,
  3.3709, 3.396, 3.4211, 3.4461, 3.4712, 3.4962, 3.5213, 3.5464, 3.5714, 3.5965,
  3.6216, 3.6466, 3.6717, 3.6967, 3.7218, 3.7469, 3.7719, 3.797, 3.8221, 3.8471,
  3.8722, 3.8972, 3.9223, 3.9474, 3.9724, 3.9975, 4.0226, 4.0476, 4.0727,
  4.0977, 4.1228, 4.1479, 4.1729, 4.198, 4.2231, 4.2481, 4.2732, 4.2982, 4.3233,
  4.3484, 4.3734, 4.3985, 4.4236, 4.4486, 4.4737, 4.4987, 4.5238, 4.5489,
  4.5739, 4.599, 4.6241, 4.6491, 4.6742, 4.6992, 4.7243, 4.7494, 4.7744, 4.7995,
  4.8246, 4.8496, 4.8747, 4.8997, 4.9248, 4.9499, 4.9749, 5,
];
export const yData_522 = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.001, 0.001, 0.001, 0.001,
  0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.002, 0.002,
  0.002, 0.002, 0.002, 0.002, 0.003, 0.003, 0.003, 0.003, 0.004, 0.004, 0.004,
  0.004, 0.005, 0.005, 0.005, 0.006, 0.006, 0.007, 0.007, 0.008, 0.008, 0.009,
  0.01, 0.01, 0.011, 0.012, 0.012, 0.013, 0.014, 0.015, 0.016, 0.017, 0.018,
  0.019, 0.021, 0.022, 0.023, 0.025, 0.026, 0.028, 0.029, 0.031, 0.033, 0.035,
  0.037, 0.039, 0.041, 0.043, 0.045, 0.048, 0.05, 0.053, 0.056, 0.059, 0.062,
  0.065, 0.068, 0.071, 0.075, 0.078, 0.082, 0.086, 0.09, 0.094, 0.098, 0.103,
  0.107, 0.112, 0.117, 0.122, 0.127, 0.132, 0.138, 0.143, 0.149, 0.155, 0.161,
  0.167, 0.174, 0.18, 0.187, 0.194, 0.201, 0.208, 0.215, 0.222, 0.23, 0.238,
  0.245, 0.253, 0.261, 0.27, 0.278, 0.286, 0.295, 0.304, 0.313, 0.321, 0.33,
  0.34, 0.349, 0.358, 0.368, 0.377, 0.387, 0.396, 0.406, 0.416, 0.425, 0.435,
  0.445, 0.455, 0.465, 0.475, 0.485, 0.495, 0.505, 0.515, 0.525, 0.535, 0.545,
  0.555, 0.565, 0.575, 0.584, 0.594, 0.604, 0.613, 0.623, 0.632, 0.642, 0.651,
  0.66, 0.67, 0.679, 0.687, 0.696, 0.705, 0.714, 0.722, 0.73, 0.739, 0.747,
  0.755, 0.762, 0.77, 0.778, 0.785, 0.792, 0.799, 0.806, 0.813, 0.82, 0.826,
  0.833, 0.839, 0.845, 0.851, 0.857, 0.862, 0.868, 0.873, 0.878, 0.883, 0.888,
  0.893, 0.897, 0.902, 0.906, 0.91, 0.914, 0.918, 0.922, 0.925, 0.929, 0.932,
  0.935, 0.938, 0.941, 0.944, 0.947, 0.95, 0.952, 0.955, 0.957, 0.959, 0.961,
  0.963, 0.965, 0.967, 0.969, 0.971, 0.972, 0.974, 0.975, 0.977, 0.978, 0.979,
  0.981, 0.982, 0.983, 0.984, 0.985, 0.986, 0.987, 0.988, 0.988, 0.989, 0.99,
  0.99, 0.991, 0.992, 0.992, 0.993, 0.993, 0.994, 0.994, 0.995, 0.995, 0.995,
  0.996, 0.996, 0.996, 0.996, 0.997, 0.997, 0.997, 0.997, 0.998, 0.998, 0.998,
  0.998, 0.998, 0.998, 0.999, 0.999, 0.999, 0.999, 0.999, 0.999, 0.999, 0.999,
  0.999, 0.999, 0.999, 0.999, 0.999, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
  1, 1, 1,
];

export const strings_522 = [
  [
    [-5, -0.707, 0.0, 0.707, 1.414, 2.121, 2.828, 3.536, 4.243, 4.95, 5],
    [0, 0.5, 0.75, 0.875, 0.938, 0.969, 0.984, 0.992, 0.996, 0.998, 1],
  ],
  [
    [-5, -1.0, -0.5, 0.0, 0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5],
    [
      0, 0.25, 0.5, 0.688, 0.812, 0.891, 0.938, 0.965, 0.98, 0.989, 0.994,
      0.997, 0.998, 1,
    ],
  ],
  [
    [
      -5, -1.225, -0.816, -0.408, 0.0, 0.408, 0.816, 1.225, 1.633, 2.041, 2.449,
      2.858, 3.266, 3.674, 4.082, 4.491, 4.899, 5,
    ],
    [
      0, 0.125, 0.312, 0.5, 0.656, 0.773, 0.855, 0.91, 0.945, 0.967, 0.981,
      0.989, 0.994, 0.996, 0.998, 0.999, 0.999, 1,
    ],
  ],
  [
    [
      -5, -1.414, -1.061, -0.707, -0.354, 0.0, 0.354, 0.707, 1.061, 1.414,
      1.768, 2.121, 2.475, 2.828, 3.182, 3.536, 3.889, 4.243, 4.596, 4.95, 5,
    ],
    [
      0, 0.062, 0.188, 0.344, 0.5, 0.637, 0.746, 0.828, 0.887, 0.927, 0.954,
      0.971, 0.982, 0.989, 0.994, 0.996, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -1.581, -1.265, -0.949, -0.632, -0.316, 0.0, 0.316, 0.632, 0.949,
      1.265, 1.581, 1.897, 2.214, 2.53, 2.846, 3.162, 3.479, 3.795, 4.111,
      4.427, 4.743, 5,
    ],
    [
      0, 0.031, 0.109, 0.227, 0.363, 0.5, 0.623, 0.726, 0.806, 0.867, 0.91,
      0.941, 0.962, 0.975, 0.985, 0.99, 0.994, 0.996, 0.998, 0.999, 0.999, 1.0,
      1,
    ],
  ],
  [
    [
      -5, -1.732, -1.443, -1.155, -0.866, -0.577, -0.289, 0.0, 0.289, 0.577,
      0.866, 1.155, 1.443, 1.732, 2.021, 2.309, 2.598, 2.887, 3.175, 3.464,
      3.753, 4.041, 4.33, 4.619, 5,
    ],
    [
      0, 0.016, 0.062, 0.145, 0.254, 0.377, 0.5, 0.613, 0.709, 0.788, 0.849,
      0.895, 0.928, 0.952, 0.968, 0.979, 0.987, 0.992, 0.995, 0.997, 0.998,
      0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -1.871, -1.604, -1.336, -1.069, -0.802, -0.535, -0.267, 0.0, 0.267,
      0.535, 0.802, 1.069, 1.336, 1.604, 1.871, 2.138, 2.405, 2.673, 2.94,
      3.207, 3.474, 3.742, 4.009, 4.276, 4.543, 5,
    ],
    [
      0, 0.008, 0.035, 0.09, 0.172, 0.274, 0.387, 0.5, 0.605, 0.696, 0.773,
      0.834, 0.881, 0.916, 0.942, 0.961, 0.974, 0.983, 0.989, 0.993, 0.995,
      0.997, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.0, -1.75, -1.5, -1.25, -1.0, -0.75, -0.5, -0.25, 0.0, 0.25, 0.5,
      0.75, 1.0, 1.25, 1.5, 1.75, 2.0, 2.25, 2.5, 2.75, 3.0, 3.25, 3.5, 3.75,
      4.0, 4.25, 4.5, 5,
    ],
    [
      0, 0.004, 0.02, 0.055, 0.113, 0.194, 0.291, 0.395, 0.5, 0.598, 0.685,
      0.76, 0.82, 0.868, 0.905, 0.933, 0.953, 0.968, 0.978, 0.986, 0.99, 0.994,
      0.996, 0.997, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.121, -1.886, -1.65, -1.414, -1.179, -0.943, -0.707, -0.471, -0.236,
      0.0, 0.236, 0.471, 0.707, 0.943, 1.179, 1.414, 1.65, 1.886, 2.121, 2.357,
      2.593, 2.828, 3.064, 3.3, 3.536, 3.771, 4.243, 4.478, 5,
    ],
    [
      0, 0.002, 0.011, 0.033, 0.073, 0.133, 0.212, 0.304, 0.402, 0.5, 0.593,
      0.676, 0.748, 0.808, 0.857, 0.895, 0.924, 0.946, 0.962, 0.974, 0.982,
      0.988, 0.992, 0.995, 0.996, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.236, -2.012, -1.789, -1.565, -1.342, -1.118, -0.894, -0.671,
      -0.447, -0.224, 0.0, 0.224, 0.447, 0.671, 0.894, 1.118, 1.342, 1.565,
      1.789, 2.012, 2.236, 2.46, 2.683, 2.907, 3.13, 3.354, 3.578, 3.801, 4.249,
      4.472, 5,
    ],
    [
      0, 0.001, 0.006, 0.019, 0.046, 0.09, 0.151, 0.227, 0.315, 0.407, 0.5,
      0.588, 0.668, 0.738, 0.798, 0.846, 0.885, 0.916, 0.939, 0.956, 0.969,
      0.979, 0.985, 0.99, 0.993, 0.995, 0.997, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.345, -2.132, -1.919, -1.706, -1.492, -1.279, -1.066, -0.853, -0.64,
      -0.426, -0.213, 0.0, 0.213, 0.426, 0.64, 0.853, 1.066, 1.279, 1.492,
      1.706, 1.919, 2.132, 2.345, 2.558, 2.772, 2.985, 3.198, 3.411, 3.624,
      3.838, 4.051, 4.264, 5,
    ],
    [
      0, 0.0, 0.003, 0.011, 0.029, 0.059, 0.105, 0.166, 0.24, 0.324, 0.412, 0.5,
      0.584, 0.661, 0.729, 0.788, 0.837, 0.876, 0.908, 0.932, 0.951, 0.965,
      0.975, 0.982, 0.988, 0.992, 0.994, 0.996, 0.997, 0.998, 0.999, 0.999, 1.0,
      1,
    ],
  ],
  [
    [
      -5, -2.449, -2.245, -2.041, -1.837, -1.633, -1.429, -1.225, -1.021,
      -0.816, -0.612, -0.408, -0.204, 0.0, 0.204, 0.408, 0.612, 0.816, 1.021,
      1.225, 1.429, 1.633, 1.837, 2.041, 2.245, 2.449, 2.654, 2.858, 3.062,
      3.266, 3.47, 3.674, 4.082, 4.287, 5,
    ],
    [
      0, 0.0, 0.002, 0.006, 0.018, 0.038, 0.072, 0.119, 0.18, 0.252, 0.332,
      0.416, 0.5, 0.581, 0.655, 0.721, 0.779, 0.828, 0.868, 0.9, 0.925, 0.945,
      0.96, 0.971, 0.98, 0.986, 0.99, 0.993, 0.995, 0.997, 0.998, 0.999, 0.999,
      1.0, 1,
    ],
  ],
  [
    [
      -5, -2.55, -2.353, -2.157, -1.961, -1.765, -1.569, -1.373, -1.177, -0.981,
      -0.784, -0.588, -0.392, -0.196, 0.0, 0.196, 0.392, 0.588, 0.784, 0.981,
      1.177, 1.373, 1.569, 1.765, 1.961, 2.157, 2.353, 2.55, 2.746, 2.942,
      3.138, 3.334, 3.53, 3.726, 4.118, 4.315, 5,
    ],
    [
      0, 0.0, 0.001, 0.004, 0.011, 0.025, 0.048, 0.084, 0.132, 0.192, 0.262,
      0.339, 0.419, 0.5, 0.577, 0.649, 0.714, 0.771, 0.819, 0.859, 0.892, 0.919,
      0.939, 0.955, 0.967, 0.976, 0.983, 0.988, 0.992, 0.994, 0.996, 0.997,
      0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.457, -2.268, -2.079, -1.89, -1.701, -1.512, -1.323, -1.134, -0.945,
      -0.756, -0.567, -0.378, -0.189, 0.0, 0.189, 0.378, 0.567, 0.756, 0.945,
      1.134, 1.323, 1.512, 1.701, 1.89, 2.079, 2.268, 2.457, 2.646, 2.835,
      3.024, 3.213, 3.402, 3.591, 3.78, 3.969, 5,
    ],
    [
      0, 0.0, 0.002, 0.006, 0.015, 0.032, 0.058, 0.095, 0.143, 0.202, 0.271,
      0.345, 0.423, 0.5, 0.575, 0.644, 0.708, 0.763, 0.811, 0.852, 0.885, 0.912,
      0.934, 0.951, 0.964, 0.973, 0.981, 0.986, 0.99, 0.993, 0.995, 0.997,
      0.998, 0.998, 0.999, 0.999, 1,
    ],
  ],
  [
    [
      -5, -2.556, -2.373, -2.191, -2.008, -1.826, -1.643, -1.461, -1.278,
      -1.095, -0.913, -0.73, -0.548, -0.365, -0.183, 0.0, 0.183, 0.365, 0.548,
      0.73, 0.913, 1.095, 1.278, 1.461, 1.643, 1.826, 2.008, 2.191, 2.373,
      2.556, 2.739, 2.921, 3.104, 3.286, 3.469, 5,
    ],
    [
      0, 0.0, 0.001, 0.004, 0.01, 0.021, 0.039, 0.067, 0.105, 0.154, 0.212,
      0.279, 0.351, 0.425, 0.5, 0.572, 0.64, 0.702, 0.757, 0.804, 0.845, 0.879,
      0.906, 0.928, 0.946, 0.96, 0.97, 0.978, 0.984, 0.989, 0.992, 0.994, 0.996,
      0.997, 0.998, 1,
    ],
  ],
  [
    [
      -5, -2.688, -2.53, -2.372, -2.214, -2.055, -1.897, -1.739, -1.581, -1.423,
      -1.265, -1.107, -0.949, -0.791, -0.632, -0.474, -0.316, -0.158, 0.0,
      0.158, 0.316, 0.474, 0.632, 0.791, 0.949, 1.107, 1.265, 1.423, 1.581,
      1.739, 1.897, 2.055, 2.214, 2.372, 2.53, 2.688, 2.846, 3.004, 5,
    ],
    [
      0, 0.0, 0.001, 0.002, 0.005, 0.01, 0.018, 0.031, 0.049, 0.075, 0.108,
      0.148, 0.196, 0.25, 0.309, 0.371, 0.436, 0.5, 0.563, 0.622, 0.678, 0.729,
      0.774, 0.814, 0.849, 0.879, 0.903, 0.924, 0.941, 0.954, 0.965, 0.973,
      0.98, 0.985, 0.989, 0.992, 0.994, 0.996, 1,
    ],
  ],
  [
    [
      -5, -2.687, -2.546, -2.404, -2.263, -2.121, -1.98, -1.838, -1.697, -1.556,
      -1.414, -1.273, -1.131, -0.99, -0.849, -0.707, -0.566, -0.424, -0.283,
      -0.141, 0.0, 0.141, 0.283, 0.424, 0.566, 0.707, 0.849, 0.99, 1.131, 1.273,
      1.414, 1.556, 1.697, 1.838, 1.98, 2.121, 2.263, 2.404, 2.546, 2.687,
      2.828, 2.97, 3.111, 3.253, 3.394, 5,
    ],
    [
      0, 0.0, 0.001, 0.002, 0.005, 0.008, 0.014, 0.024, 0.036, 0.054, 0.077,
      0.106, 0.14, 0.18, 0.226, 0.276, 0.329, 0.385, 0.443, 0.5, 0.556, 0.61,
      0.661, 0.708, 0.752, 0.791, 0.825, 0.855, 0.881, 0.904, 0.922, 0.938,
      0.951, 0.962, 0.97, 0.977, 0.982, 0.986, 0.99, 0.992, 0.994, 0.996, 0.997,
      0.998, 0.998, 1,
    ],
  ],
  [
    [
      -5, -2.84, -2.711, -2.582, -2.453, -2.324, -2.195, -2.066, -1.936, -1.807,
      -1.678, -1.549, -1.42, -1.291, -1.162, -1.033, -0.904, -0.775, -0.645,
      -0.516, -0.387, -0.258, -0.129, 0.0, 0.129, 0.258, 0.387, 0.516, 0.645,
      0.775, 0.904, 1.033, 1.162, 1.291, 1.42, 1.549, 1.678, 1.807, 1.936,
      2.066, 2.195, 2.324, 2.453, 2.582, 2.711, 2.84, 2.969, 3.098, 3.227,
      3.357, 3.486, 3.744, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.004, 0.007, 0.011, 0.018, 0.027, 0.039,
      0.056, 0.076, 0.101, 0.131, 0.166, 0.205, 0.248, 0.295, 0.344, 0.396,
      0.448, 0.5, 0.551, 0.601, 0.648, 0.693, 0.734, 0.771, 0.805, 0.836, 0.863,
      0.886, 0.906, 0.923, 0.938, 0.95, 0.96, 0.968, 0.975, 0.98, 0.985, 0.988,
      0.991, 0.993, 0.995, 0.996, 0.997, 0.998, 0.998, 0.999, 0.999, 1,
    ],
  ],
  [
    [
      -5, -2.869, -2.749, -2.63, -2.51, -2.39, -2.271, -2.151, -2.032, -1.912,
      -1.793, -1.673, -1.554, -1.434, -1.315, -1.195, -1.076, -0.956, -0.837,
      -0.717, -0.598, -0.478, -0.359, -0.239, -0.12, 0.0, 0.12, 0.239, 0.359,
      0.478, 0.598, 0.717, 0.837, 0.956, 1.076, 1.195, 1.315, 1.434, 1.554,
      1.673, 1.793, 1.912, 2.032, 2.151, 2.271, 2.39, 2.51, 2.63, 2.749, 2.869,
      2.988, 3.108, 3.227, 3.347, 3.466, 3.705, 3.825, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.003, 0.005, 0.009, 0.014, 0.02, 0.029,
      0.041, 0.056, 0.074, 0.096, 0.123, 0.153, 0.187, 0.225, 0.266, 0.31,
      0.356, 0.404, 0.452, 0.5, 0.548, 0.594, 0.638, 0.68, 0.719, 0.756, 0.789,
      0.819, 0.846, 0.87, 0.891, 0.909, 0.925, 0.938, 0.949, 0.959, 0.967,
      0.973, 0.979, 0.983, 0.987, 0.99, 0.992, 0.994, 0.995, 0.996, 0.997,
      0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.907, -2.795, -2.683, -2.571, -2.46, -2.348, -2.236, -2.124, -2.012,
      -1.901, -1.789, -1.677, -1.565, -1.453, -1.342, -1.23, -1.118, -1.006,
      -0.894, -0.783, -0.671, -0.559, -0.447, -0.335, -0.224, -0.112, 0.0,
      0.112, 0.224, 0.335, 0.447, 0.559, 0.671, 0.783, 0.894, 1.006, 1.118,
      1.23, 1.342, 1.453, 1.565, 1.677, 1.789, 1.901, 2.012, 2.124, 2.236,
      2.348, 2.46, 2.571, 2.683, 2.795, 2.907, 3.019, 3.13, 3.242, 3.354, 3.69,
      3.801, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.003, 0.004, 0.007, 0.01, 0.015, 0.021,
      0.03, 0.041, 0.054, 0.071, 0.091, 0.114, 0.141, 0.171, 0.205, 0.241,
      0.281, 0.322, 0.366, 0.41, 0.455, 0.5, 0.544, 0.588, 0.63, 0.67, 0.707,
      0.742, 0.775, 0.804, 0.831, 0.855, 0.877, 0.896, 0.913, 0.927, 0.939,
      0.95, 0.959, 0.966, 0.973, 0.978, 0.982, 0.986, 0.989, 0.991, 0.993,
      0.995, 0.996, 0.997, 0.997, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.846, -2.741, -2.635, -2.53, -2.424, -2.319, -2.214, -2.108, -2.003,
      -1.897, -1.792, -1.687, -1.581, -1.476, -1.37, -1.265, -1.16, -1.054,
      -0.949, -0.843, -0.738, -0.632, -0.527, -0.422, -0.316, -0.211, -0.105,
      0.0, 0.105, 0.211, 0.316, 0.422, 0.527, 0.632, 0.738, 0.843, 0.949, 1.054,
      1.16, 1.265, 1.37, 1.476, 1.581, 1.687, 1.792, 1.897, 2.003, 2.108, 2.214,
      2.319, 2.424, 2.53, 2.635, 2.741, 2.846, 2.951, 3.057, 3.162, 3.268,
      3.373, 3.689, 3.795, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.003, 0.005, 0.008, 0.011, 0.016, 0.022,
      0.03, 0.04, 0.053, 0.068, 0.086, 0.106, 0.13, 0.157, 0.187, 0.22, 0.255,
      0.293, 0.332, 0.373, 0.415, 0.458, 0.5, 0.542, 0.583, 0.623, 0.661, 0.697,
      0.731, 0.762, 0.792, 0.818, 0.843, 0.864, 0.884, 0.901, 0.916, 0.93,
      0.941, 0.951, 0.959, 0.966, 0.973, 0.978, 0.982, 0.985, 0.988, 0.991,
      0.993, 0.994, 0.995, 0.996, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.9, -2.8, -2.7, -2.6, -2.5, -2.4, -2.3, -2.2, -2.1, -2.0, -1.9,
      -1.8, -1.7, -1.6, -1.5, -1.4, -1.3, -1.2, -1.1, -1.0, -0.9, -0.8, -0.7,
      -0.6, -0.5, -0.4, -0.3, -0.2, -0.1, 0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6,
      0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0, 2.1,
      2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.3, 3.4, 3.7, 3.8, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.003, 0.004, 0.006, 0.008, 0.012, 0.016,
      0.022, 0.03, 0.039, 0.051, 0.064, 0.08, 0.099, 0.12, 0.145, 0.171, 0.201,
      0.233, 0.267, 0.303, 0.341, 0.38, 0.42, 0.46, 0.5, 0.54, 0.579, 0.617,
      0.653, 0.688, 0.721, 0.752, 0.78, 0.807, 0.831, 0.853, 0.873, 0.89, 0.906,
      0.92, 0.932, 0.943, 0.952, 0.96, 0.967, 0.973, 0.977, 0.982, 0.985, 0.988,
      0.99, 0.992, 0.994, 0.995, 0.996, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0,
      1,
    ],
  ],
  [
    [
      -5, -2.921, -2.83, -2.739, -2.647, -2.556, -2.465, -2.373, -2.282, -2.191,
      -2.1, -2.008, -1.917, -1.826, -1.734, -1.643, -1.552, -1.461, -1.369,
      -1.278, -1.187, -1.095, -1.004, -0.913, -0.822, -0.73, -0.639, -0.548,
      -0.456, -0.365, -0.274, -0.183, -0.091, 0.0, 0.091, 0.183, 0.274, 0.365,
      0.456, 0.548, 0.639, 0.73, 0.822, 0.913, 1.004, 1.095, 1.187, 1.278,
      1.369, 1.461, 1.552, 1.643, 1.734, 1.826, 1.917, 2.008, 2.1, 2.191, 2.282,
      2.373, 2.465, 2.556, 2.647, 2.739, 2.83, 2.921, 3.012, 3.104, 3.195,
      3.286, 3.651, 3.743, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.005, 0.007, 0.009, 0.012,
      0.017, 0.022, 0.028, 0.036, 0.046, 0.057, 0.07, 0.086, 0.103, 0.123,
      0.145, 0.169, 0.195, 0.224, 0.254, 0.286, 0.32, 0.355, 0.39, 0.427, 0.463,
      0.5, 0.536, 0.572, 0.607, 0.641, 0.673, 0.704, 0.733, 0.761, 0.787, 0.811,
      0.833, 0.853, 0.871, 0.888, 0.903, 0.916, 0.928, 0.938, 0.947, 0.955,
      0.962, 0.968, 0.973, 0.978, 0.982, 0.985, 0.987, 0.99, 0.992, 0.993,
      0.994, 0.996, 0.996, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.958, -2.874, -2.704, -2.62, -2.535, -2.451, -2.366, -2.282, -2.197,
      -2.113, -2.028, -1.944, -1.859, -1.775, -1.69, -1.606, -1.521, -1.437,
      -1.352, -1.268, -1.183, -1.099, -1.014, -0.93, -0.845, -0.761, -0.676,
      -0.592, -0.507, -0.423, -0.338, -0.254, -0.169, -0.085, 0.0, 0.085, 0.169,
      0.254, 0.338, 0.423, 0.507, 0.592, 0.676, 0.761, 0.845, 0.93, 1.014,
      1.099, 1.183, 1.268, 1.352, 1.437, 1.521, 1.606, 1.69, 1.775, 1.859,
      1.944, 2.028, 2.113, 2.197, 2.282, 2.366, 2.451, 2.535, 2.62, 2.704,
      2.789, 2.874, 2.958, 3.043, 3.127, 3.212, 3.296, 3.634, 3.719, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.003, 0.004, 0.005, 0.007, 0.009, 0.012,
      0.016, 0.021, 0.026, 0.033, 0.041, 0.051, 0.062, 0.074, 0.089, 0.105,
      0.123, 0.143, 0.165, 0.189, 0.215, 0.242, 0.271, 0.302, 0.333, 0.365,
      0.399, 0.432, 0.466, 0.5, 0.534, 0.567, 0.599, 0.631, 0.661, 0.691, 0.719,
      0.745, 0.77, 0.794, 0.815, 0.836, 0.854, 0.871, 0.887, 0.901, 0.913,
      0.925, 0.935, 0.944, 0.952, 0.959, 0.965, 0.97, 0.975, 0.979, 0.982,
      0.985, 0.988, 0.99, 0.991, 0.993, 0.994, 0.995, 0.996, 0.997, 0.997,
      0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.004, -2.925, -2.767, -2.688, -2.609, -2.53, -2.451, -2.372, -2.293,
      -2.214, -2.135, -2.055, -1.976, -1.897, -1.818, -1.739, -1.66, -1.581,
      -1.502, -1.423, -1.344, -1.265, -1.186, -1.107, -1.028, -0.949, -0.87,
      -0.791, -0.712, -0.632, -0.553, -0.474, -0.395, -0.316, -0.237, -0.158,
      -0.079, 0.0, 0.079, 0.158, 0.237, 0.316, 0.395, 0.474, 0.553, 0.632,
      0.712, 0.791, 0.87, 0.949, 1.028, 1.107, 1.186, 1.265, 1.344, 1.423,
      1.502, 1.581, 1.66, 1.739, 1.818, 1.897, 1.976, 2.055, 2.135, 2.214,
      2.293, 2.372, 2.451, 2.53, 2.609, 2.688, 2.767, 2.846, 2.925, 3.004,
      3.083, 3.162, 3.241, 3.637, 3.716, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.004, 0.005, 0.007, 0.009,
      0.012, 0.015, 0.019, 0.024, 0.03, 0.037, 0.045, 0.054, 0.065, 0.077, 0.09,
      0.106, 0.122, 0.141, 0.161, 0.183, 0.206, 0.231, 0.258, 0.285, 0.314,
      0.344, 0.374, 0.405, 0.437, 0.468, 0.5, 0.531, 0.563, 0.593, 0.623, 0.652,
      0.68, 0.706, 0.732, 0.756, 0.779, 0.801, 0.821, 0.839, 0.856, 0.872,
      0.887, 0.9, 0.912, 0.923, 0.933, 0.941, 0.949, 0.956, 0.962, 0.967, 0.972,
      0.976, 0.98, 0.983, 0.986, 0.988, 0.99, 0.991, 0.993, 0.994, 0.995, 0.996,
      0.997, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -2.981, -2.907, -2.758, -2.683, -2.609, -2.534, -2.46, -2.385, -2.311,
      -2.236, -2.162, -2.087, -2.012, -1.938, -1.863, -1.789, -1.714, -1.64,
      -1.565, -1.491, -1.416, -1.342, -1.267, -1.193, -1.118, -1.043, -0.969,
      -0.894, -0.82, -0.745, -0.671, -0.596, -0.522, -0.447, -0.373, -0.298,
      -0.224, -0.149, -0.075, 0.0, 0.075, 0.149, 0.224, 0.298, 0.373, 0.447,
      0.522, 0.596, 0.671, 0.745, 0.82, 0.894, 0.969, 1.043, 1.118, 1.193,
      1.267, 1.342, 1.416, 1.491, 1.565, 1.64, 1.714, 1.789, 1.863, 1.938,
      2.012, 2.087, 2.162, 2.236, 2.311, 2.385, 2.46, 2.534, 2.609, 2.683,
      2.758, 2.832, 2.907, 2.981, 3.056, 3.205, 3.28, 3.578, 3.652, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.004, 0.005, 0.007, 0.009,
      0.011, 0.014, 0.018, 0.022, 0.027, 0.033, 0.039, 0.047, 0.056, 0.066,
      0.078, 0.091, 0.105, 0.121, 0.138, 0.156, 0.177, 0.198, 0.221, 0.245,
      0.27, 0.297, 0.324, 0.352, 0.381, 0.411, 0.44, 0.47, 0.5, 0.53, 0.559,
      0.588, 0.616, 0.644, 0.67, 0.696, 0.721, 0.744, 0.766, 0.788, 0.807,
      0.826, 0.843, 0.859, 0.874, 0.888, 0.9, 0.912, 0.922, 0.931, 0.94, 0.947,
      0.954, 0.96, 0.965, 0.97, 0.974, 0.978, 0.981, 0.984, 0.986, 0.988, 0.99,
      0.992, 0.993, 0.994, 0.995, 0.996, 0.997, 0.997, 0.998, 0.998, 0.999,
      0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.041, -2.97, -2.758, -2.687, -2.616, -2.546, -2.475, -2.404, -2.333,
      -2.263, -2.192, -2.121, -2.051, -1.98, -1.909, -1.838, -1.768, -1.697,
      -1.626, -1.556, -1.485, -1.414, -1.344, -1.273, -1.202, -1.131, -1.061,
      -0.99, -0.919, -0.849, -0.778, -0.707, -0.636, -0.566, -0.495, -0.424,
      -0.354, -0.283, -0.212, -0.141, -0.071, 0.0, 0.071, 0.141, 0.212, 0.283,
      0.354, 0.424, 0.495, 0.566, 0.636, 0.707, 0.778, 0.849, 0.919, 0.99,
      1.061, 1.131, 1.202, 1.273, 1.344, 1.414, 1.485, 1.556, 1.626, 1.697,
      1.768, 1.838, 1.909, 1.98, 2.051, 2.121, 2.192, 2.263, 2.333, 2.404,
      2.475, 2.546, 2.616, 2.687, 2.758, 2.828, 2.899, 2.97, 3.041, 3.182,
      3.253, 3.536, 3.606, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.004, 0.005, 0.007, 0.008,
      0.01, 0.013, 0.016, 0.02, 0.024, 0.029, 0.035, 0.041, 0.049, 0.058, 0.067,
      0.078, 0.09, 0.104, 0.118, 0.134, 0.152, 0.17, 0.19, 0.211, 0.234, 0.257,
      0.281, 0.307, 0.333, 0.36, 0.387, 0.415, 0.443, 0.472, 0.5, 0.528, 0.556,
      0.584, 0.611, 0.637, 0.662, 0.687, 0.711, 0.734, 0.755, 0.776, 0.796,
      0.814, 0.831, 0.847, 0.862, 0.876, 0.889, 0.901, 0.912, 0.922, 0.931,
      0.939, 0.946, 0.953, 0.959, 0.964, 0.969, 0.973, 0.976, 0.98, 0.982,
      0.985, 0.987, 0.989, 0.991, 0.992, 0.993, 0.994, 0.995, 0.996, 0.997,
      0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.034, -2.969, -2.776, -2.711, -2.647, -2.582, -2.517, -2.453,
      -2.388, -2.324, -2.259, -2.195, -2.13, -2.066, -2.001, -1.936, -1.872,
      -1.807, -1.743, -1.678, -1.614, -1.549, -1.485, -1.42, -1.356, -1.291,
      -1.226, -1.162, -1.097, -1.033, -0.968, -0.904, -0.839, -0.775, -0.71,
      -0.645, -0.581, -0.516, -0.452, -0.387, -0.323, -0.258, -0.194, -0.129,
      -0.065, 0.0, 0.065, 0.129, 0.194, 0.258, 0.323, 0.387, 0.452, 0.516,
      0.581, 0.645, 0.71, 0.775, 0.839, 0.904, 0.968, 1.033, 1.097, 1.162,
      1.226, 1.291, 1.356, 1.42, 1.485, 1.549, 1.614, 1.678, 1.743, 1.807,
      1.872, 1.936, 2.001, 2.066, 2.13, 2.195, 2.259, 2.324, 2.388, 2.453,
      2.517, 2.582, 2.647, 2.711, 2.776, 2.84, 2.905, 2.969, 3.034, 3.163,
      3.227, 3.55, 3.615, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.004, 0.005, 0.006, 0.007,
      0.009, 0.011, 0.013, 0.016, 0.019, 0.023, 0.027, 0.032, 0.037, 0.044,
      0.051, 0.059, 0.068, 0.077, 0.088, 0.1, 0.113, 0.127, 0.142, 0.158, 0.175,
      0.194, 0.213, 0.233, 0.254, 0.276, 0.299, 0.323, 0.347, 0.372, 0.397,
      0.423, 0.448, 0.474, 0.5, 0.526, 0.551, 0.576, 0.601, 0.626, 0.649, 0.672,
      0.695, 0.716, 0.737, 0.757, 0.776, 0.794, 0.811, 0.827, 0.842, 0.856,
      0.869, 0.882, 0.893, 0.904, 0.913, 0.922, 0.931, 0.938, 0.945, 0.951,
      0.957, 0.962, 0.966, 0.97, 0.974, 0.977, 0.98, 0.983, 0.985, 0.987, 0.989,
      0.99, 0.992, 0.993, 0.994, 0.995, 0.996, 0.996, 0.997, 0.997, 0.998,
      0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.048, -2.988, -2.809, -2.749, -2.689, -2.63, -2.57, -2.51, -2.45,
      -2.39, -2.331, -2.271, -2.211, -2.151, -2.092, -2.032, -1.972, -1.912,
      -1.853, -1.793, -1.733, -1.673, -1.614, -1.554, -1.494, -1.434, -1.375,
      -1.315, -1.255, -1.195, -1.135, -1.076, -1.016, -0.956, -0.896, -0.837,
      -0.777, -0.717, -0.657, -0.598, -0.538, -0.478, -0.418, -0.359, -0.299,
      -0.239, -0.179, -0.12, -0.06, 0.0, 0.06, 0.12, 0.179, 0.239, 0.299, 0.359,
      0.418, 0.478, 0.538, 0.598, 0.657, 0.717, 0.777, 0.837, 0.896, 0.956,
      1.016, 1.076, 1.135, 1.195, 1.255, 1.315, 1.375, 1.434, 1.494, 1.554,
      1.614, 1.673, 1.733, 1.793, 1.853, 1.912, 1.972, 2.032, 2.092, 2.151,
      2.211, 2.271, 2.331, 2.39, 2.45, 2.51, 2.57, 2.63, 2.689, 2.749, 2.809,
      2.869, 2.928, 2.988, 3.167, 3.227, 3.526, 3.586, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.003, 0.004, 0.005, 0.006,
      0.007, 0.009, 0.01, 0.012, 0.015, 0.018, 0.021, 0.024, 0.029, 0.033,
      0.038, 0.044, 0.051, 0.058, 0.066, 0.075, 0.085, 0.095, 0.107, 0.119,
      0.133, 0.147, 0.162, 0.178, 0.195, 0.213, 0.231, 0.251, 0.271, 0.292,
      0.314, 0.336, 0.358, 0.381, 0.405, 0.428, 0.452, 0.476, 0.5, 0.524, 0.547,
      0.571, 0.594, 0.617, 0.639, 0.66, 0.682, 0.702, 0.722, 0.741, 0.759,
      0.777, 0.793, 0.809, 0.824, 0.838, 0.852, 0.864, 0.876, 0.887, 0.898,
      0.907, 0.916, 0.924, 0.932, 0.939, 0.945, 0.951, 0.956, 0.961, 0.965,
      0.969, 0.973, 0.976, 0.979, 0.981, 0.984, 0.986, 0.988, 0.989, 0.991,
      0.992, 0.993, 0.994, 0.995, 0.995, 0.996, 0.997, 0.997, 0.998, 0.998,
      0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.075, -3.019, -2.795, -2.739, -2.683, -2.627, -2.571, -2.516, -2.46,
      -2.404, -2.348, -2.292, -2.236, -2.18, -2.124, -2.068, -2.012, -1.957,
      -1.901, -1.845, -1.789, -1.733, -1.677, -1.621, -1.565, -1.509, -1.453,
      -1.398, -1.342, -1.286, -1.23, -1.174, -1.118, -1.062, -1.006, -0.95,
      -0.894, -0.839, -0.783, -0.727, -0.671, -0.615, -0.559, -0.503, -0.447,
      -0.391, -0.335, -0.28, -0.224, -0.168, -0.112, -0.056, 0.0, 0.056, 0.112,
      0.168, 0.224, 0.28, 0.335, 0.391, 0.447, 0.503, 0.559, 0.615, 0.671,
      0.727, 0.783, 0.839, 0.894, 0.95, 1.006, 1.062, 1.118, 1.174, 1.23, 1.286,
      1.342, 1.398, 1.453, 1.509, 1.565, 1.621, 1.677, 1.733, 1.789, 1.845,
      1.901, 1.957, 2.012, 2.068, 2.124, 2.18, 2.236, 2.292, 2.348, 2.404, 2.46,
      2.516, 2.571, 2.627, 2.683, 2.739, 2.795, 2.851, 2.963, 3.019, 3.13,
      3.186, 3.522, 3.578, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.003, 0.004, 0.005, 0.006,
      0.007, 0.008, 0.01, 0.012, 0.014, 0.016, 0.019, 0.022, 0.025, 0.029,
      0.034, 0.039, 0.044, 0.05, 0.057, 0.064, 0.072, 0.081, 0.091, 0.101,
      0.112, 0.124, 0.136, 0.15, 0.164, 0.179, 0.195, 0.211, 0.229, 0.247,
      0.265, 0.285, 0.305, 0.325, 0.346, 0.367, 0.389, 0.411, 0.433, 0.455,
      0.478, 0.5, 0.522, 0.544, 0.566, 0.588, 0.609, 0.63, 0.651, 0.671, 0.69,
      0.709, 0.728, 0.745, 0.762, 0.778, 0.794, 0.809, 0.823, 0.837, 0.849,
      0.861, 0.873, 0.883, 0.893, 0.903, 0.911, 0.919, 0.927, 0.934, 0.94,
      0.946, 0.951, 0.956, 0.961, 0.965, 0.969, 0.972, 0.975, 0.978, 0.98,
      0.983, 0.985, 0.987, 0.988, 0.99, 0.991, 0.992, 0.993, 0.994, 0.995,
      0.995, 0.996, 0.997, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
  [
    [
      -5, -3.11, -3.057, -2.793, -2.741, -2.688, -2.635, -2.583, -2.53, -2.477,
      -2.424, -2.372, -2.319, -2.266, -2.214, -2.161, -2.108, -2.055, -2.003,
      -1.95, -1.897, -1.845, -1.792, -1.739, -1.687, -1.634, -1.581, -1.528,
      -1.476, -1.423, -1.37, -1.318, -1.265, -1.212, -1.16, -1.107, -1.054,
      -1.001, -0.949, -0.896, -0.843, -0.791, -0.738, -0.685, -0.632, -0.58,
      -0.527, -0.474, -0.422, -0.369, -0.316, -0.264, -0.211, -0.158, -0.105,
      -0.053, 0.0, 0.053, 0.105, 0.158, 0.211, 0.264, 0.316, 0.369, 0.422,
      0.474, 0.527, 0.58, 0.632, 0.685, 0.738, 0.791, 0.843, 0.896, 0.949,
      1.001, 1.054, 1.107, 1.16, 1.212, 1.265, 1.318, 1.37, 1.423, 1.476, 1.528,
      1.581, 1.634, 1.687, 1.739, 1.792, 1.845, 1.897, 1.95, 2.003, 2.055,
      2.108, 2.161, 2.214, 2.266, 2.319, 2.372, 2.424, 2.477, 2.53, 2.583,
      2.635, 2.688, 2.741, 2.793, 2.846, 2.951, 3.004, 3.11, 3.162, 3.479,
      3.531, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.003, 0.004, 0.005, 0.005,
      0.007, 0.008, 0.009, 0.011, 0.012, 0.015, 0.017, 0.02, 0.022, 0.026, 0.03,
      0.034, 0.038, 0.043, 0.049, 0.055, 0.062, 0.069, 0.077, 0.086, 0.095,
      0.105, 0.115, 0.127, 0.139, 0.151, 0.165, 0.179, 0.194, 0.209, 0.226,
      0.242, 0.26, 0.278, 0.296, 0.315, 0.335, 0.355, 0.375, 0.395, 0.416,
      0.437, 0.458, 0.479, 0.5, 0.521, 0.542, 0.563, 0.583, 0.603, 0.623, 0.643,
      0.662, 0.68, 0.699, 0.716, 0.733, 0.75, 0.766, 0.781, 0.796, 0.81, 0.823,
      0.836, 0.848, 0.859, 0.87, 0.88, 0.89, 0.899, 0.907, 0.915, 0.923, 0.93,
      0.936, 0.942, 0.947, 0.952, 0.957, 0.961, 0.965, 0.969, 0.972, 0.975,
      0.977, 0.98, 0.982, 0.984, 0.986, 0.987, 0.989, 0.99, 0.991, 0.992, 0.993,
      0.994, 0.995, 0.996, 0.996, 0.997, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0,
      1,
    ],
  ],
  [
    [
      -5, -3.1, -3.05, -2.8, -2.75, -2.7, -2.65, -2.6, -2.55, -2.5, -2.45, -2.4,
      -2.35, -2.3, -2.25, -2.2, -2.15, -2.1, -2.05, -2.0, -1.95, -1.9, -1.85,
      -1.8, -1.75, -1.7, -1.65, -1.6, -1.55, -1.5, -1.45, -1.4, -1.35, -1.3,
      -1.25, -1.2, -1.15, -1.1, -1.05, -1.0, -0.95, -0.9, -0.85, -0.8, -0.75,
      -0.7, -0.65, -0.6, -0.55, -0.5, -0.45, -0.4, -0.35, -0.3, -0.25, -0.2,
      -0.15, -0.1, -0.05, 0.0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45,
      0.5, 0.55, 0.6, 0.65, 0.7, 0.75, 0.8, 0.85, 0.9, 0.95, 1.0, 1.05, 1.1,
      1.15, 1.2, 1.25, 1.3, 1.35, 1.4, 1.45, 1.5, 1.55, 1.6, 1.65, 1.7, 1.75,
      1.8, 1.85, 1.9, 1.95, 2.0, 2.05, 2.1, 2.15, 2.2, 2.25, 2.3, 2.35, 2.4,
      2.45, 2.5, 2.55, 2.6, 2.65, 2.7, 2.75, 2.8, 2.85, 2.95, 3.0, 3.1, 3.15,
      3.5, 3.55, 5,
    ],
    [
      0, 0.0, 0.001, 0.001, 0.002, 0.002, 0.003, 0.003, 0.004, 0.004, 0.005,
      0.006, 0.007, 0.008, 0.01, 0.011, 0.013, 0.015, 0.017, 0.02, 0.023, 0.026,
      0.029, 0.033, 0.037, 0.042, 0.047, 0.053, 0.059, 0.066, 0.073, 0.081,
      0.089, 0.098, 0.108, 0.118, 0.129, 0.14, 0.152, 0.165, 0.178, 0.192,
      0.207, 0.222, 0.238, 0.254, 0.271, 0.288, 0.306, 0.324, 0.343, 0.362,
      0.381, 0.401, 0.42, 0.44, 0.46, 0.48, 0.5, 0.52, 0.54, 0.559, 0.579,
      0.598, 0.617, 0.636, 0.654, 0.672, 0.689, 0.706, 0.723, 0.739, 0.755,
      0.769, 0.784, 0.798, 0.811, 0.824, 0.836, 0.847, 0.858, 0.869, 0.878,
      0.888, 0.896, 0.905, 0.912, 0.92, 0.926, 0.933, 0.938, 0.944, 0.949,
      0.954, 0.958, 0.962, 0.966, 0.969, 0.972, 0.975, 0.977, 0.98, 0.982,
      0.984, 0.985, 0.987, 0.988, 0.99, 0.991, 0.992, 0.993, 0.994, 0.994,
      0.995, 0.996, 0.996, 0.997, 0.997, 0.998, 0.998, 0.999, 0.999, 1.0, 1,
    ],
  ],
];
export const denominator631 = 330301440;

export const gammas = [
  0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17,
  0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3,
  0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43,
  0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56,
  0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69,
  0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82,
  0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95,
];

export const quantile621 = [
  0.06270678, 0.07526986, 0.08784484, 0.10043372, 0.11303854, 0.12566135,
  0.13830421, 0.15096922, 0.16365849, 0.17637416, 0.18911843, 0.20189348,
  0.21470157, 0.22754498, 0.24042603, 0.2533471, 0.26631061, 0.27931903,
  0.2923749, 0.30548079, 0.31863936, 0.33185335, 0.34512553, 0.35845879,
  0.37185609, 0.38532047, 0.39885507, 0.41246313, 0.42614801, 0.43991317,
  0.45376219, 0.4676988, 0.48172685, 0.49585035, 0.51007346, 0.52440051,
  0.53883603, 0.55338472, 0.5680515, 0.58284151, 0.59776013, 0.61281299,
  0.62800601, 0.64334541, 0.65883769, 0.67448975, 0.69030882, 0.70630256,
  0.72247905, 0.73884685, 0.75541503, 0.77219321, 0.78919165, 0.80642125,
  0.82389363, 0.84162123, 0.85961736, 0.8778963, 0.89647336, 0.91536509,
  0.93458929, 0.95416525, 0.97411388, 0.99445788, 1.01522203, 1.03643339,
  1.05812162, 1.08031934, 1.10306256, 1.12639113, 1.15034938, 1.17498679,
  1.20035886, 1.22652812, 1.25356544, 1.28155157, 1.31057911, 1.34075503,
  1.37220381, 1.40507156, 1.43953147, 1.47579103, 1.51410189, 1.55477359,
  1.59819314, 1.64485363, 1.69539771, 1.75068607, 1.81191067, 1.88079361,
  1.95996398,
];
export const norm_distribution_pdf_621 = [
  [
    8, 8.02060302, 8.04120603, 8.06180905, 8.08241206, 8.10301508, 8.12361809,
    8.14422111, 8.16482412, 8.18542714, 8.20603015, 8.22663317, 8.24723618,
    8.2678392, 8.28844221, 8.30904523, 8.32964824, 8.35025126, 8.37085427,
    8.39145729, 8.4120603, 8.43266332, 8.45326633, 8.47386935, 8.49447236,
    8.51507538, 8.53567839, 8.55628141, 8.57688442, 8.59748744, 8.61809045,
    8.63869347, 8.65929648, 8.6798995, 8.70050251, 8.72110553, 8.74170854,
    8.76231156, 8.78291457, 8.80351759, 8.8241206, 8.84472362, 8.86532663,
    8.88592965, 8.90653266, 8.92713568, 8.94773869, 8.96834171, 8.98894472,
    9.00954774, 9.03015075, 9.05075377, 9.07135678, 9.0919598, 9.11256281,
    9.13316583, 9.15376884, 9.17437186, 9.19497487, 9.21557789, 9.2361809,
    9.25678392, 9.27738693, 9.29798995, 9.31859296, 9.33919598, 9.35979899,
    9.38040201, 9.40100503, 9.42160804, 9.44221106, 9.46281407, 9.48341709,
    9.5040201, 9.52462312, 9.54522613, 9.56582915, 9.58643216, 9.60703518,
    9.62763819, 9.64824121, 9.66884422, 9.68944724, 9.71005025, 9.73065327,
    9.75125628, 9.7718593, 9.79246231, 9.81306533, 9.83366834, 9.85427136,
    9.87487437, 9.89547739, 9.9160804, 9.93668342, 9.95728643, 9.97788945,
    9.99849246, 10.01909548, 10.03969849, 10.06030151, 10.08090452, 10.10150754,
    10.12211055, 10.14271357, 10.16331658, 10.1839196, 10.20452261, 10.22512563,
    10.24572864, 10.26633166, 10.28693467, 10.30753769, 10.3281407, 10.34874372,
    10.36934673, 10.38994975, 10.41055276, 10.43115578, 10.45175879,
    10.47236181, 10.49296482, 10.51356784, 10.53417085, 10.55477387,
    10.57537688, 10.5959799, 10.61658291, 10.63718593, 10.65778894, 10.67839196,
    10.69899497, 10.71959799, 10.74020101, 10.76080402, 10.78140704,
    10.80201005, 10.82261307, 10.84321608, 10.8638191, 10.88442211, 10.90502513,
    10.92562814, 10.94623116, 10.96683417, 10.98743719, 11.0080402, 11.02864322,
    11.04924623, 11.06984925, 11.09045226, 11.11105528, 11.13165829,
    11.15226131, 11.17286432, 11.19346734, 11.21407035, 11.23467337,
    11.25527638, 11.2758794, 11.29648241, 11.31708543, 11.33768844, 11.35829146,
    11.37889447, 11.39949749, 11.4201005, 11.44070352, 11.46130653, 11.48190955,
    11.50251256, 11.52311558, 11.54371859, 11.56432161, 11.58492462,
    11.60552764, 11.62613065, 11.64673367, 11.66733668, 11.6879397, 11.70854271,
    11.72914573, 11.74974874, 11.77035176, 11.79095477, 11.81155779, 11.8321608,
    11.85276382, 11.87336683, 11.89396985, 11.91457286, 11.93517588,
    11.95577889, 11.97638191, 11.99698492, 12.01758794, 12.03819095,
    12.05879397, 12.07939698, 12.1,
  ],
  [
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.001, 0.001, 0.001, 0.001,
    0.001, 0.002, 0.002, 0.003, 0.004, 0.005, 0.006, 0.007, 0.009, 0.011, 0.013,
    0.016, 0.019, 0.023, 0.027, 0.033, 0.039, 0.046, 0.054, 0.064, 0.075, 0.087,
    0.101, 0.117, 0.134, 0.154, 0.175, 0.199, 0.225, 0.253, 0.284, 0.317, 0.353,
    0.391, 0.431, 0.473, 0.517, 0.563, 0.61, 0.659, 0.708, 0.758, 0.807, 0.857,
    0.905, 0.953, 0.998, 1.042, 1.082, 1.12, 1.153, 1.183, 1.208, 1.229, 1.245,
    1.256, 1.261, 1.261, 1.256, 1.245, 1.229, 1.208, 1.183, 1.153, 1.12, 1.082,
    1.042, 0.998, 0.953, 0.905, 0.857, 0.807, 0.758, 0.708, 0.659, 0.61, 0.563,
    0.517, 0.473, 0.431, 0.391, 0.353, 0.317, 0.284, 0.253, 0.225, 0.199, 0.175,
    0.154, 0.134, 0.117, 0.101, 0.087, 0.075, 0.064, 0.054, 0.046, 0.039, 0.033,
    0.027, 0.023, 0.019, 0.016, 0.013, 0.011, 0.009, 0.007, 0.006, 0.005, 0.004,
    0.003, 0.002, 0.002, 0.001, 0.001, 0.001, 0.001, 0.001, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
    0.0, 0.0, 0.0, 0.0, 0.0,
  ],
];

export const initial_distribution_pd_621 = [
  0.05399097, 0.05625025, 0.05857921, 0.0609787, 0.06344954, 0.06599248,
  0.06860821, 0.07129735, 0.07406044, 0.07689796, 0.07981032, 0.08279782,
  0.0858607, 0.0889991, 0.09221305, 0.09550253, 0.09886737, 0.10230733,
  0.10582204, 0.10941105, 0.11307378, 0.11680952, 0.12061748, 0.12449672,
  0.12844618, 0.1324647, 0.13655096, 0.14070353, 0.14492086, 0.14920124,
  0.15354286, 0.15794376, 0.16240184, 0.1669149, 0.17148056, 0.17609634,
  0.18075961, 0.18546764, 0.19021752, 0.19500626, 0.19983071, 0.20468761,
  0.20957359, 0.21448512, 0.2194186, 0.2243703, 0.22933638, 0.23431288,
  0.23929578, 0.24428093, 0.2492641, 0.25424098, 0.25920717, 0.26415822,
  0.26908959, 0.27399668, 0.27887486, 0.28371943, 0.28852566, 0.29328878,
  0.29800401, 0.30266654, 0.30727156, 0.31181426, 0.31628983, 0.32069348,
  0.32502044, 0.32926599, 0.33342543, 0.33749413, 0.3414675, 0.34534102,
  0.34911026, 0.35277086, 0.35631856, 0.3597492, 0.36305872, 0.36624319,
  0.3692988, 0.37222186, 0.37500885, 0.37765636, 0.38016115, 0.38252014,
  0.38473043, 0.38678927, 0.3886941, 0.39044253, 0.39203239, 0.39346166,
  0.39472856, 0.39583147, 0.396769, 0.39753998, 0.3981434, 0.39857852,
  0.39884478, 0.39894183, 0.39886955, 0.39862804, 0.39821761, 0.39763877,
  0.39689226, 0.39597903, 0.39490024, 0.39365724, 0.39225163, 0.39068515,
  0.38895979, 0.38707771, 0.38504125, 0.38285296, 0.38051555, 0.3780319,
  0.37540508, 0.3726383, 0.36973493, 0.36669849, 0.36353264, 0.36024118,
  0.35682801, 0.35329719, 0.34965284, 0.34589923, 0.3420407, 0.33808166,
  0.33402663, 0.32988018, 0.32564694, 0.3213316, 0.31693887, 0.31247353,
  0.30794036, 0.30334415, 0.29868974, 0.29398192, 0.28922551, 0.28442529,
  0.27958604, 0.27471248, 0.26980933, 0.26488122, 0.25993277, 0.25496851,
  0.24999292, 0.2450104, 0.24002527, 0.23504179, 0.23006409, 0.22509624,
  0.2201422, 0.21520581, 0.21029084, 0.2054009, 0.20053953, 0.19571012,
  0.19091596, 0.1861602, 0.18144587, 0.17677586, 0.17215297, 0.16757981,
  0.16305891, 0.15859264, 0.15418324, 0.14983282, 0.14554336, 0.14131669,
  0.13715454, 0.13305849, 0.12902998, 0.12507033, 0.12118075, 0.1173623,
  0.11361593, 0.10994247, 0.10634264, 0.10281702, 0.09936609, 0.09599024,
  0.09268973, 0.08946471, 0.08631526, 0.08324134, 0.08024282, 0.07731948,
  0.07447103, 0.07169707, 0.06899714, 0.06637071, 0.06381716, 0.06133581,
  0.05892593, 0.0565867, 0.05431728, 0.05211674, 0.04998413, 0.04791844,
  0.04591863, 0.0439836,
];

export const pointsX = [
  [9.31, 0],
  [11.86, 0],
  [9.49, 0],
  [8.2, 0],
  [9.51, 0],
  [11.27, 0],
  [10.4, 0],
  [11.27, 0],
  [10.44, 0],
  [8.75, 0],
];
