export const distributionNormal = [
  -0.3418921871695516, -0.8986826246406412, -3.0881028640234653,
  -0.9006987454304813, -0.717670648379731, 1.40376421445629, 0.4737898448821807,
  -0.08988117741882443, -0.6451719610526458, -0.5558506071771383,
  0.2846101695391897, -0.1416898337857853, 0.6553008138853216,
  0.2512498487751278, 0.44674536032249607, -0.9987247221698422,
  0.6868298844539518, 0.36267085894069806, 0.1767140959773039,
  -0.5766748538623954, 0.07852190017810448, 0.5172144829946482,
  -0.4068461194752386, 0.6070144787861804, -1.060983991852997,
  1.6652215262452679, 1.7900363341863617, 1.1575272322558794,
  -1.3903070977687049, 0.3057516441435917, 0.9664871933747966,
  0.25702445437854343, -0.8257736765272129, 0.9368768926896178,
  2.3338681971341195, 1.478341092800499, 0.4557781078642388,
  0.009497245966433372, -0.3603297707740011, -0.9905895214651321,
  0.03777113412111671, 0.0413602559078546, -1.355981904471098,
  -0.010738996538829496, -0.13100774250659503, 0.821108602877325,
  -0.10827971893784294, -1.5795384526794387, 1.4557576494350781,
  1.6171345895842406, 0.10557540498821144, -0.16984100913390734,
  -0.1691706507810616, -0.055221403026467455, 0.43054410200332494,
  0.5114156499404413, -1.3361494686455062, 0.9164973266003065,
  -2.641413806791682, 1.3978887643289553, -0.9603979170788337,
  -0.06647212968433511, -1.8858221240121513, -0.8495984243340374,
  -0.30352941827393215, -0.7512600490996771, 1.1796766743660965,
  -0.08897662293723305, 0.773219932586633, 2.055048241863479,
  -0.11767958555251429, -1.0515408236075652, 1.7313466810139337,
  0.6803887601595294, -1.0445141456874023, -1.471380899973624,
  -0.02785580946858106, 1.136975484054726, -0.18129493110938838,
  -0.1572679312552213, 0.6484396660842198, 0.8001285848161579,
  1.291706156870236, -0.36603190689519316, -0.2889287268550862,
  0.7155841799065795, -0.7509566573110303, -2.2426632982946875,
  0.5846700120776959, 1.0892175169162859, 0.6443719811065135,
  0.4411864416667742, 0.9581864472969241, -0.19321051049861082,
  -0.4003472707911239, 0.019652638222766246, 1.1017429221107233,
  0.2400575644735713, 1.5243360923804001, 0.662172637513464,
  -0.24960145563591693, 0.5532696261209334, -0.5297379576597397,
  -0.8444177379394684, 0.36359429482351147, 0.849915327163551,
  0.5448086075153032, 1.3359793467729302, 0.6300136041389314,
  -1.3145205341219601, 0.22235240544714155, -0.7325814917430783,
  -0.2237137162317532, 0.3369214798868813, -0.5340642728130512,
  1.0102465472177882, -0.4500524936396984, -0.969107361582175,
  -0.38918697394616014, 0.20086519740942121, 0.6909598925648655,
  1.1822334619648622, 0.01700448034094891, -0.15732379236319227,
  -1.7410003959310714, -0.16530874023459569, -1.4037669883082593,
  1.3515155678468036, -1.1184146805282809, 1.1301461309828886,
  1.1911978187215586, -0.7132881573771366, 0.8702982020399463,
  -1.0321542398508767, 0.29635355658161183, -0.14279979141269294,
  -1.824147917480067, -1.3308567184312403, -1.1928105420681112,
  -0.45936864214000583, -0.8572562549614265, -0.13900774233331914,
  -0.5058209655005494, 1.033993649294386, -1.1185728987786299,
  0.9814878217960422, -0.7914717999285065, 1.0075193139939254,
  -1.2049521285796594, 0.4048115798451023, 0.5397284322880326,
  -1.3479354046824532, 0.014627872096035777, 0.9434661911900092,
  -0.5476471684973838, 1.1317021534821379, -0.7487243313875369,
  0.755217693914766, 0.23518888787125236, -1.1234015121719796,
  -1.20128378367513, 0.7020371505633225, -0.32012993341268864,
  0.2641266376925544, -1.7865417693889842, -0.04241466599874931,
  -1.4134725085891855, -0.3736534734270455, 0.9312750093691513,
  0.3071274056514838, 1.4545333353353522, 0.8439568205483413,
  0.5430615173584307, 0.4992078417017178, -1.301603411196755,
  -0.2844188462210778, 0.6896070040442372, -2.055614703532594,
  -0.23396523143777354, 0.4359773546475129, -0.3513840640291636,
  2.528065584337478, 0.6171336076208285, 0.031826104694294595,
  -0.4921839470569672, 1.1101402185605795, -0.18430058284180828,
  -0.9057467261767975, 0.32236923227537534, -1.2096233011427053,
  -0.190030151170155, 0.17174315823153108, 1.0227209457227378,
  0.9789320906403596, -0.5518570473615032, -1.0932119892237546,
  -1.428306643615682, -0.9138548984464274, -0.1446055773993611,
  -0.05113305429438589, 0.1254194028491339, 0.8180774753711372,
  0.1768984660738746, -1.2191504067660408, -0.98537615039815,
  1.0469400996386848, 1.3869396920242656, 0.831355563025278, 1.1301884542752596,
  0.19753457726360185, -0.1911348694822489, -0.1473800930907392,
  -0.11980508227399182, 0.4971720965824175, -0.9448635922878917,
  -0.8467078586870687, -1.1743896963670148, -0.69911472912262,
  -0.9717561143052726, 0.668932806762574, 0.7737985861165324,
  -1.3283212819320913, 1.1395866330545865, 1.437468184599108,
  1.4961372086416322, 0.16966531574597804, -1.4779324277493484,
  0.8418766275997461, 0.8326757272716493, 0.07820713043293064,
  -0.18532739627850245, 0.10456265113712339, -0.7930341670964386,
  -0.30731811096535566, -2.015971084890435, 0.2169874051730762,
  -1.7743048234466336, 0.24349706135329588, -0.595700659572948,
  -1.1956736955176654, 0.6365816968937039, 0.10206635880966705,
  -0.43486782176094685, 2.173776943439012, -1.3609833252630974,
  -1.5976356884125573, 2.2118219452223027, 0.7490919902824493,
  1.6526466460884568, -0.7148269659811292, -0.10866657961536073,
  -0.9599814039662145, 0.08107362488815702, 0.41028776729227157,
  0.9744766961569512, -1.0790493656687594, 0.9997100117718721,
  -1.5481551301369494, -0.5228457451143687, -2.035467603135052,
  0.6429176433678084, -2.409188359133286, -0.030390963018321074,
  0.3826919159241599, -0.05271681089204136, -0.12313385743907082,
  0.34921054705744925, -1.3259841265385106, 1.0366252986673063,
  0.33505465019295155, -0.9933359411275606, -0.879790065696935,
  -1.105980182641805, 0.7154364875951627, -1.1317369933966643,
  0.07561805484400076, -2.0339141352734122, -0.21169054904940485,
  1.1365692834432148, 0.08956730628853822, 0.6015737865515112,
  -0.5015141719786398, -0.2950080782708673, 0.5039913546804644,
  0.20313643781007046, 0.6411160112864716, 0.9488678924841093,
  1.1503011978423228, -0.9295172345326072, -1.2488711176788614,
  -0.049140143433193395, 1.895376100990365, -0.7860376302194232,
  0.767227197868674, -0.25027506421502876, 0.4227271611570809,
  -1.5360582405001242, 2.0526942572821496, -0.21622969176816126,
  2.2884796338567632, -1.2821386902799572, -0.005404732468435321,
  0.10263540318428416, 1.0437261743160358, -1.4538086493606026,
  1.9434511735090934, -0.9422777933529759, -0.720204442396397,
  0.7643526760165814, 0.0033547783088652455, -0.7712652411960509,
  -0.9677772623823757, -0.19677501390477956, 1.5369145910597666,
  0.5468229196877138, -0.09450874512982578, -0.8589218931137673,
  0.45145004076917594, 1.3865186134530258, 1.4706362264653954,
  0.3688256608388633, -0.27099241513582806, 0.870826342773682,
  0.10614834424201464, 0.28019168422425356, 0.02451153093540484,
  -0.2986352990609701, 0.1368472135484014, -0.08419721537284762,
  0.8571353233081975, -1.0557662509619408, 0.06366745226869312,
  0.05835888703709539, 0.05787989541612299, 0.30125711206139494,
  0.014925557441009523, -0.9017314535715608, 0.8349928468745708,
  -0.3386480294832148, -0.25331248321309235, -0.06334232969679883,
  -0.3376829242949902, -1.1678574149458671, -0.12515016365930162,
  -0.48785329270407796, 1.5292248282673593, 0.9967417222377898,
  1.493137874416932, 1.3156356064339942, 1.268996103565, 0.7478850413498895,
  1.2481918537992707, -1.6581687721070575, 0.8924190468176093,
  -0.012829269914008198, 0.3547762246590987, 0.6957507808293815,
  0.16357557330098912, -2.2649889783984407, -0.4152548724930556,
  -1.0415909623587287, -2.2549033065399877, -0.8097468541692154,
  2.0269823144162604, -0.8681629094540374, -1.999295400606648,
  -0.4891895105069949, 0.6262710987144114, 0.1829552096479833,
  0.6569887338711348, 0.04307139491707903, 0.02965789378275766,
  -0.148404420994857, -1.2398645853616337, 1.1263454635691204,
  0.3328553925520513, -1.747028221890706, -0.22717316985595776,
  -0.7395756022365301, -1.018252972260901, -0.1422050811333758,
  0.22410551025303915, 1.515921637437184, -1.7101267448470125,
  -0.11240284264863577, -0.29797528335308443, 0.6895707504488408,
  -0.05470244510863033, -0.4039387968333577, -1.379630015392619,
  0.8254169427865021, -1.9657190351503995, 1.3323813027929703,
  -0.585230232357798, -0.8271049407689702, 0.005725392407447445,
  -0.9911543446063732, -0.10559067379230726, -1.3555741349701027,
  -0.13288554687319373, -1.1044205725331877, -1.6440206956167491,
  1.2238409589942558, 0.9703087290177285, 0.4934081855070729,
  -0.28017325366954193, -0.4124598744470492, 0.587886463541312,
  1.3627368015119812, 0.6088804890081905, 1.0272905797624197,
  2.0544567077575224, -0.40628035475792706, -0.2767517676471085,
  -0.16400555525611368, 1.7988225714261494, 0.9153655691208393,
  0.09047507486661101, 0.06669004747570037, 0.29451775496318333,
  -0.5805300894744465, -0.15186439504286645, -0.6706861786257273,
  -0.32725117917858054, 0.33475279546147707, -0.07959373523789127,
  -2.4476536546619383, -1.6460047175493642, -0.7213701656767757,
  0.3035348368291366, 0.2913288946632596, 0.5035670462883998,
  0.7793176059308929, -0.38507933581637366, -1.469495023313651,
  -0.6806669295958463, -0.3617345070773161, -0.30806730285104095,
  1.2508533608180252, 0.5247764397497445, 0.31977883463461887,
  -0.18978000773949477, 0.36410334817832984, 0.34241381965287687,
  0.17905861167918172, 0.8848851158756516, -0.9622756276765938,
  1.226701345266259, -1.3005289830078721, 0.9873856033121609,
  1.4503215399162108, 1.5439864340624456, 0.8667728632464266,
  -0.32100594368350466, -0.6324759488785624, -2.1050917761446963,
  1.2170082710677035, 1.0282870763057985, 0.38184798886191595,
  -0.7071681807866405, -1.1348676294033522, -2.582321030581277,
  0.5062971471939579, -2.5651294300472434, -0.32849773817982475,
  -1.5614928397201269, 0.5565187307646484, 0.8170212413195811,
  -0.5568458940204483, 0.25968942310389415, 2.539129031777172,
  -1.370677287696887, 1.2122855093509504, -1.0872325486408394,
  0.39000888072119433, 1.7407584518808386, -1.1132705451820295,
  0.05574729706487894, 2.214952089935318, -0.5670840012371726,
  -1.1535643165399305, 0.5865236007084808, 1.8713059914191474,
  -2.0508859560600254, -0.2350310985824593, 1.5764648319765298,
  -2.033904117848185, -0.2030676074598142, -1.4710128622251046,
  -1.5000737228316126, -0.06563710521295453, -1.6682784033601425,
  -0.15813533850438888, 0.935452506039077, -0.3454869965086116,
  2.201847767161608, 1.6597497034223667, -0.35739977552065894,
  -1.910324885501336, -0.09334004360914633, 0.09270414785218008,
  -0.6334925781416437, 0.08149573430523639, 0.7540244740630635,
  -0.9357183934661509, -0.9005541146780482, -0.2684738406051562,
  -0.9584547826222454, 0.810956522511548, -0.49363931241416253,
  -2.1981437000424005, -0.9388747445452982, 0.2628159787336906,
  0.7596450740977593, 0.47113813492696205, 1.276583712441192,
  0.5263760666693683, 0.6530539790364979, -2.592113923370433,
  0.3100880758493305, 1.3080662192688473, 1.9442507109322775,
  -0.4821130207276629, -1.2050185695431532, 0.09564806417068787,
  1.157032107579801, -0.0665282860677582, 0.5459598783737348,
  0.15675590997247102, 0.18610448022988962, -1.976500063369669,
  -0.3604640062209606, 0.9431876562498914, 1.6994929485809975,
  0.9068182428775716, 0.12318924623115733, -0.14508968931279762,
  0.021119811689228874, -0.36828653909173337, 1.4628872948022085,
  -1.3592592118145144, 1.3859036338912354, 0.5213352775797047,
  0.35945557754375085, 0.7044396267836338, -0.346041226665301,
  1.4687039948853786, -1.0705439687027738, 1.5517100803686656,
  -1.4237613820045225, 0.18277783756035876, 0.10766218092304287,
  -1.3847428461276667, -0.19527729728038762, 2.190979566706451,
  -0.7063856833125997, 1.390307976015239, -2.127930662539644,
  1.9330964141995592, -1.4234852973838317, -0.8778857502775395,
  1.0131385416433083, 0.5567002285094458, 0.13675198522825086,
  -0.794418327065154, -1.7771195343959365, -1.0465586190866498,
  -1.2700860313967117, -1.4850570480716943, 1.057012760376131,
  -0.8391097243340367, -3.3580184697643682, 0.5712969781231272,
  1.250034243157381, 0.25587839566816045, 0.1643330434973754, 1.422995241129634,
  0.24193948502156676, 0.5851424331134887, 0.12031940308342, 1.7607818189100588,
  0.25371214667767694, -1.3459867146389268, -0.32921085015949475,
  -0.2566363649338995, 0.8487423855810805, 0.08450920814058675,
  0.23002661515436004, -0.2742883103608765, 0.9170841865844137,
  -1.1286828948284546, -1.1648528116028714, 1.330229362530723,
  -0.06324109142144291, -1.3701359750273563, 0.7741669709871155,
  -0.05379023403617729, 0.7698957435049717, 0.5927740402909109,
  -1.1184880561623578, -0.5438623872723881, -0.22292027179969875,
  1.8237031162695156, -2.644552545591267, -1.2794165704199625,
  1.347579453108823, 0.8119922378554071, -0.846351060528045,
  -0.32386790963903817, -0.02504109208728223, 1.1718622050456686,
  0.8693346408006791, -2.4824194777695245, 0.8269516412585554,
  0.881794384726876, -0.09722239565571912, -0.19668741959266797,
  0.5429822016871675, 0.3995303675653563, -1.1493015375588194,
  0.7618925885108949, -0.690237032096724, 0.3909386485501705,
  1.0601764340662834, -0.9000775579303577, 0.8860282905813684,
  1.4073970442665145, -0.8527438870244737, -2.111517788367487,
  -1.2520839135664288, -0.8559552110708369, -0.8980818866424436,
  0.3111071455900797, 0.4026082306956354, 0.5634439679409041,
  -0.5699246599769087, -0.11921367607368895, -0.4895168328403424,
  -0.1424988608739104, -1.844802200663518, 1.1748649501148791,
  0.291042912277906, -0.380268623108895, 1.2996426457919026,
  -0.18550349107083403, 0.7039756774077073, 1.0213320953384804,
  0.5596772593474744, -1.0433258910508243, 0.025763545104602385,
  -1.785316941766451, -0.0769230706952681, 0.07833727014291432,
  0.9400474906665852, 1.531688490015928, 0.4699010388816777, 0.4936527594634882,
  -1.313869512930249, -0.0026074929568411153, -0.4531703926787815,
  0.1853107596445809, 0.2907184306301419, 0.6387591078971554,
  -1.0719528977006174, 0.6225283751894173, 0.3661415438156571,
  1.2180529088340792, -0.2267945969239956, -0.1698645061197323,
  -1.1193068066473177, -1.0040435820665805, -0.9004260903243133,
  1.1092885081614683, -2.229137501345648, 0.15718423860576378,
  1.2890022479979413, -1.5332267672892983, 0.2445209096780864,
  0.17580809649128262, 1.0463024284028095, 0.6041483385540749,
  1.843748175264229, -0.3760629784382259, 0.4096529291416112,
  0.08824769164738239, -0.7704794112850306, 0.14314271076958082,
  0.6622231880878797, -0.1932631565486338, 1.6866015384219823,
  0.797400616106377, 0.03613599689505813, 0.08312358020515541,
  -1.0586704119406807, 0.31625537068118637, -0.3664218122401308,
  -0.029475054606222288, 0.611022760681278, 1.0710424010495065,
  0.14791109429596558, -0.5764604438442789, 0.6952759575903392,
  0.08112634432477776, -0.2865617939566154, 1.5177869457905406,
  -0.3369226208219936, 0.9362985091231961, -0.45166871157576977,
  -0.7666062043585659, -2.1325652216748736, -1.0349025773015663,
  -0.02812840808541247, -2.0138041404974096, -0.40508743886299114,
  -0.4376289101911133, 0.06182054397357033, -0.39269896397480636,
  -0.19309049267380254, -0.28370108236463704, 0.6483585769979149,
  0.8512448126940748, 1.0638071824489788, -0.3469517096465286,
  -0.4235107111378846, 2.2335434583242373, 1.1282228084436419,
  -0.08180112549903873, 0.2447196640548961, 0.3834365344171009,
  1.1807965618986873, 0.2849157145826339, 1.00313925962437, 2.707621470832407,
  -0.13069355991365564, 0.6439011715385993, 0.12098046354882384,
  1.6257496854574462, -0.03259012912149525, 0.29665423351889325,
  0.8962840853101919, 0.9160690469520508, 2.0571536575435787,
  0.8172833173893155, -0.6436403720490766, 0.5409740081769875,
  0.5054039806404602, 0.4266225998563543, 0.6185125020594274,
  -0.09533628229270841, 1.42487470124902, -1.6185390325892337,
  -1.5564363245323953, 0.905309029109385, 0.20101692445966612,
  -1.188104204816502, -0.916950939452778, 0.317864061785948, 0.9488474475455947,
  1.460724933324557, 0.0720705587786221, 0.5654743072978635,
  0.48275315154808257, -0.7160631669160955, -0.5034801779926111,
  0.4691185007943106, 0.8676566408762251, 0.7997049870579012,
  -0.005264995820596603, 0.8234939172409372, -1.6086483488799064,
  0.21887993202007835, -1.0470118503656127, -1.5612779216642236,
  1.1444332551127623, 0.9721594165768122, 0.8219335918019415,
  -0.7906524381748067, 0.5600143278078334, 0.5649235379105294,
  1.0274308977352795, 0.022830477662724533, 0.37971665600834176,
  -1.3202659203296327, -0.4932950460203464, -2.806525588935628,
  2.282959194436944, -0.3710371065308417, 1.1815171796022799,
  -0.7455811809855747, -1.5159286331120214, 0.9454592425309096,
  1.4074957817679405, -0.8179726226271691, 1.4209333632722139,
  -0.13358234645116435, 0.9340335516395152, 0.031744094699880404,
  -0.46123056923824723, 0.7957968477654942, 0.6929492864074037,
  0.06845436820750585, 1.7181169782408676, -0.12064376300847379,
  2.74696110341663, 1.203004097552333, 2.107304032130555, 0.050649321819576376,
  -2.381385587743135, -1.0844413449725225, 0.7430295969155992,
  -0.7137876693126748, 1.9721634065349332, -1.181558737376361,
  0.1079268802355769, -2.3586407481145857, 1.9277285599401734,
  -0.3242702389551905, 0.6543839221666885, 0.46998513413400983,
  -0.37208236579177617, -0.4706257734419077, 0.14278393092249697,
  0.5442379283753384, 0.39696748704838763, 0.6855504666429274,
  0.49245327690901025, 0.9986859133542123, -0.02130240638757692,
  -0.7995860585590879, 1.2324286355643983, -1.0075476612234737,
  1.0432633582629185, -0.2841410237075363, -0.7221719562735425,
  1.8991852134035048, -0.8421665124655432, 0.45868973376521466,
  0.25680912962124663, 0.6596149530615004, -0.833480179483581,
  -0.28487472631819716, 0.7008092551601718, 0.6809855608371616,
  0.20943705634358498, 0.7341849815121833, 1.3703024659134597,
  -0.020549257160111793, -0.8118909163988015, 1.821575897022949,
  0.8763227278507477, 1.2610655233646806, -0.9159679136364693,
  -1.36219977822845, -2.4025474768639667, -1.0347065835015603,
  0.48125527886761493, 0.4182256746987168, 1.4622153900591037,
  0.5240048087682784, 0.19279168445972847, 3.132866035534436,
  -0.07426947489582898, -1.1814006006725328, -1.3010485567201966,
  -1.055569719499688, 0.9809055761668111, -1.1737709463533321,
  -0.5501891718423831, -0.9929634832078579, -0.6284474723432502,
  -1.8875418412288096, 1.2575644985102157, -0.19027951295993642,
  0.18837937025507925, 0.5063366933481535, -1.1929470526718329,
  1.977582470384113, -1.0687469170641386, 1.2369843080490737, 0.800100372704197,
  1.5289157453893718, 2.6238106532572516, -0.8882042599045592,
  0.3570717997894521, -0.7982625421849712, -0.418540071698094,
  -0.5846521553086337, -1.933845538166009, 1.4963082706110362,
  0.03394242461123491, 0.22765338242117117, 1.1931830125601726,
  1.6314386471448776, -0.3862529828219108, -0.28214233568130365,
  0.5128706561020234, 1.5479053881779663, -1.7058338049467274,
  -0.9912814774958804, -1.3663453703321808, 0.24439408800249857,
  -0.47241275522383414, 0.6793662371634147, 0.3174063850103378,
  -1.265290912266408, 0.5693516622972172, -0.6795060918469739,
  -0.4129921661204505, -1.3601259346466987, -0.21079110856702796,
  -1.2507114338859948, 0.43481907603543807, -1.0273334752595482,
  -0.6825845736169921, 0.003024194951049425, -1.7649373583251595,
  -3.7041161840010806, 0.6065327295665922, 1.0642099965321263,
  0.32562234226273146, 0.05572029550371465, -0.48704336711318774,
  -1.1713836615995168, 0.6649802137246819, 1.0003250318141126,
  -2.050829500974512, 2.724146314466315, 0.7720150324635312,
  -1.8242086713275552, -0.005663278733851664, 0.5024272391061243,
  -0.27791512692125925, -0.32870234327495496, 0.9464985582429285,
  0.026726944977364293, -0.37127586203402857, -0.8781290322716423,
  1.252291384042003, -0.4189658856050381, -0.11579322562061761,
  0.20173410774497078, -1.0970376473671715, 0.109588679051488,
  -0.07796264284515632, -1.9912517069750317, 1.5534353809108448,
  -0.9353634248929856, 0.006537431555185744, 0.572306857411116,
  -2.433596954055664, -0.49755447288470284, -0.6114444382192107,
  0.701253933845388, -0.3651940222120151, -0.0845137497071119,
  -0.7669259126721452, -0.4580292230469413, -0.4235650798555778,
  -0.6587844774897361, -0.5773793876916555, -0.6629770483104656,
  -0.29281676086037284, 0.38995004805357397, -0.038616416145381395,
  0.5060985063116443, 0.7134611846868143, -0.7043411176373893,
  -0.3809883471623776, -0.44792826726769563, 0.1921813711591105,
  -0.40273321982481874, 1.7510145988432564, 0.16374641965840486,
  1.2556722972832706, -1.598263695985964, 1.2188128153949351,
  -1.5103216894588862, 0.08429920464386097, -0.6421515070148776,
  -0.4163174730382204, 1.2774456071131377, -0.7787201557087395,
  1.0462332606337483, 1.385876994212633, 0.4901339248521456,
  0.01800009823998707, -0.11246724064387643, -0.06919732010475033,
  -1.196909861784983, 1.3474037686609668, -1.0238686957372225,
  -1.665495925986953, 1.706702377458176, 0.09491192766975186,
  -0.9033364016494086, 0.5368373301523252, 1.2722929816105937,
  -0.9793626631014347, 0.5147570486330636, 0.45768343173689346,
  0.43585636334302935, 1.0055580560830912, 1.1011949129995922,
  0.5293400055118311, -0.9030138201478403, 1.2192799319997465,
  -0.9347162301311013, -0.21853006415998033, 0.7752300794789788,
  0.4242633296761916, -1.2989954732414086, -1.1955004334407906,
  0.9194832159782047, -0.44407309369749337, -0.252004359164036,
  0.6951412465067427, -0.01972020747901226, 0.525439225527475,
  1.0158265651217708, -0.8041572753119356, -0.5927996271295651,
  -0.03324977257493222, -0.6243927232676426, 0.05421373343158656,
  0.058937283227106736, 1.0347201091702571, -0.10381180994945673,
  -0.021131374749928312, 0.5665471543611403, -0.4303192181644554,
  1.4306776294262415, -1.4242759365749746, -0.9039725751556009,
  0.026423318059339115, 0.9543770822800243, 0.4509251646720769,
  -1.4484404088107934, -1.2402150688471303, 1.354634318812119,
  1.2803591543656283, 0.5458858183204658, 0.5438469143058885,
  0.09805653600035832, -0.46919029813677215, 0.8388152752125237,
  -0.4726474645195569, 0.9005380888572402, 0.7245957109093166,
  -2.8023763293160378, 0.0652644015586876, 0.09673599467725448,
  0.9116275925419678, 1.2469645106555876, -0.6538010736071496,
  -0.575053573643497, 0.5178882965639269, -1.743460421308223,
  0.11076430214946324, 1.2608963846311656, 1.2154610061700948,
  0.5140408459250694, -1.139347502616684, 0.16431865849966767,
  0.38279595427753366, -0.23732846876221886, 0.12597572575095384,
  -0.5793863765211181, 0.451154009625756, -0.010163904682451054,
  -0.09770153910749314, 0.9520729064272483, -0.17402416152884181,
  0.5718048349402289, 0.3462359451012841, -0.4844411978287552,
  0.2599845020490705, 0.02776707763964877, 2.0279798395042437,
  -0.27766233988574357, -3.026354469227653, -1.0601162793013732,
  -1.1369743186739647, -0.012600809266291018, 0.9662753351083686,
  0.46643201011874147, 0.22123482939383896, 1.1333790897355587,
  -0.30007913514950496, -1.409932698986431, -1.5975646817884852,
  0.632249411805311, 0.8994754256378722, 0.26203724171313747,
  -0.5715727117213912, -0.6165491028209406, -0.6102838348467994,
  1.2138943453194933, 0.20876610258341455, 0.7336646845002585,
  1.3647526505469096, -0.30979125794248114, 0.03243301082448462,
  1.278125667013589, 1.389027934609947, 0.6617243439447995, 1.5031236461439432,
  -0.30450845524782916, 0.1510176824378588, -0.9912654284832632,
  -0.8942048249626113, 0.534328025185459, 0.4400723042713751,
  -0.7400461712095363, -1.6254537359685908, 1.3007039606728465,
  -0.4773119215625449, -1.0443671973598252, -0.16511695440734078,
  -1.6097893313508118, 0.3514342766114063, -0.4258987679389323,
  -1.151624486016472, -1.254137938268824, 1.0498616928026563,
  1.2518861476660839, -0.18799004264936733, 0.7817302663601842,
  -0.7761277415860235, 1.3286615732889624, 1.2005759589683143,
  -0.4372100790064065, 1.8318568701340545, -1.040051415360931,
  -0.5091647656532684, 2.865788827640669, -1.0482613629860684,
  1.1678428335457331, -0.6277427450476842, 0.45717594361128555,
  0.4367312589407843, 0.429351743397351, 0.8982037141739442,
  -0.42608953186204035, 0.20464843523780166, 0.44102221669863634,
  1.5297701016680072, -1.2161164538385407, -1.0441132449049608,
  0.16494047898438752, 0.0229201296308132, -0.29189793206227926,
  -1.2107038317997347, -0.10372799345139613, -0.014383789220063493,
  -0.7182943780730057, 0.8968550175376648, 1.2686010325657926,
  -0.4148454408944651, 0.5070241332142221, -1.8519275330712328,
  1.1168780393800686, 0.32108351330141793, -0.0001531403749889894,
  0.45942319183429403, 0.6758048041157817, 1.8703278610582856,
  0.6087186190494173, 0.37065190857966873, 1.1718860737392311,
  1.4275615546915026, 1.1568761035959938, 0.8152457735544675,
  1.2731556600668004, 1.3829670644634544, -0.7341004516416236,
  -0.6757270376682534, -0.6397453681757512, 0.9764066128217388,
  -1.3049805742307568, 0.5647599966120741, -1.0503875379744718,
  0.15223297494993127, -0.5189457869817498, 0.35793127123955815,
  -0.5419720150806997, -0.5413226999450622, -0.11037173543962833,
  -0.9521130178270287, -1.4705082729330103, 0.6141610490575243,
  -0.7682108259825562, 1.4121680709898243, -0.3889887421945005,
  1.1514594008593273, 1.3740943849515883, 0.05855334274992438, 1.49578509885428,
  0.13022806750609672, 0.687306824026861, 0.16298879547697323,
  -1.341202668321422, 0.6839527109854041, -0.25343520504607236,
  -1.5839832166245462, -0.8266598642718894, -0.17274825525160162,
  1.1183463879752154, -0.3123578636782909, 0.39211834041042415,
  -1.8782948908353567, 2.8603681332431985, 0.2039637253236458,
  -0.650234857043431, -1.445168702797927, 0.6839257067084518,
  -0.28399759306404637, 0.7046935391684638, 0.3144151188897339,
  -0.5159095089193161, 0.3674699010488761, -1.302683144057609,
  -0.6034135367716089, -1.5767100715662106, -0.9991869059559011,
  1.0876205547057924, -0.6041386575754655, -0.8697264285525543,
  0.5321005069395683, -0.7219863799392229, -1.2582194189180669,
  -2.7504137819991548, -0.8316638750423198, -2.4210969898348824,
  -0.4588481388653176, 0.31048153746049584, -0.6542785102442975,
  2.044476284477113, 1.5691379478670442, 0.012205816741537675,
  -0.7784824262878118, -0.7896195130885627, -0.549600955960484,
  0.1711239827727575, -0.6884036199196142, 1.1510840049085627,
  -2.4473424822749004, 0.3287591641584306, 1.4538119194169385,
  -0.9280528652813936, -0.4495785482052019, -0.870935656199374,
  1.0491223336688784, 0.7368696226634475, 1.4105353103125393,
  -0.31839457703424384, 1.4577036109870758, -0.23476983266387544,
  -0.12008436586645187, 0.8520424100298055, 0.09997381445387882,
  -2.1482143653601247, 1.261147806384342, -0.8149002050531425,
  -0.8634224242457712, 2.888737679443549, -0.3213755400157835,
  -0.5362414801070576, 1.251211820493888, -0.7775650499270291,
  0.3159693865101134, 1.8008755038917676, -0.7160563509159737,
  -0.0908967987486171, -0.7555215032052575, -1.2924810842560017,
  0.0783941992368832, -0.6287794580723439, 0.19641215493764472,
  0.014222881626757725, -0.03362246602093697, 1.4800944059009151,
  1.445092159064732, 1.1944446991295978, -1.5492668867973232,
  -0.9149996115643054, -1.6671243946364707, -0.813043301328872,
  1.3600039635699495, -0.8942496228251954, -1.9159186356982496,
  -0.6029585685440615, 0.9104498513630487, -0.40720216639054574,
  -0.31145013175326186, -0.23623841156852388, 0.129803894536931,
  -1.5181328936495448, 0.5277249121738542, -0.5451232619408913,
  -0.8898249895503226, -0.11548518810418872, -1.5320775162580977,
  0.5630505108584493, 0.1086453345450561, -1.7985599527735125,
  -0.4431844542537107, -1.4581929437386563, 0.8825950301507759,
  0.6716315559339164, 0.4201069830563508, -0.8682360497495908,
  -0.5232962838956929, 0.5267673896865831, 0.7464610861049644,
  -1.2906207623445214, -1.9053908258160974, -1.4252821577210817,
  0.5060034191040725, -1.241250149696326, -1.9417424706866937,
  -1.2742600894578038, 0.2970321354452442, -0.8918041541411745,
  -0.6367004247554451, 1.2359524820695789, -0.1893254650137554,
  1.1837095366408956, 0.19694022802377845, 0.03852386003411759,
  -0.40179665650192853, -0.30504089246729765, 0.9530218265924832,
  0.381377143524182, -1.4188351634126124, -1.4447913161588808,
  1.5654588264686855, 1.5306193184488726, 0.3716109664218551,
  0.6007323240656013, 0.8522634111664822, -0.5760878251600624,
  1.0136301860176142, 0.48018700290139527, 0.21865511977239252,
  -1.5662098336305124, 1.023183986089391, -0.006872750437500512,
  0.2696009379369338, 0.6074297176002861, -1.7517387280539942,
  1.0922073545644038, 0.17465362186505873, -0.5700194291332332,
  0.4615595883135121, 0.8533884942470659, -0.28446639829262993,
  0.32757231297526296, -1.699386635079344, -1.3223114628231143,
  1.4537562936319384, 1.7451971200482894, 0.9719202908368682,
  -0.3968362119095101, -0.7228220308412975, -0.5654425381504969,
  1.6801376811111104, 0.6990315696402825, -0.7124265892319959,
  1.138632484645083, 0.12284710533228127, 1.241681041810628,
  0.15929866474552315, -0.29098832867764557, 0.8425211616468186,
  0.3479821733284854, -0.01611071077047966, -0.4856310400858281,
  2.25064410771661, -1.0298010229705494, -0.023152271391024954,
  1.5633005264873312, 1.8060656109579267, 0.6814755917593935,
  -1.7148094499065611, 2.0518798803405502, -0.5334934483168569,
  0.5296811801507171, 1.0654281436566526, 0.07535635343296695,
  0.7956874400620697, -0.6801460018161557, 1.3431860469369405, 0.93495555472936,
  -0.624866054662288, -1.4012072766957984, 0.36507344897859667,
  -1.0024347931838706, 0.6338302654360946, 1.2487528109154107,
  -1.7091604292733116, 0.8363649495644333, -0.7739703966227555,
  0.997995499248692, 0.287283412943425, -1.2054920716741202,
  -1.0499417032235734, -0.40859454986053395, 1.2576379847680705,
  0.5409142199727792, 0.8594597405222983, 0.2844115434663948,
  0.9095592935402536, 0.030233688725300256, -0.2009171895237928,
  -0.7418818675999204, 1.9352840805936888, -0.41839437340549424,
  1.4130929834735448, -0.6379859677414672, 0.7940620712059134,
  -0.7552988436563551, 0.9281541145409424, 0.5916125659130804,
  -1.1920731171107617, 0.3584257863286728, 0.5154831034542754,
  -0.7102517353890249, -1.596896685364485, 0.8325354136321904,
  0.8511646592194697, -1.3330387114090627, 1.4990566552565656,
  -0.3616736638937195, -1.2840168777985987, 0.7514915922922739,
  -0.19832425735321382, -1.0306227695512344, 0.34862988530441474,
  -1.7523241185852245, 0.1628985364154111, -0.6980289121221867,
  0.46742212273391576, -0.6111338579038625, 0.1931583733776057,
  -0.12656691548683086, 0.652089207129494, 1.0563192229007707,
  0.380238214376764, 0.7381371108637956, 1.072057096337004,
  -0.18369782832257153, 0.9420042271668176, -0.32741322261445377,
  -0.16847609786153528, -1.3009650274768383, -1.1905695598079638,
  0.417392451896806, -0.8077400198399955, 2.1088641466465377,
  -0.2806648713108006, 1.4339171563742652, 0.022091923698171676,
  0.6196243744169425, -1.5622494275752674, -1.3489374591784262,
  -0.8560285849090836, -0.8330937211929573, -2.1064819821020992,
  0.311766651237751, 0.4811264497982811, -1.2542236812790075,
  0.17878343473721456, 0.3852111535401799, -0.3765945876404354,
  -1.0090037503931581, 0.6058370001665587, -0.8886107553416909,
  0.9991507678111273, 0.10731458840750671, -1.390053523568556,
  0.00424049478280309, -1.558560323793471, -0.8217040356338497,
  1.2722897019724886, 0.6230515864322252, -1.4589908067889534,
  -0.029735186156581068, 1.2398608774438669, -0.015256819279199994,
  -0.3407544747862786, 0.22163556241509336, 0.7454497324034262,
  -0.826716898279323, 0.5702369530531478, -0.5281988943443935,
  -0.44223855907523124, -1.0753017226832016, -0.7587622443424763,
  -3.0878901132351886, -0.5170444434904244, 0.8032342944306663,
  -0.3447018478400229, -0.9783812237324551, 0.05936082242573682,
  -0.39098230735460743, 0.3204265253211787, 0.779858353287603,
  0.28151708374507484, 0.07552913144956702, 1.6827188884457467,
  -0.2941014319770753, 1.680154577784856, -1.6429992345404105,
  -0.14734042672379086, -0.6753664294929577, 0.6635204446917108,
  0.8965833230617165, 0.1130377600364105, -1.0407692993769757,
  0.8711162381223715, -0.26057420736052983, 2.0031108107347815,
  1.857691101936726, -1.4142302542561396, -0.5543940935870337,
  0.09801953325171918, -0.7135107533564142, -0.5042633810651701,
  -1.4074327041726502, 1.354452546437929, 0.802585108359047,
  -0.015236798361466356, 1.0969902844304005, -0.05312927221841802,
  0.4070259283522132, -0.8036893363058162, 1.1062260203431142,
  -1.0911275828971878, -0.744231523981139, 1.2049243214022594,
  -0.32806485842790967, -0.02037808365008343, -0.7528801101995487,
  1.1011675263444227, -0.7407473031529055, -0.028489458833296833,
  -1.087467094107683, -0.46682964919875203, -0.16885336981061227,
  0.42639760831243867, -0.1239838184911005, 0.5262718781561747,
  -1.503473575740071, -0.577771975039543, -1.7431308601682405,
  0.16287446484393325, 0.2503010734308044, 0.23672757408097023,
  -0.4233364584486539, 0.3332576030461405, 0.7313641216187999,
  1.3307315063245704, -1.1050721127279297, -0.2252280442278303,
  1.5382589744562258, -1.2491553417033021, -0.07842895088220868,
  0.9366585141041829, -1.1941775926967897, -0.4660987382999113,
  1.5107544676927136, -0.5684112019307775, 1.2679146895173425,
  1.6978579836829035, -0.6180782576752887, 1.5466884679186799,
  0.996736847831455, -0.3469908658820777, 2.352826956640608,
  -0.27022450618046406, 2.4710590735627695, -0.4228383540607037,
  -0.7154967911749752, 0.6949052793919577, -0.7672820297497046,
  0.11629654872686729, 0.38091529346523, -1.743148912303021,
  -1.6952330824805681, 0.8392847809440377, 0.14503441176865828,
  0.1827331675166484, -0.7400003867425419, -0.8401233340773064,
  1.071377683186375, 0.18880290621926712, 1.992836960218145, -0.525970182560159,
  0.03660804160816636, 0.5593333790466987, 2.4079180381217427,
  -0.3864234802299921, -0.7767130452295382, 0.24044339058387412,
  1.845758500451192, 0.16571921864087386, 0.3803548889982582,
  -1.8810159684000178, 0.29360470208535844, -0.511424895240882,
  2.0520349614941833, -0.58339063891039, -0.3920556642613124,
  0.7141208862974506, -0.8333229112465971, 0.25211600317833527,
  -0.02895896690246234, -0.09090999837440114, -0.9318781323575787,
  0.3119834033106961, -0.04098262540180093, 1.3003879048271798,
  1.25823541949522, 2.0178204787975416, -0.07447996719270218,
  -1.5455123129406834, -0.1412040218025096, 0.2783693199590192,
  -0.17602540345442688, 1.2055672283381391, -0.3908391253978333,
  0.5826106839785694, 1.1791963087330528, 1.7681490208537534,
  -1.3885831271734768, 2.086625778688853, -1.4654241005736932,
  -1.823837785743258, 0.5466690840941638, 0.7695642815923661,
  0.7581804849635906, 0.2331623315178606, -0.5379787749160088,
  1.7476572414684621, 0.7417309631037863, 2.0810488122035964,
  0.11501036722430756, 1.1659666065680254, -0.21794024539070622,
  -0.4510207220726626, 0.618119122838826, 0.25279542943256356,
  0.28234114243474173, 0.3880272043310748, 1.870944700836613,
  0.5557961642368995, -0.41003982694120833, 0.09293575536721925,
  -0.053854808730550846, -0.3438384366461791, 0.07199038226112273,
  0.56219294306249, -0.3858217954374326, -1.5198569426556998,
  1.1199811065263943, 1.3813527175376863, 0.5056746675441364,
  -2.0763664121264465, -1.6025817545047931, 1.85924115462568,
  -1.2045079902526417, 1.2098768476495783, 0.322567258041633,
  -0.20008291773921635, -1.545717780098372, -1.1558477131661546,
  -2.263234800972758, -0.9767714404393824, -0.08627499538105658,
  -0.36074098093223084, -0.0010283256381493828, -2.314730814981143,
  -1.1652044974933573, 0.04663133188758002, 0.3196291968734844,
  0.4758131169428061, -0.1989343556415921, -0.3919258509625051,
  0.5812038080858213, 0.27541924079303043, 0.703710512815154,
  -0.8084595061076549, -1.7176025543021625, -0.32833885620072867,
  -2.2334860170230733, 0.21926223414329638, -0.6717516231323595,
  0.188887063331048, 1.887364484692844, -1.3778511637014939,
  -0.8210318180633956, 1.4521699937760122, -1.4028898768411515,
  0.4491228786402479, 0.7657415995854595, 1.795260494596435, -0.750365025579967,
  0.3622646415025253, 0.016407600265466497, -1.534742107663589,
  -0.6196597442357915, -3.0711766928241, -0.9076671926314955,
  0.1722528104789203, -1.1497981399453792, 1.0163980488067317,
  0.18559006085314658, -1.088356741368706, 0.11501718881842284,
  -1.005981562450515, 1.0514187580418146, -0.07898927301160712,
  0.6843746211281674, -0.3457415941833265, 0.5444870061685607,
  0.4665575463758548, 0.6718893623888484, -0.5950721118396246,
  -0.957946206798203, -0.15005024374939993, 1.6068041079725086,
  -1.2620378089370117, 0.8526876923364255, 1.485376122121551,
  0.2558743681447649, -0.2785847985444764, 0.006383389454003469,
  -1.3768580006791926, 1.1273479432067572, 0.7376799515913449,
  0.8354241524436566, -0.35851920406649834, 0.0949018762796402,
  0.5676708996937196, -0.25368260866684494, 0.8095538794835418,
  -0.5642856851759546, 0.32886836589514445, -0.9805173184657564,
  0.5663902146351606, -1.324870092506911, 0.048416800611418016,
  -0.02267550333478096, -0.5093253627697182, 0.6109352821189374,
  -1.5084302243498757, -1.387123654563013, -0.2621717179827155,
  1.1920328535500535, -0.3812439830179051, 0.445800598150592,
  -0.26329716082041404, 0.39661352417632384, -0.18918137200598836,
  0.8736554253055224, -0.7023593829064798, -0.9347938774765043,
  2.505717242677129, -0.12435400596001306, 1.6304940837786603,
  1.3281985164973222, -1.9710016666672332, -0.726069155788642,
  -0.7512409142591744, -0.17323661165246526, -1.889803372529063,
  0.966490672496007, 0.2975618634804812, 0.9355149027406139,
  -0.8289653446643616, -1.771985094528832, 0.20906429518044287,
  0.782522168844184, 0.6061331941770218, -1.4014393268504346,
  0.046420764451205375, -0.15165533430876751, 1.7733981160291832,
  1.1271643132111218, -0.17950218289678802, 0.17925411948052347,
  1.3092716937985327, 0.710829866108822, -0.9414197822137677,
  -0.13759867443024268, 0.04683755538236266, 0.06854318598259324,
  0.41917723440789584, -1.194360980757812, 0.48214032443875215,
  -2.425403078613254, -0.17429580251372717, -0.6493981474253063,
  0.6362042094450014, 1.339381644852322, -1.600952093037408,
  -0.8747401648158485, 0.6950744020895164, -0.40053022726223086,
  -1.8383283232546421, 1.0986822758477224, 1.1194291268971557,
  1.6775418064545098, -0.6185774053183541, 0.5511824663945127,
  -1.0197884633473528, -0.6732235789552903, -1.4504516252941595,
  0.2966037808897811, 2.6604252658291245, 1.821950484630184,
  -0.6072013762135394, -0.7341742038643697, 1.0315693775170356,
  -0.5264361574308898, 0.18888884153480917, 2.0671760619089903,
  0.28609317010058344, -0.9466029238851777, 1.6424479629110655,
  -0.37536335907187435, -1.690313832519655, 1.3236858190388117,
  -0.49125287156551356, 0.8286499072296699, -1.1396147786699282,
  0.28540553929560475, -1.5910970856532132, 0.1360093665339556,
  -0.9494841094170254, 0.12631879551130495, -1.105431988265565,
  -1.3057266905342793, -0.257839780528417, -0.7336641741953644,
  0.751265191898481, -0.48519756236193573, -0.6947105986824552,
  0.9188211370109624, 0.45081352311623296, 1.4900326082141238,
  0.3574285216800316, 0.26821262443587085, -0.509144604959634,
  -0.8978169559088149, -1.1195114664094432, -0.30030325422834375,
  -1.6710754940588093, 1.0335625955576522, 0.05583831755920709,
  0.1528442511816581, 1.7234468800829392, 1.5017411213719594,
  -2.0245195949265304, 1.8320676818242827, 0.4764309621165641,
  -0.27748022872763783, -1.450924263127485, 1.8187496166718111,
  -0.41075342093224604, -1.814235477445388, 0.8107469049465097,
  -0.14076404296440817, -0.6726442181187985, 1.8855825076243211,
  -1.5673261221484531, 1.7513537320447983, 1.5486366928297095,
  -2.4499555104013075, 1.7045725490400196, 0.5842192767053093,
  1.3580491317072023, -0.865023957874807, -0.4964737420749726,
  -0.5298991456166853, 0.27428134071017196, 0.7295569650568402,
  -0.867189224650463, -0.8860221718682487, -0.43647680400990413,
  -0.5308299836732435, 0.030357252378223502, 0.4337618448954583,
  0.6218324932629279, 0.976209351304543, -0.9988411006575413,
  -1.6190558965035398, 0.08468287607267933, 0.03565133032494776,
  -1.088319269309178, -0.6564051939538865, -0.7540871216555296,
  0.24045975094119387, -0.7312663558127717, -0.8394034814102596,
  -1.4206799386539, -2.183435101642504, 0.2548883361811771, -0.5355105884703053,
  0.023125175617229134, 1.323389248994493, -0.31980524717323394,
  -0.33298816070293985, -0.03967245675320659, -1.9367018486490017,
  0.2938026473678996, -0.725313006289023, -0.44265384683046954,
  0.21137224023999476, 0.4515540236236653, -1.033191209360197,
  -0.16566091203934252, 0.23043529019798448, 0.3802651028931507,
  0.6804975965011144, -0.5352336174449502, 0.23669837839424399,
  -1.4686912270490755, -0.21918639042076074, -1.4405276141417114,
  -0.17702223023184743, -0.7908202166231016, 0.5598740896733386,
  0.3057806798957582, 0.5525193143980957, -0.1481033365165998,
  -0.8968299590873108, -1.4320636220464642, -0.7955502163730525,
  -0.08610687080361601, 0.8318340402056789, -0.4148800611483305,
  0.23515879434592202, 1.2939080418655293, 0.03821180426035903,
  0.462633499259713, -0.8328116584266716, -1.5781913842625626,
  -0.6261067508403882, -0.08598384942131211, -0.4986674331044358,
  0.5683680093339252, -1.2315326711956733, 0.5658848846517425,
  0.21318099261488485, 0.8035282801909861, -1.6773280762827711,
  0.4740128257545986, -0.3089264692103267, -0.4143704789819231,
  -0.8406469860271523, 0.06111412512446695, -1.649415113845213,
  -1.4097032891031915, -1.0641753710572348, -1.133372775062417,
  1.1801863954690186, -0.3128296301611163, -1.7041503689790911,
  0.03431153286503975, -0.4741814630080316, -0.40963500552894766,
  -1.5238499407924957, -0.5524685118694463, -0.6237293186486917,
  -0.513360054172627, 0.7177476658600708, 2.2864447599519906,
  -1.8123032209948613, 0.7294908614074899, -0.0005132764158575496,
  1.1319699306679734, -0.7163204409331202, 1.0226699423996897,
  0.3170713798734856, -0.19554549928105233, 0.6003081015423273,
  0.2965516159140722, 1.2582393064514326, 1.868221134235803, 1.0987942255487568,
  0.9561787430668601, 1.483117540218395, -0.3133109745131924,
  -0.2009909258044953, -0.7112139600166936, -0.4640318436076756,
  1.3513418573449378, -1.8399055974572809, 0.5982565004447613,
  0.540555569582713, -2.174928157374256, 0.04911092926391301,
  -0.4218092805407736, 0.5472624718778717, 0.2708595278974531,
  0.28955655513689244, -0.020181072693942065, -1.7192127486154534,
  -2.153592237841609, -0.5063722430637574, -0.6960304930483318,
  -0.12517166457247794, 0.6653351917727779, -0.28415670460535847,
  0.2793980774034181, 0.3489573367227252, 0.578586243556703,
  -0.5852156854860814, 0.6557517677215863, -0.32401874413015636,
  0.296576534541763, -0.2118705500998358, 0.6814486883097924,
  -2.562235639329034, -0.0581909235052555, 1.4236334089208744,
  -0.5314058735865035, -0.12400452442578376, -0.2994079815109232,
  -0.3654345849373701, -0.5797716564321688, -0.5284389012296261,
  0.7655486262092365, 0.276210319666706, 1.5478375575325476,
  0.21059319498715756, 0.7542881592910153, 1.132073561470934,
  1.2121809940364967, 0.7645830604301354, -0.14369557588084758,
  -1.4126795381192028, 0.7157618956212062, -2.5690566642286843,
  -1.4375804778966519, 0.35699703664851384, -0.14080800257813483,
  -0.6865323160247845, -0.14985766997078998, -1.7719850250889566,
  0.2792043752756768, 0.2834724911612686, -1.667725973238627,
  -1.143239360565871, 0.4631597486768344, -1.0470668091976083,
  -0.9440303500402101, -1.3342890601379398, 1.5370747150696047,
  0.02787675965785753, -0.07736213731477402, -1.8845392781706847,
  0.7418267361662357, 1.8697144148353548, -0.14709745228780063,
  -0.3476454272128977, -0.7277150718309343, 1.5517660718781197,
  0.9839261617569054, -0.2946222045483638, -1.0046232533677772,
  -1.0423456761714796, -0.1452728443593698, -0.32501550379699135,
  0.5517771051621235, -2.194888813126656, 0.7306703375387589,
  -0.8355268634176287, 0.6483014344045146, 1.5769061446042125,
  -1.607691809694464, 1.4873582772031235, 0.49165846878339736,
  1.0504455922532157, 1.1239795193563096, -0.2743727899106412,
  -1.180733412907528, 0.9065858751418648, -1.5193031240518038,
  0.23289213390871188, -1.0800406500467963, 0.641305592446522,
  1.6589559724169836, 0.6921630635131973, 1.933361267347499,
  0.14477038769832426, 2.008520531497179, 0.6572685941282673, 0.600701301407616,
  -0.8690856826141742, 0.3348280782927069, -1.3950559314989024,
  -0.9131829363844638, 0.9620463416656666, 0.6524857469536015,
  -0.34100294225527705, 1.452350329764976, 0.915498450905292,
  0.0915317746645541, -0.4016435667449091, -0.5870467706264529,
  -0.008641269632238442, -1.2884448219593285, -0.22172634401062125,
  -0.14567195584789178, 1.4372904025685367, -0.624205758627884,
  -1.1793246206577568, -0.03210284540730274, -0.5704348379539599,
  -0.4967772540876288, -0.8009430041394286, 0.7271520923023151,
  0.4544818151182931, 0.019265894209501613, -1.0764781538074628,
  1.2529646952646918, 1.0663667869746782, 0.7939335757071608,
  1.0870645277852453, -0.8345967413043347, 0.6895435254913056,
  0.2775506045992195, 0.3148688021463913, -2.8625137483660055,
  -0.4696390239897389, -0.3105736609826396, 0.9095217800986075,
  1.2148747480691444, 1.1471443775713308, 0.8788201690521656,
  -1.1540097617823222, 0.27373582392272355, 1.6436247740485999,
  -0.722773436428288, 0.703635829233184, -0.40219972661348186,
  -0.46030331866259144, 0.33950187444316926, 0.31446953259058974,
  -1.6011005117128605, 0.1871207639361934, -0.7638522422574445,
  0.33439923693923246, 1.0358894025823175, -1.4364176600760223,
  -0.32848123057831197, 1.5118028567207968, 1.6156595612281703,
  0.8069190736134461, -0.7566753379699656, -0.9285639258805227,
  -1.7907739190700935, -1.6985856965781327, 2.311898989539172,
  1.2274378972882192, -0.012240019489174463, -0.2633193280952365,
  -1.0398515203540772, -0.252860719617318, 0.8653785111884996,
  1.4972073408574422, 1.43578206298118, 0.2621379042445017, 0.7101001273615425,
  1.2981916516531724, -0.5143841795285191, 0.30236176667019904,
  -0.718708999265489, -0.6057900162972946, 0.5834835449000026,
  0.7418361021244716, 0.750448427810162, -2.073775438415042,
  -1.4732503384944702, -1.1359392475018326, -0.3174139494434945,
  1.449346569538325, -0.7086498271148398, -1.6387823013581075,
  0.5979674841112056, -0.8532541640916188, -0.5438804725922849,
  0.29127501502613035, -0.9166523883129846, -0.535340547038592,
  0.0929245201295282, -0.16158586327460658, 0.02946914235327257,
  -0.22227144659645096, -0.46895661974028996, 0.349860358262595,
  -1.4079084808658584, 0.5300187984057013, -0.4743389324797263,
  0.1687790153100573, -0.15418037582720062, -0.18881959137383317,
  -1.5752468134351392, 0.4685907939308224, -0.28380165758702336,
  0.05716488944496251, 0.42294060881248813, 0.4760147872333313,
  1.1613829009505678, -0.575263570510912, -0.5996310473998214,
  0.2968539154583286, -1.7775140251095243, -0.0019698243957049794,
  0.7173744565795582, 0.15564480465425604, 1.0319610823010643,
  -0.29095363953118974, -1.45099758675173, 0.22966490074550538,
  -0.24624636614179016, 0.17187169269244873, 0.8132415945007926,
  -1.4277919064635758, -0.8778685222361103, -0.49526175554082746,
  0.3065685908694024, -0.759830398153565, 0.30702746942056386,
  1.1181617443951526, 0.3325509811864597, 1.3784093993050386,
  -0.5211265919136308, 0.45963739806700243, -0.8603021259282437,
  -0.734908520658113, -1.7261960597554165, 0.6600826738006941,
  -0.45335254274230136, -0.879347275642477, 0.1892952307125494,
  -1.7636692254862485, -0.9942057339519761, -0.1770184847898229,
  0.5896499448004932, 0.7567124039081365, -0.34097094401630423,
  0.7220447556405939, -0.7191758240235878, -0.09931318550829454,
  -0.4052041428250615, 0.22064609337536215, -0.7737563874325273,
  -1.22176485748849, 1.0777482512639442, 0.5549138584682097,
  0.37924022855220857, 0.39395228329358445, 1.857426396295912,
  -0.3433439423602912, -0.004075179627246808, -1.0429756920912145,
  -0.1787979191039711, -1.6806285446664626, -0.2478126881004923,
  -0.006165217343639453, -0.140071022395454, -0.25032062624463824,
  1.2017410455263458, 1.1628935637369675, 1.5918556103456052,
  0.059864876147794586, -3.4547238892307393, -2.6555294422263875,
  0.3312512266546982, 0.7475410322866534, 0.9967929116062181,
  -0.10688551674934951, 0.1612132686415997, -0.5038867328596964,
  2.2061210278602967, -0.9650970786482739, 0.6319970235020929,
  -0.5381188951792202, 0.9372084885696085, -0.13966554578708687,
  -0.6812560895225056, -0.06458859877993248, 0.8539295348386424,
  -0.8132051288863991, -2.011229458411093, 0.31643746028246483,
  2.8151362000131503, -0.6829785976795151, 0.29015390772325134,
  -1.2492114944377541, -0.30859932359534936, -0.7833457931649319,
  -1.4118001529186301, 0.18872956558670925, 0.3009146325797116,
  0.4542308766989908, 0.5834862584538588, 0.3387280467647071,
  0.6776782019197519, -0.9627852533736116, -0.4030304379989889,
  0.7854558465453397, -0.9590365063809517, -1.6329595307271538,
  -1.1001172078772197, -0.16995381002015633, 0.26509027072374863,
  0.10660842294151653, -1.1395380297675062, 1.0770631937266937,
  1.7845191636220628, -0.6178057937865076, 0.49710596449649885,
  -1.0329415771439476, -0.17680732924738635, -0.33040350313357475,
  0.5392680151992437, -0.29251848532276997, -0.5481276908144004,
  -0.14714887023614007, 0.9989958568346515, 0.4042902875948066,
  -1.001913898175432, -0.13444793002360164, -0.3164132006687076,
  -1.4964363238957854, -0.8904997813236081, -1.2363594929667596,
  0.7605244392025543, 0.1658204027468878, -0.2521605723755667,
  -1.0367070383280317, -0.11454274639183627, 1.5818801199884085,
  1.3855964212890186, -0.023275456729035898, 1.5800007859931366,
  -1.6277244820095331, -0.07381155790412351, -0.958329119951324,
  -0.2763864648014488, 0.8876145624602108, 0.21488376611678828,
  0.08049363753465358, -0.783040742690053, 0.04255671613944953,
  -0.2492963365883809, 0.08045527847421133, -0.8875646778078763,
  1.0617234718558093, 0.1540234026703807, 0.6261433318273557, 1.477267082884194,
  -1.6329748138068427, -0.24839051153060465, 0.4019216477365727,
  0.17517525219611305, 1.44000264696404, 1.7984409282304221, 0.6628081015608212,
  0.3438173691317009, 1.029174171292215, -1.546507358932006,
  0.029285303252358376, -0.743807821528253, 0.08889283301128009,
  -1.8118187490525, 1.6021199080759778, -0.5072934887485814, -0.505882561071171,
  1.418817126095416, 0.011995199043983898, 0.5923213536312716,
  -1.147700422522731, 1.6455562384035862, -1.152820530729549,
  -0.2039703439770805, -0.07306426560729357, -1.2153886788011625,
  0.6876845139678207, 0.859015393337638, -0.9393294193198786,
  -0.5763799186838054, -0.5188625740967444, -0.9194201351634052,
  -0.5963988528373313, 0.36140517884036444, -0.5264719442747352,
  -0.24493636359503826, -1.0376344708911065, -0.3064357394032211,
  -1.0355611718051718, 2.036582109381152, 0.22651145978738918,
  -0.43110020602743615, 0.8612040986905253, -1.3820449030687993,
  1.8107986411789185, -0.5728077274164567, -0.6796466546374385,
  0.8911307169121928, 0.32201752436006753, 0.36921097700794386,
  -0.19114754513690752, 0.5224116918475739, -0.043265550240880364,
  1.399220413534031, -0.7070297387520904, 0.649592597612944,
  -0.5140305489671575, 0.6769522230775912, -0.7224098246226834,
  0.6417588951865234, 0.49815649358321573, 2.428325898951156,
  -1.1050381555325537, -0.8806368819123149, 1.5333871268545844,
  1.2627531749260619, 0.03857571414653871, -1.115337166497313,
  -1.8882536450094514, 0.29666739264615927, 2.3493368061627593,
  1.280716123467627, 0.3129047796497708, -1.1899073529695936, 1.035084542300243,
  -1.9186048100662783, -0.24668393364451305, -0.10809183402704797,
  -0.3113917606626504, 0.3557317321059263, 0.5865457016184029,
  -0.13113454580234027, 0.15298787873004135, -0.1707902041079093,
  -0.07098575841977471, -0.12226356695690198, -1.6468249349444715,
  0.9739702563866529, 0.27735723738448453, -0.40455972310800575,
  -0.30424212516445726, -1.2076739258913474, -0.5955040707770527,
  -0.3804209668222768, -1.3619532807298154, -0.98659391601561,
  -0.21726271985886392, 1.3605351981929312, -1.3756115636729276,
  0.44007030971275607, -0.27342641381219984, 0.5583345886323817,
  -0.17477425165772958, -0.13962976978906222, -0.5147508270902214,
  -0.02001296364190281, 1.7026756133707426, -2.868633372856304,
  0.01572269932779203, 1.0268006392512534, -0.03831619599024436,
  -1.112306076832407, 0.6057226097780996, 0.5222786748199918,
  -1.9137665397927863, -0.6939338709736856, -0.772015973316823,
  -0.5637746380904999, 0.7409240954702019, -2.868029327650044,
  0.04217133423588968, -0.7897294127988996, 0.8019399495335926,
  0.019948196736209487, 0.021698685495292906, 0.4554091288545667,
  -0.14998263559017835, -1.450670273784239, -0.6061274144813206,
  1.7998232483324195, 1.148822138617534, -0.9218074789666281,
  -0.5202910670618486, -0.26773470233194185, -0.27882263359577436,
  0.5110874455060653, 1.0065953894511235, 0.7785456159843376,
  -0.8980298505086077, 1.2265069721615582, -0.09920797314988802,
  0.33539740091329445, -0.5341211155041848, 0.6581021175680093,
  0.1619229555855544, 2.178766462680488, -0.6410862887274529,
  0.14523923019533777, 0.14639084121574117, 1.1520896034065662,
  -1.8106251963891142, -2.1013018080837003, -0.23412146812170193,
  -1.10668569760384, -0.08353059197184048, 1.2128404151667653,
  0.562516611182469, -1.1934334726770799, -0.7961300654967395,
  1.7156515447446963, -1.0182880691219902, -0.03224869188723626,
  -1.0819518366188872, 0.26344484985798006, -0.899092828111619,
  0.7579992895288487, 1.1318577913455818, -0.0040221834431331,
  -0.9926013269640546, -1.6995399287742838, 0.6220687166854693,
  0.07027443594690332, -0.36392964226946806, -0.2628967718750396,
  -1.2113401624775006, -0.10540590643474161, -0.39043662774413845,
  -1.5423439959084917, 0.01685691434872999, -2.3028025188436176,
  -0.7665155131546503, -0.5785088313141633, -0.8835487445477406,
  -0.8912054529935426, 0.5134122629755049, 1.0166213058268425,
  -0.31833100063279657, 3.0429666256288583, -0.4610454403438918,
  -0.758675286678287, -1.3158159528342057, -0.6993124394523598,
  -0.7451258592781509, -0.14548309993329306, -1.3139258563509961,
  -0.5326093459890602, -1.6511325341151282, -1.0815361838943547,
  0.3627941041830675, 0.40192250252603623, 0.3805509555943821,
  0.33514074101562963, -0.9595970699279229, -1.7054876774204533,
  -2.5052791648803843, 0.1608754184045453, -0.38349036017040655,
  0.2102522445921011, 0.42473859819292464, 1.006978396898835,
  1.0583478456544864, -1.2029609783524509, -0.1844974885745278,
  -1.2053091787317145, 1.658962174612804, -1.2594987327701528,
  -0.09589991393658694, 0.058563437644682796, 0.4069256141284136,
  0.06051563102208006, -1.1396123294483862, 1.7759102404772853,
  -1.582679185128851, -0.29273481728677797, 0.15253662215784902,
  -1.273208204818167, -0.25444457384600894, -0.48509290348840767,
  -0.4118105432327523, 0.22701321593975088, 0.05769267368500854,
  -0.3409545897843605, 0.24908922635620753, -1.7989623915556416,
  0.21425208457604467, -0.11049369781836789, -0.146151047997951,
  -0.10978620993334369, 1.0707102532490236, -1.8257972938633147,
  -0.28587539802350415, 1.792891323247731, -0.03137184507686801,
  -0.4016487490196855, 0.377346588046628, -0.8559714945807444,
  -0.030527911466573677, 1.2488646491609732, -0.6524245189708731,
  -0.5183040648580025, 1.6686975731386389, -0.9902890833291768,
  -1.5665460186170577, 1.8602445038374478, -2.3084574831657614,
  1.2035421940055202, -0.18312778301390809, -0.21631068544611565,
  -0.5026184329075005, 1.536737954681357, 0.004239326851407303,
  0.6947534605748258, -0.430996901211727, -0.3910431306445197,
  -0.7785165378965866, 1.1818475331280758, 0.9401025672953917,
  1.4512449544425003, -0.04850655146335059, 0.4374871805935733,
  1.6294663117850332, -0.43101353552309873, 0.30278844812546596,
  -0.6580153135683594, 1.7191840481277425, -1.2674860377225543,
  1.7878475303189525, 0.2895907670314215, -1.7833166898967545,
  -0.2682894792503829, 0.29642142027507656, -0.06998236293103413,
  1.0320902004495627, 1.2369174552986968, -0.784154015858095,
  -0.09599267760875092, 0.8978318067063066, -1.1225659683868026,
  -1.001259379691093, -0.6645467470316833, -2.092999830474411,
  -0.2126910381871607, -0.7836687550465417, 0.5262795508131786,
  0.3262289190662185, 0.08123864849116728, 1.5817402059028014,
  -0.7988102138487089, 0.2917367197275711, 1.1695536776957334,
  0.7621325769628728, 1.1130363429885295, 0.2150331357846498,
  0.3769542876455665, 2.0240361166323986, 1.9667768036609876,
  -0.5203673135633988, -0.25313131401624195, 0.7273248389101397,
  0.150961862851822, -0.5478183529370596, -0.5165426855320787,
  1.044266695911149, 0.561864462067674, -1.5285789078036585,
  -0.02947911230364253, 0.9904272503241398, -1.2740345991178132,
  -1.5223404089595698, 0.2614210524466972, -0.8319827773142985,
  0.47986293953096265, 0.3487619098046036, 2.149275068556946,
  -1.8820193046180074, 0.21447740770524612, -0.32599643855987775,
  0.381885682922557, -2.1862791355876423, 0.5275223722788892,
  0.11079377815058546, 0.13458013518499712, 0.1356502059009534,
  0.3515585125669137, -1.7802644602444602, 1.1786726773184903,
  -0.14606734244838201, 0.8313836091327602, -0.09433978089776283,
  -1.241850470647512, -0.8060584568212831, -0.2767225117518585,
  -1.1488638126714463, -0.7787637872750638, 2.718397135886307,
  0.00628815426524229, 0.4718108760441011, -0.9617413645296718,
  1.9856804285723026, 1.7451387902238353, 1.0177642597026768,
  0.7856733296855176, -1.0584061655715344, -0.3287631186553171,
  -0.5464368481537323, -0.05131058463480494, 0.6505346211866393,
  0.14219955181600696, 0.8827782392729223, 1.1351088521468449,
  -1.7408703809932318, -0.4600782163326952, 0.30886119901192327,
  -0.12460948542961543, 0.26630160431419103, 1.2602739958239317,
  0.07216664308207378, -0.05333305048048224, -0.27065795922374475,
  -0.3499773966648872, 0.8039677010617312, 2.084290488938204,
  -0.5556997676658362, -0.4772197130866524, 0.08516142357527653,
  -2.2569168300831977, 0.04372898368422433, 0.9282254625869731,
  -1.5135340242653417, -0.6191603508311543, -0.06985267351467511,
  -0.016770115213651602, -0.600176132218176, 0.12145715086749162,
  0.909996897757693, 2.281106224133531, -0.27606387860514925, 3.450291815515039,
  1.1861115018191448, 0.8129588844801016, 0.4478308299109004,
  0.8302972816323707, -0.7091261149260666, -0.264751641318687,
  -0.7293813287889831, -0.18495540495075294, -0.2807283502148161,
  0.1936175281909697, -1.540411743282678, 0.006337088675165156,
  -0.31897371350129494, 0.7301319775155927, -0.6265332682499748,
  -0.6042251130715272, -1.3532920269814073, -0.6448442573994893,
  -0.6248911378270596, -0.2752706348992704, -0.9580628588124844,
  0.18634286610989137, 0.44544378371004645, 0.17880190616246736,
  1.1247467789674774, -0.10084654776757357, -0.5863494536936,
  -1.7070736659700627, 1.0786948102596643, -0.4893065052170661,
  -2.3318245177033674, 0.5439491912315444, -0.7881503396578062,
  0.21715816147984146, 1.7347344407175753, -0.7033958222293052,
  -0.88979513531151, 0.9875337743349907, 0.4959834206367555, 0.3747918695347306,
  2.348321478665986, -1.5419812126874055, 1.2030970470473061,
  -0.2971823035178351, 0.9098649932066407, 1.3311124289459086,
  -0.32403114839699315, 0.12663033027719797, -0.5424525459177262,
  -0.27370765544097275, 0.7220838977023155, -0.5341554992164027,
  0.3187284387104678, -0.7536976864675217, -0.15636738069795095,
  0.6716855317319199, 0.8485624986574083, -1.18584781461506,
  0.053399405533124215, -2.1523140543480634, -0.9020294551820294,
  0.9998033464360911, 0.1839573074929675, 2.2549480138532556,
  0.05823008774613264, 0.42830755935526715, 0.7911075800091243,
  1.0543759971522482, -0.1105126513249188, -0.011687042491645724,
  -0.44652605512022436, 0.856611641147824, 1.0629902659067358,
  0.16875002488895333, -0.7972890227095615, 0.1588215486557578,
  -0.47036359517164866, 0.2521574785133671, -2.076624365561114,
  0.38180802305124795, 1.0296941183511297, 0.9016215775339875,
  0.4224145582293667, 0.3737553246114595, -0.32994856388976357,
  -0.40905971132610613, -0.9227079601262046, -2.3522222148453964,
  1.5612931583323575, -0.9498316299324353, -0.8161042881152086,
  -0.5978516191490632, -0.765719938212607, -0.8019311945580725,
  -1.1773787662824404, 2.002167199463684, -1.7317738941772127,
  -0.44736365038110587, -0.21541991453062778, -0.40696572758938493,
  0.44647474169728163, -0.1930609328241963, -0.14357436557179948,
  0.5734277943133813, 0.4318268876456716, 0.8667922201634354,
  -0.1079620640700641, -1.614789277368713, 0.5078522418372925,
  -1.9820323821065582, 0.05203715567592886, -0.22391497241202535,
  0.0530536484646999, -0.0647915561638784, 1.8180583917161774,
  0.47917022552537897, 0.12049190488719914, -0.3199909831788799,
  0.00792077188887135, -1.4004785563930753, 0.30607503337278485,
  0.24746009800832416, 1.0096771847816473, 0.8681561186668556,
  0.4020745902311512, 0.7491722544678571, -0.41419915437770094,
  -0.6041783802481446, -0.0935719101535277, 0.22700756070054373,
  -1.0054943609669038, 1.9050242747838226, -0.7100631388336797,
  -0.5558350550624559, 0.2966919592569123, -2.2190864897753344,
  0.4891414107345649, -1.0379116677377402, 1.1937630347657266,
  -1.5493027359386669, -1.952428047038551, 1.436364186727834, 1.61137812531542,
  -0.3335366156289518, 0.49613806407709216, -0.9857626310756552,
  -1.3407943084235856, -0.11220440138648034, -0.44071304473284323,
  -0.4173291480801799, -0.12406087264125087, -0.6549887966988257,
  -0.2394892447173168, -0.010860164794480771, -0.21369047184911957,
  -0.001865265336080605, 0.6253087351342216, -0.14152724399696476,
  -1.2523234010827284, 0.795804609525076, 0.9446422767649963,
  -0.2366193032077292, -0.20599185230957448, 2.1109126465396595,
  1.06249521834901, 0.6844670371691061, -0.9394784962427806,
  -0.6320957808089629, 0.6252641415131884, -0.4123421352357038,
  0.9174590410609798, 0.13389907895524797, -0.11903118919493363,
  0.14576202759535314, 0.09374010403585778, -1.1327813501119484,
  -0.8166196042343234, 0.28842414810361333, 0.10001429930806086,
  0.3850610432442086, -2.454890081066759, 0.8474874559306453,
  -0.7483824879873945, -1.5833141898696084, -1.1484318667255615,
  0.27594609187580177, 0.5574224570005044, 1.1813339684768462,
  0.48125279197801635, 1.3564734177586497, -0.887436803095408,
  0.13775007549644458, 1.5432004295189785, 0.18906102532043215,
  -1.8335356357113943, 0.6016871609839255, 0.822930443103862,
  -0.14618991779017154, 0.24454606022694758, 0.020430872350483624,
  1.7456804547057374, -1.0106580941783112, -0.9576804151361374,
  1.2587270999626834, 0.27437304618395114, -1.0418668839449794,
  -0.4548035148502321, 0.456815507590885, -1.0111102980143345,
  0.1539223670484936, 0.5546022333010926, -0.3118087634584969,
  0.8373330645030373, 0.36171113886990625, 0.7136468671989962,
  -0.39123603210857694, 0.33898385480779936, 0.0005037499209395677,
  1.8041054260887173, 1.425684521714386, -0.7401480516081961,
  -0.8622896839974469, 0.07236133881589468, 1.6369050714557798,
  -2.1296324927184584, -0.7599881302840549, -0.785826997118376,
  -1.507853348373639, 0.38222002016592815, -1.1642074171965613,
  -0.498605092022306, -0.46695380354804666, -0.40675779859386796,
  -1.4815044750777089, 0.4605644703647211, 0.1636925716027751,
  1.1280443607939425, -0.9407828330311723, -2.440044546460388,
  -1.272829977067103, -0.2840403363620324, 0.27293396012701, 0.2204029660375722,
  -0.5942447697820787, 2.214165641129303, -0.7049616503704893,
  -1.1960107991956925, 0.22324863398308373, -0.022193313642774853,
  1.3110737487983763, 0.5861127828851703, -1.2413483992873744,
  0.5509247459934142, -0.11297651588204255, 0.9154982073112634,
  0.05290742388137104, 0.07597277995516213, 0.6269531529209053,
  -1.061163248943184, 1.043000944363817, -0.20687866067855273,
  0.06175646807491474, 1.8462062396780636, 1.0873132429596908,
  0.500216915627824, -0.27262849519406873, -1.1753698353072217,
  -0.27657851176207515, -0.6102268218198453, 1.6824789026335685,
  0.6133892083309362, -0.45306395703781677, -0.40274107781782925,
  2.7705476321916658, -0.49047910272718886, -1.330898200701445,
  -1.0059707524108128, 1.052008528084912, -0.20618485567618097,
  0.49119409905215583, -0.025670013903891432, 1.0828257835731026,
  0.578856194592995, -0.6075952346675929, 0.3051846018499398,
  0.020190065121025967, 1.7461375066432017, 1.59998096367693,
  -2.1973028926253657, -0.6037429701203184, -0.9654187695089476,
  0.5747185411481706, -1.341943019674379, -1.1217852119885992,
  -1.700485206507229, 0.9223290056144435, -0.06277437313386397,
  -0.5239074720463235, 0.27942641082164227, -0.8992189634981402,
  1.6720826863778655, -1.0024710126101362, 0.40729770237296736,
  -1.5578961695729963, 0.16083827153135866, -0.16722601853794133,
  -1.148646743359566, 2.6094812762923545, -0.2760421323254495,
  -1.3080398059000216, -0.055272275542567376, 0.6172140045739057,
  1.0836142269843085, 0.44460946618321595, -0.3648571117815623,
  0.3882757230719003, 0.4822179813159457, -0.9012685617466033,
  0.3133057602226282, 1.09679652666425, 2.607839072394432, 0.1076456680301407,
  -0.8129027496158332, -0.3268179762893311, 0.6453518807815435,
  0.8081403468405776, -1.2883579575007318, 1.3529066703207602,
  0.05643963470375918, 1.7750350227881617, 1.2618690770807517,
  -1.3269311738916139, 0.11147138295858472, -1.6019947564265897,
  -0.14666988986685942, 2.071019693230243, 0.5758186818229729,
  0.8400279241735208, -1.3848139070205276, -0.45766740057097505,
  -0.3466272726176493, -0.7418055678530348, -0.2018081209291005,
  0.5869416406783393, 1.5754202804600113, 0.5605816388744063,
  1.3027963966079275, -0.9708890653703529, -1.210495988046779,
  1.0058871881287192, 1.1155542436998327, -0.5055605882797012,
  -0.7305610469005245, -1.137535150892685, -1.427173728801381,
  -0.7150309163608547, -0.5353733728949686, -1.074592043554036,
  0.13444951818979867, 1.2079074291041545, 0.5477653561049848,
  -2.5941731481149666, 0.14347519287311747, 0.34061330220129643,
  0.258384194357957, 0.5110605180130342, 1.128990285157399, -0.5526383416244511,
  -1.383523806661556, 0.5713681359160212, -0.993699095613275,
  0.4191180018506168, 0.6659427701219098, -0.480472239049776,
  0.2948018960217678, 1.831692686769464, 0.8298787621970966, 1.0635568790902155,
  -0.23261029903134928, -0.46758502346602093, -2.9888805196749444,
  -1.0694081272468776, 1.7985978310597475, 0.4698034485262022,
  -0.8016261825622271, -0.11667302775060068, 0.5899703488270818,
  1.3039283869600997, 0.909327647257741, -0.09272420338655019,
  1.0217658812564017, 1.4648391881294263, 0.10656764475592037,
  -0.36333137586924336, 1.2038900768159346, 1.129844725322527,
  1.0751731122450805, 0.012358268193384878, -0.23598002615674998,
  0.49415157243417984, 0.5312846985577996, -0.5927552330017514,
  -0.517015518683276, -0.6755828545359516, -1.2856594220333852,
  1.8543260367066103, 0.1532679253395689, 0.5898535844343807, -0.73362760944215,
  -2.044509175945934, 0.6760483231119152, 0.4668116294558627,
  -0.5621246831681022, 0.8884145565914567, -0.9535126284966857,
  -1.1459723822544945, 1.3541036701503029, -0.6769779475755117,
  1.2470681933838204, -0.9777018986743812, -0.04226931367682262,
  -1.01181876934722, 0.8792325343524928, -1.6396105235333718, 0.996980494878763,
  0.3674822163844057, 0.22865535404992687, 0.48959173503969294,
  0.6701719947860222, 0.21077799103407294, 0.11803521013968957,
  -0.9207321338479888, -3.047185459088422, -0.45639596646201214,
  -0.668240497675614, 0.755422959591144, 0.02290083003102504,
  0.5539156819663363, -0.782058446584685, 0.22712895535423105,
  -1.2049166882028879, -0.9511069892657537, -1.8735942598492998,
  2.33318043535234, -1.6331951062632841, 1.9753206507470926, 1.9202029978350035,
  -1.0200069736831203, -0.5445294001064632, -0.6960226527234681,
  -1.6756241511908465, -2.0752924644015365, 2.399028129209694,
  -0.35533501835259146, 1.2615584586210729, 0.35594090165763703,
  -0.07159540600066222, 0.6171078513497155, 0.815924499018559,
  0.19961215319424516, -0.4084324625476778, 0.11769726818591142,
  -0.034586167050305684, -0.6454052446351741, -1.153947596393113,
  0.15545195772708245, -1.7631190340216283, 0.2555085685177223,
  -0.23269981496104558, -0.44260126705675606, 0.3093071260424887,
  0.17526743341479692, 0.49820378438198626, 1.4720970379382734,
  0.73615667757097, 0.3942011372663069, -0.2826706623931519,
  -1.1357520238351329, 1.2932746316261978, 0.47808976351324906,
  -0.21753960952129955, -0.8848307972243453, -0.6799503610881343,
  1.2098412201401478, -0.3275618240960229, 0.09894425558528158,
  1.2176497798540642, 0.1343413776245835, -0.02961527998992501,
  0.2618601266199036, 0.0843110704061003, -2.8881758882215007,
  0.3028371756555408, -0.3125906878361478, -0.8746403742539419,
  -0.05660649961650377, -0.38717941530943045, -0.47064937504301,
  0.34803934911825263, -0.5326913768375139, -0.6540342890039232,
  0.4009819128830155, 0.89725253724135, -0.3943540677700749, 0.6575079896381636,
  1.122346234248196, 0.5314805016707291, 2.116825602950067, 0.4687458365243589,
  0.09217229982448254, 0.13632865652666817, -1.1198390006405958,
  0.48054126377810097, -0.8277193068089825, -1.8409279272482333,
  0.296844319463394, 0.11665843878236715, 1.3905919704780856,
  0.9431136490981196, -1.9640620304172498, -0.2567850249513146,
  0.0782714358916613, -0.16157183199697864, 0.8276874208570777,
  -0.5230108941186038, -0.11023796501559799, -1.009965214075846,
  0.11324922293031842, -0.23400934009114105, -2.490080173287134,
  -1.6057270959611905, -1.0310390656209727, -1.3680101925690364,
  0.8482563107660124, -0.9895201524178139, 1.475485824807478, 2.179232357234307,
  -1.425823405752501, 0.15767833942591175, -1.1039558611368159,
  -1.8655921506322968, -1.1534615046501406, 1.1046385428501535,
  1.1012582226755003, 1.0156605968091588, 0.2529788242952192,
  -0.30515495073602933, 2.213643742512799, -0.5267793087784395,
  1.315929633203107, 0.7816626320402922, 1.2699262164309757, 0.533538969774565,
  -1.8407333228937406, -0.6668592032670854, -3.2116023777963085,
  0.268913419395042, -0.15061221015681478, -0.5518424463232307,
  1.2334952169768627, 0.051702764414064954, 0.4708017504966914,
  -0.43310183547353953, 0.7000740021061109, 1.709350989096445,
  0.08434827772576829, -0.6593769050262672, 0.0949745640056637,
  0.30009287834409903, 0.7711857663871994, -0.2890013862613161,
  -0.29459463905539657, -0.47073145120207843, -0.20987341051270045,
  0.059868193831586466, 1.060424892738514, 0.4256330001087172,
  0.6856199980583861, -1.2958920190152692, -1.5920914153716277,
  0.6694173032946256, -0.34544375825639706, -2.4821194548488146,
  -0.7794214691858724, -0.304528940146932, 0.8875470009073425,
  0.7361235916085663, -0.07971166986921242, 1.2830607843693054,
  0.41796304973879794, 1.1218284338309883, -1.0039607003521045,
  -0.010084964197841705, -0.1355738805085022, -0.4044531562491584,
  0.9756695304497787, -0.12860403243812854, 0.1847107284041671,
  1.178066823706232, -0.13206049175309265, 0.17835928659261507,
  0.8322494656263192, -0.6721165287003703, 1.628783001407807, 0.681983856138879,
  1.2954791123600038, -0.9122180976380426, 2.3977013864303567,
  -0.22322831045961244, -0.3585181097336328, 0.04834966746348254,
  -1.3550928057562164, -1.379543509176434, -0.47501860177688293,
  -1.1060006712942352, 1.0084591874468143, -0.48216057553326735,
  1.2097791068789443, 0.6294210665507197, 1.3941533830645574,
  -0.019201471344978925, -1.798618716914402, 0.2681898644084113,
  2.0567293316295188, 0.9057169489663928, -0.5380089016831557,
  0.40455953465527206, 0.388485347430965, 0.38903225443881884,
  -0.6935621710766836, 0.9208760097134313, 0.7297605205055242,
  -0.7764760568786836, -0.8123403527439756, 3.4007119163947137,
  1.9425366072432937, 0.08730332411125404, 0.36930970100814947,
  0.0766222182687734, 0.2517492711486139, -0.24487032816581542,
  -0.5218955255827105, 0.5290612614726187, -0.3225779343312562,
  -0.42029815854025415, 0.6840220112889994, 0.8100242675094154,
  -0.6666921024334093, 1.9610562921505732, -0.2723302943777939,
  0.3182426884253883, -0.2024086030312919, 2.0841968653159597,
  -0.703468549936617, 2.6431750454797105, -1.0069767261142113,
  1.1364505763765937, 1.5295171921120463, 1.534770768322357,
  -0.2186836401853325, -0.2139581769910976, -0.7072676297501332,
  -0.32378988113481566, 1.0044904677775464, 1.3221070612754158,
  -1.2421679997816724, -1.4054019961821014, -1.8491378441721844,
  1.3522565773754958, -0.7891733445782595, 0.6518055571871477,
  -0.5903067455746364, 1.089913994818762, -0.2440947233886307,
  -0.06764389711944017, 0.07107543551534991, -1.2063234163561827,
  -1.009236038492729, -0.153394530484774, -0.4604747067180567,
  0.027223573581721554, -0.7309987462817502, 1.395497531507444,
  0.0452521199090763, 0.1941108567088494, -0.7639264248122386,
  0.5986091889131043, 0.037424730237750704, -1.34601517416601,
  0.25613858925725946, -0.7436368961050197, -0.2163082975516398,
  0.06256489204217906, 1.0850761934667204, -1.413241769627126,
  -0.13862154311715202, 1.3333338395179857, 0.39225421431825447,
  -1.1785817906695881, 0.4199906515795054, 0.08670762685568915,
  0.8737123413701665, -0.6794620752468042, -1.2151973716865665,
  0.7464585834521639, 1.816624619617146, -1.4532381009537991,
  0.23273785551299292, -1.285814408854448, 0.48411812177697533,
  1.133941539667818, 1.8877797251950696, -0.8355795890225289,
  -0.579494136966293, -0.4114464920898004, -0.6176422373260222,
  -0.25863253172303957, 2.8432762544182233, -1.3674982087459793,
  1.1525903163182656, -0.20587637417614757, 0.4652210120040277,
  -0.48253380114134803, 0.720673032147653, 2.6768997330189226,
  -1.3793034480415622, 0.08064468410487892, -0.687509945138974,
  0.3982985670359945, 0.20137857727996147, -1.8593544855636301,
  2.1795449465726993, -0.7398273417773831, 2.1233483776834388,
  0.7512678095408618, -0.4018950060198518, -0.0037195979602243803,
  -0.3169327362271143, 1.1015988091675768, -0.3060876395358913,
  1.6871202081846022, -1.324593895914332, -0.4452911376344588,
  -0.5252259564940556, -0.3152210464593147, -0.8493684777031097,
  -1.3870113073163544, -1.2981982983715061, -0.12187088990479672,
  -0.8241052088542765, -0.26447750239737444, 1.228305671883656,
  0.9413983052726239, 1.0853875646438726, -0.8816503400924753,
  0.19680038589223328, 0.8040594145308131, 0.46361395417915985,
  -0.28761382986240047, 1.3314711340388872, -0.4056383035847072,
  0.45956349793896767, -1.0924461687682119, -0.8727973084645327,
  0.26439790167421073, 0.8059946459069524, 1.9161170948792212,
  0.08947115784537163, 1.4858568450097074, -1.0944977699646667,
  0.41369413744876743, 2.352650504587388, -0.1492662760834313,
  1.2190749275969965, -1.2282048297523211, -0.3949818983880014,
  -0.5670661068541568, -0.38065901728467855, 0.47770906442201416,
  -0.5190605620862736, -1.6820941909308753, -0.3379097497929886,
  -0.1801203632379973, 1.896087602490798, 0.8380303148794388,
  -0.24216435852464613, -0.9098248710319777, -1.1091100096136937,
  -0.03886244918349174, -0.9384089781005083, -1.3790753779474152,
  -2.322609739279581, -0.7215702105105114, 1.0385790747859445,
  -1.44169878622399, -1.5878500106087252, -1.2086178123551918,
  1.648131812699384, 1.0324710118468714, 1.261870619937399, 1.2048297149566771,
  -1.5353195250014158, 1.7271293749667005, -0.6840184305201268,
  0.6062804009067153, 1.1505465070265932, 1.4802217200823837,
  1.1902235234141265, -0.8003831986880992, -0.24012823459584298,
  0.731949310912467, 0.3747741990905514, 0.4208393574616222, 1.0887729917993858,
  -0.07359393662023679, -0.6888942267565499, -0.4038401192565359,
  0.6014155033022656, -0.12050352483161407, 0.03932188884726795,
  0.02588924748535684, 0.6493385359361924, 0.5289360660219355,
  -1.6558003788679168, -0.14357143848828138, -1.4380314241491536,
  -0.3013443397672906, -1.2107973937503236, -0.3512800775803918,
  1.280059159558455, -1.6246797672882232, 0.794969868253215,
  -0.5590298365591301, -0.4257027818256404, 0.5110733042094421,
  0.11848556501234217, 0.4753257033295828, -0.24023584947012236,
  -0.10669079887069427, 0.5882827350264219, -0.2522792360246405,
  0.8643142102353151, 0.23266914277226425, -1.2139231517938438,
  1.2622159128314703, 0.22894872502199318, -0.0033547785845644746,
  0.3120287942453177, -0.5051786381568403, 1.2095164684029154,
  0.4840595199711569, 0.374261607729485, -0.08796423303247126,
  -1.364924117508767, -1.414483803031896, -0.8955137605576046,
  -1.4315247259462633, -0.10932458159250118, 0.5637906422852942,
  0.5432639441238858, -0.6334538036919185, -0.37240138103654513,
  0.17888243504561127, 1.1370082552628054, -0.9978611480367682,
  -0.35402777962061416, 0.9984820732387828, 1.919413596808078,
  0.0913780326858898, 1.0647580376743504, -0.49971543001382, 1.2896846242733127,
  0.6446686390240169, 0.8639637199299518, 0.5896136342793411,
  0.7502357908126346, -1.3477260104241344, 0.6553214421287858,
  -2.0697280694990874, -0.33484257673044765, 0.7158257477280349,
  -0.3662066979895129, -1.0604718575598922, 0.18150198819284274,
  -0.27876276049654897, 2.104344145438236, 1.2581583273224632,
  0.11991242342450369, -1.3444185254600496, 0.6780809112678395,
  -1.8621030074305007, 0.8099566477488931, -0.13188720870956866,
  0.6184270328386747, 1.1375428615750145, -0.40412585619773034,
  -0.4320164522757232, -1.3609942576960985, -0.25095401178731674,
  1.5543762485239898, 0.06610382262353072, -0.5853688892908422,
  -1.0844691597089, 0.143957659828032, 1.9645373582925303, -0.5959960659731841,
  -0.050362429862429135, -0.17257262385618294, 0.3357728599272261,
  -0.40563694081810736, -0.2566725017132907, 0.10799513448712307,
  -1.769433231655827, 0.7862400973697662, 0.3710604497418754,
  -0.5342748436312993, 0.11983448704749705, -0.23930376548632462,
  0.614388995331979, -0.10811504922918463, -1.1889682320473478,
  -0.021873559487813755, -1.223424243329044, 0.10460557809388497,
  0.19644845538934166, -2.0187815825325988, -1.2700511001064563,
  1.4776792817547106, -1.5006852566061177, -0.3412521621991259,
  -0.6175494362832858, 2.4847826176731846, -0.033094098302702074,
  0.6211918625067563, -0.3124286260721349, 1.1698060353462796,
  0.02641611890668102, -1.3821975044063348, 0.056390662951427944,
  -0.499652206298171, 1.0681628843964537, -0.5182484633983749,
  0.3326152337429577, 1.25281968246926, -0.5424651740127279, 0.6781165289469144,
  -0.4342993791217186, -0.6020339677112422, 0.1866675162360356,
  0.0722829680482465, 0.5207380813697942, -1.6572078439249, -0.7798070682403607,
  0.4309600851791462, 1.3857939198716522, -0.5943241694175787,
  -1.2729286160736468, -0.5062793518487356, 0.1684387475663334,
  0.547082551210535, -1.2629908334477353, 0.9274371934145196, 0.823458048223196,
  -0.3781106692127488, -1.8467703320455477, -0.5925579184762518,
  -0.671824149992297, 1.3089243661261927, -0.7920887484529904,
  -0.05076461297433815, -2.149622254353643, -0.26554360008480443,
  -0.7075912086129359, -0.22014058550689236, -0.056943663970514855,
  -0.3357676031921982, 1.5417862788821335, -0.28593497521709327,
  0.6959202920783006, -0.8406442176956442, 0.5911750342679165,
  0.5054855126448957, -0.1679650019307254, 0.13861518052792962,
  1.1555931532871782, 0.7515951717485738, -0.09093889555919818,
  -0.24448134126644708, 0.2908823899498322, 1.0242530353589692,
  -1.2626097451547873, -0.9907062209022213, -0.46391958561537017,
  -1.5052307681167794, -0.8252480993041567, 1.2519324882732727,
  1.4253871115260512, 1.1595318799743493, 0.4503544585092662,
  -0.28189228584337805, -0.4155451162355891, 1.5834339813253673,
  1.2241135396854868, 0.5179286848208571, 0.33941317332805343,
  0.9240934109956283, 1.5726624605927355, 1.5273629763216952,
  -0.1443852307069359, -0.3480998751571499, -1.1077444891653114,
  0.7093234158502133, -1.625091003694464, 0.6367254535031721,
  0.4603170010025111, 0.46835724729666506, 1.1877899533856162,
  1.8046141457302673, -2.171863097221761, -0.3431503849330753,
  0.4015142218796856, 0.4631950787353064, 1.0441794556291937,
  0.6800083044087194, -0.42104476675280905, 0.6441739031149156,
  1.1241089799749433, 0.9064928321150844, 1.3806067930624606,
  -0.9559472734090211, 1.5342437813839864, -0.4233617156955888,
  1.0173812301564864, -0.04103452268085169, 0.9970119471431133,
  0.3117030136225845, 0.1650473218834917, -0.030533272255852114,
  -0.8758540177312879, 1.0175118593626402, 0.7851706049565862,
  0.03449579201171338, 0.4150578486390241, 1.564033638174437,
  -1.1332222083698995, 0.32495739974706295, 1.3078822663482936,
  -1.2495135809595581, 1.3750151231078924, 2.671901535762046,
  -0.17557973608800445, -1.2549581231389217, -1.8488551499881856,
  -1.0524640541055499, 0.15745511651127106, 2.206509005140499,
  -0.5718906798102383, 0.06871932792027634, 1.0409460512542281,
  -0.9181957433114404, 0.3489311406282477, -0.6295317330870053,
  0.0025273462547253695, -0.254614503142127, -0.4975389813507524,
  -0.4757334384791985, 1.0306701924854524, 0.8150989941499177,
  0.2699641373971889, -0.5425780493441125, -0.5170238261604528,
  1.0636410327201677, -1.6404579579108005, -1.567228365638782,
  0.34282120686307743, -0.016140245413173816, -0.5219184702196623,
  -0.2533085612156649, -1.4800989619569604, -1.0871389539464127,
  -0.9552938668646387, 1.7481691518323004, 0.11821485788289783,
  -0.25718045250560506, 0.39572095498824833, 1.899827125460659,
  -1.5849392384339898, -0.6340165984279094, -0.03477298255124545,
  0.304829962765254, 1.4444842806339626, -0.3285291188442728,
  0.7312844593167074, -0.3859360149633886, 0.5510615682131083,
  0.501456592763163, 0.5942689325767522, 0.27055907286494113,
  -0.32884488768495695, -0.4755629614302262, 1.1326150303548956,
  -0.8632835232855781, 0.39268188611123706, -2.4415871675336405,
  -2.738628915237744, 0.49536823103560346, -0.4893004003483104,
  0.7532230901868915, -0.6217144633859222, -0.37978097512284487,
  -0.8860824624349858, -1.9094651617050766, 0.17876768423995043,
  -0.5048672167011369, 2.4023873273190457, -0.2589349139305336,
  -1.1651995843317695, 0.6187310717214102, 1.510972585113103,
  1.5067553004829708, 1.1880282395463124, -0.1244075531360987,
  -0.08913544029885594, 1.6113471127039427, -0.11641767359427914,
  -0.9394911095456139, -1.3908745475759678, 0.001179221460264948,
  0.04704010690398048, 0.27137056580430513, -0.09097281457894317,
  -0.5647405696966088, -0.37100853268124634, -0.8098027401947371,
  0.16777690668495113, 1.5532269376440289, -0.8690155785440822,
  0.5848708007796027, 0.8639683803024988, 0.5556646976920127,
  1.7089652279487346, -0.4407552880886958, 0.021447438690232832,
  0.8439066885218744, 0.7976522790669954, -1.983846249938071,
  0.8949010091130105, -0.24897436483187968, -0.5646497082362636,
  -0.12628727575263757, -0.1521068422410228, -1.0667867569449887,
  1.7384931084924162, 0.2199085598788933, -1.5300992409676615,
  0.1398169470002378, -0.3173616332933728, 0.40667332453296406,
  0.6360886883682942, -0.22136012505626804, -0.8735955060504756,
  -0.7982391218289021, 1.8803391399291818, 0.8167789048390866,
  0.4149195566697642, 0.12869312337099253, 0.38147222549939247,
  0.37795296139470164, 0.6062755817060757, -0.09111317987458317,
  0.33852237216005543, -1.0976086889330423, 0.6173403451487198,
  0.6750580624326573, -1.4383260859253433, 0.18585400869509314,
  -1.4641418519847793, -0.426298221415471, 0.7031078879585831,
  -1.8492260672113978, -0.8455112133338989, 0.09337082448113009,
  -1.0531144023680235, -0.25203695356561195, -1.5974320322736693,
  -0.7461932647162236, -0.5789242359137291, -0.43880484155075833,
  -0.3957747508044477, 0.06761106368596422, 0.5566799491153561,
  1.3907776871080433, -1.3073814327988738, 0.3189108897102848,
  0.981429392784158, -0.7858735641666974, 2.066439192932744, -1.386889363268559,
  0.26556008656968777, -0.8852903815286727, 1.2137001628200457,
  0.16491230190121048, 1.7970629628216768, -1.031660373904563,
  0.392268528542676, -1.2785482016818923, -1.9465837124749046,
  -1.2062948859779739, -0.8927877529910253, -2.3944040598284095,
  0.20188654641134574, 0.7093154930109544, 0.7001990470331874,
  0.500889140719706, -0.6489390825353895, 1.031549681718374,
  -0.19818848863075952, -0.29158391009719753, 2.9758615130230437,
  -0.6889348318435284, -0.35809226679668804, -0.48031664915205446,
  0.30712865092720953, -1.1565896638320452, 2.287928800923657,
  -0.7848756893531149, -0.3931460831070143, -0.5989999271733671,
  0.8406873854798558, 1.0898951319283077, -0.24093376671377473,
  -1.2749298000218843, 1.2445446153410422, -0.4141119604434819,
  0.1897230841006775, -0.2840269233506245, -1.6926131440710106,
  0.3194774420483197, 2.1756900396119945, 1.1158708915847233,
  0.9847976506742783, -2.3435207937808973, 0.6992870646030964,
  0.17057736471094767, -1.3476195014006158, -0.7207506732905669,
  -0.9239846567439568, 2.409715330862836, 2.110203437149164,
  0.06270403831984007, 0.1664203202387056, -0.7374801096606594,
  -1.164833081275005, 0.5827998866856199, 1.205998813871221,
  -0.5358591582586331, -0.08743897708969907, -1.358823291445712,
  -1.0789672415272904, -1.1690225940452366, -0.6030415061694508,
  -0.1713494467840359, 0.557656753497241, 0.3534604106177035,
  -0.5697389677996244, -0.39789902071503447, -0.22140688521447896,
  -0.548947859955032, -0.1790171824193921, 0.5021490408723934,
  1.8328998929548306, 0.5711643188647796, -0.04779542338450676,
  -0.19254252793201815, -1.4694217105706593, 1.9979997037346797,
  2.600311090990817, 0.01781210494277861, 0.08176645740049732,
  -1.0429837846170418, 2.345855021441383, 1.2535385317302228,
  -1.7889496264332128, 1.6250519708166753, 0.567290534518221,
  -0.3730849636723398, -1.8005554583671952, -0.1364733705409478,
  -0.19133718281628717, 0.35251367899477143, -1.3112509714251936,
  2.0361237318737704, 0.27959451007186586, -1.7232990922168412,
  0.28873666703369283, 0.3622125146993046, 1.615543667394805,
  0.49197738993063744, 0.7271555891594017, 0.0943523203538828,
  0.5535513749247569, 0.7766809468970026, 1.4446995490075318,
  0.003065983001714555, 1.0263014112686006, -0.45155910235653707,
  1.7726149610914212, -0.6984091545934595, 0.4461997602656258,
  -0.18877041825993082, -0.8778524829228779, -1.2439190260697455,
  -1.2000075453097203, 0.637973375682693, 0.34308389552391655,
  -0.2436039877398232, -0.051079536517278486, -0.20393907672182027,
  -1.5716901225881357, -0.8930276959017643, 0.7031005058242007,
  -0.4894824805131817, 1.245121136547631, 0.7163357313032089,
  0.8725359060117787, -1.4853081541031339, 0.8585286350586895,
  0.391883998308783, 0.3754643471521587, -1.6336646643367359,
  -0.1070366359843705, 2.1981343550561463, 0.8007425693108762,
  -0.1358028592915179, 0.6855254079931384, 0.5549058259488336,
  1.1165553835903885, 0.942185432026313, 1.3286349171503582,
  -0.9859118316156716, 0.9465286045563369, 0.5002108671182989,
  0.48883695299266405, -1.5882923942500564, 0.2590633755060448,
  0.42224110945088833, 0.41255045089651765, -0.5293457810074721,
  0.28988973263697826, 0.3360083427183975, 0.17191570687735344,
  1.7256507273074149, -0.5830369175856004, -1.337396137137263,
  1.215466843509589, -1.2107861050879456, -0.4110028485838882,
  -0.9053689100008635, 0.2639215174062513, -0.7807817380870773,
  -0.4443573362309619, 0.7980763309784269, 1.7550939325484056,
  1.3119471945226069, -1.0526375353524926, -1.5160348406625808,
  1.014998659704722, 1.0151302699938785, 0.9580369956365796,
  -0.03133169749597984, 0.10674903976026502, 0.5985919851456308,
  -1.0944812269200248, 0.3388820306208103, -1.379237457063757,
  -0.6725241629562806, -0.16339542502998064, -0.7468444279511862,
  0.2825034450394357, 1.394430092918197, -0.5105489648317899,
  0.19600937883165903, 1.8463897553549027, 0.053459967297574,
  -1.1286974290737397, -0.5809164126812795, -0.5358141491995395,
  -0.8759191772699475, 1.5112593344881853, -0.5625410979078952,
  -0.18519890606116113, -0.11989541672925, 0.25985888188997447,
  -0.6853639264974457, 0.560107655144563, 0.2314628700872821,
  -0.4469645548630551, -0.5172675604589839, -0.08383560447561482,
  0.4500553762021253, 0.27214420581713283, 0.2628962822048646,
  0.6551726795618253, 1.0579990515612196, -1.4926188562273277,
  -0.9033882907417962, -0.04975911534149973, 0.2940530695886974,
  0.5860396042527828, 0.372085268135721, 0.3782802384491242, 0.6956110473907634,
  -0.5251641741380074, 2.6391849710512165, -0.549388484608921,
  -1.0719575951334817, 0.09779403926773442, 1.0412090128066909,
  -0.24206770899554939, -1.4944456052050699, 1.2874248848654781,
  0.02588532161393047, 0.16982121850537665, -0.707156771610142,
  1.0961030401914917, 0.42967605285099986, -0.44560042969654984,
  0.6087225837648867, 1.2590593408324013, -0.978700945718642,
  -0.4585798842547467, 1.4005964065919414, 0.3745515728011306,
  -0.9616790613994499, -0.6923477533856179, -0.5340433201441787,
  0.23694031674598165, -2.6477591970600773, 0.1799916692636522,
  -0.618196465666147, 0.021752704302129847, 2.4673620755066223,
  0.6947606239489271, 0.7763118067986605, -0.7458743567532478,
  0.5053396034550819, -0.9225720129533354, -1.68257525242121,
  1.3868722334583192, -0.4774018192603618, -2.268379356949276,
  -2.0984126927949722, 1.138810292378469, -1.042733266243622,
  0.24932101142201873, 0.8224086248512584, -0.34492342012298766,
  -0.386962039461585, 1.5077103779859125, -1.3797866664143024,
  -0.13877395647687615, -0.8138842387883066, 0.4652351254860078,
  -0.170830575110122, 0.4278173297828492, -0.79115204499237,
  -1.8312828452037822, 0.1319242643203562, 0.8508150192914207,
  -0.11505868605138772, 0.3328556047703983, -0.9249516006117059,
  -1.2078339732827956, 0.22000734735382543, 1.9066154616958055,
  -1.7107197894638295, 0.3561973156641173, 0.292746734942758,
  0.1710024202287314, -2.5259434163498287, -0.9499037620429247,
  3.0169409571925336, 0.5823747777828002, 2.2867959407561016,
  0.0406361845690167, 0.06689555187249868, -1.2269346670963581,
  -0.622437242197099, 0.43665176000430694, 1.090182947610886,
  0.8884703417371218, -0.6029487518061549, 0.4762612125003957,
  -0.49849825559228467, -1.9299058410192724, 1.493353387953696,
  -0.3438392471907561, -0.7413512856301686, -1.4335300732879204,
  -2.2811653739041486, 0.2565804751356194, -0.8841705953751615,
  -0.17763299178589634, 1.5829299825596341, 0.005214490617231553,
  0.304124221430102, -1.301740656991124, -0.27709491941469916,
  0.289755780376287, -1.5792355526428736, -1.311334445060831,
  -0.49296877493963265, 0.6729664302078462, -1.26570080955534,
  0.09323828824900365, 0.267843192111357, 1.6529201952594998,
  0.16122573597304582, 0.8411616128043687, -0.5176146252518401,
  0.06882900165539507, -2.029719773641667, 0.6133988150496362,
  0.06235295387461209, 0.02406007931275076, -0.3609749261496692,
  0.8236361372763216, -0.868111237766032, -0.1492623642934495,
  0.4049163708494537, 2.3557178313978273, 0.13732197706153476,
  2.026934865309131, 0.19779694794288258, -0.24956098133709337,
  0.034716096662548164, -0.24822923005121025, -1.6676873915434407,
  -0.09053132680812889, 0.50163267166909, -0.2157012518632773,
  0.6749205290119682, -0.9682475275142014, -1.0011552078686108,
  -0.06172438348339051, 0.012778930937459153, 0.684737081571055,
  0.40761475430690025, -1.5104729733523388, -0.9448609947092195,
  -0.38636484078183686, -1.403593721004489, 0.9368074086527561,
  0.39292292101195564, -0.5664042108764286, -0.005390609273164462,
  -1.3484950410355978, -1.1112047326463497, 0.5254308192826472,
  -0.43233977912246074, -0.2643741032287009, 0.273945297641568,
  0.933425088321628, 0.5796642780879748, -1.1834397990036705,
  -1.1858867387593826, -0.04867042463880205, -0.6658764085717508,
  1.3663399683952528, 0.6347253598303274, 1.2447208707648967,
  -0.1325629186394315, 1.6237718583704117, -0.4238964112736425,
  -2.1949306493246463, -0.0836739410473672, 0.3101240416691013,
  -0.4706115561478321, -1.5893737920929598, -0.4572397154583823,
  -0.22238928204333405, 0.4995487082742399, -0.3119800903619445,
  -1.2172794357419605, -0.7104603140969472, -0.6273197960446726,
  0.07371317580675739, 0.08601623403221084, 0.25572470162585237,
  0.19085979487079094, 0.5933002015843132, 0.5616280987125734,
  -0.14038876328512095, 0.10344106256773412, 0.7170408051765965,
  -0.12660472553681912, -1.3555025584955194, 0.15728009504525614,
  0.9366539552949001, -1.0015889673663505, 0.1810834353654806,
  -1.2281354818268915, -1.5969691519949571, 0.47256766937935235,
  -1.0189451688933449, 1.326656623236677, 0.2995125248692054,
  -0.8707687990068134, -0.06110076932619735, -0.8585589599598287,
  0.8694704301606333, 2.0632186110355417, -0.8347958578496509,
  0.9513126104984001, -0.8507163206604518, 0.8583803496427991,
  0.9157907453023428, -1.59882108685137, 2.0880305459177175, 0.3893952822530759,
  -0.5512699103349504, 1.274614691443056, 0.5175597280278759,
  0.17723003895341827, -0.8532321949834959, 0.5287895840128225,
  0.009518515569142913, -1.4656972342506553, -0.6979966848161178,
  -0.40666663102303036, -1.0598678906274333, 0.6319911280728144,
  -0.059302541797342866, 0.7312782333168049, 1.5148141536642803,
  -0.6239546462819733, 0.5559462349466304, 1.2623756917128985,
  -0.055061497554131714, 1.793863595435134, -1.2186609380258255,
  -0.154936753980838, 0.6958816817100942, 1.3942939400713963,
  0.8849443548111032, -0.4995887774375814, -0.02939907437813113,
  0.19828978994058927, -0.3728316918912787, -0.2984464641799502,
  -0.428112018953336, 1.4077130329405187, 2.5139184423704766, 1.375232180227692,
  1.4107764691916351, -0.05037152321115922, -0.28659342407692456,
  -0.933748911439922, 0.027567621168111612, 0.01773284059422664,
  -0.506276710504024, -0.022694606947658427, 0.3998961262513978,
  -0.2655262253876838, 0.7406938861458281, 1.0187527053807826,
  -1.3625098419439625, 1.4051128488151625, 0.9812997438650063,
  0.0978256215519882, 0.457139517456645, 0.2659075988293429, 1.6757241416287618,
  1.2417072508891402, -0.4631079436764828, 1.1203807778409445,
  1.5562671159951087, 0.4250400162297651, 1.3074065915214839, -0.28859505355226,
  0.0811659987310242, 0.6274828954238849, -0.6657962847123476,
  -0.09098902399528759, -0.9166497605670593, 1.0568110941479503,
  -1.6208284262445474, 0.9456676380576384, 0.5087615225362497,
  0.3006329689702117, -1.557546784896922, -0.40148816023986217,
  1.4364867794133545, -0.3043318732202858, -0.16255093298448764,
  0.08960068218086742, 2.5832687035487494, -1.3166610915163652,
  -1.336049163831454, 1.955972784526001, -1.741554596771432, 1.4668509250530735,
  1.802447550825025, 0.6609465333035934, 1.08339214458147, 0.6960872364313622,
  0.013542066788991603, 1.1501670900825083, 0.29562832736616096,
  -0.8395779912121446, 2.8129508008797153, -0.6667957871453281,
  -0.0057918452776588815, 1.2964808735647098, -1.80243049438426,
  -0.4517250829608996, 0.6686606736209606, 0.7368616684499502,
  -1.5679476092678566, 1.57680162708906, -0.7447384276917389, 0.911310537938118,
  -0.18600354452417323, -1.1746691135256788, -0.15170733758169352,
  0.2611798774491846, 0.892326641969372, 2.183370702843962, -0.1710978596858201,
  -0.39823185392487487, -0.7916881161548676, -1.0692469755260225,
  -1.169039912975881, 1.0980243045252411, 1.27689189273058, -2.25876364494586,
  -0.6301160699455511, -0.7060055211507373, 0.34143849288200884,
  -0.6995460843897072, -1.1751860923349084, -0.3720613373817353,
  -0.4325382533227911, -0.47937468702923863, -1.249731231847826,
  -0.6885518219702659, -0.5064835914221328, -0.1296807181221035,
  -0.3643484603151809, 0.29879548569601166, 0.0041835158525214185,
  2.0114669920201735, -0.06406604450560302, -1.0318882940010252,
  -1.5682031824993705, -0.5932341734749957, 1.2207521243504482,
  0.24025240330538394, -0.39240319299074705, 1.568234486555843,
  -1.324716759198747, 0.3498353963324507, -0.2573028471052638,
  0.7001727685512336, -1.2773022938937557, 0.7705201490375971,
  1.067162122081538, -0.7367852415576929, -0.4723139784790318,
  0.21018094597840725, -0.539842807589578, -0.36397706745805053,
  1.0873946520291107, 0.8231607478410334, 0.7068552487387244,
  -2.6556335572183074, 1.0825574922459094, 1.3279175841268722,
  0.7751188870845513, -0.28264743138910287, 0.003942672007534684,
  0.12321018215224112, -0.5814002487237657, -0.289417671019029,
  0.8560713057567149, -1.0436152588833767, -0.512149129936171,
  -0.5124175915965407, 1.0085687489666026, 0.3593054249702758,
  0.15770849832050482, -0.7184579700879247, 2.3305153720379908,
  0.953378252025947, -2.804518782648886, 0.7231982754232809, 0.0993095721424039,
  -1.7535327226754875, -0.07345493185191079, 1.0503129382534133,
  0.6235782353810254, -1.2288894657306582, 0.9660652643714713,
  0.0017468995450101643, 1.5854310858000538, 2.8011428761271056,
  -0.9565231190989998, -1.494648544192584, 1.007240108957692, 0.596723357590798,
  0.9360589517440829, -0.14595106045009246, 0.957547569726456,
  -0.370274991621937, -0.7058444895575547, -2.3531993772678255,
  -1.4985058810342085, 0.2213071174285174, 0.31083999459838274,
  -0.6794769179790602, -0.08723918523507881, -1.5266006709867554,
  0.23651322319051177, 0.6486020238260931, 0.01811419566521249,
  -0.13320611515723355, -0.1400460053152591, 0.1010033533277972,
  -0.9897873502712826, -1.6586831082946423, -0.3211283202873718,
  1.284868142532675, 0.8135059084711765, 0.8652662485529637,
  -2.0277530326415496, -0.6914304028039252, 2.2697810267500165,
  -1.0659317795765146, 0.49250548331544314, -1.5808444171725131,
  0.246857736842705, -0.06931982739823343, -0.954675155949025,
  -0.4765617622531947, 0.4667907473980257, 0.5667203474358307,
  -1.965701552350848, 0.6618594889386575, -1.1225399663274278,
  -0.3302101603918189, 0.4833662976901673, -0.23047658431512474,
  -1.9864002458393153, 1.850433812063576, 0.02025670794491279,
  1.3917229622146343, -0.8898546378004945, 1.7333742758420145,
  -0.7867093829019408, 0.5430482506697497, -1.674356718642647,
  0.40955785974105763, -0.9633669096711017, 1.7199725068530214,
  0.7619306723883765, 0.7018931401356858, -0.7399321413805321,
  -0.7160248014592958, 1.0161557917392448, -0.5837060406278212,
  -0.038481019166412944, -0.34688344919407954, -0.9802167998813426,
  -1.666827560373934, 1.3431808233661782, 0.057031712138624756,
  1.2903585571542486, 1.374361453699086, -0.4850922707649655,
  0.7615338576755343, -0.5074617995705526, 0.6437625174388456,
  0.14197706002863203, -1.4512131259819592, 1.3067751697451249,
  -0.001808081716418485, -2.608795040216807, 0.8334713572956434,
  0.7057224657439402, 1.004081928512543, 2.3854136289826187,
  -1.0125924610530692, -0.17839424318549832, 0.6256735934608166,
  -0.9063885708404813, 1.3595304519076195, -0.8435422291234324,
  1.425753589451991, -0.4630145330460178, 0.9184452306937259,
  1.2976970153707388, -0.8135640223943743, -1.3492626207102234,
  1.4392101035847789, -0.5735755395562699, -0.14911431857364116,
  -0.36867322367381, 0.6040896121918918, 1.0378093873560539, 1.4504278177544325,
  1.1044023803797558, -0.3886528144938064, -0.2256951872685083,
  0.4799858216670086, 0.10913040619716276, -0.9126403650729109,
  0.5810596209929774, 1.054943056958536, 0.8527578762596949,
  -1.0293326244547596, 0.7891434062341784, 0.9448079456371016,
  1.0327974911948594, -0.9776200806174469, 2.00415624332211,
  0.11387324663168473, 1.589133775211143, 1.0756430263211407,
  1.0444178611865367, 0.23357342947223506, 0.1581251888240149,
  -1.1495603835268169, -0.9552596780784782, -1.3325634829590984,
  0.8404122499947818, -0.2271195305487371, -0.5211298794487741,
  0.3729262754564416, -0.3516825484281577, 0.25764504992095744,
  0.3956796643840235, -0.1240681751016562, -0.7291769576247974,
  -0.6692607695291859, 1.1043151823486752, -1.0750079294521722,
  -0.4090474085825177, -0.3966393742082607, -0.827137416074305,
  0.5149549807475355, -0.5328325423780758, 0.04753490993731977,
  0.19149319328732867, 1.5481545751142538, -1.269910200076811,
  -1.3252342439419882, -0.361149363118767, -1.4242026710642388,
  2.1031675807032157, 0.4224382968491799, 0.3783943271070165,
  -1.6507032204118854, 0.5337712385765043, -1.3042775322497486,
  1.1614954464544103, -1.1486637057043134, -1.0112137575911866,
  -0.9410154170655798, 1.3230513419514394, 0.07899117217271634,
  0.3679631983757192, -1.7921662636231928, 0.9612492235207424,
  1.1926679331454997, 1.1642980153801494, 0.23124666812116548,
  0.6494760921526647, 2.467614870660226, 0.6962078260494885, 1.627608246852418,
  -0.8695271314648995, -0.5765643681015077, -0.1286049065574992,
  1.2517092826977283, -0.8537814741445627, -0.18485322455910894,
  -0.4781624720797261, 0.3036196833556849, 1.599664850457478,
  0.05286836628549004, 1.308228842282238, -0.8820191433865273,
  0.018138483828171563, 0.04181254537664099, -0.748100509406093,
  0.6311280240041999, 0.5558807946281473, 0.9165832260239444,
  0.030945259168674872, 1.7166406899535145, 0.2696236464746942,
  1.3108015788114746, 2.0469969372427363, -1.516520261362415,
  0.7192968558010109, 0.888496806610429, -0.6165691748494071,
  -0.8101714965017129, -1.190029323871187, -0.4563130350042221,
  0.20453688334064968, 0.8471870002750195, 0.14824698719368587,
  1.5112277359375799, -0.08374940994469843, -0.1485790294942816,
  -0.20011767712661732, -0.49647295829968513, 0.03962205653069751,
  -1.3769842499413973, 0.8630084036981999, -0.4478541255104894,
  -0.5393179169324783, -0.4747967584803409, -0.1334384361492848,
  -0.07832194250923857, -0.3368815302147266, -1.3710545808007364,
  -0.2828078002233511, 1.1573080440715302, 0.19954156416578098,
  -0.4775384373666144, 2.5445488739412934, -0.09421598299885883,
  -1.411260778457409, 0.17604948445823526, -0.7708769197662381,
  -1.0403978660113542, -0.3942179156408179, -1.2673502204398113,
  0.0521727750331276, -0.10517260987690555, -0.22027958861942423,
  0.07517125503237658, 1.1495808945513468, -1.3457048704688264,
  -0.6349118322456664, -0.4333778491517331, -0.031474456943942145,
  0.5058572502480524, -0.5970365460787199, 2.954857142348511,
  1.5395483831564332, -1.0980890807196184, -0.5428178617105966,
  -1.5508803894037686, 1.5036613692955176, 0.07607189041565765,
  -0.20276688655599429, -0.20963383653372758, 0.14047638603481383,
  0.3686270591895746, -1.615433460610391, -0.6668188869428859,
  -0.46713680603243973, 1.2362893403174746, 0.14580187209417927,
  0.04010024595999873, -0.8918254303775007, -0.4186593171228464,
  1.767614822265375, -1.8232427565363676, -1.9873314062264114,
  -0.63718532011905, 0.5528580015047921, 1.0969513278616485,
  -1.0827691861044184, 0.23168962479329794, 0.3092810531847954,
  0.40421554896519896, -0.9904819691720372, 0.853349817028574,
  -1.4138472083114537, -1.4483870974366369, 0.8696209714663397,
  0.5190491591723827, 0.06103930715674685, 2.649379843272919,
  0.5836318563791695, -0.12958939866921787, 0.5796022216489507,
  0.1619155903856163, 0.51807121188901, -1.8366918679003055,
  -0.024317964344365898, -0.47942767463239894, -0.04307679544729821,
  0.3831167180179323, -0.8545851522036003, 0.089163242148389,
  0.39065668310889684, -0.3790688514524479, 1.329044060754173,
  0.5694240686554264, 0.22657413990669253, -1.5247629591895593,
  -0.09920853970914838, -0.45560727725334327, -0.3555054274799636,
  -1.6766644932560808, 0.8902626539100369, 0.5105084996662269,
  0.5156398580801009, -1.038838150729805, -0.10711114263148323,
  0.30189920822635075, -1.813583681397082, 1.0575818204118796,
  0.5293871313609102, 0.10774355095131885, -0.877459403774224,
  -1.2976385197939415, -1.0366264630145765, -0.1318938940668487,
  0.3655761831917481, 2.4872506414717086, -0.20471068764914258,
  1.235277439772808, 1.4845533803261335, 0.6311493737454259, 1.4897424137946311,
  0.047253615253587435, -0.3054076877426417, 0.5493589597335855,
  0.4947488867100677, -0.1846481253944075, -0.674646964503388,
  0.7562115006516644, -0.06189019599812097, -0.18352767446150867,
  1.0543339229767206, -0.8472863646043272, 1.6210599848516023,
  0.5819069560562656, 1.7723385408913248, -0.09426673411586185,
  1.9476602667534073, -2.436944486324516, 1.4249208591682774,
  0.8830565772761436, -0.7158842725802167, -0.44193273698774105,
  -0.41552565952707876, -0.8429625152253128, -2.1863143633403443,
  0.4540389569572331, 0.5220655091632582, 1.0845094827882982,
  1.0447774588435048, 1.6227243772747575, 0.9730905621275091,
  -1.1400280135860106, -1.2732074014788328, 1.5086335084355904,
  0.36364317703471455, -1.4356505661101377, -1.6722558775702796,
  0.03803719574161837, 0.06319563452775087, 2.236680783184563,
  -1.3223828820002606, -0.3508097809648622, 0.6802699645307988,
  -2.5993032442486546, -1.0934072137185682, 0.498166668124658,
  -0.21765844919527866, 0.7721060339638954, -0.8313352219225998,
  0.021584597119888138, -1.7312255964583083, 1.125379819902221,
  2.0112063969763825, -1.0511297754800695, 1.0484625731934487,
  0.7538796244035122, -0.8214684080151097, -1.2344916975705864,
  -0.8705915179163315, -0.45937394056471187, 0.6423097000429719,
  1.6752069076386533, -0.242020305590856, -0.05129399801384385,
  -0.4923282552712791, -0.06232200306024798, -1.095354693789161,
  -1.748280027090289, 0.6503428095621709, 0.9335045002556683,
  1.7526715810580917, -0.5406476867332702, -1.2846359389660649,
  0.6602732122325384, 0.4097479217234418, -1.159813693229101,
  -1.498900544375915, -0.0360448911136118, -0.8430397140982153,
  0.8286960925202062, -0.4897227608756179, -1.1676036443966131,
  0.884532458642839, -0.36135612997086963, 0.5757487919345144,
  0.347103337850671, -0.013169498475162083, -1.0118994465637101,
  -1.085422869205935, 1.1894065146581172, -2.205269221834009,
  1.1130414120311574, -0.6706377859359415, -0.18875824501459582,
  -0.4199889858732297, -0.08201663994567898, 1.606386998997651,
  0.7617938883255275, 0.16581309308762626, 0.19516737260294956,
  0.1871018125662415, -0.897977159797853, 0.27163401610591176,
  0.42617722360155325, -1.8238318107604767, -0.38847258169745313,
  -0.10622878989996026, 1.3531088801541753, 0.0682059234368943,
  -0.5772781822670426, -0.3265626271637715, 0.7195708663296984,
  2.8184814310514916, 1.9279051148662276, -0.037690681741223954,
  0.7320094775048693, -1.4404203679957677, 0.29604651397722775,
  -1.148201304654263, -0.46644728078304054, 0.5623585373381423,
  -0.42377894260049953, -0.0173077487284879, 0.19663034352964212,
  -0.027624765967138136, -0.7291404702982972, 0.6917533200543791,
  0.5970299534961729, -1.1421535147907362, 1.0783840791626425,
  -1.6343915431652216, 1.0293525122041431, 1.6674970290479896,
  -0.18691339580181385, -0.9494718325295269, 0.13454451002656448,
  0.9503666901735726, -0.06429605919069653, 0.24074519505888134,
  1.3760567927433633, 1.1283380555252644, 0.031216363255809464,
  0.4390674724332295, 0.4030821760079086, -0.8900667282849136,
  -1.3279484079275394, 0.8861774335643366, 0.04952140235908036,
  0.674222557239697, -0.5636907408272464, -0.9385671336236329,
  0.1715505491962362, -0.7847505383466293, -1.056111898143947,
  -0.6436653139455925, -0.09755737093050457, -0.133947015593964,
  -0.07875527992426777, 0.9925303948636073, 1.1501507050121278,
  -0.8245124651535243, 1.1263344275361424, 0.5838667618066796,
  1.7664725736226947, -0.02365946369328168, 1.1532547835951876,
  -0.6164254314937864, 2.25158274758533, -0.2014994407446992,
  -0.966404252385078, -0.24500334397387372, -0.986240218788514,
  -0.07359016324282842, -0.9732372454207052, -0.8043843667075931,
  0.716582775480059, 1.7726243695236483, -1.1956236096494424,
  -0.6009684431390753, -0.7809268587134053, 0.16413259009002112,
  0.9753717844412158, -0.9996599956454782, -3.0811052262871175,
  0.2668906903863124, 1.9239023955406662, -0.09111313266371128,
  -0.5266319572722281, -2.2904019048725544, 2.181539840895304,
  1.6154733978108882, -0.4210266232724175, 1.301838799557046,
  -1.3557900047730567, -0.0653757624750915, -0.605861067733098,
  -0.418098768215677, -2.6907534606839154, 1.3167593606684038,
  -1.468754139323438, -1.9954106708036656, -0.6088355230442518,
  -1.4070229263904814, 0.6126046086502689, 1.195392949813084,
  -0.3903311300496125, -1.9713671063875315, -0.03210322418098058,
  -2.3912903319351306, -0.5763412304027152, 1.4601735940022191,
  -1.2676673699023795, 1.1207057939214815, -1.8562380529467197,
  2.253773803908255, -1.8298056343446127, -0.24409527285157995,
  -0.7965089446218007, 2.1475522508643228, -0.8824164966146049,
  -0.11725581685116818, -0.4010583551439644, 0.07257668945787663,
  -1.583408842592405, 0.7316929408502095, 0.7605065783012508,
  0.42267694466967426, 0.9217314013615434, -0.7004849462796781,
  0.5629109147325131, 0.1448330325843638, 0.5059382728023812,
  -0.1368208644382869, 0.864890132868328, 1.3150404904449517,
  1.3478462501678228, 0.5536425457823733, -0.2314462886064635,
  0.3549619028446152, 1.0588716282781259, 0.45755659399409465,
  -0.9631683876060299, -0.5093022590612546, 1.0465620362030306,
  1.0260217297765126, -1.3486309324226042, 0.02097958495399169,
  0.5448951865642232, 0.4968439285804641, 0.9530728647853434,
  -1.252374700993279, 1.1408501600996213, -1.4155170313719758,
  0.6872127968887137, -0.7182930878427398, -0.5558716008759659,
  0.05680467402525483, 1.4072786461041327, 0.1153707660992138,
  0.2524164135693527, 0.20341622806203966, -0.14730853178292838,
  -0.9435027781131823, 0.39133186505032713, 1.4413744107098356,
  0.4250203748017783, -0.08957055281535214, 0.47756417856213385,
  1.696315175861306, -0.3999268364052717, -1.0735117614912506,
  -0.47437293265364877, -1.20889943652852, 1.6896940916332635,
  -0.12366812417005496, -1.6480610268376936, -1.325101878253846,
  0.571856326682278, -0.11314397980044308, -0.2211192068585641,
  0.6428387167891992, 1.1318635695188577, -0.19408058529967895,
  2.4818162735968063, -0.778791351378174, -0.5666392837409248,
  -0.8108540498601009, -0.4965564194788553, 0.2450993781583896,
  -1.6703977322972796, -0.48350106680788085, -0.68972487286473,
  -0.19629279994209206, -1.3377915957672184, 1.529396664831661,
  0.8297550238203739, 0.32300120832201845, 1.850772789653834,
  -0.26448529436834733, 0.8065052218326089, 0.7441515765077779,
  -1.2979238802823785, 0.6529804862640548, -0.4213606731659206,
  0.10831577989070314, 1.1276793746524332, -0.514370481170885, 3.09517792569352,
  -0.7042781598972544, -0.31497638246485893, -1.6435516530430478,
  -1.103300920240539, 0.05563439866562707, 0.09496415701977316,
  0.8050033424104879, -1.009197118765082, 0.2984044797718147,
  -1.0181765141345622, 2.001523399308145, -1.1788325176891463,
  -0.5938043566905783, 0.3786158082081513, -1.689608561950282,
  -0.1069482520767783, 1.4151679836058975, -0.9565947222876718,
  1.1026912015726138, -1.2631032315616169, -1.1432203237027094,
  -1.8605917192327028, 0.4572969568130884, 0.23685766514111303,
  1.8534328039119268, -0.5738271759154643, 0.9051638376491281,
  0.6190830922599324, -0.23959451839633836, 0.3903992752812064,
  -0.1769703985137109, 0.5219972384217425, 0.8831947981224757,
  -0.024455826936012053, -0.8261533053228557, 0.5914789011735105,
  2.5968026451354898, 1.9661444338956533, -1.350360795728321,
  -0.06694899145367106, 0.7332023366459725, -0.1050864200455755,
  0.83638381167464, -0.9926248677203683, 1.6400651011184637,
  -1.6491508653115148, -0.18085479891874037, -0.7031637517733168,
  0.39647556541234735, -1.0196598940314534, 1.2025261026393785,
  0.6839195356539348, 0.8772622399760968, -0.19112231752028958,
  1.6931552562523309, 2.231358382366759, 0.4034804333930062,
  0.19974218000971386, 0.008972243972480625, 0.6753731587993408,
  0.6348698155998823, -0.008670807550253406, 1.0428527259703733,
  0.5160955142059285, 0.4623466178233335, 0.4746162327515519,
  0.1173208158384705, 0.00876940790513462, 1.4717164542451244,
  -0.3988691282702658, 0.596081725565168, -0.5565501167116677,
  -0.019009991516667765, -0.21987488706109018, 1.0296406325558478,
  0.36853495298157923, -0.6677568055260644, -1.180658952630238,
  -1.2489103284531724, -0.035975219940665146, -0.4090899058196288,
  0.25225998440064995, -1.549105883437581, -0.9207180661793206,
  -0.18992588588354598, 0.9373524687627881, 0.6554836101757597,
  0.05992534524496557, -1.1011968347425067, -0.18587672295541582,
  -0.10655063932640423, 0.5358203364442959, -1.424437948570616,
  1.7742242041786547, -0.6638194833873632, 0.31030880487456436,
  0.494766975078066, -0.6037900495221082, -0.3237341508629026,
  -0.7275466640477586, 0.5101432949493899, -2.566207484957088,
  -0.03571190103405034, -3.2014407859083187, -0.03984551056106187,
  0.4851501471554149, -1.285448840492273, -1.741371476001884,
  -0.07367101755182098, -0.6093937162404979, 0.6728818217362396,
  -0.4519244518727881, 1.2036859834236582, 0.4088411444098324,
  -1.0425860186923266, 1.7310949424232311, 0.28225846919115144,
  -0.6155231258561158, 1.3134034892403743, 0.7320891788115502,
  1.3726847958834225, -0.4371620142698797, 0.8149080731459324,
  -0.7095817372019692, 0.4821930382047982, 1.1672887602358575,
  1.0342324213200254, -0.05410850280584476, 0.16194648538183404,
  -0.3913866331653398, -0.4249249739663828, 0.06949554142988364,
  -1.10579349070789, 1.0230887834780291, -0.04322925886086912,
  -0.33619928586837233, 2.176209391547835, 0.5350215866836254,
  0.23852328013717478, -1.5726361110859384, 0.45341874379951175,
  0.9297485844017218, -0.7281347287436254, 0.17255451018216775,
  0.7055722304479992, 0.7632931178614464, -0.4937871318125849,
  0.5498201114291933, -0.7737054268839527, 0.21404286421058305,
  0.8259902762305096, 0.43185070788044333, -0.24039424806187865,
  -0.06583094122390402, -0.7848498082552506, 0.7406908424858628,
  0.8961268157026989, 0.17450514765936967, -0.34517601575900886,
  0.21821739410921737, -0.19601907508100358, -0.639613667072518,
  -2.1140708325045803, -1.106771005198042, -0.7923651353535323,
  0.5395722293628897, -0.6636406848539957, -0.9793325422066292,
  1.5126188764357775, -0.572946699217878, -1.1072326434034316,
  -0.8875859431290795, 0.4407671990297946, -0.28519446993207614,
  0.6219936194638336, 0.06718991861392579, -1.3708551275776983,
  -1.009611548064511, 0.4065160629818216, 2.36826229753308, 0.18778602483143825,
  0.7748222550389265, 0.22693998852172814, -1.1383493600782826,
  -0.7208173751464748, 0.4817685257464131, -0.07888506921515936,
  -0.06624576908459877, 0.46761703821997863, -0.8986610056633483,
  -0.314252040240427, 0.07813792740149982, 0.8856552647490842,
  -0.18238691621739012, 0.19946826810995447, 1.6963747721400775,
  -0.022425939357786667, -0.44096219917866025, -1.9426842958096517,
  0.534893845935168, -1.1978565726815649, -0.6387627719668135,
  -0.9688295857387628, -1.4506856661762366, -2.7735044613778848,
  -0.33003421940801425, 0.8341847316403903, -1.2063513020688155,
  -0.021456741232728407, -0.29869756926126323, 0.18681699872897695,
  -0.2696694010116527, -0.44369486939542024, -0.2922794274583086,
  0.14034273823471952, -0.5845586821905637, 0.6456729598257731,
  1.593101009941713, -0.5395641998915667, -0.010979151362696862,
  0.8167540205034338, -0.8349283091395575, 0.20626194589807245,
  0.08980256856854295, 0.6604196506542597, -0.7284113919700785,
  -0.027843970317397132, -1.2338624692411784, -0.8439354181187417,
  0.1975137030203764, -0.7139400927030367, 0.5557825024197509,
  -0.5769355866659739, 0.7597597910109877, 0.6771882786942977,
  -1.4599453108277523, -1.4402702233463724, -0.48797278168922403,
  -1.3680837082603836, -0.06902041566516863, -0.8080171400607229,
  0.1446386721750831, -0.09558697603694781, -1.8519105088515448,
  -0.21396248895607173, 1.7665218648210355, 1.285757784854888,
  -1.322598585010455, -1.2270041152697182, -0.21476498475668823,
  0.21170202666132995, -0.7130813683516296, -0.10791193565338945,
  0.11983344055630618, 1.0172077593881788, 0.7186420750846898,
  0.31483885888453944, 1.0168636574519376, -1.6618880578615012,
  0.5292065611691915, -0.5700500347511326, -0.22635253995134919,
  -0.1256005585719919, -0.5571301821789796, 0.6418282474413434,
  1.022845620932003, 0.3781904149535106, -0.9548533963581299,
  0.28330606810078146, 1.20876604469932, 1.007664656534242, 2.3899557212172327,
  -1.912871982702543, -0.5030533075076652, 1.442222826043659,
  -0.4048118293176716, 1.0245846343312632, 0.577899890598464,
  1.3224909577951809, -0.38886045801236824, -0.3744943962420339,
  -0.615935350119065, -0.23303888223282324, -1.1268633472605907,
  1.0555960981146557, 2.8616550782165784, -1.0675579714040095,
  -2.90900549760358, -0.8347473826412404, 2.2513240287601617,
  0.9936628072449389, -0.5479550929875933, -0.131972563657213,
  0.4444348330309951, -0.2899808517084358, -0.017166141182875645,
  0.2388139406222107, 0.8972920445917713, -0.17011105080641117,
  0.47774415888758776, 1.8159138220415927, 0.029139397389653925,
  -0.8205424883144808, -0.004263456254103675, -0.21057184425428213,
  0.5722359608078178, 1.054376467963567, 1.8184855274661904, 0.5127761172951707,
  -0.6555882358142215, 0.6191026906082504, 0.10809084480791142,
  0.7632370924649059, -0.6317778843958232, 0.4482646718596629,
  -0.34699349257033796, 0.2962861469467789, 0.6698194746653076,
  -0.6246941071181976, -0.4526532468051721, 0.06603459787957375,
  0.7220514007276894, 0.452164621361705, 1.3070767204026967,
  -1.5013564998157103, 0.6619435247649741, 2.3293022079283188,
  -1.277749765729139, -0.043384305480648054, 0.4443915261357966,
  0.20928162326189553, 1.283372598675985, 0.7041036136635039,
  0.02333295430053168, -0.6703244568567616, -0.5369730953366036,
  1.608400207994858, 0.7580388769460222, -0.5103687552940708,
  0.5155976492818929, 0.5255808228666471, 1.0655897733174087,
  0.0685840964722921, -0.6204426283245604, -1.27967574976146,
  0.8627111647125725, 0.1122035548396378, 0.7581157604572953,
  -1.1739405035977466, -0.09930198093982522, 1.0736453355768596,
  0.4189367107328681, 0.3482499585808416, -1.1194489288847949,
  1.3410818205243729, -2.0586252276164627, 1.1511753186538192,
  -0.09721565813959437, 0.5287366133036752, 0.6826407922763142,
  -1.581491028629891, -0.1955881331422582, -0.7220458806767345,
  1.4957084791540203, -0.7101485268024698, 0.8601816783732599,
  1.2134515894185773, -1.485922799259355, -0.335856494956359, 1.790812985978721,
  0.1889463222253214, -0.5412009219240246, -0.5636056159152548,
  -0.29787140441284726, -0.38807232621037524, -0.6768944839823926,
  1.443331937106065, -0.2902637908696638, 0.7720939186978936, 1.128111089150144,
  1.0523780816780068, 0.38952874504867036, 1.1987073698174389,
  -0.21232101050872026, -1.2434995166662541, 0.3160624477235723,
  -0.6581422399298201, 1.9353798574843935, 0.3941122419484124,
  0.3896992836178511, 0.5558750437925718, -2.169041034178709, -0.71748797014458,
  -0.6456805270227062, 0.7797042389335734, -1.5714637709557193,
  0.3125106541695288, 0.6696745912947204, -1.0339425097619124,
  -1.3336497844986015, -1.1525842325400995, -1.53210945687965,
  -0.38641211233055384, 0.7631439786955745, -0.4692076402244406,
  -2.0438450051036376, 0.5475351301079836, -0.7875759690774199,
  0.019761104879506164, -0.63039139653769, 0.9310109278505834,
  0.5593247844969456, -0.4205794109493893, 1.3332367265224245,
  -0.4557559804569067, -0.8575971528697879, -0.08890464143678005,
  0.3489654001215827, 1.0353189526336355, -0.20637216580967083,
  1.9714415878773122, 0.7458574282628205, -0.8554458159083848,
  -0.5078858728376512, 0.9944494602248667, 1.1250518125139486,
  -0.46752921456460594, 0.6171914300467644, 0.701347757056669,
  -0.11487407798816152, -0.3236368984578001, -0.6814476397537582,
  -1.3307320625106354, -1.0059851159086992, -2.1798767514211597,
  0.6406201647873838, 0.35730151422826834, 0.30664308409256574,
  1.155556088779018, -0.8532383305480592, -0.5779669680776514,
  1.0765135367385787, -1.9337904916222932, 0.15910933430798924,
  0.2859708042590921, 0.9442998165057024, 0.9358946728361591,
  -1.4808253934765891, 1.7146642910854362, 1.2913242235237965,
  -1.6487062347447763, 0.29896182348072325, -0.7583863858237339,
  0.4555636233179068, -0.8586209350508026, -1.3105027505768378,
  -1.142003479329586, -0.20555746455482346, 0.05575591482146817,
  1.19616953047271, 1.3833300720292603, -0.6421808884354985, 0.410940408113103,
  -0.014693586846924121, -1.2436748151230208, 1.3154522116947134,
  0.47168795059880997, -0.12581006159649272, 2.1963489621824586,
  -0.11684944241550005, -0.696755888133864, 0.3476921974318549,
  0.34119122263388424, 0.20602870373255747, 1.1440234733847034,
  0.6823620822697005, 1.2616401587967732, 2.5555738642096544,
  -2.7305322939249494, -0.8361425418225846, 0.8788493183421711,
  1.2877875981065852, -0.3022019715828763, -0.11701457675815306,
  -0.28798616914405223, -0.5497764681092716, -0.877953297016709,
  -0.4215876898782517, -1.6360177676214491, -0.7478423188006468,
  -1.2574890258907276, -1.9597825371164368, -0.38865522581234285,
  -1.0998995361794937, 1.3196106529394875, -0.06340615131758776,
  -0.5284389096304764, 0.04568410137541966, 0.19012251511774017,
  -0.00837479282587475, 0.6135598393210615, 1.249162436486015,
  -0.9468432340173331, -0.814755263157194, 0.2349507512986985,
  -0.10817233301961603, -0.834502068036868, 0.11625250351530157,
  -2.0739446783205104, 1.5868763031303368, 0.99320630577691,
  -0.8627576441785852, -0.07827791909095183, -0.9479782346509683,
  0.415268597717494, -0.7160052413344721, -0.20699297106411826,
  0.8394660942168045, -0.334457980891418, 1.5761492944958646,
  0.1463920242742345, -0.3649465418979761, 0.30368285556243835,
  -0.35999522337776185, -1.055551059661936, -0.31184211790971866,
  -0.31850196206892667, -0.6431179922732108, 1.3759533401705184,
  0.7673397438608662, 0.6743120570274944, 0.3164391310401339,
  1.8452927086121629, 0.37372250403441465, 0.09261376469623751,
  -0.8547528463793614, -0.03978304668656009, -0.48614928138294516,
  0.2837950119169774, 0.7188379946070064, 0.18276811805482995,
  0.15873258714252167, 1.4682653521170967, -0.129385555180106,
  -0.715682562931226, 0.3217185338220821, 1.175180298079392,
  -2.4847932119953757, -1.0953187917278533, -0.3161597740134458,
  1.5526982298517351, 1.4017147798218939, 0.9804997402523811,
  2.9689625142724365, 2.1228214539079735, -0.9374590280453101,
  -1.5396241127669559, 1.9874870752738694, 0.969184393985154,
  -0.5394893924680313, 0.7700458537024116, -1.9065611173678891,
  -1.1223110840666128, 0.06981560629949062, 0.2823322614041835,
  0.7880841721513047, -0.4932206095718419, 1.0603722535770435,
  -0.39947608094424203, 0.4143968257163202, -2.6305019730427284,
  -0.1527686709942569, 1.2830002232172082, 0.43888994704142303,
  -2.0651153781877105, -0.18775154894229276, 0.12819178434163833,
  -2.5173965789255486, -0.9929219333084479, -1.7423277408989335,
  -0.9602191558955763, -0.19724581820230025, 0.15265487001150357,
  -0.7243347079779694, -0.202881729682898, -1.9483366692453579,
  -0.5697825471817257, 1.176152197228844, 0.001631535279285714,
  1.2897282198085864, 0.9952418544454301, -2.025609943205426,
  -0.31961010404989654, -0.02344594414529139, 1.1884315125727496,
  -0.9490133113320041, 0.6688185453063739, 0.682789884785093,
  0.15384203235427002, -1.462393491618248, 0.7296988657043955,
  -0.27737946877681346, -0.5925857662764206, -0.26109935452027433,
  -0.9655955417837849, -0.9536628283341966, 1.4081721763863235,
  0.693122149630327, 0.8543083960864122, -1.356044023136248, 1.652584949471206,
  0.47929610889059276, -1.4143012484331146, 0.2925074047380438,
  0.7545582935392607, -1.1140576168353182, -0.9125306831560678,
  1.1770018391169161, -1.1741309802862812, -1.0877984379293317,
  0.1594262208716486, 0.7541147062680023, -1.2068557326280531,
  0.6939581132844164, -0.5917905989389104, 1.5088029720563576,
  0.3551201422383205, 0.8817073052982953, -0.70046372626062,
  0.18477749528459378, 0.17462961326050624, 1.6842268769962836,
  -0.37731056529138235, 0.8912158920278427, 2.4674558803546662,
  -0.3643239998443594, -0.016575380569103633, -0.5691118867294853,
  -0.4259825836340626, -0.332567251104732, 0.9498711127581106,
  -0.12674473421715263, 0.8515681741728592, -0.9851731197184349,
  0.8602046357660547, -1.8012955310682508, -0.7512335071808003,
  0.2547028917028943, 1.9716254084315894, 0.6723717406208383,
  0.7265704687639817, -1.9016316481634623, 0.3777542961944155,
  1.7368109970875818, 0.5690195002142777, 0.3168631230150074,
  0.3738669503548522, -0.45438788036196565, -0.9755933693151956,
  -0.6961168548209171, 0.20289552330751728, 2.408810119198355,
  -0.5046875778807094, 0.03486426021398014, -0.03673152315414261,
  0.8028423356998049, -1.4461370425550537, 0.07651487978042544,
  -0.016786969504363175, -0.937560427156413, 0.780495830610636,
  -0.3462194427436917, -2.2527294415737376, 0.11244252526620681,
  1.0960679028381595, -1.1821897164777573, 1.5286427759692232,
  -0.48561063494816853, 0.7958793308434786, 0.9641013541815916,
  0.5169431032044426, -0.8689588869724981, 0.5916247389028714,
  -0.20781748005280176, -1.0215633870259224, -0.4929977068174652,
  0.3405526610564183, -0.29666547262542525, -0.04414648660839257,
  -0.7998880325236888, -0.8794063558178591, -1.2436190086675203,
  -0.2864246246404271, -0.31624070676323945, -0.31242899837156995,
  0.8371856895567054, -0.3895049967749363, -0.02520900345054998,
  -1.4198937422233837, -2.013865315530271, 0.009084103092707447,
  0.23921828169942702, -1.6622415875551084, -1.879906646349376,
  0.04867224280418372, 0.3395265519456152, -0.6742509082620651,
  0.7089141232599969, 1.0184074840789488, 1.6179751255335575,
  -0.14673828896406596, -0.6948815278860032, -0.9242388840095755,
  0.2330205304241642, 2.023508762369489, 0.8170996532709268, 0.5893167766425036,
  -2.027789138633845, -1.0724203994615764, -1.2842408302157517,
  -0.9972988219050481, 1.7277520169567684, -1.9620847153529921,
  0.10589775736230397, 0.6534374060396724, 0.2781825431286676,
  -0.41007362747883047, -0.5657701963790137, -0.5942346703707999,
  -0.0037246748426265903, -1.846200045284872, 0.8402951633811268,
  0.4561432902870702, 0.6678817492922974, -1.2563305545052146,
  -0.6363350895199296, -1.4044077565222524, -0.6241208578861734,
  0.050167416181284806, -0.13047002629978208, -0.33739962460988215,
  -0.0793661302829271, 0.2291208955724937, -0.3201396695298543,
  -1.835157922412675, -1.1035644458014224, -1.2296274648050833,
  0.31353311184154475, -0.06156388994412262, 2.0889835974461657,
  -0.543352636860949, 0.6422459423367312, 1.1784718899813769, 0.824334314481433,
  1.8492223310330533, -0.16793157384445184, -0.8491944440291964,
  2.534863603445891, -0.06721021221850959, -1.1237314245669003,
  -0.9145171808095006, -0.3968197250791497, 0.7715625601347701,
  -1.1548544789758919, 1.5828785350861916, -0.4872797477060655,
  -0.4020009766982555, -0.9952153113743915, 0.30660731925142315,
  -2.444341948832177, 0.19651865684173458, 0.22114174979347023,
  0.8244859391689701, 1.1215374653340304, 0.9087795781614775,
  2.0172685304428972, 0.842517061395334, 0.1685729458951193,
  -0.5845133937766314, -1.6160367106462028, 0.9074585061346795,
  -0.21102603405043838, 0.4425973826937234, 0.4023306773688398,
  2.4039019491769142, -0.5016004893561312, 0.6702307609747822,
  -0.2796737307788297, 0.3311616372840285, 1.2699644473699048,
  -0.48858996902322244, -0.15181993678719555, 0.10457411192869988,
  0.5528649494721585, 0.49441628116957786, -0.46177500384364484,
  0.8652585295431731, -0.26578543613221556, -0.5428168111306672,
  -0.8911106370816978, 0.8375437857841407, 0.5813337782799052,
  -1.6974267946430277, 0.7455927388863978, 0.6996974407607164,
  0.4631554673361046, 0.2428814987371479, -0.44065503493056274,
  -0.3529017229496357, -0.7404984310596828, -1.4952014535614448,
  0.8317168497687728, -0.6182819290555935, -0.5956464430013131,
  1.479971936424365, 0.45293373095327344, 0.1779078598404231,
  1.3095131738189827, -0.026606550624815917, -0.08168668845033494,
  1.1383761575582099, 1.605854556692404, -1.2112583561996628, -1.49825875017067,
  0.07125183424061017, 0.14015747413165922, 0.45498409994869365,
  -1.912310150778528, -0.5439696051424753, 1.087740450228969,
  0.14238494293079312, -0.9304062267943036, 0.10847322346042428,
  -2.0038565289839183, 0.2878107037903056, 0.4291752728531956,
  1.2444125592444617, 2.2490333979920587, 0.6783137259843289,
  0.5889266946522065, -0.28269007596311124, 0.4427335018145459,
  0.4236169251504772, 0.26900444436758975, 0.5184159965906158,
  0.9565909246948763, 0.1936919067634986, 1.3609684325760807,
  -0.18723343451442415, -0.1861507188020973, -0.27323541435307686,
  0.8910999841262051, 0.6396877547892298, 0.9265360634013137,
  0.5628948545938489, -0.35069133950463677, -0.6193874912248823,
  0.5688404461473648, 0.2246820599716494, 0.1408129585578179,
  1.2470755345922098, -0.8615871554618776, 1.4197116107673449,
  0.4857984739433021, -0.48270968072739834, -0.3836525570145181,
  -1.624755011965724, -0.8786502530125248, 0.39640364323829,
  0.07096495115176092, -0.25440837013796314, -1.4860850928822573,
  -0.5563358623433802, 0.7401316495060658, 0.03850974000821486,
  0.7351497269870387, -0.8881421584889039, -0.9872797123013266,
  -1.2999231425700588, -2.66347148940702, -0.6045964950967223,
  1.6275157514249314, -1.137896234879516, -0.7232022773744846,
  0.5981177342501425, 0.5798757541501014, -1.6524274585317442,
  -0.3412863076588085, -0.6829528111857527, 0.20607821209630223,
  0.3249291191023058, 1.5312053924612756, 0.9749769728364858,
  1.0563979353832702, 0.4734294759477792, -2.8524185733133196,
  -0.05637792296100392, -0.9665822480170453, 0.8190429187890189,
  0.2500490404461336, 1.5190335425853, -1.2414393561943422,
  -0.42256508850529756, 0.07491008111828835, 0.9311761053131212,
  -0.6261659308203071, 0.4231586038346661, -0.6557900485973824,
  -1.4992581033598058, 1.9629546075267632, -0.29984589782505616,
  -1.0393849476756627, -2.007586144981807, 0.14919728265143054,
  0.6846831458364537, 1.2662943531390463, 1.0094072783104346,
  0.4493211911277351, -0.07766322462230357, -1.1423171051458634,
  -1.0279702135594182, -0.4700469115769411, 1.882476465275977,
  -0.7991480233119953, 0.38597861924194315, -0.28390482658013044,
  0.46843886548564256, 0.4665589973218121, -0.21016536768006175,
  0.02456985635231666, 1.01168356645324, 1.756429231089377, 1.008614097446762,
  1.5390816132368061, -1.691051888381309, 1.334003639084796, 0.9127105007545202,
  -0.7280437107366579, -0.043859532251202794, 2.3918423961741584,
  -0.9778963557257496, 0.055844908457013474, 2.0254578422046463,
  0.4052137273735987, -0.6630809997030379, -0.5007723447688296,
  0.9844074172042165, -0.06469332314464732, 0.15082065992395388,
  -0.19312193347495893, -0.7048665383467723, -0.9350963820593247,
  0.9197786815239585, -1.033128897306577, -0.0393961880798618,
  -1.0011106548978919, 1.923268169903586, 0.0003705876297085239,
  0.06879871104593163, 0.25519842703638035, 1.388568613535924,
  0.7746854017372035, -1.4865730684836869, -0.9953333989533282,
  1.0724985546233599, -0.8004850582283205, -2.6666430749421677,
  -2.8422780397499956, 0.7053065806324711, 1.0315643790629385,
  -0.8271949792842519, 0.1444261856231413, -0.49672518382014963,
  -0.31835272997761915, -0.8951280665881809, 0.8352608432966842,
  -0.4090081091598316, -0.08349100320347796, -0.33004833165014125,
  0.21413003341551176, 0.688672501918071, 1.6121346922138835,
  -0.14028097034262246, -2.1956954290962094, -0.5576564864746951,
  0.3200651697827152, -0.3319754694440482, 1.612598375686609,
  0.2478521742726931, -0.41485401680689193, -0.06601512017637755,
  0.5543047793162849, -1.3767586308964284, 0.9822373359334439,
  -1.1452628970120868, -0.2572808230341682, -0.23737407717868544,
  -0.5731226381067742, -0.6331557353774099, 0.649098982103103,
  0.13045069757305233, -0.676046838422239, 0.5823443330634084,
  -1.7336559853750517, -1.2351332258019216, 1.144170087502414,
  1.812454337174654, -0.8162691295928345, -0.26576706486612534,
  -0.5607717115410127, 0.956253185057908, 1.2293315145083823,
  1.8895393149059385, 0.27240831592652054, -0.06444704682726812,
  -0.1551322817420194, 1.2677289592608607, -0.19505210819169433,
  0.2950874151964862, -0.3457392867835415, 0.23761356414472357,
  0.07648441798842119, 1.9702375677936266, 0.49297287711025267,
  -0.12915568920522155, -1.394832848520266, -1.418634332593795,
  -1.4269863243995664, 0.005842900128597996, 0.07066417378966079,
  -1.8748997118264197, -1.218009927188752, -1.0875260931236392,
  0.7585705306238166, -0.19582472891829356, -1.2288979234997546,
  0.3562313395304872, -1.676213806536536, 0.033441451999499244,
  -1.1617786598448576, 0.32729871379935876, 0.2478906527161256,
  0.1377131100106522, 0.004031920619530649, -0.48798521567319936,
  2.1542498049463292, 0.2629969657419375, 1.0048897398005336,
  -0.1349933041362816, 0.2752014795666532, 0.7236942237742174,
  1.0920733345589393, -0.489565654518396, 0.5813860696318426,
  1.3205220244881775, -0.3371667023327777, 0.7779295390527633,
  0.9704484660448652, 1.1207758450097434, -0.015722300705556365,
  0.7981876121815835, -0.34619056268411724, 0.148896493645735,
  -0.6787164298766803, -0.25819579583161273, -0.7850116111476484,
  -1.6138838677022165, 0.171204012093973, -1.2182542092480524,
  -2.1815263338875046, 1.4381509483843198, 0.021737225805041047,
  -1.0451033494832793, -0.8974384231296778, 0.8756943585060398,
  -0.7940612622185367, -1.5702919995307179, 0.49401824900185437,
  0.3281089780034921, -1.056568470124154, 1.6940311039864748,
  0.13477570722376286, -1.4612520628612473, -0.973906936404025,
  -0.1920514219519002, -0.012455976707654767, 0.01137717107057773,
  -0.33282167188095896, -0.3118845645932268, 0.7244259171619155,
  -2.5081832082218383, -1.0787835135136163, -0.06689254627916454,
  -0.7451931797264075, 0.7510343174565981, -0.36819275372395005,
  0.9332955978310351, 0.928089999443405, -0.5992438183828095,
  0.26028342777997093, -0.40211773114655025, -1.5191314309751236,
  1.3276252352054552, -1.4160344967927245, -0.3307041280308166,
  0.43332815480017606, 0.0033116093708976937, 0.048137882753117,
  -0.6535160891604495, 2.069623075909816, 2.049868554897902, 1.1802267552549406,
  0.08189096117426556, -0.4533951930871297, 0.3218410452556493,
  1.9650748358196695, -1.1006991099758576, -1.297338670092412,
  0.8285651663319843, -0.3922070699369922, 0.7968061128125101,
  0.7416591873646929, -1.2098782249915851, 0.6355418764818406,
  1.2034427564515497, 0.8021298077825446, 1.0454011069771467,
  0.7720290861713646, -0.34154253658670886, -1.1304614176339152,
  -2.2208143881909614, -1.8030556270554057, 0.16775370581779087,
  1.6145724503131775, 0.313506888732996, -0.6002069142332453,
  -0.6732066880076394, 1.5956453680313807, 1.4686961557742793,
  0.7216403779452736, -0.5081624236895038, -0.8142120561464092,
  0.8879896919456041, 0.42548877511885036, -0.15401918398006476,
  1.664692566636546, 0.9647976422107611, -1.3146576433486543,
  0.05861103699939042, 0.08423872065612335, 0.49367689216713123,
  0.6106250787569507, -0.029536131784513555, 0.9135189835069906,
  0.8127871696428621, 0.6926473182145596, 0.2139913980819069, 0.642821642172966,
  1.5284850170357032, 1.2578081806029002, 0.5384453181226847,
  -1.1239852088204223, 0.9341734094604872, -0.39793232072662393,
  -1.5733349599756286, 0.3393605278403536, -1.8763059602844185,
  -0.4191579391356673, 0.6184318243024932, 0.5509315528261158,
  -0.6369860489863568, 1.2318347766260584, 0.7014208064958831,
  -0.1158195475433523, 0.3627292297939067, 1.0505390093486837,
  0.8906654552261066, -1.0933532258811272, 1.4454905858571734,
  0.32249914724546663, 0.5184467606374477, -0.22921427925051574,
  -0.19187013206562484, -0.7341843063400331, -0.24004606536660061,
  2.157559360539139, -0.10405872245027245, 2.218856582102991,
  1.2823645478821986, -0.7557186593869285, -1.520832891157149,
  -0.8137062809833179, 1.4602920223508, 0.053996989752046566,
  -1.1629567239774934, -1.2515975802230024, 1.2508749839214073,
  -0.3418108008014956, -1.458656498166086, 0.6859970768242201,
  2.1567184449510064, 1.6076058552904087, -0.11612032141221967,
  0.8628615782423236, -1.3737080732649718, -1.497545594390709,
  0.13049486153813594, 0.43069802403810936, 0.7414491152251196,
  0.7065026129826658, 0.1259293853964987, 0.5110457608239358,
  -1.080355564010023, 0.20322538603434337, 1.002789727262127,
  -1.1792198173365123, 0.12287046752031248, 0.6921333720104637,
  0.36575816561479785, -0.29240852880547535, -0.008328599522357734,
  0.43782829918235616, -0.04083682927700988, 0.2306519084219476,
  0.2231812058332361, -1.68490813180482, 0.40137390921545185,
  -0.7217004704660833, -0.11141716599707426, -0.6380625145518313,
  0.7050547550140113, 0.9109095287796861, -0.0920326341689356,
  1.4030638883769815, -0.22424626227036995, -0.8013574630099206,
  0.4167525555589769, 0.9978506756038237, 4.090094332445232,
  -0.8519642265549731, -1.048585496146795, -0.6626508403391929,
  0.740451321944539, 0.585177317179028, -0.20023390823565393,
  0.8983730675082823, 0.5751144926265617, 0.4844042527550894,
  -1.0333434261050882, 1.2219954416347523, 0.38766407211308734,
  -0.9937148015293026, 0.3073569623806263, -0.9671288500425597,
  -0.8550232255100354, -0.4521711889705183, -0.6206976417437575,
  -0.3711439637089443, 0.8219893769620393, -1.1817872638272813,
  0.5069895662902113, 0.05288351871937923, -0.06127872669308242,
  1.0944934717853194, 0.6321567205148981, 0.2683674068928151,
  0.19619689502254692, 1.2447633096341955, -0.19864865244703234,
  0.4961254832816208, -0.3910104102589678, -1.0988132995351778,
  1.7206599773045412, 0.18019616693719295, 1.6743411995215505,
  0.48299477660288387, 0.40896159150342976, -0.7429046852677227,
  0.15459216071068638, -0.04498837105886068, -0.7864153988670172,
  1.0033923370188311, 0.14464167120576119, 0.2285262898054079,
  -0.15490987679386345, 0.6024087053413124, -1.3143441179763722,
  -1.7299150936733063, -1.8769454743712788, -2.5845960400965873,
  -1.032725891048491, 0.7260891485971737, -1.875145408638852,
  -0.7234502105965533, -0.3278107606009039, 0.9618936310680777,
  -0.18363990905153899, 0.6794188109096541, -1.160598716491259,
  0.34928282873522803, -1.0199321007854991, 0.3699377875081295,
  -0.8042736588764172, 0.6884753871464503, -0.3593266289954953,
  0.445782341884099, 0.5733909846836484, -0.15738262202828046,
  1.3577818948310232, 0.9804278836536504, 0.8565432687394093,
  0.9242578888864288, -0.4966022932826189, 4.203640380423819,
  -1.2146925675627576, 0.3608886214578381, -1.3249634908125163,
  1.448506993654029, -1.8609547154467885, 1.507274664908513,
  -1.3824366645230617, 0.861422521538918, -0.23802994971579408,
  -0.5610362410088716, 0.7008303296009247, -0.7646505218150493,
  0.45371660468200714, 2.166054267831869, -1.6563162432856864,
  -0.6571131562233795, 0.5046831758576588, 0.48404294298661055,
  1.2573130297286752, -0.10127513618055561, 0.09424104301034884,
  0.6308432192105352, 0.22488265434463836, -1.0211484615269688,
  -0.7820869159381281, -1.797397789346722, -0.5682895066545923,
  1.1696700536518572, -0.402837324519694, 0.06459647947251422,
  -0.01457204242938101, 0.15662776426816558, 0.15934567131949387,
  0.8917739380143448, -3.1449017772626666, 0.2848539388595355,
  0.5956674519521168, -1.2449724379057354, 0.30793095603328147,
  1.529017894363441, -0.48709878673206863, -0.635221257436208,
  0.2415728495341871, -0.6609038962742313, 0.20499081200443894,
  0.32657017059649, -0.06593638692900021, -0.3587191671134655,
  0.06953830858946838, 0.9138067668084559, -0.2758953392661019,
  1.2482568274123247, -0.36085564101645967, 2.4371786993037845,
  -0.7428024099020925, 1.306035752066023, -0.7568399162928785,
  -0.5501373587120152, -0.9447789783367148, 1.1278396558712558,
  -0.16115560424788514, 0.6994495877020384, 0.11740018600655007,
  0.21339947862611086, -0.13005621088549665, 0.22593346770642028,
  0.6576830899124673, 0.6375905390544244, 1.3474782132775323,
  -0.24599687706226198, 1.040172422257733, 0.18660170458583486,
  -1.1910846959841408, -0.38771889585671365, 0.22473975490990722,
  -0.8174935868033005, 0.37921065133184206, -0.10244490325149583,
  0.004005078525402384, -0.7127840997153606, -1.024127552209479,
  -1.7499268141011473, -1.6183143612511361, -0.6319089228445215,
  0.7128167065607658, 1.0370280911963552, 1.4475194535470592,
  1.6035476567438567, -0.9782442285971193, 0.10342279996370048,
  1.0564068649894622, -0.3077039356472462, -0.19623606728590945,
  1.6010580530638854, 0.541992272361254, 0.6299827104156059, 1.1117209304456694,
  0.28024635461814923, -0.19350781199786873, 0.2400198518337302,
  -1.410649502755874, 0.30330334539314946, 0.33536608509409593,
  1.0259639699537597, -0.4809507333156568, -0.29186416690082434,
  -0.7632394475364547, 0.27189740638994936, -0.12657419133760167,
  0.3006028343436031, 1.3874583234234377, 1.8286162328357929,
  1.0870547910562525, 0.8779449104784163, -0.06326186746340891,
  0.9248622103821189, -0.11975635224989499, -0.08267186901227831,
  0.23630974565576982, -0.17839151549839027, 0.5063601515238747,
  0.8311154246649685, 0.9105477899281487, 0.047699640948253026,
  -0.07597161221035992, -2.5478482449860835, 0.027622531328339632,
  0.31374014343572454, -0.6804167495724006, -0.0999349179106984,
  -1.8038723016783038, 0.3276883863067244, 0.10237423172910251,
  -0.4770477910388141, 0.8918424240429694, -0.048698156243109456,
  1.1697413094366944, 0.15312728714194387, -0.427659339608634,
  0.38561565998870545, 1.1467329992142365, 0.9609168044065307,
  1.6758391517586901, -0.6555281016814173, 0.006195270799954476,
  -0.6938108640660376, 1.4894841919462887, 1.5656493245843932,
  -0.3127364534424755, 0.09006169480421444, -0.5433689907103352,
  1.450225754647552, 0.4371249430156726, -1.1857393108833036,
  -1.7890453157327049, 0.36501353243911344, -0.44294307502536345,
  -0.4051788976617371, -2.332196647871585, -0.07305558780500661,
  0.8691070386111982, -1.4842946737191691, -0.10085440658764476,
  -1.8762326806675498, -2.662441046314157, -0.5119336543611593,
  0.4335041207832161, 1.3485928677200056, 1.0310950584244984,
  -1.8913872986199238, 0.20345802808957744, 0.5259445173325691,
  0.7934723152014506, 1.1620726261269236, 0.6731980573431632,
  -0.443601750316664, -0.07428033038940358, 0.5302479401069223,
  0.22955723642140258, 0.48147211930324507, 0.9014914184073014,
  -0.5743945181603629, -0.0028661715221967553, -0.668135026538986,
  -1.650232145709041, 0.32340750795333634, 0.3057111405137945,
  0.2535294811262434, 2.2824888761345257, -0.5248825925134313,
  -1.0228829187015656, 0.667231684298238, 0.4782619270214346,
  -0.33718010914146385, 0.12769815054340625, 0.732384852210752,
  -1.1610262000535845, 0.8979218510735575, 1.1940594239961044,
  0.6926728279421142, -0.10749422521788862, -0.021521596216895946,
  -0.8958966068159687, 0.4056962730588766, 0.23086789848984202,
  2.70801213088415, 0.7049121594277155, 1.7312534385146423, 0.4389272054702859,
  -0.3064193680528841, -0.21135548106397054, 0.9878912404689825,
  0.09065145098538081, -2.057060000806852, -0.6740410249140512,
  -0.8003400053737623, -1.1168829191258165, -0.9368974168431436,
  -0.7118516135778963, 0.004742017483985424, -0.15121368861965853,
  -0.037354255892761964, -0.03831214801137775, -1.4081003915294206,
  0.886628359794616, 1.6948992981932536, 0.32424785979203435, 0.806431089045265,
  -2.62223072315971, 0.16230039444892555, -2.7481430406132756,
  -0.6395340918167982, -0.0994835407308509, 0.5365617366629111,
  2.460922615228036, 0.8260976242212069, -0.44005435846241586,
  -1.6174765291791537, -0.07836819586830383, 0.4254195113648958,
  -1.092357972160522, -0.13460730956124298, -0.11742741138879889,
  -0.42810976687593955, -0.46441788866963396, -0.7089631379144579,
  -1.2092285254096793, -0.6227695589651726, -0.6434952555339578,
  -0.011054675636770032, -1.064817628728643, 0.3342177586877828,
  0.58792263353649, -1.181343450124715, 0.5750133759871376,
  -0.07558888189260779, 0.20216114014110775, -0.136414429875984,
  -0.39571581880369283, 0.3153191584031339, 0.10946397980375301,
  0.054560281238267096, -0.6729681849406668, 0.3940373155508556,
  -0.6404840147979917, 0.38998350527956105, -0.7822005513796902,
  -0.5214147112219893, 1.4402657349670274, 1.3454178982955793,
  -1.1748535658136148, 1.6566069186655303, -0.10889871845781925,
  -0.2055549804606936, -0.3790148120884833, -0.20947548529578663,
  -0.8291498580044138, -0.8561511592315366, 0.8567881603490507,
  -1.1193806373837882, -0.5909973748743962, 0.6479212179370749,
  0.220627769215625, 0.8256961698498991, -1.937242840753455,
  -2.2934688363204208, -0.49270719823928316, -0.8691799860178966,
  1.035602502212966, -0.4423431090580411, 1.745820118415325, 0.5188830658550888,
  1.5906316495001094, 0.5918285543087132, -1.2195065147473851,
  0.7700071012564306, 1.392392565854453, 1.8184853756487573, 1.1417161292118803,
  0.9062359367629704, -0.09269599317864502, 0.42710887294194777,
  0.23260663647840293, -1.2874390549547656, -1.327187713622018,
  0.5464967696429633, -0.517936028948491, 0.5712069105860129,
  0.4400813062470393, -0.45694774525140736, -0.7810125177733509,
  0.2519600437463958, -0.6090706012868894, 0.9960406914888459,
  1.7341562934640344, -0.04881918942103162, -0.3362698909916774,
  0.8104046691489151, 1.404559520798265, 0.5914824910030845, 1.186000475371096,
  0.9286018381421057, 0.5680569119108018, -0.3522465585909241,
  -1.788811780513862, -0.3769380167284441, 0.08302809137449266,
  0.7873254415123093, -0.6468479509938411, -0.49275315457532126,
  1.3824516225346568, 0.42133888934332114, -0.4393499936280991,
  1.297382262155466, -0.08158312940399073, 1.1981926647639942,
  -2.0303570090026417, 0.9009041865399676, -1.793128025772827,
  0.32735978976933067, 0.6387815343111501, 0.9292010999494837,
  -0.200095011855171, 0.5506918554957824, 0.8508477899289991, 1.287861275894417,
  0.3262426905368455, -0.045554578339134406, 0.32511087415743883,
  -0.919964473083993, -1.2179412950090718, -0.5785903030175332,
  -0.07546672969729641, 0.4135214153831374, 0.40649628792239045,
  -1.4089262855903026, 1.040673730228355, -0.6904676369106735,
  -1.2992955710122707, 0.6002613188860666, -0.3697374100262191,
  0.3458202114039011, 1.6115429253645015, -0.8867293443144385,
  0.5069121262944543, -0.1415959066507348, -0.00994343225586592,
  -0.36627667828421084, 0.9313622941945953, 0.28004261580301004,
  -1.5903199408979058, 0.24983003085541886, 1.6626018348298683,
  -1.4037044032059613, -0.32647182480826104, -0.6879409690266548,
  0.9022477457769729, 0.32144537730838185, -0.7166077413212072,
  0.504963532063844, -0.06864685467302546, -0.34477107443225835,
  -0.21392557764564338, -0.5422421506930167, 0.2901708051955385,
  -0.6967914580085457, 0.3975269737473507, -0.0808341592448833,
  0.2590259406175054, -0.9188744695243682, -0.11457178689868361,
  -1.6265245183048054, -0.513804463844578, -0.3062791156378451,
  -0.6689334584790426, 0.11401762530257684, -0.9958266058150753,
  1.030408275580788, -0.8959640269311084, 0.3936824848740003,
  -0.10578841436806831, 1.2460082534864716, -1.5615943116206183,
  1.1740404784410445, -0.415961330212511, 0.9145971017581047,
  0.9629871700392705, 0.5124924441511394, 0.0769711768604824,
  -0.24168228045756338, -0.21065078303371845, 1.5139085895943836,
  -0.6992365051707758, -0.15163162210135162, -0.2005480150600328,
  1.3726422649888301, 0.218657144373, 1.0317691171606789, -0.23703159811542954,
  -0.4618103773618499, -0.2480798838705349, -0.24424100181820477,
  -1.863078351991008, 0.01162192444742775, 0.391555744699042,
  1.1277339753360873, -1.0770056752025516, 0.3868436102392727,
  1.2591325959631308, -1.1110004223480459, 0.6578710489907169,
  0.6773644997780379, -1.3482561280756253, -0.5384683771883927,
  -0.4870302429273871, -0.7622974069107809, 0.32798821206365386,
  -0.27079697133195096, -0.7965172658657884, -0.8209372666802183,
  -0.431068429149602, -0.2847503137552072, -0.092122842247441,
  -0.20674653942134952, -0.21071175371456433, -0.35220575378590857,
  0.5529214422677922, -1.746712169267385, -0.5015322802762451,
  -0.8262811315302218, -2.3528255219718064, -1.7109028037914455,
  -1.917022023940772, -1.0044499239685818, -1.6110161275141008,
  -0.34290769848201647, 1.3477111863185158, 0.694877171920921,
  0.8034326005306527, 1.4283452219421187, -1.213222031572997,
  1.1724166388914596, -0.5168135846271185, -2.9023886888121257,
  0.460001555173897, 0.6010498202829815, 0.021563836992958472,
  0.5357606846133105, 0.430119037568898, 0.8043055296016393,
  0.10588697642611093, 0.4504611039085703, 1.0602472636200508,
  -1.0936304658193647, -0.7609327919378485, -0.9825848570303625,
  -1.6192697458989842, -0.8501202734643248, 0.47121425537828726,
  -1.0831743410902879, -0.8399417423235233, -2.130578473279198,
  0.40942583152910855, 0.5891691587580703, 2.9549794441977175,
  -0.42633955857146544, 1.0671514145607996, -1.6082053942032144,
  -0.782403871464913, -1.0504906458214185, -0.9622501012094478,
  0.006355018869462019, 0.08590792703044481, 0.2984909404954868,
  -0.6572254692739243, 0.2293251359332403, 0.6553234991061788,
  0.8629992261824808, 0.2361682228938146, 0.35273509652962787,
  -0.2438773004421969, -1.3361732080446758, -0.20635786369145542,
  -0.9856422389376498, -1.0733601338419223, -0.10196274108678202,
  -0.4762273137005618, -0.014987182120026475, -2.192901896878533,
  0.8656907280948511, 1.9738065045027426, 1.8228327692450508,
  -1.6076219970885361, 0.3886584429024246, -0.2212825435989906,
  0.5271988054936008, 0.5104145876242194, -1.3075742648714674,
  -0.5114854614533821, -0.9954212967167625, -2.568284530918001,
  1.1996129952013521, -0.6219736371248062, -0.296478883945581,
  0.30980926386883606, -0.4791733712507256, 1.0027463851685188,
  0.8345742447966235, -1.1179806542007886, 0.40919492836029264,
  0.45390495971111344, 2.9670444384299457, 1.4326943196360526,
  -0.7409248995891535, 1.2737935115715815, 0.38963496686454135,
  0.04392507156543612, -0.9602942879389691, 0.8387268500322526,
  -0.5734583338400387, -1.1215067422978808, -0.508895429009816,
  -1.1564069290636194, 0.2055082576467104, 0.40043790332759976,
  -0.32627189952583, 1.6966846971986842, -0.8604795442447726, 0.522423318416383,
  1.8001883951037185, -0.8697378435878245, 0.7114576720611453,
  -0.6262543471809584, 0.6855578084476975, -1.1378448477059486,
  1.5205233116575538, 0.9314750595159771, 1.1280271765933734,
  1.3625237430026138, 0.05815873530910801, 0.8876484369295119,
  -0.6305488283415355, 0.7016247345020534, -1.904844000105437, -1.1830889484567,
  0.5173700467205368, 0.5561710367970955, -0.3786041122722583,
  -0.4599293352287879, -0.961275943178748, 1.415163541112238,
  -1.5565617707832302, -0.3891648140594683, -0.6309768682738747,
  -0.3617436653400407, 0.4412866228052597, 0.2778000446746308,
  0.7989814079549563, -0.19969083321060271, 1.3361943903159785,
  -2.3188261535580224, -0.4493574749149715, 0.7836145630870376,
  0.3153503384728796, 0.44300804929431653, 0.48075768268821023,
  2.86562542521584, 0.35532823398090335, 0.7701105559679301,
  -1.5597606989017876, 0.48632850580870185, -1.5680044670354196,
  -0.18268674803186769, 2.167715246792118, -0.1853712707967801,
  -0.9661287893633046, -0.04488270282535784, 1.9822926387802406,
  0.4166431913397592, -0.42332245605067875, 0.4422953965895077,
  -0.629477867182054, 0.7267432406269309, -0.833468150184567,
  -0.24138377449863832, -1.3037762087592828, -1.4494298393951082,
  -0.27718197734051336, -0.19913099453018568, 1.2461152586452657,
  -0.5223357180127388, 1.1388088350564278, -0.04027347136280053,
  -0.12137288116300146, -1.201493221465404, 0.30650290607280406,
  -1.0839697366550947, 0.0720150780876556, -0.3707238444560169,
  0.36393525408035166, -0.13859686069785315, -0.06036901957801567,
  -0.38820388269487766, 1.3079684218419347, -1.6568325405644313,
  -1.193635154317096, 1.0193952572995248, 0.666766671330407, 0.9414798507558917,
  -1.7977294862102224, 0.7892151986560997, 0.9970566521466494,
  1.3869301570001922, 0.2436731142703962, 0.98970187376939, 0.19388040093446707,
  0.10840369592448117, 1.4096778798574903, -0.865497964704346,
  0.48056188692289387, 1.0127214957811752, -0.449431417400107,
  -1.3640371681335692, -1.3578354167079019, -0.9562570096754621,
  0.15787681901471354, 0.5109723180307115, -1.5588238876538254,
  0.14913199456922185, -0.5833669122028362, -0.013282988185747203,
  -1.088326453188565, -0.7557683608103442, 0.9843895510193783,
  -0.37844280837228444, -0.7154810288936133, -1.0673137698909891,
  -0.9280954740103228, -0.44624895073403537, 1.289519891042069,
  0.857603092130945, 0.38897982453462654, 0.22799675428487595,
  0.4146789796042746, -1.480788392535019, 0.045081101556791035,
  1.1190858477274528, 0.6347352431745175, -0.5038918767412646,
  1.251946626323949, 1.3519463393190378, -0.44351257197099114,
  -0.7193484296622122, 0.377635169576638, 0.6526736521771103,
  0.023042694889223666, -0.2721307387817774, -0.02634365516194052,
  1.8476583057015075, 1.491599441732724, 1.0033601242054053,
  0.15693836921721524, 0.7463330577152771, 0.007854948095836195,
  0.8529503362273946, 0.30862505624249503, -1.8361933559706134,
  0.16558527545937182, -0.37397280214104084, 0.501783680551349,
  0.8647028113163454, -0.12008232560231821, -0.6383149653316972,
  0.7657686206749804, -1.2445298365161164, 0.463966002837373,
  0.6836960755617597, 0.8023617931942126, -0.06867148860048027,
  0.06466580902582914, 0.4437196492127359, 0.7760746680696679,
  0.9774754400869521, 1.638236524964039, -2.4886414950111804,
  0.4334129410221961, 0.8508339271149614, -1.8782224005435613,
  -2.4550476081314963, -0.2568086939205207, -0.8773983830488264,
  -0.0682222788144208, -0.6687333222885574, 0.5555220662780258,
  -0.5693527383208192, 0.727519836173533, -1.4143010853558138,
  -0.06861540720142421, 1.0012943624691593, 1.611633307152462,
  -0.9670832142968001, -0.9228936438493739, 0.43392647148645924,
  0.25684668466528987, -0.4913009131034509, -0.6866813644349833,
  1.7897850829707498, -0.027528669928988267, 1.139513116187007,
  -0.6677929498541915, 0.7084015302132357, -0.8207223649536096,
  0.7138035158886993, 0.1908632628436664, -0.7094292473802785,
  1.2412414985122562, 0.8545758556557841, 1.6960404271751899,
  -0.3025988823278299, 1.187499555790176, 1.1904743279402488,
  -0.9382229949564691, 2.137030071828503, -1.067108315047267,
  -1.6036724888982514, 0.21171338676668852, -1.0280004977491413,
  0.6703377475131193, 0.7247684708161114, 0.8720405811760983,
  1.3530722194464637, -0.7913813775821279, -0.4536306046942862,
  -2.300173205507135, 1.1627035515400024, 0.36146658698430123,
  -1.0678518571434046, -0.13570698838033343, -0.8927669928980027,
  0.09307577148203362, -0.0433017674385236, 0.10015716155688839,
  -1.1960671872733715, 0.855406955607014, -1.9884862227980684,
  -2.2133396386430415, 0.26159318478378024, -0.7345555473084902,
  0.5120524111690404, -0.15624287920607594, -1.0467561171731181,
  -1.9866063572162478, 0.06666436848592393, -0.33344242949112285,
  0.3827033502653347, -0.5763153878110373, -1.6140861100308272,
  -0.07421932388926822, 1.1046165790584461, -0.7397581306796311,
  0.2600601177340487, 0.5226573101885118, -0.17372704623239546,
  2.0443295046152232, -0.6297208129169846, -0.06304580547462206,
  0.333358588753703, -0.7927480077065459, 0.3292182085067241, 1.149206254911382,
  0.9942620339396872, -0.45935067806720375, 0.2013760957315314,
  -0.645304562194835, 0.745715617607479, 0.15523832690817493,
  -0.4331012360524988, -0.3241650011962928, -2.139042102999625,
  0.36839758706042275, 0.7262845626562485, 0.6293074247448528,
  -1.117330051516982, -1.0622901503077065, -0.9656670223337762,
  0.826357565747602, 1.168564576244931, -1.1493408410288404, 1.715579788040344,
  0.01908430617227162, -0.08267566624453558, 0.0644355096186216,
  -1.5582809069140573, -0.74152206999417, -0.7639950366279167,
  1.0789588261865555, -0.03894109454747774, 0.3742079859480447,
  0.042397267038643674, -1.4386063731141967, -2.0896202156911565,
  -2.3492382981748916, -0.3651061995664866, -0.231330379973008,
  0.4032502982614691, -0.7528575229413232, 0.30494270581352234,
  0.19816874498773074, -1.3077556889881774, -0.6785473999247716,
  0.35165277609686746, -2.2892465986811623, 1.183403550089628,
  -2.325351493299267, -1.3789552500531828, 0.663120677417423,
  -1.0578197479161848, 0.14609151437488463, 0.083678243050888,
  -1.0538254575124313, 0.475406308184481, 1.2976167898616995,
  -0.8354072182645792, -0.9589654077902613, -0.0998900380879548,
  -0.6851594927022255, 0.42074410224844383, -0.9122251143098422,
  -0.08916259648687853, 1.2956288145868748, -0.06809781980371173,
  1.6612757510481528, 0.2823226413251873, 0.10882727006095132,
  -0.5376991661151775, 0.8954596690971047, 0.24085893224747162,
  -0.9047340259946063, 0.06368589348113562, 0.0717761254113717,
  1.0116209589122607, -0.49684213080629913, 0.6851230729328087,
  1.6420070555967545, -0.8433824636187647, 0.5697541672510952,
  0.20872545224886263, 0.8946717632116675, -0.8345216872743624,
  0.5138215769241691, -0.0930976401330079, 0.2442777023674504,
  0.4160899289512228, 0.908318770853091, 0.3159642634177375,
  0.03097819046472997, 0.4359811133198379, -0.714899545630031,
  -0.677264766068655, 0.377885106751656, 0.4605859609779263,
  -1.6234701372317162, -0.4742442248697354, -0.9439772894807225,
  0.4593012612160934, -0.6073030769461566, -1.1057681265715362,
  1.7284738185938502, 0.13643396526505894, 0.9124697070949103,
  -0.9505255644475069, 1.1297132128436722, -1.8971908113784173,
  0.2979409405891782, 0.6891986660305445, -0.2884460922184184,
  0.021193224908087685, -0.4422835927023886, -1.5881163197563697,
  -0.36909077736147816, 1.2491066461728986, 0.7162491860851068,
  0.11885851850471398, 0.48330800927748097, 0.3136336344203315,
  -0.9313925474062406, 0.4498878171871943, -1.2653301273165525,
  0.39070796498086247, 0.5545206017190702, 2.0607676342882297,
  1.4249873099607413, 0.28897825319902654, 1.0542728347622008,
  -0.5243194219492615, -0.9965991620177498, -0.8215899592153488,
  -0.22809017570840823, 1.022556486153951, 0.4175039431946036,
  0.11407086274261423, -1.1367236299328831, -0.4764551251225722,
  0.9675191486191979, 0.17672591105580165, 1.1916797419817966,
  0.018011466689228612, -1.98649836333125, -0.45589641977523626,
  0.8854108561264051, 0.9809827156354449, 0.11192024127138624,
  1.1314293267211275, -0.40711291761827234, 0.13973708808796698,
  1.536613469697984, -0.37113211275262936, -0.21276046074887547,
  -0.8661701540198764, 0.146698719042931, -0.5670506738766644,
  -0.425862465159781, -0.2029467554626432, -2.0070210064210876,
  -0.696979961750713, -2.225241062868125, 0.38433853997614564,
  0.5728192302475467, 2.2002097268334566, -0.04876545614975007,
  -0.3747916372219153, 0.7229222946770675, 0.06891834288253251,
  -0.507104965652932, -0.02497900018459434, 1.2535717444284504,
  -0.002475353936684812, -0.2403534053011393, -0.8939887025235514,
  -2.027208911587408, 0.1887816556629858, -1.4257961628208289,
  -0.4068148091271697, 0.0004961993637151149, -0.36012529694507933,
  0.3828774595589092, -0.3466677908592921, 1.9323605788438172,
  0.16493340268549317, -0.4640847426319334, 0.6688467319699359,
  -0.5947133891311092, -0.28163719652171476, -0.5117516963730984,
  0.40420371742245736, -1.8077726190947798, -0.3361493889557148,
  1.5232281963888796, 0.6530969060542147, 2.6311786780121476,
  1.0474451300339893, -1.5787649582391783, -0.6560699748154708,
  -0.2863460809597163, -1.0363169670388668, 0.5459637945874425,
  1.676432521723883, -1.3605936309380722, -1.011664564382673,
  1.5570867291133814, 0.13898822590948354, -0.288814167558381,
  1.2794397934015138, -1.6440438311818941, 0.34901512725520134,
  1.4360009248628283, -1.386052085873364, 0.2996347312530097,
  -0.5644359140367292, 1.551335871007465, 0.27090442149088634,
  0.41928187101702574, -1.1294893227918807, 2.057650863280381,
  -1.2897793228143384, 0.5192661384374351, 0.8619024674726763,
  -0.4750626500078782, -1.6335697526491915, 1.038153532321253,
  0.8715676656634653, 1.1681787227957015, -0.8538965020346565,
  1.0511570736108176, 0.8765770121033845, 0.9487364330430568,
  -0.27939705862525593, -0.00456129582751797, -0.4481929863336498,
  -0.06738931062348202, -0.9366122073347086, 0.6235199745849617,
  1.9577806115154304, 2.8787577779265385, -0.693807897398305,
  0.1925528717306819, 0.2553903978878205, -0.6998115046389063,
  0.8896546418435379, -0.4150146852000292, 0.2644601684820398, -1.7464655450798,
  -0.7265771718821677, -1.9105852399969976, -1.2869991415002011,
  -1.296889422680557, 0.6306450158523493, 1.9845487131640138,
  0.3782864075312887, -1.2969857369939692, 0.6696033096679199,
  -0.20300727629354767, -0.6070984066978363, -0.9831714531714314,
  -1.487919370062344, -0.6990254077649494, 0.10941858934616887,
  2.1293861964458514, 0.14966563528174068, 0.4012586964375383,
  0.28158392474060556, -0.03713549097622993, -0.09737712147689762,
  -0.15027616608062774, -0.21345077561914633, 0.8485427899457034,
  -1.2353351715494485, -0.5869847025767481, 0.39449945360695343,
  -0.6896585030752131, -0.9435149475519605, 1.3817371040407862,
  0.8783729696620298, -0.6943021679584264, 0.047613964452394276,
  -0.12022529946964462, 0.21115821709832946, 1.1140860320307993,
  0.7099368093996216, 0.0015607950189957182, 0.6586626285988028,
  -1.3475643916852313, -0.6041653416312124, 0.43324337496076015,
  1.3122123474040768, 0.836356867028647, 2.010736094136983, -0.0796689423560317,
  -1.41156311254543, 0.0994223618852983, 0.90586249685676, 1.5841780657146332,
  -3.442688581471501, -0.7761799121974624, 1.6113425262233927,
  -0.7213869599792808, 1.2229539426259457, 1.0914986780975426,
  -0.25683492018950094, -0.7199144734140216, -1.1213072885503128,
  -1.290569608126298, 0.2104485459377237, 0.6935131536528165,
  -0.2949339896508841, 1.4541327396363959, 0.47075236108226404,
  -0.1502955909994521, 1.0152624106286832, 0.42126100351860196,
  -0.556740631927925, -0.6074119024629189, 0.4679476561058617,
  -0.1518516142551707, 1.2158100160740777, -1.018651520569489,
  -1.1375665632456444, 1.4068074544594125, -2.2560231927075436,
  0.07262174856177926, 1.151767069322126, 0.28856339726932373,
  -0.6817626015357569, 1.2867216391368068, 0.224220373852324,
  -0.6885739174628941, -0.6284848334407168, 1.135059902682984,
  -1.205834278371547, 1.0369929990875613, -1.2417119700943478,
  0.3841327101775034, -0.9844431946163651, -0.7280591478718346,
  1.6610636735621251, 0.5205234130585524, -0.31177850534366924,
  0.3035754904187541, 0.087734981843742, 0.43297870642895775, 0.66559399729994,
  -0.9636523527604219, -0.17008165703189554, 0.007428218659454386,
  -1.0252173756303364, -0.49566478389430996, 0.9164837758632814,
  -0.48183968170199665, -0.5092495097390306, -0.9319136687965723,
  -0.5785895995674899, 0.6796451251685561, 0.678214131395739,
  -0.30340765610809856, 0.8996526974289611, -0.44534456015048346,
  -1.4046666205545777, 0.3821225616320544, 0.680450320880231,
  -0.796818557180588, -1.5809267068308754, -0.0025962895819083066,
  0.16862145169829781, -2.9977875017159956, 0.025629780211048767,
  0.5060677189368411, 0.8274687669183927, 0.8193651131321819,
  -0.28672173383730165, 0.8616458043297313, -1.3724298358903766,
  0.9456113672500707, 1.8584093834675874, -1.0240426395725988,
  0.760722955041099, -1.217727019778092, 2.1944080809853164,
  -0.4348245468907133, -0.5521674072704635, 0.7724463578607254,
  0.6982304581739848, 1.566369528225112, 0.1329109575594876,
  -1.0072343812842162, -1.759900681455655, -0.3487980332476477,
  1.3324854297862903, -1.8709998080713102, 0.599867601609038,
  -0.37093622190299114, -0.6867773323782695, -0.35497519920148224,
  0.7550768789882367, -1.8368924895255123, 0.7301811873400592,
  -0.42770008736251924, -0.3179474241762776, 0.34198130280281047,
  1.2706233404074034, -1.3351557009097905, -0.7982461587178445,
  -0.6364675072445062, 1.582652413946388, -1.409785930448701,
  -0.13413454216853946, 0.5092625986574424, -1.2660712194792727,
  -0.8428402290805885, -0.20417564569154023, 0.42014736021725346,
  -2.3661061116996587, 0.7250657185967778, 0.051560934785662975,
  -0.0642211850525172, -0.1369722608884332, -1.0320898301575152,
  -0.6988239881396296, -0.6287767710348476, -1.492794305094198,
  0.3765056494743519, 0.5287233960145765, -0.7110571457858725,
  -1.1226021149276249, 0.14235782627237645, 0.7085040280951183,
  0.17780892794383615, 1.2292418447462201, -0.6868659682293469,
  0.34093219475285236, -1.583139891538382, -0.39489666398745527,
  0.2755117045410589, 0.5527003294628171, -1.6041881006144316,
  -0.3705549287527103, -1.5176881140651166, -1.9376642026087036,
  -0.14053503499293352, 0.6625156233988067, 0.5827795484421099,
  0.040664015489999206, 1.2173287642843227, -0.4148712911198806,
  -0.47560216447924164, 0.7179636289051673, -0.1842716699190715,
  -0.26485869218158986, -0.2498455328983317, 0.26028264310701027,
  -0.8862614941300966, 0.11198353711514158, 1.0578770006577267,
  0.7698001729579784, 0.06642469516980613, -0.8490043511965742,
  -0.8238667951731381, 0.3519043314860058, 2.010505753125993,
  -0.045055998263176855, -2.9355576046469394, 1.0488410799953725,
  2.6280596760830357, 0.4634327201696543, 1.9007628445919404,
  1.0346172201688737, 0.5925954862223664, 0.24762660150472598,
  0.8904957505067758, -1.6638479522251308, 2.8066687432336326,
  -0.33860567617231907, -0.9295800699421972, 0.012525574910397769,
  1.1824150232358364, 1.405908161317929, -1.168583638828473,
  -0.18722171572304896, 0.8217679712634035, 0.04920079088318152,
  -0.2710445999447419, 1.2769621626271266, 0.8326123783947026,
  1.7190632985803893, -0.03637213619986422, 0.37754007002504414,
  -0.8066838293317568, -0.10493709747803316, 1.0195126540003387,
  1.175154166304691, 1.3647471060599852, -0.01213847516563065,
  0.23427561930971585, -1.4785941312363722, -1.0984252291533827,
  -0.435102886503591, -2.016281622251544, 0.44597529045818735,
  0.36334079813456693, 0.5096414153724682, -0.480533193223413,
  -0.003670336310366373, -0.5636068172849313, 0.0654824709646633,
  0.45887924397737934, 0.18107454194882708, -1.664647441433183,
  0.13152832343194887, -0.18681418822075607, 2.2081878310321237,
  -0.6445699348175348, 0.9611342506601787, -0.06953910113475074,
  0.45827764145179656, -0.734905671381288, 0.5375887438227239,
  0.4587299979468723, 0.2530759883568305, -1.5080154330440119,
  -1.4031713138886488, -0.3330423168814326, -1.206584184507167,
  -0.5666828664387878, -1.3768957259440207, -0.8885289376043372,
  -1.472802048660795, 0.4352391121417922, 1.5539413441129288,
  0.19580303964916465, -0.4974786455103695, -0.08070556355982626,
  0.8202319085696569, -0.7867286235671527, 0.33587704031126503,
  -0.6075799826194241, 1.1254007596462745, -0.1751365059529942,
  -0.6068668320234407, 0.41511493094963353, 1.7236068703589322,
  0.26156060104425977, 0.8324132046863759, 0.011238409634058586,
  -0.40062888586135925, 0.6256313259873753, 0.4476492785592948,
  -1.7353543154139113, 0.5029914726938979, 0.46792641673447016,
  1.5185586752139353, -0.639341801335563, 1.0878967837357292, 0.928909589871559,
  -0.8092154262692193, -0.9440741950373932, 0.7250975275331294,
  -0.08294174334478811, 3.0822787174543778, -0.5243433972878069,
  1.7056928511826221, -0.2670513440883262, -1.254567232641582,
  0.771102201393143, 0.3737391036347621, 0.42627420212463746,
  1.4868433796850091, 1.3003436461080538, -1.8064037624314346,
  0.5521664624051271, -0.053636601387533435, 0.3969182159377511,
  0.3731232948296566, -0.10498412338648636, -0.7537632710917866,
  0.11388981956363509, 0.5603579730362523, 0.2918277213583148,
  1.1129966608422386, 0.34883506230331635, 0.6758661100583686,
  -0.6412661453606873, -2.35617733388919, -0.06243755707485691,
  1.0605987368618557, 1.1911598324380561, 0.981705740059636,
  -0.48731690753644386, 0.22489470018129756, -0.23352849158828595,
  -1.545035669061356, 0.5019004072133642, 0.05404250872945324,
  -0.2737953963235959, -1.2648667948927559, -0.6057486496427205,
  -1.016615872334574, -0.9708636319198135, -0.38698286955862204,
  0.06384419840368807, -0.9910314155276206, -0.6344502186190755,
  -1.7730151112275478, -1.6857486367314953, 0.5430821249217338,
  1.4174820918942612, 1.7574077378798172, 0.25880564362907027,
  -0.7911843615521652, -0.19698180354396896, 0.2159388360611612,
  0.04054971939098514, 1.069375710401215, 1.7537767631533072,
  0.3303390861379521, 1.1670437524622348, 0.48936912266935534,
  -0.5036099580899571, 0.09101337656098607, -1.450474665285598,
  1.607457224831979, -1.2243188276458155, -0.6249087577117564,
  0.4861486526629256, -0.5498101788846805, -2.2556198166659054,
  0.04576453431827023, -0.729602693014928, -0.7941411457364943,
  1.8826490243483938, 0.6991252563314713, 1.9424036590185634,
  -1.0305482401573531, -2.388203202735176, 1.9606683490709156,
  0.5903429183388323, 0.8612558419185433, -1.1582628775214008,
  0.005150123171743605, 0.6657984364635989, 0.8289875417295014,
  0.021767424553785386, 0.3420787820260873, 0.8691023096926938,
  -0.8402063459238956, 1.4673251019777622, -1.1567204702165956,
  -1.9096367773388383, 1.0608277454928003, 1.6092649645499886,
  0.17728054037654492, 0.5566720396177061, -0.8120332223039899,
  1.0808615771423058, 0.8174789198139154, -0.9574128106057133,
  -0.8082166383893818, 0.8699924657406527, 1.3026328987526603,
  -1.4315791016965989, 0.39710735126368013, -0.2747264500841277,
  -0.7065001053381583, 1.7820204742648402, 2.180979900629133,
  -1.5364550266189456, 0.4519995287455867, -1.066864215181979,
  1.5827991133845496, 0.9910066089754108, -0.890498888984554,
  -0.8358548399598018, 0.37963469602923255, 0.7409878698277811,
  0.25822747294037707, -0.6591400213526962, 0.1236869808793837,
  -0.3051163952018384, 0.523258960905163, -0.644185055952613,
  -0.44365456076216997, 0.4291841776473853, -0.5931077086873345,
  0.00587883696568572, 0.6125164406135715, 0.18046980750828992,
  0.6549530615316419, 1.1037674292808626, -0.6173175179319766,
  0.7585434542480711, 0.6464796050574062, 0.48586128167733245,
  -0.3494221032605822, 0.17105313455832696, 0.7925448087455297,
  0.7395969956450386, -0.25860394537062903, -0.827591407812339,
  0.9648802009659638, -1.7107578632417673, 0.08704355598950331,
  1.154183269640823, -0.4784656562926696, -1.1895783813109022,
  -0.9160455094999942, 2.015310633312948, -1.563436761398612,
  -1.8859651193816183, -0.46572257876254025, 1.887689277928415,
  -0.4888413006305044, 0.6982591973970106, -0.2638318822478243,
  0.5997161463119288, -0.9354767552113286, 2.2463380160845396,
  -0.11575842246164411, -0.4545379505643854, 2.3796721619254226,
  1.6824263576839793, 0.9760106789967588, 0.8795420830135283,
  2.0776589300243833, -0.332834263095219, 0.047150280456271024,
  -0.23550251390847124, -0.6643880695070881, -1.5692001637563835,
  -1.0476332726031137, -0.11234771570273794, 1.3571228147132297,
  -0.6624931096242302, -0.1348269405589245, 0.7346080008679617,
  2.533420403451941, 0.4247735326958732, -1.215775488728678,
  -0.8542822726697402, 1.3522407167238617, 1.6643348088019951,
  -0.857179533530474, -0.5743415956147359, -1.576514836609568,
  0.060756064010044006, -0.17685246337664257, 0.4533415486764885,
  0.05283705292829925, -1.0730484842112888, -1.1976965068816445,
  -0.20515538901776278, 1.6559375887557142, -1.486635011050392,
  1.8974913119432546, -1.779853759011059, 0.011581596571427702,
  -0.26319626223944353, 0.31053587345502015, -1.5817438592468567,
  -1.5488777722989437, 0.330008071557423, 0.2759751373081011,
  0.13828020752550158, -0.8325200497520538, 0.7257531214214181,
  0.23622108186986998, 0.7421313675344111, -0.014265608709900646,
  2.203829297049556, -0.2138979237627843, 1.456550190784022, 1.8455026380864585,
  0.5127553815490943, 0.5147968227360847, -0.2768810853826411,
  -0.5119707746244491, 1.0914633265703149, -1.1287756217225762,
  -0.03348880759681861, -0.4126988443564571, 1.418289922685249,
  -0.7579884868336388, -0.8490872334723569, 1.923638468905212,
  0.8574756772964686, 2.385928394723314, -0.30188559550892524,
  1.2968883806093245, 0.5444447846862208, -0.6000943423509983,
  -0.29640451574317017, -0.08440722857959268, 0.7128430416145287,
  -1.7611620380577302, 1.2392334450402345, 0.39990581999947356,
  -0.3815424007599413, 0.0857381979187156, 2.0404055204868805,
  1.3683629421377363, 0.8775334046956843, -1.4292922621407416,
  0.13739082223311366, 1.1772518538672851, -0.24473488379323835,
  0.11180215936212286, 1.1238493098952334, -0.504604563626711,
  -1.3667724763411806, 0.7991693426617232, -0.5397262281059254,
  -0.2813379504709495, -1.312183177504159, 0.9377869375825966,
  -2.2196689983442317, 0.9425484629774729, -1.406412783133558,
  -0.7040453598718256, -0.4339663208453947, -0.3921440804015145,
  -2.0151212613150222, -0.72303957973799, 1.1192565024869796,
  -2.349630954454873, -0.5805506036396946, 0.47275973474910354,
  0.09942532873666012, 0.04815911319186496, 0.01782528837055431,
  0.2555495369318103, -0.5892671304293171, 1.633830705854842,
  0.4383827283429445, 0.48136599755507953, 0.3460793738113885,
  0.6056473742184805, 0.26817134423533734, 1.5930965000499675,
  0.1683374189868118, 1.5203316686052555, -0.28674986576301303,
  1.7216130956130464, -1.7096306365406808, -0.9035123117352012,
  -0.3781829523422735, -0.21095687234603575, 0.05618357446256989,
  -1.051943019397544, -1.0444606666783378, -0.2302339140071493,
  0.9219931932901048, -0.6009888933439357, 0.45329424931130136,
  0.006347077363566785, -0.11502158575614731, 0.9646049583902377,
  -0.016515257250399304, -1.1551055020749752, 0.8074231708871186,
  1.7142337981266498, -0.39852173510639316, -0.5523410123797384,
  -1.5124518970886112, -0.4227312001610395, 0.25430578279013205,
  -1.7929837346299518, -0.7777276734824418, -1.521098905673341,
  -0.11853582722739232, -1.2465414923741003, 0.17943177096846694,
  -0.22180039699507667, 1.0239003541215297, -0.09835764115783076,
  0.9119393684047524, 0.301010313764769, -0.657392151917189, 1.4748679726270508,
  0.22078511453168315, -0.7814093745561586, 0.3708030695873348,
  0.4294011867217828, -0.1939683565577948, 1.3742780624316624,
  -0.39473762871343804, -0.43772410734290157, -1.258127081313136,
  -0.38372641696792315, 0.6447188503663039, -1.235723915277238,
  1.3728115732355488, 1.0762441107064618, -0.09173820424487615,
  0.5746380144322814, 2.024105333678171, 0.06805972692825948,
  -1.5358278052478342, -2.0587539971264213, -1.5646869333969722,
  -1.3391671976963586, -1.445385897584334, -0.7838863191124742,
  -0.05642419158316476, -0.4842875451537511, -0.06776781157665816,
  -1.0806889702087035, 0.6913677918210202, -0.41644911621234065,
  0.5716700553127808, -0.023407351829276987, -0.12134522577536391,
  1.6015178369074163, -1.5355525470301497, -0.9930743352345643,
  -0.29970783538932055, 0.4752471971298383, 0.5258856096688153,
  1.0030784705334885, 1.344674223050548, -1.323596605159923, 1.1943775602963544,
  -0.7611373398466719, -0.2501697863015284, 1.6149715001051115,
  -0.3675915205629982, -0.3215629826548795, 0.5071062356427665,
  -1.8394246475766514, 1.4559042742229271, -0.28368935577667603,
  -0.4267402340255088, -1.37381275051333, -0.3977504634083262,
  1.2387240913722075, -1.6573468024741491, -1.2961868221039583,
  1.5044837421624884, -0.8788550657382832, 1.008960722015091,
  1.6416927275186366, 0.5643640541680348, -0.3920612296357578,
  0.3283916544175307, -0.6108807815474373, -0.6540143789555162,
  -0.7495784680884355, 0.13904936282842364, -0.5596736641577406,
  0.5418332544811927, -2.0435602659480443, 0.7502246524568199,
  0.5363859572872128, 0.5553372170453958, -0.18889362250888797,
  0.631726070639277, 0.5521542154895374, -0.7728456929800376,
  1.9467040229289783, -0.6856943104209052, -0.4963545413053151,
  -0.35643492804793053, 1.4477320455294622, -0.126881026643597,
  0.17582416800736708, 0.870572211490824, 0.6110381647988491,
  -1.3333716755899132, -0.8158956858038897, -0.06945306509636143,
  1.2619244339781361, 0.7742146685619946, 0.34909629167223, -0.9642683205705347,
  0.011536313513241315, 0.5452583518196088, -0.8290692758901788,
  2.27330208087378, 0.8756700552337132, 1.614458078871931, 0.4635627013500706,
  -0.27796950400350995, -1.3681220069157827, -0.48168621749844853,
  -0.3780611770696201, -0.06026440092891738, -0.9639725171943019,
  1.2847590276597767, -0.91089354516584, 0.2237579531468225, 1.5209894402600328,
  1.7656886544882937, -0.3024543865024795, -2.101368100108672,
  -1.5047222928595392, -1.7083347856161109, -0.8593443672063262,
  -0.18543686142600307, -0.6903181621089595, 2.6303207045868255,
  1.1561562612974645, 1.3245245297463015, 0.19394936514738492,
  -0.31165962679007386, -1.6003291786730542, -0.7954605601311464,
  -1.0347869825412788, -0.7717072000329339, -1.802144382668829,
  0.10572919751511185, 0.07729036749177412, 0.8041031491491021,
  1.2250839510145375, 0.7386673763621475, -1.0643489108406823,
  -0.2225997431473341, -0.4412772661568144, 0.09000621426168479,
  -0.7380107248783969, 0.9684451078359653, -0.37107676805212203,
  1.036508645226969, -0.3472020356709623, 0.7408241392829804,
  -0.4778938015736586, -1.732558478266721, -0.10166637101695945,
  -1.0441704228223494, 1.2199144047467336, -1.394896474065891,
  -0.3176919690565261, -0.7097921977939049, 0.838129301588392,
  -0.1559087917342048, -0.47260726517056506, -0.8777598951226789,
  -0.959346893947864, -2.5474884475613533, 0.1721683710159454,
  1.3657738085882232, 0.07942080135832771, 0.9837530158484957,
  1.0100180020683467, 0.16296796353826232, 0.3330324782606736,
  -1.073561326047472, -0.08630421433873303, -0.07444519785002299,
  -1.0469584141446409, 1.830765406326887, -1.1026342264462932,
  1.9044333015246049, -0.672323142238079, -0.16322932057005626,
  3.106620837066689, -0.07104886251295031, 1.3544131434560496,
  -0.3550211585629185, 0.5906544940878405, -0.4147480591151223,
  -0.041584317720058724, -0.1813415332948029, 1.5090116929754829,
  -0.6958980971495363, -1.245255849170918, -1.9515803632815831,
  -2.1442606376650897, -0.32914762926483826, 0.6037302194212172,
  -0.25690931610665013, -1.7216588998855804, 1.5104171383365468,
  1.4451600892709637, -1.2150720898192302, 0.6121519364000294,
  -1.2581047080400922, 0.8053317587721507, -0.5082912549493047,
  0.09718239931848754, 0.5527827550704701, -1.0197189527106958,
  -0.2819795885553361, 0.5609300727660937, -0.22350750541423617,
  0.6975836396357795, -0.3564013927634188, 1.0404905561923905,
  -0.4515868055475483, -1.1385912887683867, -0.4671241950528047,
  0.7843771687374261, -1.8452974494256131, -0.2511400218716058,
  -0.3534837520805203, 0.09885953907111683, -0.2956362958901182,
  1.0495168150604683, 0.5001880368465768, -0.4168146039305464,
  -0.15465169143739377, 0.5611801553982949, -0.6055016824665386,
  -0.9388182077017326, -0.7133009624833692, -1.930365232164404,
  0.1692878997385355, 0.4282867178614645, 1.650756764930127,
  -1.1270352531388477, 0.6782914907851098, 0.12889842687007302,
  0.30344124262813305, -0.6869408259805552, 1.0806028177989273,
  -0.046320102493138296, 0.8087153284940201, -0.1165587462207855,
  -0.9341438760473947, 0.24225040105647816, -0.6450696495376774,
  1.4929999438438504, 1.1459957968821202, 2.205815831429261, 1.165707756918521,
  -0.6654703240103657, -1.4775126846916478, -0.06344132210136189,
  0.8705078236379085, -0.243823665764967, -0.2710384946216088,
  -0.059023116897674074, 0.3957099080609397, -1.7935095033025032,
  -0.7181677285181677, 0.5658350842075617, 1.7733084776632373,
  1.1283197131735951, -0.4383886301386527, 0.40054147758239,
  -0.7323700822959672, 0.17704438599560693, -1.4688553447817774,
  -1.7609066366054207, -0.11741070684091383, 0.9777045761022156,
  -0.0764430688545431, 0.05181549031287799, -0.809197231668116,
  2.060755808393576, 1.6951153000216403, -1.282722669909602, 0.5622834208649968,
  2.0809221836832994, 0.0333203462232531, 0.4053274293330122,
  1.3494837593633549, -0.4973144749078105, 0.8670790355998812,
  0.6582530734203913, -0.2063000392666057, -0.3698522292588932,
  -0.1810347082160714, 0.7643269899137657, -1.8777837463397606,
  -0.18431626877002322, -0.11721860206562104, 0.9738481211606351,
  2.026607653081386, -0.29102261943453345, -0.4362005631004922,
  0.22238992674050345, -0.4927837830169621, 0.23965387687725642,
  -0.5572031303590802, -1.3231198894589418, -0.06429862157069137,
  0.7336412795631563, 1.0729084266373188, -0.88753129833416, 0.7601844724548811,
  0.551258504913057, 0.9610459038806605, 0.25687397365782777,
  -0.562029029376682, -0.9029787418212815, -1.9740790508782715,
  -1.268711150817421, -1.7252411333437196, -0.3287429254702376,
  0.36561015934763985, 1.9422762217318705, 0.512422731041212,
  -0.6547987188025595, -0.28672117271614017, 1.5497761308885494,
  1.0421228510113174, -1.5435239726984706, 0.2786717368568388,
  0.3700432665652455, -0.3709606873934373, 0.024884209603384153,
  -1.7119412509976184, 0.7962902338475922, -1.0556787895002628,
  -1.353446086062772, 0.21649112960487965, 0.9052651961590594,
  0.26956977311895747, 0.10433660332849364, 0.3257665463411606,
  0.19114936913669878, -0.0643478552131243, 0.2120456628523379,
  -0.5995063617872739, -0.6100685823462654, -0.6396493695159287,
  2.198143327964325, -0.958395462593604, -1.827996568300174,
  0.44113655924702794, -0.08277905074321013, -0.6329855483939476,
  0.04984298064095342, 0.687781002866067, 1.1035148036919271,
  0.23008233472408793, -0.3342374573994505, -0.030908455539356255,
  1.5009527454466698, -0.11273833969534217, 0.767714079979667,
  -0.0993497041038897, 0.02259193809263886, -0.7015969446233146,
  0.8486236221114506, 1.620188697535686, -1.1023697095602605,
  -0.9574840383879502, -0.9007861665849402, 3.0281624409420016,
  1.4253192141441824, 0.21775449601383362, 0.2368093162704397,
  0.11551154155721925, 1.3010653845739952, 0.3611128379316839,
  0.6292641136160192, 2.06637808622863, 2.240938120817932, -0.4674092700725058,
  0.5072478592729591, -0.39275272406132977, 0.4154421391191942,
  -1.1717997852015187, 0.38239317897201075, -1.3535527504044746,
  -0.3614629948294652, 0.6628345013119444, 0.6965517163079681,
  -0.11859429307241652, -0.29032096690121356, -0.27646148101230256,
  0.6161942074161563, -0.23141061066623664, -0.21815420243605657,
  -0.7062556407263132, -2.193946193670382, -0.19560372878928975,
  0.19991001911899303, 1.213151854565529, -1.3452436302768676,
  0.47030638364390803, -0.6080571185194859, -1.3394025018233382,
  0.2194520824979723, -0.8542367098449485, -0.7187746618932357,
  0.06890598090806718, -0.09099608406557169, 0.29306448601154206,
  2.410511705413514, 0.21171978068646655, -0.3933249517912057,
  1.4388786403037677, -0.6392106899783735, -0.33855941797480965,
  0.0962058324329556, 0.28686785080225363, 1.4695550167710696,
  0.7106121033382315, -0.8008231961210923, 0.3997667341866674,
  -1.088450672535433, 0.8413553176210116, -0.19834585585965694,
  0.3252936537606116, -1.0214595759972027, 0.32651802019000575,
  0.6194694986548198, -0.8484540707403561, -0.1335329254744705,
  -0.6009925125427603, 0.11241027822083455, 1.1353427134299607,
  0.5024651082993392, -0.430989180087857, 0.3724351893141318,
  -0.8841581219440944, -2.278794948794837, 0.4860418355421844,
  -0.11915175749757442, -1.8573353394002932, 1.1242256738610494,
  -0.3743821153293423, 0.2206172881431701, 0.8736668165735288,
  -0.6413108178440653, -1.6221724609455566, 0.7213834881054627,
  0.7626605906735239, 0.700694894000538, 0.5308814271775011,
  -1.6259262204614164, 0.8241511697730809, -0.2541319020696307,
  -0.5774247069660263, 0.07645099673894401, -0.7120027750753748,
  -0.3421165753613505, -0.30630707401810287, -1.117886327073184,
  1.826368768148955, -0.6562648807475584, -0.2866173055159918,
  -0.17379257829037914, -0.26286867719460805, -0.43236192404259,
  -1.3787434508801613, -0.9612174805075531, -0.16011590852597915,
  0.2235984083714568, -1.3233387899490312, 0.7215550705543983,
  -0.24587075217854434, 0.5051213342113855, -1.3429512666376027,
  0.4622099866597241, 1.0147538508489042, -0.41435426450193286,
  1.3552527400533623, 0.6048299084545972, -0.29938979754349976,
  0.8024357608120853, -0.08914434121405164, -0.4702106604166268,
  -0.8791684640696121, -0.4084904474616734, -2.070923008572694,
  1.6906073109662816, -0.011463155082900332, -0.02752715971706642,
  1.2840708797837417, 0.3145813358586589, -0.7395166056092767,
  2.6918642982465877, 0.3813263587959789, -2.969789815437205,
  1.0164608982656338, 1.7220511564607697, 1.4088644244995139,
  0.6571810337927781, 1.2114185029134614, -0.4003507367370942,
  0.42439295749561134, 0.1411998163214299, 0.23492691399189536,
  -1.2992956042875003, 0.3058376912074825, 0.5416763503035296,
  1.8568719372614373, -1.298544679587167, -0.8363730505358592,
  -0.07717791853541031, 0.7179637978691041, 0.21848571662750604,
  -0.6699505933210256, -1.5154480261771992, -0.13876990409271378,
  0.09197569875855156, 1.7682674917819652, -0.8464094581185522,
  2.951091056427173, 1.5635206717090668, -0.2929962457226677,
  0.6464651769614842, -0.31297349798137525, -0.6085835986367719,
  -0.18803284651576632, 0.08863463441024151, -0.7157816376110804,
  -1.6022977356996553, 1.6739471629977416, -0.6015450466393608,
  1.0346164331007972, -0.2201138790443463, 0.11277481452465009,
  0.7237081334916942, 0.006684548296698448, 0.3986123246044063,
  -0.005982512012365599, 0.980426817289096, -1.3772885144771274,
  -0.9214809282735117, -2.3908753876261977, 0.06627925755429319,
  -1.7202394721799785, -0.5607413453138982, 0.45594741578346776,
  -2.2615070572286977, -1.073530648447316, 0.10335287151661993,
  -1.7445700347313364, -1.4705360053581553, -1.1625558408085952,
  0.5272636461282044, 0.7954654864756832, 0.9471509098345177,
  -2.206093270113545, -0.8865009075903756, 0.9166450129528524,
  -0.15962304249720013, 0.011901195222035142, -0.3225351850965618,
  -0.17522997629632941, -1.2340650613096524, -0.3686468976320762,
  0.797343302437861, 0.20386230507225742, 0.47739393391988516,
  0.04225338431675162, 1.442199970621897, -0.5359303527594435,
  -1.2787987414642603, -0.708766807882105, 0.4918736470497566,
  -0.05122748119733212, -1.6384888762579275, -0.7767151131142065,
  -1.4207017467199174, -0.45706396183205306, -0.7612403912843453,
  0.53602808267008, -1.2826945651497326, -0.7639507944003648,
  -1.2779805677470857, -0.41628524437685094, -2.410903864974223,
  -1.0406898823756439, -0.03537956024317356, -1.2486552354253635,
  -0.9564208969247509, 0.5363884193195056, -0.8530864526136455,
  0.022827189770826893, 1.908072627527606, -0.6569512778882783,
  1.747627847009021, -0.3182268689470744, -0.4787420598613022,
  -0.5173222806712163, -0.24956153843638496, 1.2473952709072753,
  -1.9414291042759795, 0.714238149881196, -0.36318996333510256,
  0.36711357019494895, -1.4280799844653171, 1.031998963561148,
  -0.4182818568762805, -0.20017037541286684, 1.9187298655015015,
  0.0017339403843522022, -1.2309167945079729, 0.20480066486903276,
  0.8663099978755093, -1.3403454439642208, -0.1731241946603806,
  -0.4024074277233422, 1.3682221729318376, 0.6336517262133937,
  0.2019454531538337, 1.5741190567847223, 0.8589011257271436,
  0.018739184261398853, 2.1426473210572037, -0.3247892378127769,
  1.0059566697416291, -1.3150365436121176, -0.5066269205796862,
  1.0772317854150746, 1.7329391129162517, 0.142905515899344, 1.6340467414059974,
  2.4132103063698187, -0.24715144124739316, 0.4901703316511811,
  1.5289473963947504, 1.0498021998538458, 0.6532876227055403,
  -0.7570214054509379, -0.5081818238296105, -0.8109942875814466,
  0.686759336239187, 0.5485557222191055, 0.5993782934738594, 0.2936942796405371,
  -0.693918201980755, -0.04849248232719022, -0.0633013211748949,
  0.8223819512838524, -1.6448299172281506, -0.3679476953307136,
  -0.03964361716412586, -0.21986895476391766, 2.1698996587808828,
  0.019873000524423895, -1.167606163448874, 1.2463801432675365,
  -1.7989658909754234, 0.1578781954921004, 0.42128141714068257,
  -0.8287793286147087, -1.4845756599374782, 0.05012696477067094,
  -1.0167971355607417, 0.4254745131389928, -0.8540139627604151,
  -2.1157181131122536, 0.9008690117245781, -0.2168859794864378,
  0.08321968212665082, -0.179516274690226, -1.0493953694582405,
  -0.12470530723872145, -0.9874862399654702, 0.2618926080923164,
  1.3774503647842329, -2.0269139120000412, 0.6026260202360717,
  0.448555208482688, -0.32441914280642925, 0.03283585128374686,
  0.36170093613663873, -1.1679735203317827, -0.061703307160870954,
  1.977363169339752, 0.25268117874577056, -2.1678873378750745,
  1.5409168599594505, 2.0598547923721613, 1.2145909252349418,
  0.44340815623188157, -1.9950662718950645, -2.242000121220666,
  1.250617033423724, -0.5595876445485589, -1.5481311176133579,
  -0.1810405286430937, -2.1500722115589714, 1.7937634654130548,
  1.2011609471123004, 0.14529862286706124, 2.358167716959329,
  -1.6256790384843365, 1.2875881997773282, 0.4501984109021647,
  -0.6832171503355577, -0.051595227446662194, -0.07341915680184675,
  0.8832824839757406, 0.5313740404116308, 0.7622311935133099,
  0.8383166287138546, -1.5792287269012846, -0.5636292014401177,
  -0.5330293329411744, -0.06934215177147125, 0.3969030234842222,
  -2.0718798252497206, -0.6820929535223218, 0.4488957089650311,
  -0.3772151902769864, 0.7253787454319282, -0.47027280264489224,
  -1.5055903308549534, -0.27935879786975387, -0.6110655548455123,
  0.807113124170302, 1.6457504283749715, 0.03833882535328004,
  0.48747793122152344, -2.4045204609377895, 0.18232883105307301,
  -1.2474847153874136, -0.6017620527737029, 0.12405481571842092,
  -0.2611120572577527, 1.000911548416482, -1.5156108913118986,
  0.17423820235671378, -1.5592657182612915, -0.8137682723400864,
  0.16684088911837763, 0.24121064826549476, -0.6723600278559846,
  -0.6245669216995297, 0.24300919336094962, -1.7299373873931925,
  -2.937093322500509, -1.224293959663686, 0.0011121636338857202,
  -1.7605155948626094, -0.19824655015566692, 0.6225219576393236,
  -0.6728303658059056, -1.2507983948212835, 0.23017160823258087,
  1.5275726598690929, 0.2892199876842341, -0.0426177145142006,
  1.8358174563729883, 0.8628700002511147, -0.14240459293927596,
  -0.23191294553660421, 1.0205120208589566, -0.45529562542733326,
  -0.8715772325603739, 0.8535216839446198, -0.9875316735780421,
  -0.4210030797218648, -1.163022336126062, 0.42795753104875867,
  -0.060397095242329064, -1.4793063155338262, -1.2127720043205263,
  -0.4131785459584399, -0.4045969924187613, 0.033187993321457984,
  -0.44666582579456005, -0.31767476433354125, 0.2877711793726807,
  -0.15322390941274, 0.8984094166464478, 0.42771857780247663,
  0.7240915292107616, -0.1219481483514786, 0.3898767099886583,
  -1.334081726091556, -0.4583145490957826, -1.7439917393814588,
  1.8900447609606696, -0.08225821911355254, 0.24940388116095535,
  -1.4200613007730125, 1.8703489259572155, 0.6089125256925321,
  0.18919177537164197, -1.4689672961178042, 1.8869407135280332,
  1.3756795471381607, -2.2468037149870397, 1.2729243684037577,
  0.5480471143257689, -2.0826137120909065, -0.553330333873015,
  0.0886932645278748, 0.07426304963916383, 1.8056854704213714,
  1.8766435078556574, -0.32832586396035196, -0.28852766432405685,
  0.11500104079778378, 1.187091965117088, 0.3609783314278204,
  -1.8175447155766533, 0.1408247075603972, 0.5986684821928583,
  0.6610583309957735, -1.0621450894662186, 0.42395240951060525,
  0.775048576926286, 0.025125366093618873, 0.5218476585627577,
  0.4962875540184897, 0.7079897207789599, 0.4232647440348199,
  -2.1892379109436173, -0.5913399496062572, -0.4385746782902595,
  -1.6327328116143924, -0.02779894835408243, -0.46289934161411633,
  -0.9026460483868941, -0.14322096228451525, -0.2774519205279623,
  -0.25513880273988776, -0.6437092853921474, -0.5845267253687971,
  -0.3262037642736679, 0.6872332255157948, -1.127520593579025,
  0.15406945101900876, 0.6788362091910275, 0.47628984816301556,
  -0.16275487380238446, -0.7990867952062202, 0.06601638141318067,
  -1.5574380414339744, -1.1471079190716906, 0.04791970796044768,
  0.9935743790270932, 0.9204000991520696, -1.1012579434008651,
  -1.6197013293541038, 1.4577023377165987, 0.8339980049346482,
  -0.011423650800642287, 1.4013393059965724, 0.9218075589605792,
  0.4237586473834221, 0.7075818432026567, -1.0262594566028769,
  1.8407908648443523, 0.3486094822449474, -1.2527645540419674,
  -0.19397439004132044, 0.5495918432905361, -0.4064255262523264,
  1.2875882308549587, 1.8107929774050024, 1.1788278655867555,
  -1.0241094613837949, 0.7059215419862928, 2.5610501890562487,
  0.025533803059896266, -0.12493147779094342, 0.6292204020301019,
  -0.2994593677272221, 0.6855507451706795, -0.6816672031578633,
  -0.5811661348680769, 0.12396575888045809, -0.2214371599419143,
  -0.5275614740779621, -0.5709001245445602, 0.14442917390338222,
  0.21170142731028782, 1.1141853592840296, -0.49167710396567005,
  -1.6259964930145778, 0.9196532101638194, 0.21719127334834878,
  1.0671944936262534, -0.1511408782822783, 1.7598312244670755,
  1.0304864265704505, 0.4188274219076101, 0.10085218759054096,
  -1.0080286497025308, 0.6113011287216695, -0.20843015542463453,
  0.18907213201700102, -0.3017200580315877, -1.5600442590222796,
  2.0754394403909995, 1.2073130961476997, -0.629964166195141,
  -1.0322684673944411, -0.7861708423010099, -0.07100108079995016,
  0.563213780067459, -1.307844785486466, 1.4885766133033491, 1.1499122462822164,
  0.32086415597645807, 0.5393948357585822, -0.6293255511956752,
  -0.16111179461348163, 0.2820646192086101, -1.2525202609735955,
  -0.6930101775970627, 0.8522213299213465, -0.10235327743825032,
  1.8308023840128578, 0.6009647645240587, 0.697624622820628,
  -0.3935122455639294, -0.14542837422096072, -0.9366906081859366,
  -0.37734240524091817, -0.6787712584399401, -1.9263549035860794,
  -0.1349863531550499, 0.6527631133070969, 0.1581828167683617,
  -0.9631610768960738, -2.6450204770225225, -0.021337037060464626,
  -0.6769086774687568, -1.6664988391975686, 1.3383908527625388,
  1.6885422799411514, -0.46134556955383266, -1.279647579700751,
  -0.044883781204080826, 0.15560299650962592, 0.429338395157796,
  -0.06916677658886118, -0.6424655685254332, 1.3414138607664838,
  -0.6690346970168954, 1.0926401875030847, -0.5491368825661836,
  -0.5108101594741524, 2.6361908783692964, -1.0323861397651386,
  2.2957423544702142, -0.8531197567997216, 0.3107398754173361,
  -1.2015359431958077, -1.0009853880328923, 1.553141737064088,
  -0.4647088666329518, -0.8531232697333979, -1.2436392267229017,
  -0.7294236725739515, -0.8533328313520508, 0.38109131310863875,
  -0.4102077242882096, 2.887336687765994, -1.3952220082871245,
  -0.187872469894885, -0.8981194777860146, 0.6212963066206827,
  -0.8145575552509575, 1.8356729540311778, -0.7553661465831308,
  2.8907620879193763, 0.8854041820353211, 0.006571855943794241,
  0.6558162657337272, 1.416682171516606, -2.0503510521005373,
  -1.0716842370141753, -0.20793082763885645, 1.0725765414756037,
  -2.0509904627766153, -1.247116766567509, 1.3476717819166568,
  -2.0820951402226036, -0.8183746507975979, 0.017914845661818425,
  1.7127645505955127, -0.2945363338987729, -0.23626118657362208,
  2.0756521083983723, 0.8901872965176822, -0.46679975409604485,
  0.1510699178454303, 0.5309983463874628, 1.1754076190397167,
  -0.633474048647832, 0.03963446250209131, -0.6132077902723561,
  0.6339315812152504, -0.9435804402163023, -1.0912726448836012,
  0.6415930813910673, -1.3797612433190254, -0.5261517852311007,
  -0.6944350561053139, 0.2322743104235142, 1.3561160365673381,
  -0.37928269203219217, -0.2772239798223614, -0.8945379552668664,
  -0.15901574235875737, 0.40372722129127614, -1.705346805508768,
  1.082592332705069, -2.3047718152918333, 0.5527110699856357,
  -0.500009637702214, 0.6595083311819286, 0.76875258134335,
  -0.09145929453757047, -0.28992949959334746, -0.1777799173160585,
  0.12042720630202518, 1.433911258871702, 1.312045764286125,
  0.22390447310099929, -0.28814631905957727, 0.6477551395869459,
  -1.0997244437728293, -0.7646495152556438, 1.913703496469549,
  0.0946011199316835, 1.4170470200904999, -0.5802826048577469,
  0.48197406960026834, 1.2675308177449411, 0.5501930776470852,
  -1.1111360930186267, -0.7524193067186385, 0.0711104339120526,
  -0.3189156237167046, -0.3124941744678327, 1.2759219523338226,
  0.20790575303420666, 0.10653761349566732, -1.4546368689969538,
  -1.6466401875547272, 0.4942075657060798, -1.2930708960297035,
  0.30666914786853827, -0.6963331017534725, -1.433644104802954,
  1.8569033994258828, -1.6438138786720007, -0.029542636453446788,
  -1.776790174732819, 0.7282547782253584, -1.6164578363028357,
  -1.7672857045909387, 0.2744441002246707, -1.1543186460405173,
  -0.8187463650886139, -1.0784837878065696, 0.027041246779410485,
  -1.6244190452732876, -0.46446557083453116, 0.2563147659794235,
  -1.5883950205027193, 0.21451729688917454, -0.031073688599300636,
  -1.735083836815263, -1.030715168490539, -0.9261405670460665,
  0.12907464311938086, 0.2683988816290755, 0.6619051998786362,
  0.26340954084169077, 0.4291053799489262, -0.6548445305099632,
  -0.39706564457742094, -0.9863376947958076, 1.0449646761587608,
  0.5049718534287483, -2.1393014023736363, -0.49733456973091184,
  0.4562278002467541, -1.458255242636493, -1.160768819516052,
  -0.39421084950992424, 0.32751547070957604, 1.4998391350149385,
  -0.6890628121966013, 1.7637954547575534, 1.2519525771571367,
  -0.5317260962947178, -1.3689666755463852, -0.3589957515085877,
  -1.27778480837635, 0.07698034687744279, 1.1251452239401143,
  -0.5544638190570894, 1.4471334846216295, 0.9801444479995546,
  -1.1665701419195635, 0.16171514635797393, -0.27348512693850713,
  1.2456889690686843, -0.9683321952578974, 2.8243750138528565,
  0.10163608507198406, 0.4721138063773359, -0.8707093677448081,
  0.4865893368808951, 0.2514130605074945, 1.1721766512250253,
  -1.4083183040018394, 0.11505303158273096, -0.08477155399988225,
  0.8315115548806825, 0.9848112186391574, -0.2130874992085869,
  -1.0574191554599064, -1.762396991236997, 1.541791549697035,
  -1.0308133275235982, -0.674654903929665, 0.7017878806325514,
  -0.0702544577563141, 0.3798407635243849, 0.6187989138265737,
  1.0637022858442855, 1.1912118774426321, 1.9288069728688149,
  -1.5346830532864582, -0.6002475259948112, 1.9258810293709832,
  -1.2649477842391916, 0.9847762335187491, 1.186808045359636,
  -0.6248935162115125, 0.2545251323436375, 0.9864576398605088,
  1.020185756843118, 0.4038961086121855, -1.1905560012690912,
  -0.3318375573570255, 0.8956099477580297, 0.16338597258164458,
  -2.2238308827002, -1.2324499987568076, -1.3003933833625074,
  -1.1984279259349495, 0.5525077739005966, 0.2623053713820779,
  -0.1030170391179583, -0.8583597028906758, 0.3901300427845025,
  -0.6442125258528948, -1.2068503662901997, 0.5182430031407885,
  0.6916088056220018, -0.12911917102245918, 0.23424733628257569,
  -0.37097375566668067, -0.6163319934668636, -0.1306535424734515,
  0.9501769967267651, 0.8872511226168786, -0.829564133208367,
  -1.9120821571077722, 0.035859493343282464, -1.3050461139502565,
  0.10646704596413228, -0.5692665915121805, 0.14466684075003425,
  -0.5379611542695415, -1.3629684541724154, -1.2243422834233877,
  1.6458424559838019, 1.2614575299841848, 0.2590104793929164,
  1.7481432832482184, -0.2721561815839445, 0.25116834667969284,
  -1.0221471456204116, -0.7349017886858514, 1.8211555492672653,
  -0.0438409312950989, 0.22713042125078023, -0.2490604951927814,
  -0.32628611502935506, -0.11332759331298345, -0.40325910859146985,
  0.7793777969214585, -0.15886719077307102, 1.9729681361239821,
  -0.8226772882560307, -0.9888979305280703, 0.0876724096772531,
  -0.49836421617770293, -0.7821176332565849, 0.42318631748360136,
  -0.8297087780471655, -1.326824321206012, 3.0679740998419285,
  0.1734266237482497, -0.1547987271758525, 0.5485336548526241,
  -0.9582513989059259, 0.5834020866290368, 0.0549314996101759,
  0.08458660414156423, -1.1715137518847882, -0.22175490188174968,
  0.27339947292192557, 0.5501678544108674, -0.22248503806224604,
  -0.2868751282423155, -1.6864534778768068, 0.43079411989534505,
  -2.0643837669384246, 2.0348576342731546, -0.8256040712378185,
  -1.5204668469077123, 0.06799747076203758, -0.7927566668162599,
  1.2391206844749816, 0.4029492705473897, -1.3292207337239348,
  -1.8049009340542848, -1.275464828155581, 0.2764283365953673,
  -0.5602311472341798, 0.12112399577230079, -1.188942339443697,
  -0.04783874651215262, -1.1387359278312934, -0.20873796648305368,
  0.2731926130218595, 1.4459595270818446, 0.35169244044601633,
  0.9338150979370242, 0.9791064275705283, 0.9300740715165307,
  0.5297506110818818, -1.404874758472475, -0.4960621877595103,
  0.2674468009471168, -0.6216645802045601, 0.8928854343124057,
  1.305184129150614, 0.11181695525730631, 0.05095229094344462,
  1.2520189913254847, -0.3174946456088544, 1.0982077185325463,
  -0.39666293259281227, -0.05827952274107268, -0.9272302255734833,
  -0.18183924317369424, 0.6478330383055749, -2.1868422951323434,
  -0.423601605940059, 0.2585541943582096, 2.393644867968211, 0.867620242627465,
  1.8042488305138185, -0.9877737566547171, -0.07312514582611888,
  -0.37876109954245485, -0.5274502841970751, -1.1066369120961645,
  0.9569102707182068, 0.7305329402037666, 1.065192152412259, -0.761980785014455,
  -0.7609290467994733, -0.2862015780891435, 0.7595450022738548,
  -0.4902177552082847, 0.6217196443304401, 0.8133225335011117,
  -0.06837691635366351, 0.024091867654446104, -0.32082304922543314,
  -1.5686913246557923, 0.6306568732520694, 1.7215887200319413,
  0.38898316704808283, -0.4913129569525076, 0.2780900061076763,
  -0.8621738851700822, -0.8212164995735585, -0.7838559965956666,
  -0.026126479000124034, 0.17650999726364805, -0.495815409544045,
  -3.0003680736413774, 0.7170636053544067, 1.4125553849947245,
  -0.35092590857015193, -1.5268071088956279, 1.3317252017259407,
  -0.10465954446809347, -0.6019352477891485, -1.156753368903071,
  0.6006591768817551, -0.8211844806603902, -1.4002454491565615,
  -2.638357762046155, 0.2324597811140637, 0.25131836958352505,
  0.5690407179750103, -0.09740340842382855, -0.21842702978013873,
  0.21330840840527682, -0.8106193795499914, -0.3916037991150015,
  0.6803624932452165, -0.5691138247149847, 0.053966917003286215,
  0.17419315966273663, -1.626647223112804, -0.14332920485393674,
  -0.19972247110873784, -0.6572515521245009, -1.340018983805067,
  -0.7057126977416021, 0.17061866575775195, -0.650362295362358,
  -0.6062422931635928, -0.5572918993928629, 1.6573100913297307,
  -0.7731808744211733, 0.9996264073516637, -1.0536295614850193,
  1.282083871690506, 1.0773720024768103, -0.2174757292115373,
  -0.5388781736207539, 0.668687597293966, -1.3190836556799874,
  -0.5231893162343306, -1.746177464424442, -2.339566576113738,
  1.3532878651330023, -0.31520934319963473, -0.5505386951770284,
  0.059068987589100334, 0.08849591702216963, -0.03183549382548564,
  0.32861019032627614, -0.29589831661298716, 0.5825986228302212,
  -0.8480810228650112, -1.019139281228859, 0.6414915661755463,
  1.4572813774201376, 1.187941992135107, -0.5685661379842736,
  0.7948503639818809, 0.987967623450303, -0.14858541979161163,
  -1.1640827116627728, -0.40717753698151016, 0.9676066921435492,
  -0.25482881830395615, 0.9771844121772334, -0.813737559865164,
  0.6077806160453065, 0.5653368457754392, -0.21815342855933903,
  0.4773477728912552, 0.45230902967793757, -1.1398075687211662,
  -1.4957125066863552, 1.5580202234730656, 0.2083806937869467,
  -0.16055518556959783, -0.6948512447334666, -0.9506437690027888,
  0.8944135826403812, -0.311437320617222, 0.8250099292776706,
  -0.16902750242691408, 0.011190304972586176, 0.9548652466471942,
  1.785411166363531, 2.3905999952969976, 0.44991803967851074,
  2.4561915842967466, -0.37430739279095043, -1.2503217467241032,
  -1.4955709923807174, -0.7336355319966084, -0.36510287716006123,
  1.755272966453367, -0.8239589781470882, -0.21133921348071752,
  0.22784929283140415, 2.02238031486539, 1.4988365206677199,
  -0.4920194227613568, -0.8434698319383537, 0.10696728033348225,
  -0.799325335229405, 0.5818266488759081, 0.3943682014027523,
  0.5580093714934161, 0.7095194857034695, -1.4772727972562347,
  -0.6225940807693673, 1.2066914977993652, -0.8302446850294624,
  0.9058011681614753, -0.24233943154467572, -0.018170521949166216,
  0.29890418821533865, -0.16806148261279877, 1.488502078037852,
  0.19635792155867077, -0.9657708235989769, -0.29053693151797877,
  -1.90161960831173, -0.8832043201794478, 0.24565371008815587,
  0.40518154138024953, 0.5775223164265049, -0.35549784078410035,
  -0.5622114347893349, 1.5377012403172943, 0.860156370779329,
  -0.14865065866616517, -0.3094508629955516, 0.17243865650586607,
  -0.9067172558567161, -0.5498735663830666, -0.6985986933513088,
  1.5384349499819205, 0.04653444571450602, 0.1211341503252964,
  0.26610211614181384, 1.918588156956375, -0.07972881574423928,
  1.863737825917203, 0.34418301794917067, 0.1280821777316778,
  1.7970992341133427, -0.19383461433930388, 0.45845332956975965,
  -1.8731280951045497, 0.5621756053005869, 0.38573368179331763,
  0.5250844266944602, 0.992198606161249, 1.9228336759225144,
  -0.6336950017861878, 0.9955106349334302, -1.2604213514643974,
  -0.2658452088246213, 1.8705013281212295, 0.47603339757115354,
  -0.05645205676713506, -1.191842018203841, -0.6224523526379598,
  -1.0256036876297383, 0.782074273340713, -0.0651050450609313,
  1.9743991309722149, -1.6547888019576995, 1.5293887514671334,
  1.8248325777401724, -0.7299379438321033, 0.0915039522786139,
  1.0878729746117715, -1.0083584401413868, 0.05491119656138073,
  -0.8486777976811662, -0.40103455321828896, 0.7297408604429508,
  0.13858425676407524, 0.6387212463204349, 0.3465838643475472,
  0.5224866343905809, 0.44150193966820694, 1.8806153573807396,
  0.060274814548983764, 1.1391500068974043, 2.281569161604386,
  0.5004905473410648, -0.6730208220294438, 0.7379332497896396,
  0.24267435841473214, 0.8781987346940002, -1.5565860239380616,
  1.7446205934699672, -1.4203994512838318, -1.7765289782290354,
  0.9017768522762201, -0.9493163794450912, 0.5904253275540259,
  -1.6747464550966578, 0.8427967705509811, -1.1499439721452052,
  -0.42977511152545594, -1.5242455408849995, -1.3299086528206459,
  1.53243992413434, 1.800787888172803, -1.0244145839255347, 0.5995379594885081,
  -0.22016189795377183, 0.7273604022269163, -0.008260066267287492,
  0.2554650426387327, -1.5899918687634245, -0.5102060458811215,
  -1.4640143711346587, 0.19302218392642842, -1.5259053883743943,
  -0.6931224901793199, -0.20819243415245567, 0.6670028886722872,
  -1.4384297130273127, -0.3883351554190213, 0.26384292272788445,
  -1.1917772478564572, 0.7890826146922806, -1.0463671118229625,
  -0.5269788355716832, -0.7229607238739686, 0.012919945574953838,
  -0.2697994810396195, 0.757303735112576, 1.046939144226902,
  -0.20339060202609244, 0.04642432943919943, -0.49961881429258787,
  -1.282914312147308, -0.4810199335735935, 0.06049539744299669,
  0.9618150136088344, -1.5910595613569631, -0.6402171250046157,
  -0.9521396121666937, 0.3678763418254158, 1.5949083810849398,
  0.5538526823885892, -0.9164365486853239, -0.5727748838864268,
  0.0831320183497392, -1.0622657090200116, 1.0011229868693607,
  0.7185490119498655, -0.5922640528643104, 2.213610326323997,
  0.15097592795044906, -1.4657203299506159, 0.09564083319269738,
  -1.0986493506983914, -0.7015800682352304, -0.06400859912683927,
  2.990017957733202, -0.1118600279525207, -0.9417646583361329,
  -1.3256719485428115, -0.9966919184619736, 0.6754083449853827,
  0.8310168665234372, -0.3285867526563875, -0.8557853355270956,
  -0.7072745428557654, -0.690746197048946, 0.598812512402881,
  0.7234103717545228, -1.7553924693181753, -0.9843151794041438,
  0.4947649096793788, -0.8674606422306685, -1.4351220078205138,
  1.1803484952704688, 1.3830296266688968, -0.9985102892824272,
  1.0503405467350329, -0.4652213765094479, 0.6976098101373969,
  0.9808979046488576, -0.6269620573978448, 0.7269567866360152,
  1.733866638805598, -0.17073222132715155, -0.1704496869316886,
  -0.21770168209326132, 0.18240499265857982, -1.0334731837332694,
  -2.3406087620110863, -1.8144809734146456, -0.13514340753544118,
  -0.27433738999614693, 0.10282384365482285, 0.7805542409115004,
  -0.01799848769127569, -0.5009052247078685, -0.8581102667458836,
  1.527842517353195, -0.4099038557041111, -0.6359963459607225,
  0.8722400796569818, -0.2550045060442389, -1.5452604199531736,
  2.4803002889752994, 0.43113112900338363, -0.045875402664710734,
  0.4718258710413958, -0.3846984246720341, 1.0067337543959372,
  -0.40924278255214297, -1.2516224004780931, -0.2516705531279376,
  -0.34184237964090086, -1.030681544753571, -1.8324550090379819,
  0.24867329340717617, -1.3154380385960374, -1.187508582026764,
  0.5096902627006782, 0.13968389574186585, -0.834295726141511,
  0.9851300425845607, -0.510633677979778, -1.379591660160219,
  -0.7991545068828717, 0.8998272994003689, -0.7344442076959051,
  0.7854090430599393, 1.29555617755025, 2.009415124644694, -1.0901809758128969,
  0.608458197892191, 0.07961586747184027, -0.17028881841271024,
  0.07959644154573976, -0.1403142202820921, 1.0745390745149224,
  1.0026851287220224, -2.115194886805484, 0.8794919766743072,
  0.7168262944492744, -0.18062422642791337, 0.819380762607537,
  -0.7738187648620295, 1.3262554415047527, 0.27382085427061964,
  -0.23762231800493971, -0.7867810299665912, -0.10999620503890443,
  0.22251833077210936, 1.5121620651275614, -2.1933114862377168,
  -0.38904809952471503, -1.0113592049986064, -1.4425310388018895,
  0.30232024425030096, -0.41023916564302565, -0.4554124823873757,
  -0.28381417984733487, 1.6571363247496198, -0.0017809079286513554,
  0.04020287580840111, -0.7228985956418224, 0.5043828295190509,
  -0.7240970580685683, -0.47925963984311093, -0.18470240553653103,
  0.4160327265541935, 0.7637040308026707, 0.3829038825555139,
  0.3287655845336608, -0.09838473323590397, -0.12917045686235326,
  -1.8044259181517104, 0.09284445151357773, -0.16790868364229136,
  -0.37076487472438197, -0.129594337880653, 1.2878279173436458,
  -1.0444510217188774, -0.6420850970986541, -0.35114760456017363,
  0.6663083848424104, 0.7003992834190875, -0.04328711814784079,
  1.9710903543391731, -0.03577299998791534, 0.31598994695245264,
  -1.0784404034157653, 0.2708763132024054, 0.3470232783358839,
  -1.21214556877148, 2.597968671635008, -0.4317418574618845, 0.5715828496721261,
  1.1187397250516904, 0.7015283656027175, -0.19516985257633285,
  0.4247980699190043, -0.5406629145645857, -0.2405265631408376,
  -1.027762045678449, 2.0977399235205705, -0.6896089263329978,
  1.4598130152667588, 0.9211157579122276, 1.1125028588174137,
  -1.5613069592595334, 0.26415549579781633, 0.6619809672540707,
  -0.4691940515741404, 1.0366785662374847, -2.144203107144432,
  -0.9348528414410319, 1.1615022350412987, -2.1401663339573784,
  1.0731828248943112, 1.1106194143252006, -0.630316101541416,
  1.4459690493976851, 0.4441692308145523, -2.2831376508733583,
  -0.6456290522423023, -0.2845818971559732, -1.2879121353221827,
  -0.4581486162768608, 0.16308569780323842, 0.47753171832669544,
  0.15097245601327935, -0.155691009172244, 0.7665858770954807,
  3.387365333694452, 0.6263593381847052, -0.036640473098315925,
  0.5789231117880789, 1.1047497807370976, 0.09713533160830694,
  -0.6229046849382562, 0.3743532095865816, 1.210207758486542,
  -1.552422488086601, 1.4341544830115904, -1.4400853318153966,
  0.9346010854283024, -0.7732041762793852, -0.8801517292144663,
  -0.25131561163413907, -1.4336446476421125, 0.37488127823128964,
  1.0576566283898727, -0.6606476648657529, -0.3679161822179882,
  -0.19334513678127707, -0.41657302156942044, -0.40180877737751847,
  -0.36399079313484406, 1.640837388086155, -0.07731879656147787,
  0.06105877884008161, 0.412575497611593, 0.1503896195794801,
  -0.1506720878683953, 0.547462841043491, -1.4884735084951728,
  0.524648537398641, 0.5343734659278633, -0.1944403329144353,
  1.5508472687409707, 0.33389008981067114, 0.8991959686484952,
  0.9217819073335257, 0.08637668287565943, 0.19254679301049535,
  0.20097476321117824, 0.17007955247053116, 0.19861614225378474,
  0.26195060995364194, 0.803682385322532, 0.14141541641776237,
  -0.16175236635642204, 0.8338157544564535, -0.5609129620686059,
  0.7377483551484303, -1.2266984939105523, 1.7665373109100093,
  0.33328337357303406, 0.9845728878615065, 0.9175725395449092,
  0.7158651052545711, -1.0057095334864796, 1.5739864947736195,
  -0.4669579196701859, 0.1860272126448873, 1.3657970234601737,
  0.3186301588831575, -0.7691715575903005, -0.3709620772038739,
  0.4476642045066028, 0.9991452560553199, -1.0007705855423705,
  -0.277498257951596, 1.9536558862186306, -0.744710542432399,
  -0.8185944646465438, -1.5665142595016026, -1.5186671073932463,
  -3.031374159752175, 1.180517396217348, 1.2553977477459946,
  -0.10977234350888099, -0.5551885174303872, -0.7220867887197482,
  -0.3055995676345934, -0.5830214066604625, -1.89569838628823,
  0.5029282633522194, -0.15136738486099507, 0.8885137139008549,
  -1.0711101310237383, 1.0892344824943354, -0.5665877644848425,
  0.962327720254785, 1.1799294379961283, -0.5596084524471842,
  -0.371788748030798,
];

export const distributionMedium = [
  -0.3418921871695516, -0.6202874059050965, -1.4428925586112193,
  -1.3073441053160348, -1.189409413928774, -0.7572138091979301,
  -0.5813561443293429, -0.519921773465528, -0.5338384609752078,
  -0.5360396755954009, -0.46143514421952897, -0.4347897016833837,
  -0.35093658510117565, -0.30792326839572537, -0.25761202648117726,
  -0.30393156996171883, -0.24565148440785584, -0.2118557986662695,
  -0.19140475157976564, -0.2106682566938971, -0.1968972968428494,
  -0.1644376704865995, -0.17497716826871426, -0.14239418297476034,
  -0.1791377753298898, -0.10820087911546067, -0.037895797141319104,
  0.004797882480009412, -0.04330918580442901, -0.031673824806161655,
  0.000524917715804741, 0.008540528236515327, -0.01674172039268856,
  0.0113058858744381, 0.07766480905328614, 0.11657248360181982,
  0.1257402031764798, 0.12268117798674173, 0.1102962818646714,
  0.08277413678142634, 0.08167650257019926, 0.08071659193538154,
  0.047304998995695964, 0.0459858172790022, 0.04205262706154449,
  0.05898862653580059, 0.05542972556827625, 0.021367888521448858,
  0.050641148948257615, 0.08197101776097729, 0.08243384888307992,
  0.07758240930583016, 0.07292669119098313, 0.07055357833510444,
  0.07709886058361753, 0.08485451753641796, 0.059924623041998235,
  0.07469311793093458, 0.02865740734241566, 0.05147792995885799,
  0.03488980131889583, 0.03325493146400501, 0.0027933909008914194,
  -0.010525231212154332, -0.015032987936181682, -0.026187943408355854,
  -0.008189964038587908, -0.009378003140038574, 0.001963995928463914,
  0.031293770870392694, 0.029195554582746117, 0.014185327107880674,
  0.03770808538056633, 0.04639295936406583, 0.031847531296712916,
  0.012068209832629538, 0.01154971607547096, 0.025978251562384484,
  0.02335454038932407, 0.021096759493767252, 0.02884173364920494,
  0.03824767085855803, 0.05334958032857824, 0.048356943575914295,
  0.044388876864961346, 0.05219347341195691, 0.042961862713991536,
  0.016988849520711093, 0.02336728954944126, 0.03521006985351731,
  0.04190415679036342, 0.04624418162597658, 0.05605001243964268,
  0.053398304748810195, 0.04862203553260037, 0.04832027097728959,
  0.059180298308562106, 0.061025984698000975, 0.07580689487661106,
  0.0816705523029796, 0.07839063143229745, 0.08304630785081349,
  0.07709694604974016, 0.0682362279344593, 0.07104916190483122,
  0.07839695591670595, 0.08275594331482368, 0.09435986371721355,
  0.09927411821649537, 0.08642143955887305, 0.08764604285516375,
  0.0803225827248223, 0.07763199600839243, 0.07990646516522128,
  0.07456758918280151, 0.08263378709689623, 0.07808091290256636,
  0.06920643600015329, 0.06535439053841957, 0.06648364726234458,
  0.0716446079673241, 0.08074779529517277, 0.08022955696221161,
  0.07831380414507125, 0.06375929054446211, 0.061941290300183874,
  0.050400280232400854, 0.06056524341688835, 0.05142586416149949,
  0.059723712367817865, 0.06836091928654872, 0.0624393353724299,
  0.06851346218947887, 0.06029952411455086, 0.06204807250319575,
  0.06054183820969657, 0.04678497867911435, 0.036802067830488595,
  0.027955933946326006, 0.024475044117137922, 0.01822163064849562,
  0.017114381542989884, 0.013457491004223875, 0.02054454765901665,
  0.0126885652697915, 0.01932417661586171, 0.013808557727804783,
  0.02052281959446776, 0.01229815551276221, 0.01491491167497781,
  0.01839049790420334, 0.00940151170297533, 0.009435670921230627,
  0.015500804169729189, 0.011867591442844588, 0.01904601812258365,
  0.014155761119334472, 0.018846026516773914, 0.02020667344353164,
  0.013059122283434695, 0.005516619761952927, 0.009816129211344098,
  0.007791920238190523, 0.009354936808034205, -0.0015292856537659064,
  -0.0017755831257236373, -0.010228858128498858, -0.012392099886228298,
  -0.006808270837379899, -0.004961590387445406, 0.0035734676577171536,
  0.008459417383825434, 0.011549602932811591, 0.01435223648895473,
  0.006832489930750675, 0.005177652793524375, 0.009044485286466255,
  -0.00255472363948352, -0.0038475197724348615, -0.0014040482478795955,
  -0.0033376394952900046, 0.010571169426868062, 0.013885718269458008,
  0.013983220369592991, 0.011247181626746719, 0.017155208707036143,
  0.01607790500891398, 0.011174582502607004, 0.01282111504108726,
  0.0063871970611725625, 0.005358833981427392, 0.00622541900356334,
  0.011492235204180825, 0.01647903858271783, 0.013564494552234645,
  0.007917675757459188, 0.0006271969789153263, -0.003991399462626808,
  -0.004698003371856631, -0.004930178626469273, -0.004281673245993637,
  -0.00021058835184942638, 0.0006618700443364041, -0.005317601900812502,
  -0.010098375308116584, -0.00496712057536512, 0.0017570669251161864,
  0.005745521233290034, 0.011125630960763572, 0.012013292609824705,
  0.011050505111758006, 0.010303190969293394, 0.009692354005709898,
  0.011970296728030962, 0.0075199065465615545, 0.0035651483741836383,
  -0.001863214965637555, -0.005061616406724629, -0.009475746534115257,
  -0.006392071291857575, -0.0028617968239463077, -0.008832335045154165,
  -0.0036824742016575733, 0.0027512340965601323, 0.00938850509453823,
  0.010097694522199467, 0.0035424957456728265, 0.007219399832752097,
  0.010824012616328066, 0.011116982693791558, 0.01026657412681193,
  0.010673022734614993, 0.007223635653795021, 0.0058794401554225825,
  -0.0027241791000917038, -0.0017931978107986162, -0.009272149817700876,
  -0.008210094308579044, -0.010668213828513642, -0.01560573666888511,
  -0.012899564745388893, -0.012424498945574612, -0.014162948833703712,
  -0.00519598206209422, -0.010729807952710559, -0.01718064486514896,
  -0.008156342880989239, -0.0051029221827495795, 0.0015547066054882022,
  -0.0013108200848582642, -0.0017385322742228184, -0.0055410833523656415,
  -0.00519873272690903, -0.003562959104786274, 0.0002724905236911301,
  -0.003943610477060634, -3.8343464418870326e-5, -0.006038796125940301,
  -0.008034189751378255, -0.015832010572084686, -0.013308065537832224,
  -0.022452646811097317, -0.02248283052291185, -0.020948077695460827,
  -0.021067959707523395, -0.02145166608997282, -0.020063418100656632,
  -0.024936256564976986, -0.0209899310808421, -0.019671247446494716,
  -0.02326410609476434, -0.0264130985933017, -0.03036755677662955,
  -0.027645644205929576, -0.03166052183935043, -0.0312718313441209,
  -0.03850158695397394, -0.039124568832015046, -0.03491061237224719,
  -0.03446604837703012, -0.03220256141998905, -0.033866786989345955,
  -0.03478954773592377, -0.03289243188234494, -0.03206426040973999,
  -0.029710483235977, -0.026300802484339067, -0.02221537887209373,
  -0.025354831659846366, -0.029573853335774006, -0.02964109144607442,
  -0.023048566814442777, -0.025652625051319843, -0.02295575490533347,
  -0.02372632883519684, -0.022218040017655368, -0.027315145069784892,
  -0.02033524774645625, -0.020990413111077338, -0.013292179621184536,
  -0.017507616533672152, -0.017467540758621697, -0.017071161405674815,
  -0.013581696485537613, -0.01830375206873455, -0.011892788259656681,
  -0.01492335830882059, -0.017213231958455574, -0.01468389245044575,
  -0.014625703189931841, -0.017058627749437047, -0.020105802860440062,
  -0.02067024123438364, -0.015709779985039213, -0.013923961890839994,
  -0.014178977027672228, -0.016843781179363384, -0.015371159097764202,
  -0.010976520312338528, -0.006346480478658108, -0.005177719913806018,
  -0.006003231389650808, -0.0032885887451822838, -0.0029508204334934046,
  -0.002079612726854183, -0.00199804480150983, -0.002905192368052522,
  -0.0024791179597706474, -0.0027275012345824314, -0.00012185631172552248,
  -0.0033111143016053266, -0.003109371631212861, -0.0029247822658425657,
  -0.002742732332663029, -0.0018352701105912139, -0.0017853866952590553,
  -0.004455849801716924, -0.0019722737760474334, -0.0029654175981924705,
  -0.0037017324970598227, -0.003876631609082518, -0.00485267340056178,
  -0.008243357778244887, -0.008583203144178191, -0.009972391809569206,
  -0.005523844930734153, -0.0026354715383176577, 0.0016627277316686923,
  0.0054276930001567305, 0.009037888458913424, 0.011142866102477462,
  0.014657209817525156, 0.009918326016039087, 0.01241126590530906,
  0.012340165804409576, 0.013302064846136233, 0.01521368589931059,
  0.015628104579203547, 0.009275410754753285, 0.008096159967953813,
  0.005188439407492088, -0.001054907957003712, -0.0032827094617205476,
  0.0022949417027794, -8.987432778722079e-5, -0.005552184508877004,
  -0.006869997386256072, -0.005149505277286859, -0.0046397364021506255,
  -0.0028515513473579626, -0.002727769821038726, -0.00264071158554465,
  -0.0030314990102344955, -0.006338539348072461, -0.0033180486736266133,
  -0.0024239703724944924, -0.00705156785662768, -0.007633900137049187,
  -0.009565144733617739, -0.012219586385005327, -0.012560755662560107,
  -0.01194121046382817, -0.007952012427533099, -0.012384759126542159,
  -0.012644546356469675, -0.013383745156979038, -0.011567325271692682,
  -0.01167849826096314, -0.012686879491226367, -0.016191861890973526,
  -0.014039409705097629, -0.019018184259805032, -0.01557950872023054,
  -0.01702532273961523, -0.019076157215638913, -0.01901352703982304,
  -0.02146224446442392, -0.021673622427559304, -0.025016731481550647,
  -0.025286403520029758, -0.027977511173429156, -0.031997519094929955,
  -0.028881294583542397, -0.02640805195086598, -0.02512455506825379,
  -0.025752753833281593, -0.026702894178769962, -0.025196547713769755,
  -0.02180306764231315, -0.0202648150651168, -0.017716018484027905,
  -0.012686473031985307, -0.013639484852144971, -0.014275021767108651,
  -0.014635818233347217, -0.010276543258204204, -0.008056778000700503,
  -0.007821055864654302, -0.007643225069092597, -0.0069237941642538445,
  -0.008286279426273304, -0.008626511927734427, -0.01019166480408902,
  -0.01093944667761376, -0.010126053166698254, -0.010289122842921711,
  -0.015997236500577487, -0.019805665194616708, -0.021441013680589108,
  -0.020685255888706025, -0.01996132514496596, -0.018749453914796137,
  -0.016906342922080916, -0.017754667790500944, -0.02109200194112888,
  -0.022604788472447037, -0.023380829018453606, -0.024030798136792843,
  -0.021126733993387794, -0.0198860449621534, -0.01911582981567546,
  -0.01950194786527686, -0.018636021689106195, -0.01782284637076839,
  -0.017380416127959517, -0.015357399240059041, -0.01747578453857478,
  -0.014698603445260408, -0.017562368210433263, -0.01532915049593861,
  -0.012079370694581296, -0.008636747232729464, -0.006704275686417863,
  -0.007396570109318935, -0.008770370941778809, -0.013367567005820296,
  -0.010675278519882607, -0.008406801762621296, -0.007556573460607053,
  -0.00907746825914191, -0.011519529346222626, -0.017084034760151315,
  -0.015953621840164037, -0.021447535219920683, -0.022107858237038754,
  -0.025411259484856542, -0.024165156722009636, -0.02236774988858743,
  -0.023507362136203344, -0.022904815784628663, -0.017465253475580256,
  -0.020332228124354215, -0.017726270962672805, -0.019982613320643626,
  -0.019119473333187125, -0.015422250801224882, -0.017723819552547326,
  -0.017570114287657312, -0.012909316366523753, -0.014063846960004267,
  -0.016432870805284782, -0.01518192376894917, -0.011276151687814394,
  -0.01549022153155863, -0.015942883133725435, -0.012666324049136432,
  -0.016816709662686842, -0.017198371338500627, -0.020171407107184892,
  -0.023191615914785763, -0.02327806293983295, -0.026621559566703493,
  -0.026888321795786024, -0.024940263439845005, -0.025587832597559684,
  -0.021097035017400078, -0.017715049628185254, -0.018397147471342832,
  -0.02218858582410835, -0.022330888739678426, -0.02210127788819767,
  -0.023319188844877842, -0.023110809275990934, -0.021568874190000743,
  -0.023379071257874307, -0.025112618774514966, -0.025592621184437336,
  -0.027428964021913336, -0.0257818412585863, -0.026699208849087434,
  -0.030948610984495088, -0.032721904214106035, -0.03214580697639102,
  -0.03060535779142186, -0.029631098582259947, -0.027099674529888918,
  -0.026029121838981262, -0.024718150601769913, -0.029664963266064062,
  -0.029011592036996, -0.02644522387709995, -0.02266994430849961,
  -0.023548420554042944, -0.02580313457883132, -0.02557179896216557,
  -0.023323502561895672, -0.023405485073291994, -0.022327141581915046,
  -0.021988610293532464, -0.021595981820846762, -0.025277533763499914,
  -0.02590758352375829, -0.024089393580468146, -0.02086171129177626,
  -0.019127730069029816, -0.018862213322201112, -0.019097273798906134,
  -0.019022520851902164, -0.019670506043441744, -0.016925028634468316,
  -0.019406237845522006, -0.016813415203941272, -0.015822349471374706,
  -0.015132500340832182, -0.013812184511246007, -0.014420662610429257,
  -0.011709282980638018, -0.013641463064072571, -0.01079018520718234,
  -0.013359223746813866, -0.013003258118307201, -0.012784661308449683,
  -0.015265598351522407, -0.015590529216014944, -0.011614727241379871,
  -0.012864315291867677, -0.010345154984314523, -0.014140111091044504,
  -0.010656682602152549, -0.013179590842834122, -0.014720956545926289,
  -0.012892025054486368, -0.011880315900731603, -0.011616783451921351,
  -0.013002272909643887, -0.016119087859266315, -0.017936441529861347,
  -0.02014093024441566, -0.02271547526695921, -0.020821215204427472,
  -0.022254294905179852, -0.028086050455632977, -0.027040006775739856,
  -0.024815138744497485, -0.02432697607595373, -0.023999441319750032,
  -0.02149165157547034, -0.021035888363364737, -0.019988948257187094,
  -0.01974703730659984, -0.016682443750288895, -0.016217848234089643,
  -0.018498755380581648, -0.019030796638764714, -0.01943696000337178,
  -0.01795542528394439, -0.017780868838587437, -0.017359427539279704,
  -0.017795639564443706, -0.0162110974862253, -0.01809345247326799,
  -0.02003054598531125, -0.01775354782592502, -0.017830126182146428,
  -0.020102909121382077, -0.018770241537307414, -0.01882890149124187,
  -0.017509963957803385, -0.01649112588727131, -0.018327787437729788,
  -0.0192022210481036, -0.019540623125764052, -0.016483834171543022,
  -0.02083494130965515, -0.02291524152306062, -0.02065369912267797,
  -0.019281959523043565, -0.020642270544433367, -0.021140177997790683,
  -0.021146572938921, -0.01919402174745686, -0.01774217752760696,
  -0.021762858278409437, -0.02038058710652513, -0.01891363593281228,
  -0.019040760542752062, -0.019328680573627133, -0.01841879241462908,
  -0.017743591833078217, -0.019568685293926188, -0.018310293548668827,
  -0.01939056161707406, -0.0187319272508345, -0.01700290744103143,
  -0.018415826881814354, -0.01697102797212876, -0.014699308558669999,
  -0.016033774447946757, -0.01936522757023537, -0.021321923897213456,
  -0.022644639090832513, -0.024029824609110385, -0.0235003823181164,
  -0.022828286713993763, -0.021905023320836447, -0.022766689416207627,
  -0.022918097558527067, -0.023649443538592604, -0.023835436366973384,
  -0.026680696936186733, -0.024806210747339512, -0.024314233920197385,
  -0.024867817729200027, -0.022811124462862913, -0.02306336069016209,
  -0.021937913262766007, -0.020325440297385408, -0.019430374402871736,
  -0.02100802543006427, -0.020936069167703242, -0.023646331644813452,
  -0.02372804443476814, -0.023571742268492974, -0.022098318364922518,
  -0.019726124764341067, -0.01897974189292946, -0.01819947933378727,
  -0.02016858272831077, -0.020141934640645412, -0.020798038364945617,
  -0.020486224752223187, -0.020016127085482456, -0.019022499280078778,
  -0.02060823783191694, -0.019641115105569068, -0.019061861863945605,
  -0.017207117080290393, -0.01752087079263127, -0.017748589231087325,
  -0.01939270597349961, -0.020860143940851446, -0.02216902183725539,
  -0.020487807082428165, -0.023764735412195547, -0.02349666285809487,
  -0.021555096421917302, -0.023787994015517566, -0.023392258169361812,
  -0.023098885040259243, -0.021526236049901788, -0.020607477496885667,
  -0.017873818181986672, -0.018398253262888926, -0.017772447440660127,
  -0.017617673514984153, -0.01871513960502795, -0.018479538658340018,
  -0.017490145160162375, -0.017745258384238533, -0.015275190562780244,
  -0.014099104011884208, -0.014026509935429091, -0.013886322215168508,
  -0.015391774794023714, -0.014914584656649311, -0.015419623776740528,
  -0.015439789387686696, -0.014542278570968984, -0.012989224665932539,
  -0.012759367067415539, -0.013563505550692093, -0.012553762725704867,
  -0.012420505105433913, -0.012809910345279351, -0.010638850974873932,
  -0.01110100928910498, -0.00976098168173256, -0.010385145142034872,
  -0.01145174748225565, -0.014439231248724127, -0.015874482087054425,
  -0.01589169265727684, -0.01869381390249442, -0.019234981444455894,
  -0.019820147778367308, -0.0197061244658646, -0.020226337631149036,
  -0.02046709550725301, -0.02083320675462072, -0.019903773721631084,
  -0.018695523255035096, -0.017196212028298234, -0.017652305385999798,
  -0.018212883294496877, -0.015107012478471037, -0.013532178014390992,
  -0.01362608303156382, -0.013271212499851647, -0.012727031777057475,
  -0.011092067950104404, -0.01068713254308287, -0.009302123810613672,
  -0.005595543190363984, -0.005765976455654573, -0.0048820755740297365,
  -0.004711066689351941, -0.0024987725887457017, -0.002539546784589535,
  -0.002134683753055729, -0.0009206043354026909, 0.00031689856781924207,
  0.0030889157632043645, 0.004184735953818241, 0.0033140032817713383,
  0.004035694563509885, 0.004707770014015178, 0.005272582369895151,
  0.006092421834720729, 0.005957003004110007, 0.007848893268436555,
  0.005683263540263893, 0.003605976853997054, 0.0048034576670852175,
  0.005063687729144342, 0.003483332904580572, 0.0022658272533142235,
  0.002682733771851387, 0.0039309721805238715, 0.005850331813124704,
  0.005937463690710896, 0.0066727289254114915, 0.007297506382921557,
  0.00634945831830947, 0.005682142040415598, 0.006287941202185395,
  0.007412443421081002, 0.008445419358026007, 0.008427567254928851,
  0.009487471481178539, 0.007385996389800506, 0.007660307590358586,
  0.006294151945337896, 0.0042662449937084495, 0.005739328986110327,
  0.006986322647517684, 0.008036512427355858, 0.007008598720017168,
  0.007719402999050352, 0.008434684301889223, 0.009741089703726903,
  0.00975784948344393, 0.010230942586416946, 0.008531712876434762,
  0.007891627724780697, 0.004306382862792918, 0.007205432241385989,
  0.006724819104445419, 0.008215672353808155, 0.007260289776698671,
  0.005332202532535731, 0.0065207322923313965, 0.00828964018308343,
  0.00724769533716886, 0.009028155876129876, 0.008848771596472904,
  0.010011064033712908, 0.010038332579090785, 0.0094477700454851,
  0.01043193660082929, 0.011285083288087507, 0.011356455678748453,
  0.013484586006132641, 0.01331755194758394, 0.01671760611607778,
  0.018191253931526548, 0.020783205269242457, 0.020820214087768275,
  0.017847187105304294, 0.016484654927210563, 0.017381623991393758,
  0.016480058894841266, 0.018888535923954678, 0.017411971012146175,
  0.01752316868932484, 0.014607630141099181, 0.01695214108448039,
  0.016534488232534648, 0.01731425526668398, 0.01786696696249268,
  0.017391418995719183, 0.016797000978133768, 0.016950269749355623,
  0.017590959492522065, 0.018051367899750058, 0.018860457716408457,
  0.019433814640370445, 0.020617916331681013, 0.020567287922599785,
  0.019577959398496422, 0.021039225273395107, 0.019801455253543276,
  0.02103157773312185, 0.02066522406992778, 0.019774532007165828,
  0.022025323242371025, 0.020991600950854384, 0.021514537788147527,
  0.021795318923986548, 0.022555533029037216, 0.02153644289509362,
  0.021172101433484476, 0.02197927144978696, 0.02276101082035324,
  0.022982190969077446, 0.02382385107622905, 0.025415433363270692,
  0.025361165723927853, 0.024373840155386902, 0.026490685923193216,
  0.02749048832546093, 0.02894004770858575, 0.027831000805598593,
  0.026201421932170635, 0.02335745366660139, 0.02211995186874389,
  0.02265632491430331, 0.023117899418135766, 0.024795169220747613,
  0.02537632130403927, 0.02557099033096449, 0.02917992766569559,
  0.029059916757851592, 0.02765729738655335, 0.026119443388744612,
  0.02486893568598342, 0.02597290409300516, 0.024589116491567628,
  0.023926929523440957, 0.022756744928813452, 0.022008234334247867,
  0.019815869149904516, 0.021235305651464496, 0.020993020635872977,
  0.021184538198366343, 0.021738997804251814, 0.020352369892749436,
  0.022584103188634685, 0.021341129361467516, 0.022724113637562632,
  0.023607495750138357, 0.025316131674813988, 0.02826227058817276,
  0.027224369647637397, 0.027597500224720895, 0.026664325035557403,
  0.02616183700312665, 0.02547320792498487, 0.023266767895603117,
  0.024923732465586733, 0.024933865827548134, 0.02516138492585747,
  0.02647082621244303, 0.028268102607664127, 0.02780443248973395,
  0.02745812325155402, 0.027999878310538916, 0.029694310317080086,
  0.02776165094596226, 0.026628121325893466, 0.025080373001828936,
  0.02532378445022036, 0.024771970104683714, 0.025496880699433136,
  0.025819789443139896, 0.02439314778379233, 0.02499464724793518,
  0.024217909939120506, 0.023736401044781776, 0.02221400023544021,
  0.021957950665327613, 0.020560948047817926, 0.021015178451313118,
  0.019866932390293555, 0.019098385884815123, 0.019080818463029593,
  0.01713320036609925, 0.013075131244651945, 0.013721599216680204,
  0.014864676906903757, 0.015202456977942706, 0.015246450287959826,
  0.014701667405746001, 0.013416634546585373, 0.01412178993530625,
  0.015187955602202258, 0.012956835238728488, 0.01588152723358025,
  0.01669632626938838, 0.014714727778971867, 0.01469281594401184,
  0.015216698246011963, 0.014902179120296006, 0.014533899889432928,
  0.01553172072278785, 0.015543694224664404, 0.015130425467977758,
  0.0141771069431756, 0.0154970581980784, 0.015034371356967523,
  0.014895193062310517, 0.015093746637956275, 0.013913140062579283,
  0.014014598746554795, 0.013917165227919514, 0.01179529340548253,
  0.013424934089949086, 0.012423045643293398, 0.012416837189613962,
  0.013006816136106585, 0.010431443746431039, 0.009897284002339413,
  0.009244614126056275, 0.009970751922194081, 0.009577497441969545,
  0.009478972575844855, 0.008666833574539425, 0.008179167893639238,
  0.007728495401207905, 0.007033487087453007, 0.006424723676224772,
  0.005728155755322907, 0.005417818004163141, 0.005817124577423173,
  0.00577103169285595, 0.006289505759818425, 0.007021567539245956,
  0.006285928774844061, 0.005885852043503951, 0.0054175196190341885,
  0.005610059672168294, 0.0051895207643444154, 0.006985637099816546,
  0.007146747873258052, 0.008428601620085583, 0.006780712084079375,
  0.008022548255504431, 0.0064684599876289085, 0.0065480415261322126,
  0.005885427074098494, 0.005454607788269598, 0.006751234698896376,
  0.0059513656658947076, 0.007009638193837592, 0.008410926157271317,
  0.008899985039195047, 0.008909214362928103, 0.008786239231208953,
  0.00870730850313612, 0.007488282041773003, 0.008841732028257037,
  0.007799642797413971, 0.006112852909526506, 0.007825430477047804,
  0.007913042647261791, 0.006997214060028954, 0.00752918204807343,
  0.008797751556160211, 0.007807610860110518, 0.008315067754778136,
  0.008764436118760252, 0.00919110138072256, 0.010185479579028319,
  0.011273225773864371, 0.011789228542527687, 0.010878976752786026,
  0.012080170545278036, 0.011139955648876466, 0.010912108407002605,
  0.011669608873872748, 0.012078117508330488, 0.010781308813226892,
  0.009589330807047032, 0.01048754787039467, 0.01003926321401608,
  0.00978109215748598, 0.010455659238538391, 0.010425987786505404,
  0.010931894699806944, 0.011918052374411426, 0.011117978523738536,
  0.010526482337986035, 0.010483648429069284, 0.009863045915191757,
  0.009906357133469493, 0.009954192183316944, 0.010952989373362693,
  0.010841241759611162, 0.010810139992578537, 0.011350214836474129,
  0.010921409561715945, 0.012298476700284834, 0.010906447230056869,
  0.010020794739848102, 0.010036657915205442, 0.010949064122321208,
  0.011373751478064218, 0.00996602326177795, 0.008761609878243358,
  0.010056963784820714, 0.011278408198840724, 0.011791959985701076,
  0.012302569346852888, 0.012384787915072928, 0.011923509097015608,
  0.012714793083729012, 0.012250775629041357, 0.01309918758054871,
  0.013778096476854788, 0.01109348787266709, 0.011145079219034728,
  0.01122651681699688, 0.012082411375670808, 0.01325513891534784,
  0.01262225825830562, 0.012065219555081167, 0.012544218681036511,
  0.010882908709428884, 0.01097731456334196, 0.012157596971338017,
  0.013292788866808543, 0.01376474745027533, 0.012678954371116236,
  0.012821606962017977, 0.01316932721325436, 0.012934118015155325,
  0.013040160799147632, 0.012484934428650663, 0.012895673263104883,
  0.012874102095709598, 0.012770760374958941, 0.01364779319106752,
  0.01347272606912731, 0.013993072862110628, 0.014302423767361256,
  0.013838476212388125, 0.01406723645944824, 0.014079956831946102,
  0.015948138541289607, 0.015676025030235824, 0.012859330128145184,
  0.011866753246156731, 0.010804977763790632, 0.010783365772073103,
  0.0116648159282874, 0.012083956199430675, 0.012276544480456834,
  0.013307917567168056, 0.01301987799665642, 0.011713218146350554,
  0.010236816403291073, 0.010806947104851129, 0.011620746077866714,
  0.011849855405986815, 0.011316562383018463, 0.010743169081462335,
  0.0101765386034256, 0.011273820104534139, 0.011453685571272645,
  0.012110838436540148, 0.01334051281118594, 0.013047023464452363,
  0.013064615104524988, 0.014211542622121601, 0.015456847325009121,
  0.016041704788013453, 0.017386263505333465, 0.017095482368248406,
  0.017216350779863575, 0.01630698939188961, 0.015486708478056726,
  0.015953712363481927, 0.01633511397490989, 0.015655526117601314,
  0.014182358018780674, 0.015336189052551139, 0.014894748093218614,
  0.013946438383770948, 0.013786274347285162, 0.012335357809574616,
  0.012638124701361967, 0.012246923191424149, 0.011209604644893045,
  0.010082848150757948, 0.011007918297245399, 0.012110921167795479,
  0.011933211608455192, 0.012616261346478022, 0.011917020209126517,
  0.01308331299307677, 0.014134191440842468, 0.013735124888722881,
  0.015341239504663986, 0.014409736720140075, 0.01394803080975788,
  0.016460665873045024, 0.015523410565950734, 0.0165368841129866,
  0.01597073329650095, 0.0163580952019573, 0.016726843591201888,
  0.01708847803450263, 0.017860032531997763, 0.01747162521406772,
  0.017635241306745816, 0.018005011590930875, 0.01932417833619883,
  0.018247072292454505, 0.01732167131928603, 0.017450147218037202,
  0.017454903724483092, 0.01718613497054151, 0.016120258263275646,
  0.01601631355233491, 0.015989970309031272, 0.015354226284458079,
  0.016116770221528326, 0.01719929767385699, 0.016826202044696086,
  0.017249151079354874, 0.015637791868880223, 0.0165863192138511,
  0.016848364991895484, 0.01683374632863935, 0.017213977811032517,
  0.017779291825028014, 0.01936809848817832, 0.01987311178771665,
  0.02017343610003853, 0.0211586479371978, 0.02236070170365447,
  0.023329544916201306, 0.024005241357018946, 0.025070160725057966,
  0.02622680203999698, 0.025579715015587087, 0.024983365736094026,
  0.02441860045664471, 0.02522673968615667, 0.024098489207855643,
  0.024556676925994805, 0.023646478606858083, 0.023755265828806545,
  0.023296515995492463, 0.023579146700935083, 0.0231018883365624,
  0.022625982275616678, 0.022513937020591197, 0.02169354396095515,
  0.02043853822765493, 0.020937464707343895, 0.020274871684094607,
  0.021442567321096062, 0.021098534370957257, 0.022045235264163594,
  0.02317665714674721, 0.02320623631531174, 0.02443646092896167,
  0.024524767779193008, 0.025077546808590558, 0.025192472849147548,
  0.02405475832694058, 0.02460375828755494, 0.024372636954775533,
  0.023036793222566795, 0.02233165076821455, 0.02216989296886147,
  0.023078075649065572, 0.022800396891344256, 0.023105870790036627,
  0.021534465201916465, 0.02387867136875485, 0.024027256396770436,
  0.023471393154033255, 0.022261640191964097, 0.022806219670578488,
  0.02255391390352698, 0.02311442304507582, 0.02335358617795321,
  0.02291120463972739, 0.023193629800718495, 0.02210773563703436,
  0.02159585243539063, 0.020288979235062257, 0.01945607410010232,
  0.02032804510467839, 0.019818692166113833, 0.019093716517606364,
  0.019511474490262685, 0.018908140190482797, 0.017869825101776655,
  0.015621016322653234, 0.01493328507966219, 0.012957591426041309,
  0.012575252908787372, 0.012816472572391996, 0.012276751712507944,
  0.013919596928970842, 0.015175831461230997, 0.015173434354919703,
  0.014533389306014282, 0.013885401471691495, 0.013431708752341918,
  0.013558573011409026, 0.01299429472127155, 0.01390842300254648,
  0.011933101248712252, 0.012187171868527585, 0.013342319903422134,
  0.012588600779975524, 0.012218867060787382, 0.011512908209260476,
  0.012341669731193076, 0.012919904330500541, 0.014034430172591482,
  0.01376954650151034, 0.014919175533744077, 0.014720536704629021,
  0.014613378594477132, 0.015278532709993674, 0.015345751187568184,
  0.013630001689909431, 0.014618526099334492, 0.013961741672452089,
  0.013267608629795256, 0.01554070750000376, 0.015274580922187178,
  0.014839288056339314, 0.015814345258577125, 0.015189144789557737,
  0.015425979625558174, 0.016830739282730642, 0.016254570186662487,
  0.01617039786228285, 0.015564674235071279, 0.0145387559931175,
  0.014588799443935499, 0.014084987182763783, 0.014227653198221434,
  0.014227649467516614, 0.014190266564791266, 0.01533461015521759,
  0.016449865653586943, 0.017368022187862868, 0.01614790154223578,
  0.01542327312736687, 0.014114915687426097, 0.01347221311010186,
  0.014517657015738383, 0.013812639731144953, 0.012316724013757828,
  0.011840135870800565, 0.012535654226444724, 0.012211031008643493,
  0.01196090646245964, 0.011769246757416411, 0.011860322874530234,
  0.010680682769268805, 0.01107902192905662, 0.010650844651250655,
  0.009958170932634059, 0.00986175021085326, 0.008677465059955446,
  0.009102924036009548, 0.009179260240387652, 0.007794019464131791,
  0.00744870669711966, 0.006327328234659236, 0.00699725767037492,
  0.00750499968585509, 0.007819963031939436, 0.007151728087025989,
  0.006747423199844031, 0.007143478010572697, 0.0077061245920752785,
  0.006718803765507525, 0.005265832922360408, 0.0041796157692522515,
  0.004560362205773359, 0.0036158508244980743, 0.002142094520322928,
  0.0011758551683334311, 0.0013996496314778429, 0.0007245144812339646,
  0.0002430757053754448, 0.001175686578103145, 0.0010320205512616225,
  0.001923262085617035, 0.002070112210570474, 0.0020975416671720902,
  0.0017938618189246457, 0.0015633323265984076, 0.0022776405054767005,
  0.002562036231672279, 0.0014965210895101454, 0.00041315941366865393,
  0.0015846007812248025, 0.002728231834080187, 0.003003928945132336,
  0.003450328045296987, 0.004083770644641155, 0.003651129633601108,
  0.004403722075019896, 0.004757991085315038, 0.0049171407346357806,
  0.003749016590126379, 0.004506397696738016, 0.004497949925294633,
  0.004694613862988726, 0.005141415274209851, 0.0038400225754482193,
  0.004645623857453366, 0.004771369418109881, 0.004346542516002456,
  0.004684218325306378, 0.005310568344436829, 0.005096868516533387,
  0.005334507016502975, 0.0040791895333690655, 0.0031031846383311836,
  0.004169841336120614, 0.005449067845093552, 0.006158664925116883,
  0.00586299737057937, 0.005328770810306734, 0.004910623331214568,
  0.0061369974584326486, 0.006643869859443512, 0.006118233558938069,
  0.006945490133873164, 0.0070300898529960885, 0.007930637593300708,
  0.008040964143703203, 0.007823171505086051, 0.00843066640329692,
  0.008677612953787966, 0.008659598183639514, 0.008300636209587613,
  0.009927881109084727, 0.009173908009679627, 0.009150483241997956,
  0.010275863432617313, 0.011575277142838231, 0.012059659149068569,
  0.01081192135350814, 0.012285616630755102, 0.011891836641615411,
  0.01226515267875247, 0.013023915640552112, 0.013068791405701437,
  0.013631826404734797, 0.013133064486531423, 0.014088562318751545,
  0.014749629793561746, 0.014290795012746932, 0.01327610105453292,
  0.013528104885424084, 0.01280085871644105, 0.013245085759874282,
  0.014128222089506546, 0.012897301624247385, 0.013485072964675784,
  0.012923407151845945, 0.013625525535378982, 0.013820438560598386,
  0.012952600475022072, 0.012196630130997466, 0.011897560351330425,
  0.012782319175490036, 0.013157146642344035, 0.013757361247932658,
  0.013949178528030787, 0.014583463312033779, 0.014594539196968858,
  0.014442126376091375, 0.013907621786709033, 0.015264526065527521,
  0.014958485910643244, 0.015944476388473214, 0.015483637456739642,
  0.016031932128393992, 0.01548912370067777, 0.016130941556402288,
  0.016535355909428767, 0.015686614004217954, 0.01592713272163863,
  0.016277452476710603, 0.01576832200168206, 0.014639004769632924,
  0.01521136054910287, 0.015795943275445784, 0.014853361406344098,
  0.015889816220485315, 0.01562633856513695, 0.014720032277575073,
  0.015233461936121902, 0.01508474486140788, 0.014357043042053227,
  0.014589499816922738, 0.013361623779117218, 0.013465468857336864,
  0.012971718419460723, 0.013286871265725949, 0.012854147267687422,
  0.012979011690201218, 0.01288244011430016, 0.01332449182039642,
  0.014045289837729088, 0.014298185503847208, 0.01479772927566222,
  0.015526873666739008, 0.015389572011336308, 0.016027736374391047,
  0.01579136957536225, 0.015664638167221332, 0.014759738053376617,
  0.01393190199715317, 0.014208813836480316, 0.013645062921750222,
  0.01508112809222643, 0.014878562339210661, 0.015849841322123085,
  0.0158541108723119, 0.01626680414882908, 0.015188582679072188,
  0.014257438623196762, 0.013663791949573106, 0.01308658846413171,
  0.01164274066401847, 0.011847045572509781, 0.012166283262459285,
  0.011305379139725454, 0.01141915499271288, 0.011672917381407697,
  0.01140950659102653, 0.01071770099307115, 0.011120898350234757,
  0.010511736770213144, 0.011180640038847051, 0.0112456393278049,
  0.010298815569091142, 0.010294724873084193, 0.009236118227560205,
  0.00867580794174671, 0.009527299784085484, 0.00994044745186201,
  0.008951933821821084, 0.008925916928762306, 0.009753158165667616,
  0.009736361673092152, 0.00950113292379056, 0.009643409536460784,
  0.01013657731318127, 0.009576059245135386, 0.009951334274458018,
  0.009591367566351762, 0.00928934221431862, 0.00856483248492816,
  0.008052598121224951, 0.0059872594211873185, 0.005638571619246242,
  0.00616994785029982, 0.0059363447905858915, 0.00528144288205426,
  0.005317399916325321, 0.005054077851693472, 0.005263488507383701,
  0.00577748642694589, 0.0059603376188014125, 0.006006440199206162,
  0.00711684579407142, 0.006917495510966758, 0.008024133792893937,
  0.00693290883034714, 0.0068310109865201, 0.006380715646269621,
  0.006814185124531785, 0.007400717186454781, 0.007470306806250535,
  0.006780221482890939, 0.007348863599101124, 0.007172714308529374,
  0.008484105961897477, 0.00969829308991772, 0.008763956772761514,
  0.008394672805312468, 0.008453404692892026, 0.007980605637195035,
  0.007645367426002384, 0.006719874900430995, 0.0076007459275796874,
  0.00812000416561461, 0.00810475821096247, 0.008815055357876649,
  0.008774674440291058, 0.009034121511243451, 0.008505004676727138,
  0.009219201824200388, 0.008503761782118861, 0.008014655033734679,
  0.00879186910280515, 0.008573272913622337, 0.00855449771481319,
  0.008061020976048211, 0.008768991510613222, 0.008283867695297027,
  0.008260081584993927, 0.007551789939426585, 0.007245341981326987,
  0.007131656563772475, 0.007402150726190969, 0.007317440236689169,
  0.00765181809617337, 0.006678781783336123, 0.006302687345226162,
  0.005177649694092099, 0.005278997261669118, 0.005436365326003823,
  0.00558481924689918, 0.0053096933471586056, 0.005519916366196416,
  0.0059849030447695125, 0.006833012265819322, 0.006121620631146417,
  0.005973698850546048, 0.006952794873297282, 0.006150682397833299,
  0.006096706882019615, 0.006690177422339871, 0.005924805994603014,
  0.005624154055561921, 0.0065822255473742385, 0.006216453646942843,
  0.007018550427534325, 0.008092781325409401, 0.007695212411758165,
  0.008671730974897077, 0.009298278290595591, 0.009072493028128752,
  0.010556821377471678, 0.010379111676485642, 0.011935518989506694,
  0.01166069353245852, 0.01120133946757701, 0.011632970742781792,
  0.011141541720389055, 0.011207843742461237, 0.011440803698178167,
  0.010335898335457009, 0.009262538372703062, 0.009784564940357988,
  0.009869574272116818, 0.009978156931190016, 0.00950736060747769,
  0.008974342605793386, 0.009640426204903468, 0.009752683397894925,
  0.010994439363342797, 0.010658416445993921, 0.010674645104631928,
  0.011017556813345722, 0.01251468390972823, 0.012265658838479965,
  0.011773470002504909, 0.011916032297131698, 0.013058613274174726,
  0.013153669690965946, 0.01338217076085225, 0.012204062465354196,
  0.01237895409967365, 0.012053610093872066, 0.013319892745268904,
  0.012949724921661177, 0.012698636645664292, 0.013133222921780641,
  0.012609101476475143, 0.01275731119287481, 0.012731512628808431,
  0.012667457306803973, 0.01208404434221819, 0.012269167403309845,
  0.012236316204787259, 0.013030491043642001, 0.013797715275589985,
  0.015031719440320255, 0.014976635325469162, 0.014016925025182475,
  0.013921521861797294, 0.014083959084215733, 0.013967255976457205,
  0.014698298904286458, 0.014449655480434758, 0.014797793365543908,
  0.01551083593466057, 0.01658344192298315, 0.01572401282873455,
  0.01698984520395467, 0.016084277735550486, 0.014961004192523131,
  0.01528541424737465, 0.015745340385999645, 0.016197768871421696,
  0.016329903197028543, 0.01599252725173759, 0.017045851287149222,
  0.017486389349043834, 0.01874007247349982, 0.018798524382881,
  0.019494621520129273, 0.01935063433582919, 0.01906556078649071,
  0.01942840364660721, 0.019569666979407423, 0.01972863338924126,
  0.01995130483479255, 0.021069729847482472, 0.02139263228370796,
  0.02113226266438092, 0.02117556995792907, 0.021130343689882966,
  0.020910482978837144, 0.020941235476899932, 0.021266898357517015,
  0.021022106599372124, 0.020096097555348653, 0.02075668915232826,
  0.021573373443075774, 0.021863776138997225, 0.020605844371451996,
  0.01963329338350937, 0.020734854977067556, 0.02000161569207072,
  0.020713263558074017, 0.02089369033301936, 0.02076168519080178,
  0.019826473569733615, 0.01912499732466447, 0.017764019508148418,
  0.017171328530825692, 0.01710971666429092, 0.01688480553476917,
  0.01687414929968712, 0.015487939451719923, 0.014786399085145306,
  0.014805309377783332, 0.014986213762053778, 0.015259539327404164,
  0.015132571991915724, 0.014891423637084908, 0.015226718121660834,
  0.015380678194247443, 0.015787733093491033, 0.015300589335097921,
  0.014277019846830193, 0.014074767263567173, 0.012748772700566204,
  0.012870537713209326, 0.012467106858262026, 0.012571005536985692,
  0.013674474329896732, 0.012855929836937091, 0.012365696005132074,
  0.013211644476207799, 0.012380110993343815, 0.012636415434451152,
  0.013078119354774325, 0.014122774908843293, 0.013674920309845748,
  0.013879012652464414, 0.013880492223917311, 0.012974864972521108,
  0.012605119438208827, 0.010803845015158409, 0.010267668110519382,
  0.010362175194748321, 0.009685696876882357, 0.010272359086631688,
  0.010374466076594714, 0.00973492521079419, 0.009796171437442023,
  0.009205602987507167, 0.009811188783587532, 0.009759620571162912,
  0.010151155684660882, 0.009944721374992678, 0.010254600960380252,
  0.010518971148917606, 0.010901930263706219, 0.010551250841192725,
  0.009991101935675432, 0.009898592487302557, 0.01082112600289193,
  0.01008621899657559, 0.010572428733067137, 0.011422949894190829,
  0.011563844083384244, 0.011396707768506443, 0.011393821574888423,
  0.01059505758049597, 0.011237238653311533, 0.011654734465344886,
  0.012127893235005028, 0.011915122226221158, 0.011962733674329833,
  0.012281373677781305, 0.012128958788185533, 0.012585674092134763,
  0.012255467246531962, 0.01243659590708609, 0.011868869255071886,
  0.012185738595289082, 0.011422143032123917, 0.011443258704258219,
  0.011423795634070517, 0.011126903297466306, 0.011468674453489935,
  0.010603128383556355, 0.009807609440501961, 0.009652899925471692,
  0.010325088642711363, 0.01010260621563147, 0.01035002131610561,
  0.01019471644542654, 0.010413898979590407, 0.010300749732999942,
  0.010789902523692588, 0.010386080731263273, 0.009851174133522602,
  0.011262863086318764, 0.011186200073856166, 0.012101063284988826,
  0.012844201316164622, 0.0117246494719302, 0.011308522114197219,
  0.010878674630333986, 0.010774947708484523, 0.009704802257900323,
  0.010243229871990422, 0.01040482640382872, 0.01092484331014507,
  0.010452994889934671, 0.00945218742816108, 0.009564203201310508,
  0.009997718605484863, 0.010331875262195365, 0.0095409670257177,
  0.009561616408374747, 0.009471399871879425, 0.010457936066598277,
  0.011082143096863518, 0.010975671406364273, 0.011069629222151073,
  0.011794072338544138, 0.012183941715995492, 0.011652390030415915,
  0.011569241805089642, 0.011588878950734004, 0.011620573055927026,
  0.01184724528137306, 0.011176757106809864, 0.011438403533105388,
  0.010085354403651554, 0.009983034116793962, 0.009617320760420085,
  0.009964652738626616, 0.010701171847830879, 0.00980878355055223,
  0.009319271127549243, 0.009698560469895465, 0.009471789442956754,
  0.008450905402792336, 0.009052910576975067, 0.009665700983332784,
  0.010585654709461398, 0.01023881730040527, 0.010536857878418553,
  0.00996949812003432, 0.009593497527257584, 0.008790392399193,
  0.00894861856108997, 0.01040547386178669, 0.011400281665613379,
  0.01106076374142065, 0.01065196781843338, 0.011211681310592695,
  0.010917079755117912, 0.011014545123014783, 0.012139975619339896,
  0.012289840605379961, 0.011765569000956476, 0.01265665227631719,
  0.01244473528486542, 0.011515281918159898, 0.01223114145832392,
  0.0119566136431528, 0.012401678108322562, 0.011774218164543557,
  0.011923173701359596, 0.011051019044474626, 0.011118968118694028,
  0.010596901228728967, 0.010659759400528303, 0.010053846399623801,
  0.009339912304705785, 0.009195020931152032, 0.008792387221056358,
  0.009194593507447162, 0.008926920439840565, 0.008546164206549278,
  0.009038470844085466, 0.009277268169637977, 0.010077244042163362,
  0.010264798727712967, 0.010404004246173926, 0.01012377306537252,
  0.009634317146788054, 0.009025941185820252, 0.008859366497928943,
  0.00795520349440002, 0.008506901930152173, 0.008532348927802202,
  0.008609894280974613, 0.009530859364649138, 0.010331831056547855,
  0.009240172566213586, 0.010217559970641504, 0.010467406381223458,
  0.010313176260650954, 0.009530929237423902, 0.010498943516415013,
  0.010273674872324818, 0.009298523000428662, 0.009726647136083613,
  0.009646299730797711, 0.00928221727730273, 0.010282910765487806,
  0.009441967784190396, 0.01036999749343952, 0.011189095840263947,
  0.009879279658123675, 0.01078071224822043, 0.011085570602530417,
  0.011801279189727376, 0.011335625850872073, 0.0110660879698074,
  0.010779103761008199, 0.010918818626834902, 0.011299654952446989,
  0.010834353639097993, 0.010359575171386323, 0.010123153806740138,
  0.009837086573805191, 0.009847932327401604, 0.010071869946296512,
  0.010394869219430954, 0.01090453385377666, 0.010371967590848748,
  0.009513017741563356, 0.009552622514129805, 0.009566365909501483,
  0.008988531364649544, 0.008638508363429904, 0.00823749593965548,
  0.008359525500875415, 0.00797106652959724, 0.007526250493933273,
  0.006776929303404504, 0.005628417488540366, 0.0057590568589243486,
  0.005475521162052043, 0.00548476181883486, 0.006174403099408204,
  0.006003911650520835, 0.005826707221689962, 0.005802935453678,
  0.004788572642136131, 0.004939415061095296, 0.0045584800473498005,
  0.004325314079217464, 0.004433207214267374, 0.004666082639480607,
  0.004125813356815495, 0.004037474790012084, 0.004155206363287159,
  0.004350689677491871, 0.004701934823893752, 0.004421594454076075,
  0.004542132484143625, 0.0037780072976637386, 0.0036624218141394133,
  0.002914136821416173, 0.002820953824495791, 0.0024101664691916504,
  0.0026985596006992266, 0.002855272175825939, 0.0031393362803335692,
  0.0030612150650459013, 0.0025966352126182528, 0.0018563564421027305,
  0.0014451101435905295, 0.001399980256504341, 0.0018278185151077277,
  0.0016132418520472547, 0.001733440283593254, 0.00239813915271976,
  0.002416552348147851, 0.002653046154371677, 0.002223942556744023,
  0.0014126410542700456, 0.0010906711251296357, 0.0010460175248494098,
  0.0007898855665565926, 0.001080653047994799, 0.0004495146331234895,
  0.000738887903347961, 0.0008475539415635805, 0.00125792241101625,
  0.00040018812450946106, 0.000642074047711754, 0.00048405028902975356,
  0.00027238981491192445, -0.0001564318963792902, -0.0001252032740445088,
  -0.0009653917154969611, -0.0016826717039835671, -0.0022233804568350967,
  -0.002798736201802328, -0.002197320273144058, -0.0023551618940220913,
  -0.0032194560570922136, -0.0032004047936799638, -0.0034393601758282892,
  -0.0036453417404089788, -0.0044158458453517485, -0.004693481441108637,
  -0.005006917308049185, -0.005264181041280248, -0.004898469434349875,
  -0.00374005526378044, -0.004653932558237781, -0.004283152359265191,
  -0.004281249342635506, -0.0037079641862224843, -0.004067324991440284,
  -0.0035498162881181417, -0.0033882942749384943, -0.0034850501686978673,
  -0.0031811784265181877, -0.0030304074032080296, -0.0023962848723610526,
  -0.0014562761190403672, -0.000903664114184617, -0.0004232010583708383,
  0.00032117462716692654, 0.00016388668878159046, 6.305720883508488e-5,
  -0.00029329400219974867, -0.0005255116034042947, 0.00015109869136464705,
  -0.0007693899010058605, -0.00046987695583297737, -0.0001994994213309546,
  -0.001285777472256492, -0.0012606168598070808, -0.0014704714824023755,
  -0.0011967892163872761, -0.0010611679217143728, -0.0009163658673752573,
  -0.0009259598448785278, -0.0017812544137041008, -0.0028518071417776864,
  -0.0031021902526289944, -0.0034465880174379907, -0.0035070575040525177,
  -0.0031749610545605453, -0.003314406088580792, -0.003174171721769281,
  -0.0029995899129222365, -0.0027113907883089417, -0.0029999020784019456,
  -0.0026737873903821476, -0.0028327903378041045, -0.0026847145094759306,
  -0.0027881182838656302, -0.0024500566205288427, -0.003714148266310823,
  -0.0037410370991039815, -0.003036856316657028, -0.003297393307421251,
  -0.0033568842542513956, -0.00350272223319557, -0.0036809260060681314,
  -0.003964435223797513, -0.00422241577766167, -0.0038439639379434297,
  -0.0037063451253612906, -0.002944290163348566, -0.0028394607646492517,
  -0.0024679555536307697, -0.0019115349959924323, -0.0013163915994078837,
  -0.0009411346410396614, -0.0010110437699523982, -0.001702021985492901,
  -0.001351012241066923, -0.002606614026879941, -0.0033079697765719097,
  -0.0031319536522802244, -0.0031991782855447983, -0.003532674204402407,
  -0.0036040522511177173, -0.0044662565284643, -0.0043280154798268035,
  -0.004187830138062998, -0.0049977318630389286, -0.005551620733453932,
  -0.005323648277515075, -0.005830086372274479, -0.006285965995048017,
  -0.006930940785792498, -0.006181423477124828, -0.006164898400397519,
  -0.006199426644293922, -0.007109916150608217, -0.006747059245415944,
  -0.005838361195013633, -0.005906734424003366, -0.006072065189745453,
  -0.006421022156206376, -0.00566791094594329, -0.005189846176521623,
  -0.005329601057435118, -0.0058118856386659735, -0.0063119019389712375,
  -0.006378903357688887, -0.006532463165129514, -0.006263527920270529,
  -0.007317271437461858, -0.0069621282185127606, -0.0073606682546835745,
  -0.007045446089943571, -0.006284296839249599, -0.007053464712859211,
  -0.006336032287551491, -0.006097071394523204, -0.005590336304044674,
  -0.0050488358938527485, -0.005177884266644695, -0.005740889788024425,
  -0.005304160843586948, -0.006028562261389922, -0.005914300809371703,
  -0.006427745526980412, -0.006118269493548256, -0.005323105099130619,
  -0.004990176140365784, -0.004065390146335318, -0.003994414572732716,
  -0.003035160546960592, -0.002720580387515509, -0.0024332366342797347,
  -0.002845731848929849, -0.002685087790822504, -0.0033471756860712345,
  -0.003779607131270091, -0.0033207824525066996, -0.0030093833407279207,
  -0.003169797939168619, -0.0024793234952861978, -0.002044056651094365,
  -0.001999707915873676, -0.0021890228655795222, -0.0024659441476632665,
  -0.0024688666869366106, -0.003077180762278328, -0.0031805609813082784,
  -0.0032479009599786844, -0.0025674388421097595, -0.0028609413538122023,
  -0.0034161389372496464, -0.00342967040256571, -0.003696999571613987,
  -0.0039293653842982544, -0.0043047839611965715, -0.003960406900808854,
  -0.0037446693845645715, -0.0037338459774177864, -0.004238192149411225,
  -0.003647401318859485, -0.0031448112820846915, -0.0027705960769254198,
  -0.002259176497449976, -0.002649578732350014, -0.002325062509085292,
  -0.002193911774732759, -0.0020454046487743864, -0.003384575221675715,
  -0.0036027569946135095, -0.0037463355278165127, -0.0033193752119556317,
  -0.0027501256216373613, -0.0022130427149615246, -0.0018017293574605331,
  -0.002339390595176286, -0.0022106241704944315, -0.001443334940555461,
  -0.0017794626672505815, -0.0014509040776369676, -0.0016374724307728359,
  -0.0018509046533097444, -0.0016921359188462643, -0.0015451523444578719,
  -0.002288440150855827, -0.0022004656018140027, -0.0025540643839196795,
  -0.0023977055433984946, -0.0019161252520600385, -0.0025811700062667873,
  -0.002732189496800636, -0.0020306910965145813, -0.0012817604241420369,
  -0.0009077665166743899, -0.0012573352361254962, -0.0016860484079444476,
  -0.0025127988102837053, -0.003296204305788483, -0.0022273237915479685,
  -0.001659873297279502, -0.0016647534385119335, -0.0017853871750987125,
  -0.002263758665042942, -0.0023791879423125293, -0.001979667823007366,
  -0.0012897520343831364, -0.0006287254405397331, -0.0005079131970063796,
  -0.0001813470018967434, 0.000415057682832272, 0.0001786943966929919,
  0.0003173740994343897, -1.245451220089726e-5, -0.00029020671842973285,
  -2.2666043994154727e-5, 0.0003171684810486598, 0.0006606351748806718,
  -0.0002887634858012144, -0.0009625793938564151, -0.0014815444912994761,
  -0.0016259377293946294, -0.000963090535576576, -0.0012862351641515822,
  -0.002033608996280271, -0.001759885869862621, -0.0021481641545054654,
  -0.0023950795184242346, -0.002261289042549722, -0.0026776784320171323,
  -0.002920128531519443, -0.002876523140863825, -0.002948696556113365,
  -0.002933961174790918, -0.003033614734728065, -0.0032452055635225976,
  -0.003084921603547056, -0.003722318862740481, -0.0034802593991266765,
  -0.003693703947213983, -0.0036155559094898, -0.0036837464982206504,
  -0.0037675562967157197, -0.0044786328836561844, -0.004264671134757732,
  -0.004391044094275033, -0.004363228489422237, -0.004170227659565908,
  -0.003953439842458505, -0.0034275660424616513, -0.0036854983854785407,
  -0.003954184385935865, -0.0038186241787054624, -0.0046175860710166395,
  -0.004616393922581111, -0.004291465547017593, -0.0042195194065761735,
  -0.003753610862642884, -0.003882689527213017, -0.004532786066846674,
  -0.004427623207927791, -0.004536159448023779, -0.00445701729811778,
  -0.004090336306279703, -0.004728481339967419, -0.005119673114562467,
  -0.005339172479733207, -0.0051995539643575886, -0.005537196400236427,
  -0.0053974089826063715, -0.004895147403090163, -0.004744367184774905,
  -0.004126612041188567, -0.0043574156036316185, -0.004150367494006169,
  -0.004532237145404133, -0.00485786188170048, -0.005624946640111227,
  -0.005328417633233362, -0.005527894091429742, -0.005916776771247744,
  -0.005829938689626836, -0.006611547976775178, -0.0070504787260974875,
  -0.007125986503113804, -0.006860988309817354, -0.0065220742431427205,
  -0.0066704543983215825, -0.006347299094534926, -0.006663269185372272,
  -0.006704319214757705, -0.006880802750457574, -0.006780082566249599,
  -0.007119452612650607, -0.007656668625421875, -0.007176825601598104,
  -0.006928442621452364, -0.006757873420403932, -0.006580959443929764,
  -0.005758361316948368, -0.005907274233156283, -0.0059064664313018276,
  -0.006363526469054101, -0.00643948875655847, -0.007176692215787842,
  -0.007282605946370898, -0.007282114354376734, -0.007340508773040359,
  -0.007447313220280621, -0.006916035382518482, -0.006402285009606983,
  -0.005700679260987488, -0.005671909820264018, -0.007184651916496687,
  -0.008345697418605361, -0.008196881939169209, -0.007865853505430346,
  -0.007425985394610883, -0.007469512541812083, -0.0073957230487309775,
  -0.007612815750878317, -0.00664527473531399, -0.007063995488443285,
  -0.006784929541285845, -0.007016851830957577, -0.006604886150154537,
  -0.006662915221082111, -0.0069569837364707, -0.006982095551304454,
  -0.006607134039810578, -0.0069582868455774875, -0.007830467512055085,
  -0.0076894201315442015, -0.006462104644524769, -0.006756114419855056,
  -0.006627135261669519, -0.007166685569605291, -0.00729751570763729,
  -0.007634196088312906, -0.008243114543139583, -0.008157734100950657,
  -0.008023820597189538, -0.007823623673284742, -0.007567645369333597,
  -0.0074177986829926015, -0.007121476883423939, -0.007534647560678668,
  -0.0077055619039968675, -0.00736294358501227, -0.007773856176893074,
  -0.008475274249638115, -0.008946215549736295, -0.009015645301556225,
  -0.00889749619982119, -0.008847730616391056, -0.009334677342984989,
  -0.008867007144504717, -0.008095326348133558, -0.008357567409397377,
  -0.008140257206514361, -0.00858065313257256, -0.008652915450491289,
  -0.008791065123176168, -0.008555846633767406, -0.00867766672758508,
  -0.008908991780795551, -0.008968245907865992, -0.00853638468132678,
  -0.00835958524994514, -0.008784908157875575, -0.008838679241258425,
  -0.00897023378421285, -0.00960617482316607, -0.009982625082354294,
  -0.010506493885380523, -0.010177274870398484, -0.01010215849070694,
  -0.010205425732125396, -0.010643166291867786, -0.010687454262924893,
  -0.010008899693580481, -0.009414519233196066, -0.009420420015442061,
  -0.00874407056607671, -0.009432705364649002, -0.00946007732576272,
  -0.009863336587397045, -0.009976557967267074, -0.009595415240970904,
  -0.009500135452618715, -0.009461953962170146, -0.00979001960624474,
  -0.009767829383376702, -0.009869324513548315, -0.009831067587251087,
  -0.010202673688106557, -0.009749044341706255, -0.009679766656844965,
  -0.009410919680741708, -0.008782568876614518, -0.009468750644645876,
  -0.009569646658533531, -0.009395948349375615, -0.009318070205685536,
  -0.008706800396672579, -0.00794493373198999, -0.007662273371563185,
  -0.0075142196047126115, -0.0070777192295981145, -0.007725627327115965,
  -0.007710056889345885, -0.008019601786166284, -0.007978865159517505,
  -0.008736781077119598, -0.008060234798600869, -0.00826982054753033,
  -0.008478638315270005, -0.007879940427513847, -0.007871607035701897,
  -0.007620059273477683, -0.008097679869727896, -0.0074051949793286845,
  -0.007884648866206132, -0.007966693090101893, -0.007993919176474623,
  -0.00849868287196989, -0.008207758009103283, -0.007845509407872398,
  -0.00823443797150998, -0.008471560459286398, -0.008684489542989967,
  -0.009064279220062702, -0.009309103969382114, -0.009154639684878048,
  -0.009370098787164535, -0.009468169671763983, -0.009896037462533579,
  -0.010019390083973135, -0.010445810783233509, -0.00959500948640708,
  -0.009496917891361879, -0.0096720023133453, -0.009310492931442491,
  -0.009880092271748447, -0.009124937259948089, -0.00935863659251712,
  -0.009636418614085675, -0.009263275641622427, -0.009126099326921936,
  -0.00896950285492903, -0.009044876476063482, -0.008825084677749326,
  -0.008839322158345906, -0.008257479292357322, -0.008546108891473277,
  -0.008274375321487972, -0.008483106717957501, -0.008200336367381777,
  -0.008494855743981903, -0.008226820397349377, -0.00801817461490992,
  -0.007014738011299513, -0.007466785527775945, -0.007826114785547358,
  -0.007192131551635337, -0.006669950093379707, -0.006651353437300825,
  -0.00710685294965087, -0.007879397833453664, -0.007754378625539208,
  -0.006787168455334738, -0.0062590703044229385, -0.006128211817356849,
  -0.006613367203074977, -0.006186616728063378, -0.006969752761371413,
  -0.007067875635249077, -0.007109211133772727, -0.007233662074275336,
  -0.0070852706621002725, -0.006842675250461327, -0.00689344807339918,
  -0.006828163742323869, -0.006895087024105738, -0.006921235808844895,
  -0.006968275911270694, -0.007636786575369022, -0.007236783705376346,
  -0.007120859460533228, -0.007282683102083506, -0.007403545715865502,
  -0.007891857506010128, -0.008130821399166308, -0.008282158856655375,
  -0.008832208073182463, -0.009229349303053472, -0.009313812709694079,
  -0.00875786749423035, -0.009312372036290676, -0.009130140616278896,
  -0.009237273276674484, -0.009007300885301288, -0.009074440193026046,
  -0.00912729660176938, -0.009331919641222416, -0.0093362404519023,
  -0.008643959071464513, -0.009799977427885225, -0.009789665235256668,
  -0.009371010023428515, -0.00938269560516724, -0.009827781715428432,
  -0.009579475789049437, -0.009365016857352236, -0.010132611183404408,
  -0.010408115317083003, -0.010714844216800983, -0.010937493087120505,
  -0.010634933091725208, -0.011784327457999668, -0.011762632378910851,
  -0.012075319991619846, -0.011748274885342141, -0.011735545378666818,
  -0.011722123367075506, -0.011534671018671961, -0.011590205701612798,
  -0.012167222569328368, -0.012405282766487476, -0.011679229669092087,
  -0.011214471411868768, -0.01157900023795154, -0.01178256649118239,
  -0.011884947345518693, -0.01199167972706618, -0.011782615328491784,
  -0.01137575236213956, -0.01106028855688937, -0.011414368222339157,
  -0.010920385245330419, -0.010955601674490592, -0.01081750239116213,
  -0.011026072982279317, -0.0107594880458051, -0.010690717658058641,
  -0.009819118462063998, -0.010070319086920899, -0.01000854122324458,
  -0.009946354590068046, -0.00948449610119816, -0.010200086367502458,
  -0.01103054773434765, -0.011119111021520084, -0.011553859666989257,
  -0.011582410532639737, -0.011096913773837435, -0.01086956001840489,
  -0.011338087717556507, -0.011648896421627471, -0.010965087854261545,
  -0.011363711906999072, -0.011371973370598854, -0.01179529478746255,
  -0.011686504216456447, -0.012037119121195745, -0.011732997316831587,
  -0.011281520495409394, -0.011278655721513469, -0.01166576525652039,
  -0.012331330778412246, -0.01208127163475285, -0.012048822577392074,
  -0.012187412896294034, -0.01228611736833291, -0.012758000109422704,
  -0.012794446964782784, -0.012942949591907973, -0.013544129248478962,
  -0.0135321838482443, -0.014431347373379954, -0.014726629731362389,
  -0.014947894331669612, -0.015288655747996042, -0.01563215253122959,
  -0.01542476546125439, -0.015020358693508273, -0.01513916427201955,
  -0.01394178534096987, -0.014116776986763576, -0.014408075308239135,
  -0.014917034196595005, -0.01518458517597568, -0.015469829909896033,
  -0.01552061634349892, -0.016027607846820863, -0.016229240063113697,
  -0.016867126639021623, -0.017282364180852872, -0.017134186220477077,
  -0.016970874962197065, -0.016816016438411872, -0.016678961626319183,
  -0.017045998647845693, -0.017702979845811685, -0.01867053184310246,
  -0.018600723930875536, -0.018742538791442782, -0.018653574229133715,
  -0.01848138309421253, -0.018083300881482304, -0.017665593800948363,
  -0.01812536703002187, -0.018189877352450912, -0.01865000111267543,
  -0.018000015767566758, -0.018480844085538333, -0.018510816625163205,
  -0.018480989127380755, -0.01831642177602455, -0.018285937610209347,
  -0.018719384224758318, -0.018025941556789986, -0.018630288116686496,
  -0.01873611998123093, -0.018670017031736885, -0.019154020962210038,
  -0.01924476163050306, -0.019424348423817593, -0.019575556976730475,
  -0.01948056900565325, -0.019450852701190156, -0.01957460317735766,
  -0.019471231176767596, -0.02015565085383639, -0.02006552869488603,
  -0.020100282026601437, -0.020148707215218935, -0.020183130219334957,
  -0.01976436116618012, -0.020457389920093067, -0.020559199666201004,
  -0.019863858208028485, -0.01986826908839217, -0.020014545134342858,
  -0.019862357798769905, -0.020182460837354123, -0.02018642006071012,
  -0.01970093763178063, -0.01994289693630801, -0.020133401969917225,
  -0.019488071066169208, -0.019858888870700534, -0.020449452875949237,
  -0.019731630755066263, -0.02060485694827904, -0.02013798164280467,
  -0.020200120339476844, -0.02027485759752053, -0.02045860753097195,
  -0.01986561569463824, -0.019856439850501943, -0.01958451827499763,
  -0.019741008340778052, -0.019882187854961986, -0.02017053234376534,
  -0.019713838549893063, -0.019349305163700398, -0.018790992992247776,
  -0.01880227024403947, -0.01862917105935145, -0.018004182252812057,
  -0.018160743796887223, -0.018039126065957947, -0.018281541288496742,
  -0.017623659581031296, -0.01809673391038085, -0.017413440583014476,
  -0.01729732704004381, -0.017965009218817614, -0.01805961408277512,
  -0.01794080749631579, -0.017960460651691434, -0.017564065536137922,
  -0.017090676282992705, -0.017380024958803756, -0.017409667738837672,
  -0.01706468414500234, -0.01748122569897438, -0.017851763610082522,
  -0.018095248167093667, -0.018876168220653064, -0.018949085778954985,
  -0.019236682495490365, -0.019031601204772822, -0.018901852794298943,
  -0.018864234273906207, -0.01826318116080943, -0.018556179296202787,
  -0.01843974668868918, -0.017994137752310926, -0.017701626798162003,
  -0.017277811966907617, -0.017190771521890174, -0.017043151649542815,
  -0.016278988688748375, -0.015536827089815092, -0.01572568997289537,
  -0.015814472928782936, -0.015536663840245017, -0.015474444659866818,
  -0.01567330304921205, -0.015860334185314694, -0.015464616742202906,
  -0.015249195444139529, -0.015813660088809252, -0.015818755335720074,
  -0.015443710234840516, -0.015912633814901236, -0.01647368699000167,
  -0.01637022655089642, -0.01667376676331302, -0.016489043286269017,
  -0.01635321176782689, -0.01554814549261322, -0.016241743099125818,
  -0.016156037619629394, -0.01627109161180921, -0.016123297708864007,
  -0.01692855033887469, -0.016726602667280564, -0.016679320360711087,
  -0.016623256811583696, -0.016566838337069973, -0.016430495614512944,
  -0.01708352559031077, -0.016640980733571765, -0.016688863220332705,
  -0.01637522695097135, -0.016404049336903617, -0.016856912020314783,
  -0.017148453041667188, -0.01724430756851733, -0.01766203348402229,
  -0.017942882839664744, -0.016933535728367817, -0.016924973158311176,
  -0.016744827250016886, -0.017093020520937908, -0.01635535074226636,
  -0.01570678883469416, -0.01532641671524721, -0.015031714821795859,
  -0.015415449448772595, -0.015530650797745581, -0.01572576516649089,
  -0.01573883820817653, -0.015494154602082219, -0.015436264107802451,
  -0.015106644106561817, -0.014684701518060934, -0.015317699567006725,
  -0.015480734947052797, -0.015361884843000403, -0.015401902279112716,
  -0.015298751965457172, -0.014831851252503513, -0.01480001865303971,
  -0.014814112666144113, -0.014907657034172484, -0.015030124044271436,
  -0.014730892102325512, -0.013964266324005376, -0.01416205219525102,
  -0.014331051341561752, -0.014294753466728904, -0.01511263168577211,
  -0.015091180130770286, -0.014747405844065567, -0.015293411898135248,
  -0.015513319741883615, -0.015533101086540619, -0.0155335512372419,
  -0.01574622587564893, -0.015696333738651425, -0.015359840379328872,
  -0.014525368698909956, -0.014620369973848652, -0.013362231925377745,
  -0.012926851259771747, -0.012627182995715188, -0.012460168845223126,
  -0.012154600516551048, -0.012407217955626625, -0.012498647094526283,
  -0.012758293121941875, -0.012820638202256436, -0.012917600819705787,
  -0.012842877545823488, -0.013395343681713853, -0.013388209758229803,
  -0.013498649044006118, -0.013229996361000489, -0.013451485444384017,
  -0.013664761122227748, -0.01414820654476805, -0.014375730372637717,
  -0.014595894601795462, -0.014689865308463623, -0.015029819540356962,
  -0.01495727894754347, -0.014791488143561623, -0.014721800096655207,
  -0.014311771820633569, -0.01434289943788067, -0.014548583204243747,
  -0.015156967489925205, -0.014763919779630706, -0.014934373294514846,
  -0.01576629076108894, -0.015565387860158344, -0.01584259810479331,
  -0.01575902537897386, -0.015131383404754945, -0.015378073167774496,
  -0.015691372007668347, -0.01533205068018172, -0.01514898033599377,
  -0.015009416681780912, -0.014163860010815703, -0.014710289679154968,
  -0.01427489198994279, -0.01437600257304783, -0.014045798573126541,
  -0.01356538492044117, -0.01367622596416718, -0.013626152246736281,
  -0.013814816675445162, -0.013907503137201769, -0.013645117611055777,
  -0.013830616677914416, -0.013712141773964156, -0.013975669389595768,
  -0.014026360636056555, -0.013782335051583966, -0.013475559941762198,
  -0.013892477528772616, -0.01386855577866174, -0.0146284866594611,
  -0.01494372679037498, -0.014583376267212173, -0.014512896791258965,
  -0.013707552252350338, -0.013682033401694618, -0.013525299503553849,
  -0.013240069840486611, -0.012861750893997334, -0.012896342073746158,
  -0.012895913851514534, -0.013049411246653904, -0.012741675559323935,
  -0.01236115488671479, -0.012297112743936972, -0.0125745930938718,
  -0.01251402908618642, -0.012675756237753168, -0.012582241677459692,
  -0.013310812847203306, -0.013171391945333703, -0.012803538149814669,
  -0.012481103341745627, -0.012327809136045554, -0.012191768567424164,
  -0.012303694173384831, -0.01244339699632593, -0.012763799869655701,
  -0.013586973133193963, -0.013033023034190957, -0.013362417762354894,
  -0.013644576592004406, -0.013849849621715248, -0.014113941679527292,
  -0.014390562905959366, -0.014798772173553774, -0.014091064815084571,
  -0.014693549146674234, -0.014845256755802717, -0.01491555982547493,
  -0.01505292848972297, -0.014891272563212639, -0.014953656898037912,
  -0.014998676397048683, -0.014792788898549574, -0.014636573551734533,
  -0.014328381665820136, -0.01436110927239275, -0.014920308492552188,
  -0.014737712421881615, -0.015424616985319006, -0.015401070118770576,
  -0.015473824446158316, -0.015449922293067674, -0.015467126488978,
  -0.014828044746836083, -0.01465591991398862, -0.014608846481455988,
  -0.014715177308996874, -0.014707298454420523, -0.015189473561566885,
  -0.015077729385241895, -0.014986443631627997, -0.014630286652686989,
  -0.01432355058412565, -0.014178917676583004, -0.013913865186255068,
  -0.014052804890937971, -0.014257567408410977, -0.014285078453414488,
  -0.014201412489768871, -0.014545014898253147, -0.013879883474246884,
  -0.014121027656913818, -0.01430860176612626, -0.014200951866152903,
  -0.014963888038439817, -0.014789517475045484, -0.015143294152176433,
  -0.014725421242076916, -0.015255682926491772, -0.01592482709371528,
  -0.01542334608065535, -0.014861799145413353, -0.01497176285020408,
  -0.01479545728727642, -0.0151302735541, -0.01558724150820875,
  -0.015620534809338407, -0.015766966951923148, -0.01590524593991497,
  -0.015942476792479975, -0.016162382614884088, -0.01623920644085672,
  -0.016237356701638568, -0.016305233331115186, -0.01630027114280074,
  -0.016079862689940203, -0.01612294214780663, -0.016547315803465718,
  -0.016268540262858806, -0.01593889675787498, -0.016014575909606754,
  -0.016079703532644107, -0.015350782233784511, -0.014981530435023708,
  -0.01474199325433736, -0.015058575419003038, -0.015269744893811372,
  -0.015050609113309492, -0.015186481728262431, -0.014867628558078074,
  -0.014816785527485686, -0.014852390038475593, -0.014797535387644355,
  -0.014760479177530497, -0.01514205626658661, -0.015415504764699108,
  -0.015311875960855894, -0.015272555751081291, -0.01513610939832216,
  -0.01596737139889062, -0.01567327915524978, -0.015922754541300897,
  -0.016456243797709443, -0.016841400525483465, -0.016741812943034052,
  -0.01654658537759932, -0.01613941999559577, -0.015970411428836134,
  -0.015504228062943637, -0.0158003002446185, -0.015748178596369664,
  -0.015219183479940973, -0.015149888972206791, -0.015766499940921337,
  -0.015557193615184099, -0.015273056835543619, -0.015317405365677304,
  -0.015229405546648306, -0.015217333685477988, -0.014621429188560483,
  -0.014958383405404107, -0.015277193696824714, -0.014846495828785665,
  -0.014748753502995624, -0.015095752871388191, -0.015244252622140922,
  -0.015084880657180414, -0.01542103503360875, -0.015363901632096569,
  -0.015171670220651984, -0.015271682726800956, -0.014984320156113446,
  -0.014857401200916, -0.014612030952212762, -0.014738840380211539,
  -0.014619781916667945, -0.01461469324512097, -0.014002947493579151,
  -0.013518856212742576, -0.013763101320438528, -0.014048224500101503,
  -0.0140191987818227, -0.01346482528610826, -0.014175187040869036,
  -0.014425459840615072, -0.01468423258039292, -0.015184959983408762,
  -0.015051736724894068, -0.015436842515936853, -0.015598707926156741,
  -0.015749865024489593, -0.015880768249655102, -0.016371271498258868,
  -0.016211707850931005, -0.016151539195595317, -0.015768992923449035,
  -0.01607815530316418, -0.016888033816748284, -0.01730752010373905,
  -0.01739657947477688, -0.01729967342017014, -0.01722035989349088,
  -0.01741282967664251, -0.016668722150531827, -0.016898153126605142,
  -0.017291059706434894, -0.017210933226191882, -0.017212592360529744,
  -0.016770419810210532, -0.01656979312046165, -0.016977237766558427,
  -0.016788377778610315, -0.016820355218139384, -0.01651051189393553,
  -0.016487449456800873, -0.016456741974432237, -0.016243126471478936,
  -0.016589930362110105, -0.016238374000223602, -0.01630160460940381,
  -0.016275723285569487, -0.015658394162943155, -0.015292929737123864,
  -0.015122174571385224, -0.015207441564968893, -0.015591474134893505,
  -0.01567783649016392, -0.015874511642439696, -0.015312886836131497,
  -0.015105051432770484, -0.015249783391000835, -0.015377795050870946,
  -0.0144577404183602, -0.014614895044411318, -0.015049311976971392,
  -0.01537624085867177, -0.015024201027225998, -0.015087227949299509,
  -0.014920358691883078, -0.014923900588163806, -0.014562322958334668,
  -0.014366926673332581, -0.014562196030802716, -0.01445698155305321,
  -0.014445584498226204, -0.01386663576717016, -0.013336113873861776,
  -0.014053815740030527, -0.014247537538447177, -0.014559909043199395,
  -0.01436644927622915, -0.014802148839864906, -0.015165332259533128,
  -0.01571807738063765, -0.015410520959983527, -0.015426045001993976,
  -0.01559265097415791, -0.015496018461286702, -0.01578538419312588,
  -0.015232890553004443, -0.01555593967671424, -0.015417616601133709,
  -0.015922024237815156, -0.015864240551718662, -0.015913705185047495,
  -0.01628375844809046, -0.01542622577835158, -0.015511310958419195,
  -0.01593315446199021, -0.0159459894117718, -0.015739479302839744,
  -0.015381033360131191, -0.015231101645808064, -0.015345023447742164,
  -0.015213550891872573, -0.015051573838076477, -0.015340055930494617,
  -0.01523310968378029, -0.014871356386334607, -0.014018442425755498,
  -0.013978889724046817, -0.014238533487417562, -0.014340086262856782,
  -0.014125830995872567, -0.013858861457613978, -0.014272525558893797,
  -0.013828924262372169, -0.013806131995435377, -0.01322609271048609,
  -0.012812771747830909, -0.013238604023314959, -0.013198205582439709,
  -0.013712712237505822, -0.013755754379826752, -0.013081069768949707,
  -0.012890548982281338, -0.012614702128091234, -0.01305834881573832,
  -0.01320204922031338, -0.013309779502509613, -0.013545081759728773,
  -0.013605870600274258, -0.01341202053207587, -0.012899328598874162,
  -0.01271433473839891, -0.012290113283595193, -0.012599139380334962,
  -0.012985184126924211, -0.01265693916163567, -0.012293586123677066,
  -0.012452397135317766, -0.01268352318931365, -0.01304544456244858,
  -0.013500294444802696, -0.01372586699204258, -0.01389354539316856,
  -0.014234386812886062, -0.014186624556219605, -0.013794173029674847,
  -0.013613897097368379, -0.014442061170223832, -0.01439139795108898,
  -0.014277541408384557, -0.014190121807305254, -0.014021772243260277,
  -0.013655539607117802, -0.013828179838385366, -0.014266763132276872,
  -0.014079299976371525, -0.014392778310975336, -0.014254099238626777,
  -0.01403657545565251, -0.014185691716392316, -0.014086942087840651,
  -0.01349723613612905, -0.013227872993895506, -0.012884071987482955,
  -0.01295420484003446, -0.013099268917451815, -0.014048481437629642,
  -0.014385011936931062, -0.013807076698487748, -0.013652962445707415,
  -0.013903989275945236, -0.013936718269090029, -0.013744452408823818,
  -0.01332507849432066, -0.013031520516034926, -0.013056868061477201,
  -0.012727830621312533, -0.012258165326096153, -0.012220406886286167,
  -0.012331941501592066, -0.011945716662494723, -0.011583243506309002,
  -0.011238350978301578, -0.011230864741254726, -0.011302145794669089,
  -0.01114188779903534, -0.010969961781172634, -0.011154304389290689,
  -0.011314539173672694, -0.011524883795383358, -0.011928218565322581,
  -0.01133763177567956, -0.011285557888582045, -0.011095444307834745,
  -0.011323876860833266, -0.011966476512882917, -0.01174909426971552,
  -0.011597938008273455, -0.011771770261244669, -0.011487620536859353,
  -0.011784883082760211, -0.01214267093738852, -0.011711814317682529,
  -0.011921545128923964, -0.01152476298631043, -0.011829166620742716,
  -0.011838754068634393, -0.012153609237172989, -0.011873034435917194,
  -0.012385223702467682, -0.012067713253086988, -0.011948357929301611,
  -0.011872720170112918, -0.011715126060996072, -0.011500898250488056,
  -0.011431087041541898, -0.011390438282615293, -0.011675856266157471,
  -0.012628322410751843, -0.012767521797217107, -0.012973063652305973,
  -0.012732187155991411, -0.012721020431708426, -0.012543502667799265,
  -0.012784503276605054, -0.01270938948241882, -0.013082537306744474,
  -0.013376036822376204, -0.01395790051428328, -0.013223961072173643,
  -0.01373036030480606, -0.013108781863852339, -0.012504810673693374,
  -0.012819458444776891, -0.012985462173050912, -0.013198644816793238,
  -0.013717342322682178, -0.014360378854834035, -0.01360784112235367,
  -0.013714364556652375, -0.013316959501127998, -0.013201925899520907,
  -0.013220111349567977, -0.013023869767158485, -0.01276587151979287,
  -0.012699792482856331, -0.01282288196032595, -0.012782297336524263,
  -0.012789075039264014, -0.012985661791779839, -0.01334010787273707,
  -0.013287687976588058, -0.013830945147046003, -0.013747351257019693,
  -0.013815285623666925, -0.013948283756865775, -0.013848049521269078,
  -0.013789427238895839, -0.013630768047194295, -0.013170505405934852,
  -0.012938443720280813, -0.012812394314402613, -0.012895915907741747,
  -0.013243334257966798, -0.012839214874767243, -0.012687412469576142,
  -0.012750736178092904, -0.013020229398440943, -0.013226262803349714,
  -0.012848539677054625, -0.01294570339561559, -0.012911169457658524,
  -0.012531483882431211, -0.012486180717253229, -0.0124914625856691,
  -0.012406890579132242, -0.012377085352326315, -0.013263036924374737,
  -0.013165685457611596, -0.013257872958343903, -0.013522995304079698,
  -0.01353625176694506, -0.013651183530569325, -0.013791711879742893,
  -0.01368048191939921, -0.013839981272477922, -0.014036661551350867,
  -0.013909198844215001, -0.013629443935991005, -0.013746302322680412,
  -0.013540332917353367, -0.013191901455032646, -0.013024875265788315,
  -0.012371947467438879, -0.01222450101203226, -0.012192516698050488,
  -0.012147027824168492, -0.012486186419641988, -0.012335275048292814,
  -0.012584780565967445, -0.013144077949473797, -0.01304928027442399,
  -0.013009626431850836, -0.012580652838663203, -0.012288659468074512,
  -0.012884802831223313, -0.012959276181488991, -0.012931427978780457,
  -0.012976786661727725, -0.012720330222582267, -0.012875954060305972,
  -0.012905637600231364, -0.01320952652936139, -0.013170995527088484,
  -0.013238262765761667, -0.013992477720244425, -0.014477024027167086,
  -0.01478638557360464, -0.015198075201531451, -0.01493546741991115,
  -0.015231783833714103, -0.01477867817759216, -0.014112008157716164,
  -0.014540839080436328, -0.014488540514233372, -0.014819283477385339,
  -0.015380974787599271, -0.015726266210494454, -0.015386452801619524,
  -0.01504787042579262, -0.014735439850698667, -0.014654314316108993,
  -0.014742318144167136, -0.014067458646693795, -0.01422268475935857,
  -0.013820096285398984, -0.01357940559604174, -0.013191170380667567,
  -0.013025845270248687, -0.013578356599639105, -0.013775782059496295,
  -0.014741892813495333, -0.0146562222269026, -0.014697271740166471,
  -0.014859404301163469, -0.014482713105847226, -0.014462747652598381,
  -0.014316407333494253, -0.014442661607880764, -0.01422731601905798,
  -0.013708009509532371, -0.013678474483256674, -0.013872903399409345,
  -0.013840137756000232, -0.013745664985581904, -0.013509524362425229,
  -0.013592379057733167, -0.01367686560613896, -0.013814242998863918,
  -0.013873155008333221, -0.013851003807119667, -0.01352839843278164,
  -0.013396557724723548, -0.0131867688424357, -0.013571618902493558,
  -0.01404508014918496, -0.013840143902275271, -0.013939545465331063,
  -0.014679185833740857, -0.014908287776027295, -0.014995026515880819,
  -0.014724804351981648, -0.014500066729724677, -0.014519579477522245,
  -0.014131430998956042, -0.014002216142276085, -0.013662655409847606,
  -0.013958620157290005, -0.013957462805643915, -0.013993787900537243,
  -0.014110377738801985, -0.01381492104979047, -0.013849176230747893,
  -0.013789939982348459, -0.013434480166157413, -0.0134698486848178,
  -0.013412671595316327, -0.013160686453116792, -0.013356979524980734,
  -0.012867956302546908, -0.012661093601611684, -0.01227176615936121,
  -0.012539527638527734, -0.011822620763432884, -0.011885482996467729,
  -0.011988524799897324, -0.011970593687783393, -0.012369619894577341,
  -0.012775670945745107, -0.012912916471526321, -0.013237371133094358,
  -0.012934197080103287, -0.013073392090027098, -0.012710743068980544,
  -0.01252036897777992, -0.012103453224358959, -0.01210555634083914,
  -0.012634736779397658, -0.012551578768977814, -0.011939003011014965,
  -0.01166742681924894, -0.011823149149090332, -0.011699995441960973,
  -0.011581667428101445, -0.011463247705409478, -0.01166481358111021,
  -0.011389321461968542, -0.011170434916792083, -0.01139638874671883,
  -0.011632794875407514, -0.010625906497930345, -0.010049752364083377,
  -0.010021043111215391, -0.009909212113538691, -0.009883709186812398,
  -0.009806622274515573, -0.00987586047949092, -0.010026631876753352,
  -0.009867936588749416, -0.009959964251416426, -0.0100806874624458,
  -0.009876539609871846, -0.009635463218481287, -0.009828601560402196,
  -0.009249440557254688, -0.009326726354499264, -0.009230523883198269,
  -0.00928724087648896, -0.008672775333139253, -0.00887664733272947,
  -0.008098691424013588, -0.008391617533893413, -0.008055985111169726,
  -0.00760534818935753, -0.007153436054428813, -0.0072153956924284925,
  -0.007275935306278762, -0.007480850907696754, -0.007573420129302583,
  -0.007277321858996307, -0.006888499284227542, -0.007249692120630303,
  -0.007658389081770748, -0.008196518671218563, -0.007799074003953965,
  -0.008027279106341326, -0.007834627183335927, -0.008004642395942846,
  -0.007684269289081246, -0.007753233832284147, -0.007770699759168706,
  -0.00774771254771841, -0.008097049680859903, -0.008388756845432128,
  -0.008430995637637004, -0.008562633293746619, -0.008552215184030326,
  -0.008762473196573316, -0.00835390176663325, -0.008338309555558289,
  -0.008279440940767826, -0.008499105761660691, -0.008322672081139107,
  -0.00830938114496279, -0.008697910274507097, -0.008621012336199382,
  -0.008834369631923277, -0.008894576807756047, -0.008873845891350495,
  -0.008556574998265207, -0.008963848177339973, -0.009001430117902236,
  -0.008612460175961961, -0.008496334256351818, -0.008835194799767175,
  -0.008711041398962524, -0.008683423839409803, -0.008428100990680174,
  -0.008622209748058282, -0.008971132582626965, -0.008752737174695543,
  -0.008225171464640097, -0.008642684590756584, -0.008572961731107897,
  -0.008941786867441522, -0.008799448556631933, -0.008469653177634979,
  -0.007922552953061206, -0.008161277220747814, -0.008326021413292663,
  -0.00844222794851218, -0.008617789910868812, -0.008689819510929942,
  -0.007868400710835138, -0.008259886402754272, -0.007925732631101703,
  -0.007982696844495962, -0.00784656229074207, -0.007983084361162147,
  -0.007773580014839888, -0.007001900419256743, -0.007396239944435567,
  -0.007370948096101951, -0.007566278078021214, -0.007449750703598585,
  -0.007389811459056806, -0.007921221695528707, -0.0072937213603972584,
  -0.0075037969613198224, -0.006892887507579935, -0.00667558722181082,
  -0.006788830608285903, -0.0067879514239123535, -0.0068767672271206025,
  -0.00655942523559621, -0.006645151685023886, -0.006160526403230001,
  -0.006537652653090155, -0.006663118333668185, -0.00681136385629837,
  -0.006899506091966565, -0.007140211512426891, -0.007534347786578255,
  -0.007902898315014843, -0.007935432711700479, -0.008168357876124726,
  -0.008241484593534498, -0.007888790025229539, -0.007618106507893395,
  -0.0073065313450793225, -0.007555703134406024, -0.007497482026421227,
  -0.007266335089207546, -0.007132257558094688, -0.0072120985977486346,
  -0.006831141502519083, -0.006944600154605055, -0.00681191867050563,
  -0.007120600572722779, -0.0073666712685419395, -0.007289443484244483,
  -0.007058396867940162, -0.006512195365029867, -0.006484942851341505,
  -0.006061343138636126, -0.006370207050902312, -0.006251039860973326,
  -0.005582037721311284, -0.005622776093401481, -0.005275639556074269,
  -0.005622176589283745, -0.0057324767937593024, -0.005891449784430615,
  -0.005997556117244953, -0.00586064510095872, -0.006005862960886655,
  -0.006480003930609422, -0.006573734062357835, -0.006622800115277155,
  -0.006085007463325184, -0.005846489429320449, -0.005913245889516865,
  -0.006168516046292484, -0.006479905513702816, -0.006489045379260195,
  -0.006752002470885827, -0.007139117668481462, -0.007792098667243759,
  -0.007993332981267797, -0.0076983576690447955, -0.008102415270779073,
  -0.008547414593409478, -0.00888536739480677, -0.008418864810320788,
  -0.008125903966904748, -0.007768561107055478, -0.007427464545574823,
  -0.007857130479336307, -0.0073693636799418635, -0.007559540483438262,
  -0.007387065085464069, -0.0070618028461067505, -0.0066441438955511505,
  -0.006308133882269378, -0.00653100086649779, -0.006596544422538569,
  -0.00638937868471669, -0.00628249041276625, -0.0061627478145396195,
  -0.00585587120590343, -0.005874850770323246, -0.006066172724381013,
  -0.006177562796218637, -0.006007463953525893, -0.006039508750860931,
  -0.0060268166978116496, -0.006017889127410762, -0.00583462390787396,
  -0.005685121338701522, -0.006146305032812538, -0.00618470266719518,
  -0.006584659851966676, -0.006666971965877685, -0.007003133445996178,
  -0.007099219670984847, -0.006740079498208782, -0.0071913876398517415,
  -0.00696769515354581, -0.007121601521375637, -0.007238262942865121,
  -0.007093846234268769, -0.007058865896874168, -0.006924534354343829,
  -0.006989487393073164, -0.007017236157748261, -0.006851598992699799,
  -0.006919868154600202, -0.006677589489864405, -0.006611048835913299,
  -0.006946599726118395, -0.006593956627324956, -0.0065285281046445895,
  -0.006527646752375753, -0.006439207984747297, -0.006577631362535833,
  -0.006240202367040424, -0.006104196896211522, -0.005998715530536065,
  -0.0060214395442599175, -0.006398075541478461, -0.00678823506696763,
  -0.00703441942305922, -0.007428905799831084, -0.007457116119817981,
  -0.007299007136035775, -0.007146665422903532, -0.0073199177433098984,
  -0.007420880537362228, -0.007369372847126404, -0.007053071678494581,
  -0.007326851196692501, -0.007422624933826182, -0.00714482744744877,
  -0.00661292285764879, -0.006585875947479445, -0.006290251247252674,
  -0.006426368537946899, -0.006068919284551625, -0.005889504462850889,
  -0.0056497433756422894, -0.005485713786869906, -0.005277526044555992,
  -0.0056472447127960314, -0.00546525994218987, -0.0060334577978344885,
  -0.0061239391183993235, -0.005925328475525477, -0.006024415760870358,
  -0.006314338070960824, -0.006262711813054335, -0.006337595036105583,
  -0.005757737415096147, -0.005410603093553284, -0.005376192597529657,
  -0.00574375843142, -0.0055561007284289825, -0.006065441443573585,
  -0.005841628473416574, -0.005876189915762654, -0.0057050541639111114,
  -0.00539174972002541, -0.005500991940978206, -0.005617813485852142,
  -0.0059889461376074135, -0.006056004737566271, -0.00562895704920788,
  -0.005609331117696871, -0.005767908677372241, -0.006062877572923655,
  -0.006021865944328533, -0.005483314639535734, -0.005644656921320883,
  -0.005656871554738286, -0.005702452044170683, -0.005609229190779638,
  -0.005718407059673561, -0.005786880209647261, -0.005755843107984204,
  -0.006236802308569925, -0.006020750809202877, -0.005917975884004982,
  -0.006061942332982444, -0.006027647473439956, -0.006091175828018626,
  -0.005922245751470845, -0.005950060885787044, -0.006271970592225565,
  -0.00627621476765962, -0.006607231365038294, -0.006576994059584534,
  -0.006521809104583467, -0.0070686188256236905, -0.007411727350829025,
  -0.0070083892169057384, -0.007413949050571015, -0.007504567458048907,
  -0.007670115590701616, -0.006993921143261604, -0.00700100011726742,
  -0.006830665826968065, -0.00691350615232593, -0.00659461196763796,
  -0.00658566839384378, -0.006958260982146188, -0.006941107198248661,
  -0.007074488654420811, -0.006783490718547772, -0.006921874098601839,
  -0.006830032846818892, -0.00648940285349383, -0.006634300331503896,
  -0.0064492325398070255, -0.006564836470253368, -0.006725687126990534,
  -0.006673461039125823, -0.0066521445085946755, -0.0065097989685465275,
  -0.006955211285048513, -0.007163695735265753, -0.0070455393757958475,
  -0.006670009729193677, -0.006828407076818578, -0.007169582018612388,
  -0.007304040469536452, -0.0072567087194594605, -0.007107452052811644,
  -0.007445509490603008, -0.007193926416624235, -0.006970452654278306,
  -0.007070275197731365, -0.0075649512011860065, -0.00772220737518468,
  -0.007900681425874578, -0.007546886410411907, -0.00775761481815904,
  -0.00776916342131591, -0.008344157539687005, -0.008413185838813446,
  -0.00860078391307535, -0.00865752742208656, -0.00867047623853827,
  -0.008758169838257755, -0.008342585692259255, -0.00841696736147813,
  -0.008228288749252095, -0.00845121749294422, -0.008290674989126053,
  -0.008153154596290391, -0.00819591934002452, -0.008156644032408695,
  -0.007845397764069678, -0.0076423387882641565, -0.00766460464145072,
  -0.007727890781649811, -0.007648112453909633, -0.007372497831096368,
  -0.007707674666162773, -0.00797008751886861, -0.008091771398798287,
  -0.0084912215046462, -0.00870908143470742, -0.008372910349451958,
  -0.007990676272705624, -0.007679502883513977, -0.007557458129612357,
  -0.007630536133798229, -0.0077391685119877875, -0.0073155334880694286,
  -0.006987764237823607, -0.006848084501512089, -0.006755969242711992,
  -0.006508402918180518, -0.006088522337613937, -0.005680906309262181,
  -0.0057177663476352, -0.005808728650719555, -0.006101407471573364,
  -0.0059114380548655095, -0.006341270697721788, -0.0061706054312141165,
  -0.006046835835450326, -0.005920999208624831, -0.005604448969273403,
  -0.0051245394796923995, -0.005698814210077257, -0.005788229040687484,
  -0.0056803343517019544, -0.005556161837642899, -0.005278233424228326,
  -0.005096844716490647, -0.005206912967889515, -0.005035119101200942,
  -0.004736482735404554, -0.00449554425976984, -0.004129405656195366,
  -0.0043809431476733865, -0.003974437275934507, -0.004085210460937058,
  -0.003815480743319571, -0.0038253062559746726, -0.0035611634073605047,
  -0.0034779802471942905, -0.003433526197568681, -0.0034406727550735042,
  -0.003670678909825999, -0.0034015216778090066, -0.0031937292860739898,
  -0.003183800539683634, -0.0030736499868317226, -0.002661036166884048,
  -0.002958630579151228, -0.002872336886960119, -0.002527492213654343,
  -0.002855473825633802, -0.002493162335511918, -0.0017901143076208656,
  -0.0018357883212293783, -0.0021650374895997647, -0.0026501150079865747,
  -0.002925801441573118, -0.002883695661065676, -0.002303802563742429,
  -0.002453261203796613, -0.002434590535086209, -0.002160952811039704,
  -0.0024011297356596314, -0.002309037397425316, -0.0024734039319351682,
  -0.002472093806133055, -0.0025381342485992667, -0.002667749552894148,
  -0.0027915887384769513, -0.0025211198085570517, -0.0023071951319588125,
  -0.0022359758453961267, -0.0023772786888842834, -0.0025118268058703145,
  -0.002233166884405066, -0.00266123711984442, -0.0030699537155912692,
  -0.002979619121551397, -0.0029830553164056054, -0.003118512224498337,
  -0.0031838019032538593, -0.0035691176246349468, -0.0038517388651987897,
  -0.0040998332923172865, -0.0036430374150689506, -0.00361127877673224,
  -0.0036773468261665464, -0.0035733097587494042, -0.003077632562077682,
  -0.0034894684396803667, -0.003653582736918323, -0.0036616804209709717,
  -0.003581427652192037, -0.0032048175850174845, -0.003289405286852964,
  -0.0030984580904392495, -0.003197948100021617, -0.0030539471864562406,
  -0.002922905487768028, -0.0027678310037211515, -0.0026968738635683317,
  -0.0027815216740592194, -0.002904194595635806, -0.0026096370794359276,
  -0.002832840888099348, -0.002730296235001258, -0.003362451981838645,
  -0.004071253863998766, -0.0039418653964081955, -0.0040675733826687245,
  -0.0038714856914285486, -0.004031424334373019, -0.004128668006937324,
  -0.004356857863192964, -0.0048496432495980036, -0.004802160103104715,
  -0.004931442692711238, -0.004309235721914712, -0.0043750304708058295,
  -0.004674907648243432, -0.004513904037869033, -0.004122608791509371,
  -0.0037326041685681005, -0.0034250529830932934, -0.003456266218426885,
  -0.00347836556691294, -0.0030619587906698096, -0.0030911817127640628,
  -0.0033325219003498487, -0.003690043679704557, -0.0036887893610183203,
  -0.003675725004524631, -0.0036049097906191633, -0.0036273983118002002,
  -0.0037717918196192453, -0.003866270013820847, -0.0040735582005957775,
  -0.004029369343592552, -0.0036290463854980475, -0.0038514536155567942,
  -0.0037001889047666714, -0.0034773097449394786, -0.0033337190907440596,
  -0.0028941044701947735, -0.0030064918376533174, -0.0030002167720829085,
  -0.002782949736348183, -0.002577657295003391, -0.003085674882860589,
  -0.0028554809110595447, -0.0029185559710084995, -0.0030624788898568865,
  -0.0030940426185614893, -0.0031322021063009157, -0.0034045151003712403,
  -0.002958674910048029, -0.002901646446693643, -0.0032923339868627306,
  -0.0032557331477355957, -0.003336046597018551, -0.0032312384755640547,
  -0.0030678549011086864, -0.003123627070284762, -0.0033459698235364065,
  -0.0035489558174090735, -0.003068004044177879, -0.0028587526636563716,
  -0.0027521493696187563, -0.00271861741228697, -0.002620634539827985,
  -0.0025235989467799146, -0.0023684117990223686, -0.0023910276930273534,
  -0.002304170775867332, -0.002583158172239511, -0.002425296317561396,
  -0.0022528209207308913, -0.002618326969344945, -0.002570369632025749,
  -0.002942176165313144, -0.003049845556271984, -0.0028702987132730386,
  -0.003339631648834331, -0.003553652381155821, -0.003529027260001786,
  -0.0037956224784696604, -0.003858659891137802, -0.0042632228188815265,
  -0.0044515299361143545, -0.004597298194418749, -0.0047074472414396365,
  -0.004806627384367104, -0.004788265900830001, -0.004645941891953908,
  -0.004292311980904745, -0.004622458705206231, -0.0045405100353948106,
  -0.004290834192695506, -0.004488703238258545, -0.003964550391847256,
  -0.004314480759478006, -0.004246209935463543, -0.004469033448764812,
  -0.004161026066648804, -0.004118287611651872, -0.003663099021701574,
  -0.003922825468109574, -0.0038227518752803782, -0.004144652241393157,
  -0.004635043319462713, -0.004938339594742499, -0.0051623742688268505,
  -0.00576510930554723, -0.005712738143954067, -0.005532448625256409,
  -0.005354548071775581, -0.005226966496979344, -0.005389151459448079,
  -0.005127957798697998, -0.005176575409081292, -0.005248681988811432,
  -0.004498339629130623, -0.0046705682381428006, -0.004759479357277026,
  -0.0048790862913300394, -0.004800633252049541, -0.005090172978188303,
  -0.0045138927635861815, -0.004709963566749379, -0.004807536066005914,
  -0.004956755651919365, -0.004744442284826276, -0.00446968335555591,
  -0.004529021895921838, -0.004847737595401506, -0.004534371065946667,
  -0.004637073570805629, -0.004588349540303878, -0.004658384270582154,
  -0.005081324576219946, -0.005000022279971312, -0.004453894040078509,
  -0.004173392090748312, -0.0039258398898058796, -0.004511324112501347,
  -0.00433524245407863, -0.004291492427273971, -0.004627408408512616,
  -0.0048064392247331306, -0.005036176344832911, -0.004432790161122848,
  -0.0039045273014400405, -0.003887891795540619, -0.003845367897404729,
  -0.004028501881868846, -0.00431819606190207, -0.004171709514310373,
  -0.00386984607619974, -0.004002511740085633, -0.004023313651167562,
  -0.004361000584815255, -0.004628781855919784, -0.004918865017900183,
  -0.005067837033130954, -0.005109241816435462, -0.00496914572599143,
  -0.004879939763735656, -0.005020486921744088, -0.005118217900299634,
  -0.005172007670832878, -0.005307208031917961, -0.005350387245039382,
  -0.005224269096898866, -0.004767592286451233, -0.004624539154024201,
  -0.004635259512661022, -0.004681909777909126, -0.0050454589962741665,
  -0.004548425457134972, -0.0039022186805415826, -0.0038968331836111937,
  -0.003875592595814491, -0.004133180149612515, -0.0035507791083260226,
  -0.003239310002568206, -0.0036816459739406715, -0.003278294409604214,
  -0.0031370295349006177, -0.003228600805726715, -0.0036733735989861746,
  -0.003706228620495814, -0.0037526374639773354, -0.0036645399574346178,
  -0.003987799891048402, -0.0034835706444431573, -0.003413623008980763,
  -0.003838495901571636, -0.003766237278964755, -0.0036758721550195043,
  -0.0032761635547850364, -0.003153939084527036, -0.0029737492181949538,
  -0.002949741800934944, -0.0028125035477350197, -0.002620320744370931,
  -0.002263574412166865, -0.0022622610663280565, -0.0020088578457478825,
  -0.0021195845069574355, -0.001682565411759608, -0.0018540884519323562,
  -0.0017438118462918047, -0.0017898321726731164, -0.0020053457398933384,
  -0.00231078442172557, -0.0026052758800211174, -0.0024478081682308738,
  -0.0023628900793411837, -0.002422163076309363, -0.002434115268262438,
  -0.0024836007696017195, -0.0028688712144380895, -0.0030873687168159313,
  -0.002914071078891755, -0.0030334450753182244, -0.0027272997278512266,
  -0.0025509723538857873, -0.0023364376938304642, -0.002699911153734704,
  -0.002488877449688533, -0.0023922647902670548, -0.0022997209225368515,
  -0.0026991736510907686, -0.002724715257537104, -0.002186081123833313,
  -0.0019896219482926462, -0.0020223551276818924, -0.0018542094287039461,
  -0.0017180822806898777, -0.0014447326190249847, -0.0012141289619757817,
  -0.0008892208148679563, -0.001129822331917492, -0.0008984039126529615,
  -0.0007760627820301699, -0.0006565575304376142, -0.0010439747673140474,
  -0.0009805184730292563, -0.0008772936857307383, -0.000776482238624606,
  -0.000905338722966111, -0.0008344649546600069, -0.0007523882471373312,
  -0.0007103253737817923, -0.0002898769927098977, -0.00043176816402543025,
  -0.0007572220026265101, -0.00046123172141156367, -0.0007557146589703316,
  -0.0008555071994531632, -0.0010754764121966942, -0.0010110472865418317,
  -0.0012005880475531435, -0.0013082810604774233, -0.0011140671119742869,
  -0.0006874924217483021, -0.0003687370339521995, -0.0006242040886544418,
  -0.0009920222043277257, -0.0007454823640197803, -0.0004990302164317381,
  -0.00026654512648436793, -0.00027407789863992117, -0.00024813290041957845,
  -0.00010299472348161158, -0.00036816996753214284, -0.00028598726390124545,
  -0.000619954682113855, -0.0007826433523981564, -0.0008220073760431763,
  -0.0010025549124844022, -0.0009339592192949701, -0.0005964255830739991,
  -0.000719751469228467, -0.0006721863990396638, -0.00022571263985331173,
  -0.00021273881676548413, -0.0004853855165134845, -0.0006255862477127042,
  -0.0007548276297343959, -0.0009661179121197673, -0.000601110561793844,
  -0.0007367138405935758, -0.0007812161788856304, -0.0008099460873637694,
  -0.0007470888826429256, -0.0009121363362626946, -0.0007769182616710278,
  -0.0007209568668881469, -0.0008284595404598573, -0.000952842753590521,
  -0.0009728000764226968, -0.0008642232405347466, -0.0007985172407615393,
  -0.0007350680589892522, -0.0005772841408702195, -0.00032269627754600574,
  -0.0006815075687096936, -0.0008985043915878425, -0.0009102469080381942,
  -0.0008393763370394587, -0.0006984013236861488, -0.0006088759467746679,
  -0.0005179061714095068, -0.00035080848692506785, -0.0004767536190707555,
  0.00015656253368123067, 2.474553988353339e-5, -0.00023232456579353128,
  -0.00020882268043426037, 4.079808550225227e-5, -1.7219769058268033e-5,
  -0.00037525244405491737, -6.679732134604669e-5, -6.058273347840462e-5,
  -1.9911964687680503e-5, -0.00018916444425815836, 7.316917733450787e-5,
  0.00017594498682581554, 6.932542818353372e-5, 0.00021486661860359679,
  0.0005158095959437349, 0.000281771174501434, 0.00017212681239169746,
  0.0005066763297781171, 0.0005960111510036595, 0.0003662415539285748,
  0.00020087655155580323, 7.337197000550871e-5, 0.00012988997162230036,
  -0.0005017629594444206, -0.000458716588773518, -0.0006060074206946844,
  -0.0006006775728465734, -1.2507231311905249e-5, 0.00015303318593380244,
  0.00033792093572244605, 0.0001602090334388141, 0.0002804898416344457,
  6.081535870300722e-5, -0.0003396215922203417, -9.568807292780443e-6,
  -0.00012312547961748835, -0.0006625443218219236, -0.001161296140289151,
  -0.0008903259504819815, -0.0011379122005516459, -0.0010784066354239284,
  -0.0008828040151183018, -0.0009645044701427308, -0.0010561468098842892,
  -0.0006980251567165227, -0.001025288716578314, -0.0010579693020493213,
  -0.0012507649067661733, -0.0011401445865402361, -0.0011803746554173303,
  -0.0010786923362805045, -0.0012659135098956923, -0.0016994640741444212,
  -0.0016678146832409392, -0.0014659480401022552, -0.0014928402602753833,
  -0.0014137045336409045, -0.0016322416600200017, -0.0019175981141536132,
  -0.001865108770381622, -0.0014138246440003998, -0.0018179158886386575,
  -0.0017332987221170895, -0.0016637145931792641, -0.0016229241053876482,
  -0.0022191263945337135, -0.0024429008067293197, -0.0017301095276926663,
  -0.0015922513999347026, -0.0010522825013608388, -0.0010424479962722853,
  -0.0010264248830956868, -0.0013154860107102264, -0.001461907924002632,
  -0.0013586522869702697, -0.0011014558685211995, -0.0008918983190263491,
  -0.0010336922082131428, -0.0009213081948605151, -0.0010384402446245047,
  -0.00149239821138719, -0.0011406697911130524, -0.0012212857820327525,
  -0.0013953521037279861, -0.0017320872838794544, -0.0022679202144436925,
  -0.0022070862789912685, -0.002414314547105973, -0.002455474678005383,
  -0.0020831436640933025, -0.002081430202181745, -0.0020095509412351997,
  -0.002314580536647048, -0.002379052694994785, -0.0023105247022499375,
  -0.0026803476450127507, -0.0029871833067749588, -0.003102040688779848,
  -0.0029435995191298293, -0.0032394657820249114, -0.0032168661675880342,
  -0.003153386060262754, -0.0027656376216489025, -0.0027272501278299195,
  -0.0025297568296946044, -0.002650272709063379, -0.00263355241096643,
  -0.003107613734921224, -0.002963469140863577, -0.00294820115979404,
  -0.002941889339164795, -0.003025541917858837, -0.002832441782564705,
  -0.00303451529867481, -0.003068656753028014, -0.0029734221527473225,
  -0.002422969118079744, -0.002390364137636529, -0.001916996927595296,
  -0.001870421847168412, -0.0019281720359046975, -0.0019196302483293003,
  -0.0019770316931680045, -0.002365128235537594, -0.0023856654352982713,
  -0.0022682880861822044, -0.0023179814421256497, -0.0021603374685562607,
  -0.0023851657650528035, -0.0026175459516753164, -0.0026312949252812043,
  -0.0026277111518247533, -0.0024678960407522395, -0.002372572319146556,
  -0.002723048824150783, -0.002941947045778356, -0.003031011597168846,
  -0.0033562700062276757, -0.0031379826417839833, -0.0030460464988745725,
  -0.003176786383854279, -0.003177300031856439, -0.003489366313694468,
  -0.003746257632417255, -0.0036235641297705902, -0.0037229420191986595,
  -0.0037833478503016727, -0.003718999229937477, -0.003501916976624629,
  -0.003366862276517033, -0.0036400905554536283, -0.003913758761056386,
  -0.00392411670270826, -0.004077275493052785, -0.0037602694222944448,
  -0.0036126085459640515, -0.0033239765276262805, -0.003353851456454714,
  -0.002977811310897315, -0.0030750660705929603, -0.0035813852166437926,
  -0.003599882342701697, -0.003527445509635014, -0.0036352673264951705,
  -0.004001235137426734, -0.004105812544053627, -0.004156166285575953,
  -0.004039998187199615, -0.004111001205916411, -0.004390661979207339,
  -0.0045533883336940275, -0.004696882897682731, -0.004678820225785833,
  -0.004657932373584543, -0.004597977818208205, -0.004552982934992505,
  -0.004415387265367808, -0.004285142560816971, -0.00431645234244207,
  -0.004291669105342214, -0.004125807418912703, -0.004153963492043256,
  -0.004464546942974877, -0.0044273813542827205, -0.004211189914666551,
  -0.004440261521798312, -0.004397661361778271, -0.004678592909176139,
  -0.005044048626202942, -0.0049344543815940485, -0.005167079000660751,
  -0.004861614848771452, -0.004791820274197278, -0.0049903477796380425,
  -0.005003208293400719, -0.005198798520638672, -0.004998416108569653,
  -0.004524706299329132, -0.004714830217705667, -0.004495959466625974,
  -0.004689646891939278, -0.004492148036897004, -0.004281605164936527,
  -0.004646321423327752, -0.004167776518836317, -0.004077798681897151,
  -0.004202871164560707, -0.003910636803818566, -0.00379149804100575,
  -0.0037501500426059273, -0.003944140004912593, -0.003822511300798954,
  -0.003819466099504743, -0.004153075813824951, -0.004311379169746076,
  -0.0044031572837637, -0.004643856196726907, -0.004498704581526374,
  -0.00451119690822248, -0.004343514722665272, -0.003997386295144894,
  -0.004138606627715925, -0.004011053714581252, -0.003722714291669714,
  -0.0037344007891116572, -0.003325297922424301, -0.0036018248029939036,
  -0.0036362504010780364, -0.0034771605825401294, -0.003159340868885302,
  -0.002957453236314265, -0.003070323991814552, -0.0030763064390734295,
  -0.003030562210000358, -0.0031145506564417107, -0.0031816105823099,
  -0.003278076055265865, -0.002957832952906405, -0.002386724199712988,
  -0.0020741972250243775, -0.001753750260538856, -0.0017647746988496548,
  -0.0018293470519165505, -0.002040570887906579, -0.002033861576314459,
  -0.002029383392768801, -0.0021435956978902632, -0.0021482494594957328,
  -0.00205722741382879, -0.002116862768734596, -0.0019487680077220227,
  -0.001717840072566252, -0.002025641927773534, -0.0017074287910157464,
  -0.0014851798259115146, -0.001462731633918318, -0.0013590927075701692,
  -0.001298707101710044, -0.0009198901040297928, -0.0006392606683944801,
  -0.0007436789756891474, -0.0004906034775364105, -0.00013927020751324448,
  -4.333625299220111e-5, 0.00025159944016694096, 0.00018645585582043132,
  0.00020471505376297994, 0.00034612131624497234, 0.00019598780125092507,
  0.00017544138128775642, -3.109729903401471e-5, 0.00020693022156215358,
  -0.0001580856209206459, 5.4842277251025246e-5, 0.0001693384915789559,
  0.00023694956954444505, -0.00011350796351887678, -0.00020378566308620438,
  0.00011928394902902755, 5.08371960683037e-5, 1.4289259412750604e-5,
  3.44210331029647e-5, 0.0006147926984625791, 0.0003189086274349896,
  1.880351347611197e-5, 0.00045794899641560927, 6.692485595144493e-5,
  0.0003960954125486414, 0.000800414787781417, 0.0009484960166992754,
  0.0011912508156597524, 0.0013470570904614806, 0.0013497907846328617,
  0.0016072576827274095, 0.001673137375688071, 0.0014846850619363162,
  0.002114352725053401, 0.001964574368611308, 0.0019628379863309707,
  0.0022525689701220135, 0.001848746400564085, 0.0017472757452259504,
  0.0018964400033059613, 0.0020607882207582546, 0.0017097914853483256,
  0.0020618459859302893, 0.0018949632432090232, 0.0020981391982346945,
  0.0020561240801372175, 0.0017933448846021978, 0.0017590736895885133,
  0.0018169801189991373, 0.0020157102376892446, 0.0025024025608945716,
  0.002463678433692538, 0.00237431725341632, 0.0021972687732806926,
  0.0019584269889964074, 0.0016974512056300427, 0.0019417308075283513,
  0.002225747328339901, 0.0017221862164748234, 0.0015814963353432212,
  0.0014239747375057146, 0.0014996512383168652, 0.0013436553024850839,
  0.0010819134231441726, 0.0009989189278583955, 0.0009025130634486451,
  0.0007957373408846853, 0.000517780690698264, 0.0003646541123291611,
  0.0002520461928591615, 0.0002231850723982637, 0.00014222312583206875,
  0.00020853157666913917, 0.00020941392611993948, 0.0006557649199268758,
  0.000641404633832903, 0.00041236077876749614, 6.447531784974457e-5,
  -6.70764889779376e-5, 0.0002035551228241977, 0.0002567574384674986,
  0.000169751023570708, 0.0005171291218277454, 0.00022361109562163886,
  0.00030102734567408136, 0.00024399748637566663, 0.0003989175330921026,
  0.00011617772087107, 0.00028662107735706913, 0.000522603271784005,
  0.0003595542127770462, 0.0002550497837052325, 0.00030145250169698817,
  0.00018208360443924755, 0.00010162422506176389, 0.000341803820335466,
  0.0005235217850043472, 0.0006794793312515795, 9.309676247684217e-5,
  0.0003319986374455984, 0.0006249345565738936, 0.0007957913738092723,
  0.0007332763268831781, 0.0007339840216308407, 0.0007609849471446439,
  0.0006326708114446412, 0.0005687549141704069, 0.0007572331144000943,
  0.0005271951205679846, 0.00041429568761120313, 0.00030138686170099854,
  0.0005233255282440102, 0.0006022828564662854, 0.0006368496805507818,
  0.0004786678020271414, 0.0009911016494509285, 0.0012005095541071506,
  0.0005837323960058115, 0.0007425487790887286, 0.0007642071010758334,
  0.00037881673864688874, 0.0003626002333557489, 0.0005931558631361758,
  0.0007299253646768743, 0.0004600352437165282, 0.0006719301809839768,
  0.0006721660233192176, 0.00101977710464774, 0.0016338391877667008,
  0.0014237631171052751, 0.00109582091909789, 0.0013163215301078813,
  0.0014467788123297691, 0.0016515131327967463, 0.001619186682165364,
  0.0018284987870579176, 0.0017470400544815177, 0.0015921721337960203,
  0.0010769004599663434, 0.0007488359704686028, 0.0007970770644008092,
  0.0008648756468486512, 0.0007161345463620076, 0.0006969093398524034,
  0.00036314675673906953, 0.0004147417046162324, 0.0005563291406410199,
  0.0005601635731644031, 0.0005309569620878964, 0.0005002700024115498,
  0.0005222043287592989, 0.0003061210744280651, -5.57875707222542e-5,
  -0.00012581429541509018, 0.00015438499739522203, 0.00033170165827897603,
  0.0005202227016300414, 7.823681029354491e-5, -7.241866674658962e-5,
  0.0004219950656465195, 0.00018977516698750883, 0.00029696343351231976,
  -4.721187789517327e-5, 6.5215168209525e-6, -8.56254517100928e-6,
  -0.00021623419916358085, -0.00031983261761813294, -0.00021826475938468135,
  -9.501723499441237e-5, -0.0005222301311291336, -0.0003782966850035827,
  -0.0006220858811023059, -0.0006936732126641469, -0.0005885570409154328,
  -0.0006384675982915093, -0.001069499024000436, -0.0006676970901706706,
  -0.0006631571888829545, -0.0003611211543051852, -0.0005540280110924739,
  -0.00017806784113299723, -0.00034857105272215975, -0.00023079974328946124,
  -0.0005935572555103408, -0.0005047029624001653, -0.0007132501156834015,
  -0.0003406460106663583, -0.00017561649813138455, -2.3653563795060763e-5,
  -0.0001837722584102386, -0.00033864915784790283, -0.00011877149379921398,
  -0.00024497981324861, -0.0002532470650006452, -0.0003281779344621844,
  -0.0005399541657020551, -0.0008999990244333069, -0.0006096380777081792,
  -0.0005971885420243062, -0.00031842461507628774, -2.1645712158722893e-5,
  -0.00012634474627329346, 3.80186983580838e-5, -7.147425056724686e-5,
  6.740279682046057e-5, 9.800656158902139e-5, -0.0002149108882910029,
  6.682872814215493e-5, 6.64246526153045e-5, -0.0004957088239779771,
  -0.00031605198078116085, -0.00016398682512216447, 5.2259065346756e-5,
  0.0005657921912708188, 0.0003477211079207668, 0.0003092571603646191,
  0.00044380198314871117, 0.00024874232024838145, 0.0005410609674714722,
  0.0003595767081528517, 0.000665981268071992, 0.0005663293200139456,
  0.000763552977378301, 0.0010421638178494852, 0.0008672054445221221,
  0.0005772913740572859, 0.0008861434161806693, 0.0007628419173670933,
  0.0007306794365750315, 0.0006514252200741981, 0.0007808628406172735,
  0.0010032579777640536, 0.0013140265368928466, 0.0015504870629041779,
  0.0014668601230077556, 0.0014181860181456348, 0.0015207069340944055,
  0.001543754631516352, 0.0013479978607016997, 0.0014721065361742486,
  0.0016975926128913567, 0.0018797155068881063, 0.0016590881341962673,
  0.0018275339776401144, 0.0020291978809034725, 0.0022495887924523185,
  0.002040125417204372, 0.002468019436846368, 0.0024918239725717603,
  0.0028307776045389857, 0.003059913069877856, 0.0032822829071865616,
  0.0033314483953516007, 0.003364488681462308, 0.0031184526438591755,
  0.0029139768318851332, 0.0026291053600867144, 0.0028077752565752404,
  0.002758750244676452, 0.0026470707243836686, 0.0027259878609420785,
  0.0026504693149610216, 0.0027047928302158146, 0.0027884935483316405,
  0.002761479777324403, 0.0026056487282713637, 0.002462637570702726,
  0.0026971241731240858, 0.002467825225544236, 0.002380287417884576,
  0.0022954257288956056, 0.002119063227980614, 0.0022280844689499084,
  0.002114362762925036, 0.00212401438790897, 0.0021642457834686515,
  0.002492620959622174, 0.0022224143720162215, 0.0019405764403147335,
  0.0018635036448235256, 0.0015608580220075096, 0.002006775001146319,
  0.0020959628504988934, 0.0021757716234058963, 0.001825288376579074,
  0.001938060466933097, 0.0016612025625844997, 0.0019069822285925151,
  0.0016632172523355453, 0.0014486701278188072, 0.0012490801051179604,
  0.001528945076925354, 0.0015453426694519822, 0.001622891421982409,
  0.0012433338352187246, 0.0014464237209148361, 0.0016983741247694449,
  0.0019442188363903749, 0.001992697155478065, 0.0021295568880921405,
  0.002650580834366894, 0.002797117332405162, 0.003140338948273352,
  0.002956037476169196, 0.0028336725256671537, 0.0028059253061013594,
  0.003069518247720527, 0.002888709850929585, 0.00284910184788949,
  0.002747644017489236, 0.0028110923598212026, 0.0031477682523149125,
  0.003158248985458497, 0.0034332902063851124, 0.003246722057714039,
  0.0032498591467745937, 0.0032579810267724598, 0.003099766983724898,
  0.0032319834588871027, 0.0033483060880534392, 0.003540485153696514,
  0.0035462509382567873, 0.003906598948146407, 0.003962480556459036,
  0.004237257490490788, 0.004666679327731116, 0.00434696790682104,
  0.0044971990242604586, 0.004682913227534865, 0.004552425496369786,
  0.004381336894522225, 0.004130568332530685, 0.004033917702107353,
  0.004075995974014707, 0.004252897149906652, 0.004283103587927162,
  0.004599157411825996, 0.004580631815819175, 0.004548522872148294,
  0.0045056249052653, 0.0044006419666221845, 0.004408021269903993,
  0.004118663860768822, 0.004298536057593416, 0.004203864227281838,
  0.00409008533233527, 0.003989857863977657, 0.003961101158806435,
  0.00394388713293446, 0.0038725996580656753, 0.003585078290341126,
  0.0035252010420631216, 0.003766376385505737, 0.003807290740318752,
  0.003706717040338197, 0.004237496684562337, 0.004216934136591697,
  0.003921365601909298, 0.003957300491023356, 0.003795573456947534,
  0.0035776699846044023, 0.0034946748697232367, 0.0032295841531380194,
  0.00323979128366565, 0.003217186529462029, 0.003170595581963824,
  0.0031856019720118475, 0.003424484091740818, 0.0031434154762073683,
  0.0030105149872005216, 0.0029196386098288138, 0.00291247764885572,
  0.003017170565716503, 0.002892289459234829, 0.0035065143557993905,
  0.0038260570786620134, 0.0035968733977555494, 0.0034832500384067567,
  0.003160097514614204, 0.0034719871990417477, 0.0034870744607243343,
  0.0034442209471118855, 0.003399958783115035, 0.00342842740767406,
  0.0035042576883596668, 0.0031681693100539267, 0.0030291101452027563,
  0.0029315451076062345, 0.003187428467608281, 0.003217010389123853,
  0.0032246593388482155, 0.003039079805421645, 0.0029516630564729975,
  0.003317396353718367, 0.0029389131061240666, 0.002526592758220082,
  0.002394092362015179, 0.002508083645747376, 0.002734676449932864,
  0.002509980970207268, 0.0025574105322567486, 0.0026208749731118173,
  0.0027039520674419964, 0.002498536158188743, 0.002674477283265332,
  0.0023816259941202593, 0.002081756477082071, 0.0022610371580056602,
  0.0023678115633805315, 0.0023799312691424332, 0.0029266061786847263,
  0.0030465122803160468, 0.0030191307958095355, 0.003138136593715241,
  0.0031709012354387044, 0.0032771319576696866, 0.0028976003982930415,
  0.002891987784405094, 0.0027925404313294635, 0.002783084785920554,
  0.0028614717672132187, 0.002684787937835344, 0.002702603853412305,
  0.0027825120056791377, 0.002703877046153165, 0.0029769551156833313,
  0.003093556003608351, 0.003139549126453192, 0.002825165894289403,
  0.002804175623644796, 0.0027098910796552883, 0.002636229693975744,
  0.0022909787229744975, 0.002473501163917368, 0.0025779062190966332,
  0.0026833226875291384, 0.002469370043030919, 0.0024468642897603273,
  0.002508353477426977, 0.002135515859920406, 0.0023521509799023367,
  0.0024603050903847915, 0.0024819060846114913, 0.0023014053031019865,
  0.0020348056466013625, 0.0018218342976858044, 0.0017944222992510904,
  0.0018689830208933323, 0.00237828254106768, 0.0023358549708586626,
  0.0025884034314899522, 0.0028918982045586894, 0.003020534051311528,
  0.003324878346038922, 0.003333869082205019, 0.0032706929911829503,
  0.0033824131766867173, 0.003482917671170942, 0.003444445065227062,
  0.0033058044171860447, 0.003459709915189821, 0.003446354119989881,
  0.003408149373651201, 0.003622843096552747, 0.003449046281254364,
  0.003779373203568097, 0.0038974066014555403, 0.004258386624784765,
  0.004238279457286673, 0.00463481526371314, 0.004136737070814685,
  0.004426515598674661, 0.004605681598201062, 0.004458792718653985,
  0.004367803821445179, 0.004282233521190742, 0.0041096082667599135,
  0.0036634025279929337, 0.0037551287101577485, 0.0038606694107183484,
  0.0040806712049727386, 0.004292496319493101, 0.004621847536618713,
  0.004818891018732831, 0.004586009223654569, 0.004326136656906047,
  0.00463201452835352, 0.004704999111095208, 0.004412243914912033,
  0.004071526962771169, 0.004078427748788812, 0.004090436119046576,
  0.004543846018938027, 0.0042744192721321, 0.0042023355936430615,
  0.004339552486059779, 0.003811215879579522, 0.0035886112073137185,
  0.0036889312999947204, 0.003644042356475094, 0.0037998537902965497,
  0.003630558214437461, 0.003634197054912825, 0.00328265505014824,
  0.0035099842974845594, 0.003916647536836171, 0.003702988884949392,
  0.003914521499711185, 0.004066336297869852, 0.003899257823003835,
  0.0036486728461941264, 0.0034718085551234137, 0.003378190887421185,
  0.0035073984726902547, 0.0038453886686397013, 0.003795688710228645,
  0.0037845549821114107, 0.003684309920431599, 0.0036709753117486342,
  0.003448994768605651, 0.003095253043674532, 0.003225930927082262,
  0.003413714247495786, 0.0037667430803536234, 0.003656893518244338,
  0.003396999866341109, 0.003529488009214485, 0.0036114034021796438,
  0.003376841890762048, 0.003074024437372273, 0.00306614073814797,
  0.002895657994880519, 0.003062015858402945, 0.0029627641410345613,
  0.0027270479894965748, 0.0029045807880980126, 0.0028312593889919394,
  0.002946557745310216, 0.0030158045823535495, 0.003012548637260506,
  0.002808423135409949, 0.0025895952061235333, 0.002828199331465711,
  0.00238436065384451, 0.0026075634374814333, 0.0024722921195442383,
  0.0024338769855277373, 0.002349036081157631, 0.0023320951823570615,
  0.0026541298950282705, 0.0028065064021399726, 0.0028392189421129785,
  0.0028778080580160376, 0.0029147637259213984, 0.002734079425174554,
  0.0027879996049781897, 0.0028728811655228123, 0.0025067347049242948,
  0.002428382136506984, 0.002406611514980944, 0.002677184886102575,
  0.002690309007582805, 0.002574175949658372, 0.002508282695781809,
  0.0026518100343794703, 0.0032153140609988647, 0.0036004060579586947,
  0.003592146188424951, 0.0037378296546882396, 0.0034490557699350987,
  0.003507551863139276, 0.0032773482140252635, 0.0031834783840897995,
  0.0032952016725921076, 0.0032098892186821814, 0.003205791427999702,
  0.0032444145414385252, 0.0032382517982745065, 0.00309206842061052,
  0.003229498325147292, 0.003347974074383331, 0.003119467892682729,
  0.003333920348061659, 0.0030073549515485416, 0.0032119692173484993,
  0.0035436983502627195, 0.003505743568646125, 0.0033158695746038484,
  0.0033420107380404958, 0.0035306235003260016, 0.0035171175897941377,
  0.0035643459548088865, 0.003837533145650563, 0.0040613143441340675,
  0.004066717258760346, 0.004153250132278245, 0.004232591605204979,
  0.004054763146288672, 0.003789951382655704, 0.00396534146060873,
  0.003974394731852463, 0.004107565437695469, 0.003994772766605891,
  0.0038075707991003815, 0.0038408795716176848, 0.0036843198301211104,
  0.0034739593263549183, 0.0033455331955210338, 0.00332551277803571,
  0.003298281568281297, 0.0032820075576719065, 0.0034781702360986238,
  0.0037055042041351078, 0.0035413381051544036, 0.0037638496171304202,
  0.003878789762204632, 0.004227956518119941, 0.004222433162958246,
  0.004449964321459677, 0.004327043039373903, 0.004771868000685455,
  0.004731046447401191, 0.004538894627291876, 0.004489529199279776,
  0.004293577904187251, 0.004278176729350981, 0.004084915475466036,
  0.003925107354852662, 0.004065948791241043, 0.004415397204742801,
  0.00417832904850926, 0.004058805688409008, 0.003903792721505017,
  0.003935427232337893, 0.004127183323377938, 0.003929080465874711,
  0.003320352307478303, 0.003372348823177423, 0.0037511515937331407,
  0.0037324443793262294, 0.0036278772654359413, 0.0031756734842141813,
  0.0036049923977756887, 0.0039226019357881245, 0.0038388845943365473,
  0.004094547370575017, 0.003826748128325385, 0.0038131229047373917,
  0.0036931083002811217, 0.0036100947445802845, 0.003079916949336581,
  0.003338362639621664, 0.0030488086463165776, 0.0026557979325604357,
  0.0025355676295763965, 0.0022584773024641384, 0.002378435268530923,
  0.0026128653165844802, 0.0025356661033494716, 0.002147942125252657,
  0.0021412156589709934, 0.0016712703325240854, 0.001557801054798282,
  0.001844084821814459, 0.0015949656195530402, 0.0018145282697986608,
  0.0014500613060449297, 0.0018917800239508357, 0.001532623668192293,
  0.0014844707772846725, 0.0013280628166027666, 0.0017486436882562487,
  0.001575413841018225, 0.0015521364207063371, 0.0014732859523231279,
  0.001487208686512584, 0.00117693146425751, 0.0013199175690502194,
  0.0014684863871974201, 0.0015508985292992548, 0.001730902540025441,
  0.001593563238476507, 0.0017033241597698267, 0.0017313065074579183,
  0.0018298614265929714, 0.0018027653300784344, 0.001971402955623227,
  0.0022279118611691017, 0.0024907279428696195, 0.002598353761623672,
  0.0025526597666279504, 0.0026214493904963844, 0.002827587208390145,
  0.0029163148194702817, 0.002727847261447359, 0.0026279779214195263,
  0.0028315532057958163, 0.003031044369096794, 0.0027675625022758003,
  0.002771111912225462, 0.0028767479361249152, 0.002972981557914188,
  0.003158041916937842, 0.0029135370010838547, 0.0031350978700672156,
  0.002858934325344218, 0.0029921289268551882, 0.0028517737572172058,
  0.0027430727115687276, 0.002753588486965281, 0.0030267361061051437,
  0.0030485801718241985, 0.003097057588892148, 0.00313599231590345,
  0.003106757079972856, 0.0029228422683946257, 0.002998290796518544,
  0.0032776413733127414, 0.003359533166211472, 0.0033414919924623816,
  0.0034335383213773022, 0.0037620618295356443, 0.0036837364709336254,
  0.0034747751716199143, 0.003382097183290731, 0.0031470222300792106,
  0.00347399917257692, 0.0034493544500836765, 0.0031292942986713176,
  0.0028719350325305468, 0.0029821606023968275, 0.002959668613165211,
  0.00291627611210562, 0.0030401720347923763, 0.0032586821775496475,
  0.0032204899446335975, 0.003700094391934713, 0.003548712800568857,
  0.0034384249869244673, 0.0032809520658556176, 0.00318430910925378,
  0.0032310740559093245, 0.0029076050171862504, 0.002813613003307009,
  0.0026798149959909277, 0.0026413810352147876, 0.002382510240158311,
  0.0026773575378203113, 0.0028370250409636617, 0.0028988208686573608,
  0.0032554156136950247, 0.0032037581354040666, 0.0033587159794814594,
  0.0035015882765939586, 0.0032506385140488414, 0.0033758997146946883,
  0.003294030675974446, 0.0033142699801206646, 0.0035309106553947154,
  0.003431141556603292, 0.004026624373270648, 0.0038902283046647317,
  0.003828836966434172, 0.0035117281136893244, 0.0032987156717427853,
  0.0033087860359902114, 0.003326418850723526, 0.0034806171433874574,
  0.0032858714249435197, 0.0033426140914205194, 0.00314624363232287,
  0.003530325345887317, 0.0033031226473794325, 0.0031884045917909666,
  0.0032605189605225, 0.002935404867779884, 0.0029143058553097283,
  0.003185423858333454, 0.0030012050203015905, 0.0032122374510350983,
  0.0029692759258983657, 0.0027494045275426003, 0.0023920318532502636,
  0.002479261944325977, 0.0025241964541413125, 0.002878980546044281,
  0.0027684580936752677, 0.0029413638954678433, 0.003059398709330769,
  0.003012922188146001, 0.003087105710377534, 0.0030526317482438762,
  0.0031519703023544267, 0.0033203995516979906, 0.0033150845447160317,
  0.0031563953559141226, 0.0032689283524362346, 0.0037649187362157454,
  0.004140134704697472, 0.003881197421112495, 0.003867659540975868,
  0.004007031732282002, 0.003986188504964874, 0.004145194736706934,
  0.0039548261227922875, 0.004267239770872421, 0.00395158148429694,
  0.00391630635919615, 0.0037813674168044515, 0.0038562947585322783,
  0.003661041765630715, 0.0038897018954941044, 0.004019379590718827,
  0.004185870126540633, 0.004148640201331556, 0.004470539689810876,
  0.00489487045251609, 0.004970805976033044, 0.005007905285372792,
  0.005008660253700179, 0.005136300104908413, 0.005256180842676351,
  0.005253530102593949, 0.005450979997145383, 0.005548134588889824,
  0.005635027965955534, 0.00572422180482689, 0.005745441921585521,
  0.005746016820061481, 0.006024665449110153, 0.005947718700018672,
  0.006059847525187806, 0.005952968732589618, 0.005948227429598305,
  0.005905344194791869, 0.006099712011074584, 0.00616851141141067,
  0.0060406075744515765, 0.0058154273922495486, 0.005577384183020668,
  0.005569502429582943, 0.005490864195513303, 0.005537653941475597,
  0.0052429347874700894, 0.005067430427923693, 0.005030478880394514,
  0.005207121925086137, 0.0053303036807691604, 0.005340643650762377,
  0.005131111842696997, 0.005094949814147563, 0.005073816823585279,
  0.005174260903755738, 0.0049037571744323, 0.005238475382340779,
  0.005111927631485908, 0.005169642623400258, 0.005262211602877411,
  0.005147078850301798, 0.0050849202357273905, 0.004946478893270192,
  0.005041925108281739, 0.004556233871019674, 0.004548628935249123,
  0.003943268396192561, 0.00393500168315551, 0.004025831268937365,
  0.003782488247280219, 0.003453213914403018, 0.003438664917710654,
  0.003323079783580474, 0.003449340153550804, 0.003363485366215522,
  0.003589747854068008, 0.0036661239182511525, 0.00346897823469913,
  0.0037944541134083505, 0.0038469054253461427, 0.003730263234897657,
  0.003976859586998104, 0.004113928924201521, 0.00437151802018481,
  0.0042884292862198, 0.004440944741320405, 0.004306628962173811,
  0.004396507918209663, 0.004615178894576277, 0.0048087523562091875,
  0.004797677684186244, 0.004827211382306456, 0.004752763083819488,
  0.004672042111238199, 0.004684217824859282, 0.004475677410111347,
  0.004666930340278063, 0.0046579391277379555, 0.004593964423346793,
  0.005001473416989971, 0.0051009143388035996, 0.0051447001887001235,
  0.004848792309616357, 0.0049329044325283926, 0.0051062856998642,
  0.004968845959574888, 0.0050002525683122585, 0.0051315195681023405,
  0.005273550590637626, 0.005180076029408328, 0.005282068545400798,
  0.005136218050094794, 0.005175324498271601, 0.005328948857570165,
  0.00540876206098013, 0.005362774594165751, 0.005349457400784144,
  0.005201673734119466, 0.005339199756698327, 0.005505733242573444,
  0.005537321918165369, 0.005471780274047041, 0.005511530949277827,
  0.0054738827882409745, 0.005353395759876992, 0.004957611590266448,
  0.004750044634182, 0.004601245832616251, 0.004701091107631232,
  0.00457637721026948, 0.004392812113363341, 0.004674145085630158,
  0.004566420198591084, 0.004359111031408162, 0.0041928274642641485,
  0.004274201997640761, 0.004220257034552852, 0.0043353629340179695,
  0.004347072053928535, 0.0040909345609816865, 0.0039021631489471443,
  0.003977123845248182, 0.004417236498578009, 0.0044513643207132894,
  0.004594715807635175, 0.004636082370000586, 0.004423473470735652,
  0.0042885951280506596, 0.0043773790496978065, 0.004361899881029864,
  0.0043487757789916455, 0.004434869118973244, 0.004267069811135578,
  0.0042078985107358805, 0.004221629942550661, 0.0043853130687914275,
  0.004350635714672195, 0.0043868558060765565, 0.004700884743777744,
  0.004695851003918481, 0.0046131686198400785, 0.004251955957174621,
  0.004350368770597845, 0.004127448885292421, 0.004008262711608308,
  0.0038279405895600446, 0.003558386548276538, 0.0030438297856443053,
  0.0029821258120997225, 0.003136080730756565, 0.002912101585793678,
  0.0029075896726630506, 0.0028517575440192284, 0.0028858062653194234,
  0.0028353704386582528, 0.0027527561482171645, 0.0026981811974945364,
  0.0027236379308100953, 0.002615042827237354, 0.0027339294822564955,
  0.0030278975193099994, 0.002927621766692946, 0.002925052148598083,
  0.0030753992700381055, 0.002920614691462266, 0.0029581661838365245,
  0.002974200970096627, 0.0030955680459105765, 0.0029605538414041173,
  0.0029548693010537216, 0.0027266742201418714, 0.002570492317847297,
  0.002606446432694683, 0.002474315409435283, 0.0025763265058604914,
  0.002469504033386419, 0.0026090709863863465, 0.0027333715590246196,
  0.0024639023839349403, 0.0021981565512345716, 0.0021078856602142282,
  0.0018555948125028318, 0.0018425469460673248, 0.0016934838709694439,
  0.0017197895736385852, 0.0017018858449153863, 0.0013608975456702683,
  0.0013212942007186882, 0.0016458989397073454, 0.0018819923182539864,
  0.0016385216238920916, 0.001412709707535978, 0.00137298576515005,
  0.0014116278817945802, 0.0012803837605173175, 0.0012603300746745429,
  0.001282102588534556, 0.0014686136325587228, 0.0016002534015477346,
  0.0016577389228329222, 0.0018440152381593643, 0.001538799301065315,
  0.0016355835567271146, 0.0015307448223959462, 0.0014889620419093776,
  0.0014656642379471585, 0.0013632823012871657, 0.0014806480636364514,
  0.0016677797919010843, 0.0017367526138761006, 0.0015615529528922175,
  0.0016131450633386337, 0.0018341543822027846, 0.002018271259770427,
  0.0024553022718417075, 0.0021048306734932384, 0.002012412426478757,
  0.002275849487685484, 0.002201400387684505, 0.002388341918849906,
  0.0024935542677858506, 0.0027348259555079104, 0.002663262489870505,
  0.002594350072780809, 0.002481355973366881, 0.0024383385782607275,
  0.002232111097282121, 0.0024244360903471884, 0.0029463839987309472,
  0.0027510008347589186, 0.002219658408036594, 0.0020669550617404297,
  0.002477253688828795, 0.0026580280009856645, 0.0025576244413597388,
  0.002533097515544137, 0.002613648324059531, 0.0025603232830475947,
  0.0025567288106594904, 0.0025997707512336477, 0.002762738378527006,
  0.0027312552626674287, 0.002817747051382819, 0.003147820977286735,
  0.0031525518794367825, 0.0030026528730320674, 0.003001330800774582,
  0.0029624781220307125, 0.003066020043217651, 0.0032572021577694506,
  0.0035872436714618912, 0.0036798066370360974, 0.0035599832923521174,
  0.0036718391359494094, 0.003690810612270621, 0.0038287845054318626,
  0.003713345589903119, 0.003794070363149853, 0.0037303834417748544,
  0.003783488499590249, 0.0039043661740304873, 0.0037903036675357993,
  0.003707494605403595, 0.003718800083958677, 0.003849074403988371,
  0.0039303646210251285, 0.004166613053907955, 0.0038937250508502023,
  0.0040129801794682035, 0.004434304554853029, 0.004202024831975495,
  0.00419340568140266, 0.004273122834690308, 0.004310241882386706,
  0.0045417882902059675, 0.004668405815160409, 0.0046717834026532385,
  0.004549656346337079, 0.004451696369549281, 0.0047417937672026185,
  0.004878013854576728, 0.004784857685864261, 0.0048771955006863925,
  0.004971304235073877, 0.005162959180697719, 0.005174417380750401,
  0.005061408521338317, 0.00482938086046008, 0.004984289091563748,
  0.005003646243711803, 0.005139587058551801, 0.004926795127373981,
  0.00490798805843367, 0.005100796528128496, 0.005175442255798901,
  0.005237313223576187, 0.005034520897195306, 0.005275380334661897,
  0.004903372294295795, 0.0051099450004337505, 0.0050915079548229355,
  0.005185841427233108, 0.005307861411175664, 0.0050221061635543655,
  0.004985986206891455, 0.004855107382970008, 0.0051234395233175686,
  0.004994724035405784, 0.005148589986168263, 0.005365949763004459,
  0.00509773236210116, 0.005036420686625802, 0.005357488030259764,
  0.005390489798046043, 0.005292252664379243, 0.0051900248353442665,
  0.0051355761416237876, 0.005064944220957001, 0.0049424658753744995,
  0.005200750930362952, 0.005147705231655587, 0.00528537283414459,
  0.005486884987108698, 0.005674735551740116, 0.00574360064135205,
  0.0059575851739396895, 0.005918439084326585, 0.005694408609922681,
  0.0057500500654826744, 0.005631051626695204, 0.005976884746024541,
  0.00604643059035394, 0.006115160947399355, 0.0062136312828543405,
  0.0058240799459163784, 0.005694570178666514, 0.005577961675766161,
  0.005716520164625622, 0.005434276018040021, 0.005489219009299905,
  0.00560803571274989, 0.005422102866125912, 0.00518264079756999,
  0.004975637959497817, 0.004700864077688887, 0.004630960060457746,
  0.004766505632050868, 0.004681822025501558, 0.004315882970994746,
  0.004412903733119585, 0.004271477148689131, 0.004274242659799793,
  0.004160949971617449, 0.004326370278217301, 0.00442540639781379,
  0.00434958038222999, 0.004586627679079828, 0.004504526268675693,
  0.004350799150427036, 0.004334173113595657, 0.004395604704862679,
  0.004579337256623286, 0.004541747893995651, 0.004892166536429877,
  0.005024151798582779, 0.004870906924547701, 0.004779604079148447,
  0.0049557959709315555, 0.0051551722643710385, 0.005071049753812402,
  0.005179967970946378, 0.005303819205439479, 0.0052824428452129405,
  0.005223947497302038, 0.005101850842385417, 0.004864369257789325,
  0.004684694624805591, 0.004296466182288092, 0.0044095300946202,
  0.004472221866539484, 0.0045258934228851345, 0.00473030297631368,
  0.00457796479564529, 0.004474548333214384, 0.0046648285938472155,
  0.004320825875086585, 0.004348290124276243, 0.0043982497684371125,
  0.004564958098826835, 0.0047301167643237895, 0.004466720397259799,
  0.004769893162848911, 0.004997924770498848, 0.0047048706929664575,
  0.004757006935487324, 0.004621817671933873, 0.004701686925502057,
  0.004548805285343337, 0.004315970378143939, 0.004113046064157795,
  0.00407593623926948, 0.004085081519499916, 0.004295995257814355,
  0.004539942556022644, 0.004425559671163866, 0.004497445586007711,
  0.004494052546327204, 0.004273411063620938, 0.004505149982078183,
  0.0045877056987448615, 0.004564667223957716, 0.0049518398604103744,
  0.004930327800665426, 0.004806420646165243, 0.004866958389241817,
  0.004926327191403273, 0.0049618200217141015, 0.00516281907824542,
  0.005282296718187458, 0.005503915674278229, 0.005953663460616036,
  0.005471123175413152, 0.0053227427690312745, 0.005476722422754727,
  0.005702720109692308, 0.005648463776354408, 0.005626852951735926,
  0.005575133201498869, 0.00547732559295523, 0.005321764645145814,
  0.00524660452991282, 0.004957700398219218, 0.0048252118344742564,
  0.004603090729824388, 0.004257491569401048, 0.004188377634945156,
  0.003994201076061154, 0.004225538591774786, 0.004213648350932774,
  0.0041200198471568195, 0.004127324606652121, 0.004160006945522456,
  0.0041578047670664834, 0.004264848862368434, 0.00448348208815411,
  0.004316436132736115, 0.004172638432720329, 0.004213147141315375,
  0.004193423469823461, 0.004046258091422568, 0.004065943397637282,
  0.003701444077918259, 0.003979096631242079, 0.004152553971088768,
  0.004000571117275711, 0.0039861489454600715, 0.0038193132665963452,
  0.003891408813197169, 0.003765288166710189, 0.003728371323264608,
  0.0038747351976767866, 0.003815492908044657, 0.004090761430731598,
  0.004115669756102419, 0.00405108063960713, 0.004103509646592753,
  0.004039811477764139, 0.0038544711119884345, 0.0037992600960699827,
  0.003742903876072607, 0.0036298163068157405, 0.003869691070644391,
  0.004003118203253656, 0.004120243651239719, 0.004174806699350986,
  0.004496399345973311, 0.0045608817254159315, 0.004576256770460601,
  0.004426234231502879, 0.004418517477982534, 0.004332903551479754,
  0.004381666787976959, 0.004506310250611125, 0.004537404234180674,
  0.004564295615922622, 0.004819518119235818, 0.004796121139929796,
  0.004670536568887239, 0.004725790663912194, 0.004929738129919419,
  0.00449598848704045, 0.004304416499544386, 0.004248605947382598,
  0.004518229771847923, 0.004761474296578085, 0.004931315596135232,
  0.005447158129841514, 0.005815589362794034, 0.005651484523300627,
  0.005382694195019142, 0.00572740800042416, 0.005894936601708236,
  0.005800120132815725, 0.005932963124918906, 0.005600586677144693,
  0.005404598550169237, 0.00541578878779073, 0.00546388961680174,
  0.0055998083876483045, 0.005513192583175395, 0.005696327836820162,
  0.005625997614848096, 0.0056969401396834785, 0.005239504964742925,
  0.005212092026512705, 0.005433737842851072, 0.005508912350169591,
  0.0051498653082868275, 0.005116421928562387, 0.005137755844737303,
  0.004700574850842969, 0.00452770662901672, 0.004225063620089492,
  0.004058002435347477, 0.00402313859387923, 0.004048875690228604,
  0.003922770499150314, 0.0038869725936315253, 0.0035491007276157766,
  0.0034498912367160813, 0.0036527809090330585, 0.003652431273047979,
  0.0038748587702004417, 0.004046286575089812, 0.003695377821669945,
  0.003639490962228014, 0.003634809760170025, 0.003839543940714794,
  0.003674918361020131, 0.0037898162064071315, 0.0039070873754189946,
  0.0039329784037351505, 0.003679814303247933, 0.003805141258435425,
  0.003756610949489058, 0.0036537045858607746, 0.003608026004234456,
  0.0034408354629565504, 0.0032757606675448306, 0.003518025957373901,
  0.0036369232200761338, 0.003783565432257884, 0.0035491932177510776,
  0.0038333627432126384, 0.003915282582314531, 0.00367097310238078,
  0.003720721023778587, 0.0038500197275008995, 0.0036575425173489004,
  0.0034998237661570553, 0.003701803458988513, 0.0034991132535599693,
  0.0033113469852903725, 0.0033382031480095127, 0.003467335673485947,
  0.003259197570596602, 0.003377955981138869, 0.003275640602950793,
  0.003534411199625491, 0.00359483150054295, 0.0037457099324669646,
  0.003624732534048636, 0.0036558477457886815, 0.0036852095464953135,
  0.003973764091043691, 0.003908307553810659, 0.004060608889971668,
  0.00448336421371711, 0.004420082236356422, 0.004416480338465626,
  0.004318104975332189, 0.00424430962485896, 0.004186557299630977,
  0.004348683916373386, 0.004326213327046421, 0.004471413320336191,
  0.004301837492193837, 0.004448471516225679, 0.00413916284843115,
  0.004009796061305059, 0.004052722961243687, 0.00438957841158958,
  0.004503919760820878, 0.004627497811309182, 0.004301306992353883,
  0.00436519971933456, 0.004661546930653112, 0.004758067873578306,
  0.004811437411051191, 0.004874534609366085, 0.004796028213644488,
  0.004628468925056409, 0.004508724337950128, 0.004542619229282704,
  0.004953324302731466, 0.004866280596124561, 0.004871403201933627,
  0.004864300090040835, 0.005000520307796002, 0.004752843645761039,
  0.00476508972701269, 0.004761412528713529, 0.00460066161781535,
  0.004732999187147233, 0.0046731505442531565, 0.004288256666654182,
  0.0043066941485156805, 0.0044927792360714425, 0.004290549771907533,
  0.0045502792362451215, 0.004466776525140451, 0.004601576823951272,
  0.004764979374591191, 0.004852188317862066, 0.004703429197111237,
  0.004803330696635623, 0.004767146079421629, 0.004592510970844913,
  0.004507857990615534, 0.004565018188449486, 0.004513788513141005,
  0.004505514363315883, 0.004368759255038594, 0.004218534010253129,
  0.004006461008438416, 0.0039571099318625675, 0.0039027100309629577,
  0.0038489760903476127, 0.003990507461520576, 0.003923688730965903,
  0.003918742603261061, 0.003677049769306448, 0.0033346291370424308,
  0.003335604781698069, 0.00337562559556263, 0.0030930781463428376,
  0.0027737091292981105, 0.002781492499431209, 0.002838587287401061,
  0.0027238069016493286, 0.0028435001756083713, 0.003015600494859912,
  0.0032892296925960524, 0.0032638142227236705, 0.0031455646729085032,
  0.002988513961869979, 0.0030274628302178106, 0.003369511467349903,
  0.003507245072936156, 0.0036063835957944345, 0.0032626618492242747,
  0.0030806819708093193, 0.0028629347596816884, 0.0026937885129939276,
  0.0029854791163831346, 0.002653260993903106, 0.0026707127343304888,
  0.00278069527502769, 0.002827231579159769, 0.0027574730288881036,
  0.002661437949596227, 0.0025606279329908567, 0.002559566585004431,
  0.0022474342851783497, 0.002388900816085836, 0.0024654838354058327,
  0.0025777714266067933, 0.0023653691445194272, 0.0022576261521671235,
  0.00202037444316486, 0.0019147857024685135, 0.00192292136769846,
  0.001900602934173934, 0.0018434142897201927, 0.001829728825518532,
  0.0018680255680201283, 0.0018137789886572794, 0.0015043682254096233,
  0.001318267044199311, 0.0011110022299461917, 0.0011635985446956188,
  0.0011530401389577261, 0.0015044084589353782, 0.001412727986897707,
  0.0015205397658933054, 0.0017185130796385504, 0.0018568608430764573,
  0.0021674990590155826, 0.0021389013668663788, 0.0019957960810374886,
  0.00242148814950217, 0.002409787309245405, 0.002220583476604924,
  0.0020665875477814564, 0.0019995928698125385, 0.0021288225872373844,
  0.001934567499668022, 0.0021999601415324706, 0.002117805104968591,
  0.002049988561621851, 0.0018826621690151346, 0.0019337818900489229,
  0.0015234706302833703, 0.0015561714832452098, 0.0015929899906748258,
  0.001730943544602452, 0.0019186415871417458, 0.0020706209631387858,
  0.002408288173172255, 0.002549033486159717, 0.0025768431867307325,
  0.002478519583152879, 0.0022075022974480303, 0.002359059472039899,
  0.002323340507606944, 0.0023970265397719836, 0.0024639498414502073,
  0.0028657296639925302, 0.0027813425413729044, 0.0028929748241013557,
  0.002845722866642671, 0.002900616014012239, 0.0031124287574685904,
  0.0030302455052906374, 0.0030043681352551316, 0.0030213388526809364,
  0.00311319378262071, 0.003195255430756163, 0.003117605086855961,
  0.0032615591567267785, 0.0032166431308020796, 0.0031255008416581197,
  0.0029762625008831916, 0.0031155195546598087, 0.003211985730623312,
  0.002928309537066407, 0.00305216951527677, 0.0031683351432983536,
  0.003245025228692287, 0.0032849712986220175, 0.003210981297583821,
  0.003151639070580451, 0.0030277386923514846, 0.00277815861701352,
  0.002916223022601756, 0.002812776202938442, 0.002713132643297374,
  0.002959055533888529, 0.003033951451901072, 0.0030630534503015595,
  0.0032804328380500927, 0.0032754607895618426, 0.003261328695543231,
  0.0034501054839787323, 0.0037165511858757105, 0.0035145603450801094,
  0.0032649304729864036, 0.003276229609394518, 0.0032989749142337105,
  0.003374018131551281, 0.0030557981699383114, 0.002964945254589961,
  0.003145080675542201, 0.003168198700157052, 0.0030132228659116235,
  0.0030307266004501337, 0.0026976885560451584, 0.0027449945151017817,
  0.0028157361007584004, 0.003021673540324447, 0.003394146463119097,
  0.003506054866289584, 0.00360310735962279, 0.003555652828987494,
  0.003628436695242975, 0.0036980288225760692, 0.003741982834429121,
  0.003827236108200231, 0.0039850306906094165, 0.00401644431473144,
  0.004241105239940271, 0.0042094094048542975, 0.004177903259834941,
  0.004131996703883774, 0.004278748521789518, 0.004383861674191078,
  0.004536384367166123, 0.00462872081006784, 0.004569970800095168,
  0.00446682028562584, 0.0045601051824624905, 0.004596482963786103,
  0.004618990643163835, 0.0048242535778985195, 0.004681139701281444,
  0.0049148358979893, 0.00499424204693998, 0.004913722990843837,
  0.004849581974005711, 0.0045806259426573485, 0.004434878272862764,
  0.0044995489154902885, 0.004510513185077269, 0.0044678084377041795,
  0.004222004858990469, 0.004129579819056029, 0.0042509121747578114,
  0.0042565589240298515, 0.004377009347243926, 0.004229947365395821,
  0.004066601457707727, 0.003851811514695413, 0.003412529021131227,
  0.0033124122709059906, 0.003579814862139779, 0.003391917570001234,
  0.003272333271952438, 0.0033702179849651394, 0.0034650691755155087,
  0.0031926736289277042, 0.0031360159017422232, 0.0030231909013989423,
  0.0030565771265214188, 0.0031094905807341074, 0.0033606569025422172,
  0.0035203309068041638, 0.0036933308418150854, 0.0037705012287225876,
  0.00330135059229978, 0.0032915494306060217, 0.003132292649415931,
  0.0032662461260436777, 0.00330675544881454, 0.0035555207183265167,
  0.00335122224820629, 0.0032813426238004644, 0.0032930927449445727,
  0.0034452795601927575, 0.003342030755604286, 0.003410864428842368,
  0.0033027987053954456, 0.0030565176199889217, 0.0033777070808061562,
  0.0033280227280442594, 0.0031571981916085306, 0.002827838102636635,
  0.002851809515107777, 0.002963457023920836, 0.0031702891942081844,
  0.003335003057216242, 0.0034079958866876834, 0.0033947294457600144,
  0.003207276593241749, 0.0030385905977971792, 0.0029612131849455696,
  0.0032685746325483538, 0.003137375057998888, 0.003199961496479179,
  0.003153033613498367, 0.0032290731349679113, 0.0033047806389200143,
  0.0032699055942673463, 0.0032733848413692814, 0.0034380770154035577,
  0.0037243263873947344, 0.0038883900234860606, 0.004138992900275698,
  0.0038623173851326286, 0.0040793770002924955, 0.004227628121805666,
  0.004108171133411137, 0.004100347335762366, 0.004489737754685783,
  0.0043295574035557595, 0.004337955732713481, 0.004667396627003935,
  0.004732674712197316, 0.004623857101896644, 0.004541518196411025,
  0.004701131472027218, 0.00468982944358802, 0.004713625377553231,
  0.004681415094444715, 0.004565909974236148, 0.004412970148058813,
  0.004561931207680602, 0.004393091177007928, 0.004385967494031376,
  0.00422241892174902, 0.004534509627714516, 0.004533832567227034,
  0.004544280442121964, 0.004585023964000092, 0.004809952224941411,
  0.004935053857946329, 0.00469272922393469, 0.004530281834692121,
  0.0047037377828468485, 0.004572982863065886, 0.004139273485276433,
  0.0036771927526083716, 0.003791075139863664, 0.003957867302136153,
  0.003823005571390349, 0.0038458159510223666, 0.003764620330621528,
  0.0037123794369614176, 0.0035666294051752744, 0.003701469582524742,
  0.0036345690186177287, 0.003620448180332138, 0.003566377725003915,
  0.003600493676995246, 0.0037114724568820243, 0.0039719880415527405,
  0.003948627238575545, 0.003592467255360711, 0.003501606165231191,
  0.0035528466255124276, 0.0034985452311007818, 0.0037589173719511887,
  0.0037984082725984513, 0.003730687077988376, 0.003719406824510394,
  0.003808440681640373, 0.003585228543955963, 0.003743432893679448,
  0.003557719893856308, 0.003515567575994637, 0.0034766453519269875,
  0.0033834952253585394, 0.0032806783572269333, 0.0033849771788913192,
  0.0034054948149956567, 0.0032957995723031748, 0.003389269876337251,
  0.0031089204161611067, 0.0029091072571780377, 0.003093241006814248,
  0.0033851205190207063, 0.00325291822061557, 0.0032095348980729576,
  0.0031185995148999344, 0.0032722565494869103, 0.0034698805433552027,
  0.0037738401621082393, 0.0038171264134399187, 0.0038061284799389187,
  0.003780526287570692, 0.0039840934373489645, 0.003952042519212241,
  0.0039989166735637585, 0.003942616254462486, 0.003980226257341975,
  0.003991894134994223, 0.004308265120297302, 0.0043868791183651855,
  0.004365398891837345, 0.004140375050182134, 0.003911596354629154,
  0.003681548457401792, 0.0036818958857366573, 0.0036926612791638386,
  0.0033907823826339356, 0.0031945419087310812, 0.0030193257424608205,
  0.0031406799353425026, 0.0031087278863857607, 0.0029109105049814343,
  0.002967632359055203, 0.0026981008279323153, 0.002703034763283233,
  0.0025161795491291656, 0.0025682864854760673, 0.0026076388060135465,
  0.002629307686719984, 0.002629532608614277, 0.0025508706319777833,
  0.0028958047349457784, 0.0029374942943314153, 0.0030980635644445873,
  0.0030759370834798822, 0.0031195329730157946, 0.0032349541953129598,
  0.0034093357424563988, 0.0033303965926948526, 0.0034229447311897535,
  0.003633781785737053, 0.003579236253707841, 0.003703152128695688,
  0.0038578313789222763, 0.004036509672576224, 0.0040333492742431875,
  0.004160352994522627, 0.0041043327010018985, 0.00412748092817132,
  0.0040183306866743845, 0.0039764233626343815, 0.0038503466552981284,
  0.0035918813710103014, 0.003618656471764767, 0.0034235002881327897,
  0.0030745782449875256, 0.0033037138621261715, 0.003306656632231996,
  0.003139312656794563, 0.002995588153796404, 0.00313484118879756,
  0.003007656105292879, 0.0027566910940253707, 0.002835042219688501,
  0.002886911767732482, 0.0027179934989359483, 0.002987611402728001,
  0.003008616837191028, 0.002775268521701237, 0.0026196467554606808,
  0.0025886333623258467, 0.002586236960594406, 0.0025876370138051058,
  0.002534227888184921, 0.002484169172617111, 0.0025990914502340007,
  0.002199476250540849, 0.002027454769037959, 0.0020164889773039573,
  0.0018976201149584733, 0.0020167765802585596, 0.001957901018823409,
  0.0021059909690241117, 0.002253206232692534, 0.002157593925489307,
  0.002198618374607947, 0.002134369788953861, 0.0018926688355436173,
  0.002103269719796184, 0.0018780254747973709, 0.0018252095063194267,
  0.0018937237878840281, 0.001893948884817353, 0.0019012891917805752,
  0.0017972711980728734, 0.0021253933505184057, 0.002450277240974915,
  0.0026371072660405807, 0.002649677266660442, 0.0025773580674289505,
  0.0026279785981389893, 0.0029390830460181163, 0.002764152281551184,
  0.002558113799400001, 0.00268899750285945, 0.002626434754532476,
  0.002752235432190956, 0.0028692621904951214, 0.00267721983306328,
  0.0027774200636916484, 0.0029674889787443407, 0.0030939787411381043,
  0.003258926854484521, 0.0033805677024777016, 0.0033259998960721987,
  0.003146659905953567, 0.00279493429341254, 0.0025093791761847065,
  0.0025355047614244865, 0.002790331973810157, 0.0028394415923907144,
  0.002744143495705247, 0.00263734134188895, 0.0028890013777008753,
  0.0031205299125921363, 0.0032340043042587007, 0.003153253249783134,
  0.0030242091529412976, 0.0031639037832163824, 0.003230558592454959,
  0.00320574405204585, 0.0034678909158174656, 0.0036195441341949626,
  0.003411613978440569, 0.0034203191389863734, 0.0034330625009411425,
  0.003510351454065246, 0.0036060504968305185, 0.003600827142649062,
  0.0037442116614584443, 0.0038716802226655346, 0.0039801830011771755,
  0.004013260842582236, 0.004113860587673634, 0.004353881238980206,
  0.004551213464950598, 0.004635251573664235, 0.00445762795698276,
  0.0046039246967944855, 0.004540593003052601, 0.004292382282118403,
  0.004345082525207148, 0.004049336174710274, 0.003982794150290404,
  0.004079390444922098, 0.00416534645912851, 0.004064584020743237,
  0.004257508312478831, 0.004367039074173002, 0.004348159622929283,
  0.004404446896397317, 0.0045687268214055264, 0.004707853329241088,
  0.004535473254011832, 0.004761647341690872, 0.004811511983860328,
  0.004892107503812248, 0.004855379172034035, 0.00482452026831048,
  0.0047086154962577296, 0.004670234646192986, 0.005007783897665696,
  0.004990686154077684, 0.005337687078207608, 0.005537816659903891,
  0.005418534855446537, 0.005179422929077654, 0.005051151358915936,
  0.005279066922109653, 0.005286695785690915, 0.0051037862162900734,
  0.004907057761932012, 0.005102075437023493, 0.005047785472040939,
  0.004818759610104133, 0.004925326931320361, 0.00526191274674656,
  0.0055125139263749075, 0.00549349393648615, 0.005627541479373243,
  0.0054119192166337815, 0.005177008695594809, 0.005196592670097472,
  0.005263077268748723, 0.00537808836669535, 0.005487604849765637,
  0.005506415060689694, 0.005585356245224866, 0.005415810434099926,
  0.005446689231414982, 0.005602353822960277, 0.0054174564803948135,
  0.005435782742064331, 0.0055429118511545646, 0.0055990989130425125,
  0.005552622364739563, 0.005550457820550087, 0.005617853804547877,
  0.0056106122327502835, 0.0056456872477423665, 0.005679587125969807,
  0.0054161736453012515, 0.005477858913344584, 0.00536459126079327,
  0.005346403788863997, 0.005246215542470244, 0.005355169075005124,
  0.005496133327761145, 0.005480953753053486, 0.005698442538398014,
  0.005662664616380155, 0.005537116992293925, 0.0056010795741210625,
  0.005755395218915731, 0.006390496904069875, 0.006257046231890298,
  0.006093072573818063, 0.005989133513682378, 0.00610326905190007,
  0.0061932432669602215, 0.006161174422544714, 0.006299759680868066,
  0.006388098667192915, 0.006462324778076128, 0.0063008893253695365,
  0.00648960316459794, 0.006548764187250197, 0.006393540170224039,
  0.006440237365291595, 0.006289202756633849, 0.0061556038070035264,
  0.006084523349066563, 0.0059873327512850755, 0.005928862782841629,
  0.00605536418017215, 0.00587125961910466, 0.005948916260460789,
  0.005956188433138031, 0.00594577248966379, 0.006114382728092486,
  0.006211338332519745, 0.006251932335084051, 0.0062813401323727125,
  0.0064730556075277956, 0.0064413079356419305, 0.006517086978561456,
  0.006455578778462815, 0.006284590400010832, 0.006549768340753991,
  0.00657662364520751, 0.006834512090526256, 0.006908129942182466,
  0.006970280732344971, 0.006854380428480974, 0.006877211178022343,
  0.006869197336514791, 0.006746644486800045, 0.006900590376903851,
  0.006921863130699813, 0.006956082467740379, 0.006931091583185556,
  0.007023014647983042, 0.006819068493850561, 0.006551053962651926,
  0.006260435766642987, 0.005860735600665939, 0.005700534054059557,
  0.005811636554760224, 0.005521589207775858, 0.005409197625937385,
  0.00535783028229212, 0.005505261817555035, 0.005476113232122903,
  0.005579956482920673, 0.005400295618188864, 0.0054532658173751,
  0.0052953441530284385, 0.005351494821854291, 0.005226841218513527,
  0.005332021105509824, 0.005275893869847055, 0.005343684951412806,
  0.005431090290654573, 0.00540604202722089, 0.005614067846756931,
  0.005763993072196318, 0.0058948218090358094, 0.0060360215425962925,
  0.005958752009187343, 0.006603953612079233, 0.006416263966900989,
  0.006470731139225965, 0.006266178332043334, 0.006487720699988338,
  0.006200907240282184, 0.006431416109702981, 0.0062181705883406655,
  0.006349457716211493, 0.006311947446459842, 0.006224877435954114,
  0.006331461056050012, 0.006213176001912072, 0.006281822025639651,
  0.00661307546824797, 0.006358064071414059, 0.0062563358867629105,
  0.006332746562827743, 0.006405970075461676, 0.006597679969661403,
  0.006581150270588429, 0.006594580620326406, 0.006690206943639852,
  0.0067236259124560565, 0.006566218242097797, 0.006445462900774842,
  0.006169308085672654, 0.006081376229750372, 0.006259458365872519,
  0.006196857480962717, 0.006205792551646857, 0.006202614054632779,
  0.006225621878158862, 0.006249038310249601, 0.006384439671060624,
  0.005902665291465192, 0.005945305351625397, 0.006035435589528576,
  0.005844266904703501, 0.005890422242996637, 0.0061231028834061165,
  0.0060477673267213036, 0.005949833755438021, 0.005985812227842778,
  0.005883997005170705, 0.005914390351987871, 0.0059633304893878265,
  0.005952358458651004, 0.005896717395853917, 0.005906426257973461,
  0.006044910141522956, 0.006001911781082566, 0.006191337660257811,
  0.0061353768463110634, 0.006505962718636899, 0.006391756290863584,
  0.006589812370683021, 0.006473489084279919, 0.006388691575474876,
  0.006243806934208742, 0.006414625674528119, 0.00638910866068277,
  0.006494629440073966, 0.006511512612028065, 0.006543002408986069,
  0.006522214216428698, 0.006555599982327968, 0.006654660911877662,
  0.0067506353381238645, 0.006954548277734422, 0.006916082428382232,
  0.007073183894070136, 0.007100476159301478, 0.006918353470117188,
  0.0068583782042620466, 0.006891485843937726, 0.006766237428160267,
  0.006822814127826631, 0.00680621818047254, 0.006805792798596294,
  0.00669653226222916, 0.006540038549693604, 0.006273422451841321,
  0.006026861853313018, 0.005930058244102422, 0.006037308532118909,
  0.006193708832887154, 0.00641232338539954, 0.006654533778690178,
  0.006505193556950099, 0.006519886947854702, 0.006679033071553597,
  0.006631384849559234, 0.006600642698909823, 0.0068422271550257294,
  0.006923298211715053, 0.007017672554672322, 0.0071849757892461525,
  0.007226323666113035, 0.007195932426799792, 0.007231176737942226,
  0.00701657394098539, 0.00706141152746423, 0.007111086920650282,
  0.0072652250270093, 0.007191375993830861, 0.007146146782866756,
  0.007029651153905722, 0.0070696976847850765, 0.007049494526958561,
  0.007093864741562045, 0.007302473545957071, 0.007577679614148341,
  0.007740767257514727, 0.007872218034436313, 0.007861474327217186,
  0.007999952239638645, 0.007980662445815672, 0.007966976979110037,
  0.008001443812117835, 0.00797331327192609, 0.00804851877037968,
  0.008172699051896668, 0.00830882442387981, 0.008314765723506413,
  0.008302054763178579, 0.007916627999042683, 0.007919598887528934,
  0.007965697854147594, 0.007861947673676373, 0.007845703420273062,
  0.007572730992203366, 0.007620955706773193, 0.007635227927894195,
  0.00756223349732692, 0.007695388171403961, 0.007686897725090425,
  0.007861826885366146, 0.007883691042539017, 0.007818146568400393,
  0.007874992417545789, 0.008046326554268625, 0.008189658455269267,
  0.008440470531266174, 0.008340625633189078, 0.00834030307194517,
  0.00823474817610362, 0.008457392012533831, 0.00869141544694499,
  0.008643116743881216, 0.00865534910236384, 0.008572425211750542,
  0.008788954699499989, 0.00885327907077438, 0.008673910814024518,
  0.008404023525847558, 0.008457552422412145, 0.008389805067249638,
  0.008327744937788506, 0.007976578487254466, 0.007964422446706573,
  0.008093587380884538, 0.007869773904414823, 0.007853470983363382,
  0.007570999296459197, 0.007170757646689954, 0.007092954227624029,
  0.007156855196686758, 0.007357849504826304, 0.007511218375076442,
  0.00722678180872009, 0.007256170942504853, 0.0073338421534048315,
  0.0074515451737743555, 0.0076243926409829115, 0.007724014503683434,
  0.007656470989044051, 0.007644210507018247, 0.007722397779549653,
  0.007755581749428762, 0.007826433759233399, 0.007960075898406136,
  0.007873001347859074, 0.007871395850345237, 0.007770348552529194,
  0.007522552633494433, 0.007569756003984547, 0.007614301257908023,
  0.007651037914595852, 0.007990819518512196, 0.007911238662473973,
  0.007757319869377952, 0.007855778269561419, 0.007925998623159252,
  0.007874490248866023, 0.007892371708393638, 0.008000472645502316,
  0.007826069143682371, 0.007958839994208903, 0.008135737918743113,
  0.008237816220267626, 0.008220560809288327, 0.008216126975503862,
  0.008081365798906535, 0.008140622864071955, 0.008173811252631898,
  0.008576051765091897, 0.00867978126124304, 0.008936345702299548,
  0.009000380082011832, 0.008953414663885731, 0.008920615959742832,
  0.009066339482295563, 0.009078481930800263, 0.008771020847059536,
  0.008669427029805986, 0.008549074540605814, 0.008381674273810273,
  0.0082410914226626, 0.008134014440506383, 0.008133510129332352,
  0.008109822423259961, 0.008103064979990634, 0.00809616719235631,
  0.007885736797301068, 0.008016288368092529, 0.008266865166937622,
  0.008313795360703416, 0.00843231589733611, 0.008041720048923779,
  0.008064620683484345, 0.007655505697393095, 0.007559455000225658,
  0.007543570893424785, 0.007622060235527083, 0.007985997419178251,
  0.008107342958603055, 0.008040879707613729, 0.007799848063354122,
  0.007787072964179674, 0.007848981122851586, 0.0076859150263222575,
  0.007664828300686876, 0.007646293371113682, 0.0075817369177437486,
  0.007511821405140077, 0.0074057084076105196, 0.007225546370913158,
  0.007132269038171656, 0.007035951121876746, 0.007033273409212648,
  0.006874645186312271, 0.006923083054542732, 0.007009042449494937,
  0.0068332506606526, 0.006917288543410548, 0.006905087098507262,
  0.006933958317351356, 0.006912765474626142, 0.006853249054185887,
  0.00689883964084698, 0.006913996304089614, 0.006921036239806839,
  0.006820594635259567, 0.006877790605852714, 0.00678218260032859,
  0.006838768737759073, 0.006722270978993763, 0.006644305673088686,
  0.006855910312098862, 0.007053454879392764, 0.006879055141943596,
  0.007122449633463747, 0.007105334842478163, 0.007073969014262355,
  0.007017032164077611, 0.006985110530715792, 0.006861841332936766,
  0.006734628331674314, 0.006859912566312101, 0.006693947262753287,
  0.006605883122170239, 0.00670036092635629, 0.0067318718128328335,
  0.0068524851115128165, 0.006566209846328752, 0.006227570999719986,
  0.00615412263092284, 0.006025283344986893, 0.006176803171163197,
  0.006110805538404338, 0.006366758063470826, 0.0064421502829164895,
  0.006675153592111544, 0.0067612055628051655, 0.006580898590255511,
  0.006693134139015609, 0.006896823604209705, 0.007163077065709492,
  0.007329800512020463, 0.007461876053638295, 0.007447162101936769,
  0.007508804538899165, 0.0075418634068591455, 0.00735170468169591,
  0.007155765844769805, 0.007234940977447169, 0.007157857318277061,
  0.007240635283241509, 0.007304148221020496, 0.007236036147447664,
  0.007120406317035342, 0.007156317088145542, 0.007065947984409654,
  0.0072109589438677825, 0.007464139611588081, 0.007455889343480103,
  0.0074055118291410805, 0.007523184624762386, 0.007727878593432494,
  0.00781339787447698, 0.007985975445517938, 0.008120804950892367,
  0.00820279881631335, 0.008150024386239085, 0.00788696454069669,
  0.007830637699176031, 0.007841642741423257, 0.007955702413470503,
  0.007859900854815449, 0.007786668985969614, 0.007987731579731306,
  0.008048180710729196, 0.007982762056782885, 0.008171270755627723,
  0.008158150685439208, 0.008332081482586032, 0.008034158190099516,
  0.008164618451401368, 0.007901463938001186, 0.007948127438706903,
  0.008040260257002864, 0.008174775566537396, 0.008144366778769589,
  0.008223570791720978, 0.008346563671466602, 0.008533299618959735,
  0.008579660248015316, 0.008571762051547976, 0.008617938435516741,
  0.008482497593696509, 0.008303640397750356, 0.008218062394919314,
  0.008205861667102984, 0.00826494556706159, 0.008322988322105361,
  0.008116452432581548, 0.00826690519052935, 0.008165108200129991,
  0.007974655078613254, 0.0080609187931206, 0.008005902289724748,
  0.008055088997516559, 0.008288527319887645, 0.008158248153710888,
  0.008230836405514226, 0.00820903391089021, 0.008206392783847178,
  0.008151914594864336, 0.008286199740987934, 0.008325722198239537,
  0.008093259545469992, 0.008128405921060273, 0.008368916668103276,
  0.0081636734530053, 0.008115041641021392, 0.008013900110845908,
  0.008143819309693233, 0.008189330866636679, 0.008084058960727045,
  0.00815621688595259, 0.008145064995207858, 0.008093828621887959,
  0.008061600518205633, 0.007981730597855674, 0.00802268097872894,
  0.007920415433315814, 0.007976937493132153, 0.007964055117626203,
  0.008000467283761066, 0.007866059665314416, 0.007848307331464336,
  0.007611373027950814, 0.007535794561959724, 0.0074903141401916375,
  0.00739229591491715, 0.007407744383388269, 0.007262411289052695,
  0.007410607387588577, 0.007279778331206435, 0.007335730069773303,
  0.007319351881784619, 0.00749866411421147, 0.007271555563663657,
  0.007440407795628545, 0.007379142893587144, 0.007510395491802498,
  0.0076486099825514445, 0.007721627603923817, 0.007731642000056363,
  0.007695578679862956, 0.0076640120524647205, 0.007881740381106225,
  0.007779540894829033, 0.007756504599598371, 0.007726407139742912,
  0.007923592325794501, 0.007954031954863864, 0.008101896640768805,
  0.008066498302175854, 0.007998655842507354, 0.007961687668736162,
  0.007925284278221305, 0.007655259218866528, 0.0076558316092314015,
  0.007711220429472319, 0.007872793244663695, 0.007716312865542505,
  0.007770989429917139, 0.007951430901659493, 0.0077901056633016925,
  0.007883817778528365, 0.00798031254387854, 0.007784861262624826,
  0.007706150421349463, 0.00763487302711971, 0.00752396373873914,
  0.007570120191040007, 0.007530032764265382, 0.007414259215146583,
  0.007295003308740685, 0.007231902195676292, 0.007189878272827862,
  0.007175586616399559, 0.0071448064543797405, 0.007113464696335009,
  0.007061778962951487, 0.0071402860337561515, 0.006888078318009655,
  0.006814976900526655, 0.006695210352448631, 0.006356052564274957,
  0.0061092490494207286, 0.005832897379210901, 0.005687741801431047,
  0.005455490132228987, 0.00540545232863602, 0.0055982292543849625,
  0.0056972067016374806, 0.005811741575123338, 0.006015952525506198,
  0.005840950661849172, 0.00600836967565939, 0.005933348588802915,
  0.005516085742690874, 0.005581282338506567, 0.005666690906771695,
  0.0056689707212111305, 0.005744980430688061, 0.005805822589417552,
  0.005920286423564942, 0.005934614457104076, 0.005998318453872703,
  0.0061493786265573525, 0.005991817044258511, 0.005881958197534244,
  0.005740384606123775, 0.005507675150230161, 0.005385162557358661,
  0.005451852477590719, 0.0052960227905641125, 0.005175049874417827,
  0.004869418288391265, 0.00492730302343787, 0.0050108855492940405,
  0.0054328521575973485, 0.0053710998105251165, 0.0055229345473691415,
  0.005292204160072805, 0.005179595714665374, 0.005028699453725395,
  0.004890457521373934, 0.004890666804218759, 0.0049022423521865015,
  0.0049441835947784014, 0.00484960144181901, 0.004881660215668112,
  0.004974540672456704, 0.005097045624699713, 0.005130032231019357,
  0.005179647569914391, 0.0051441035499326425, 0.004952705531725652,
  0.004922557069859026, 0.0047812496809849165, 0.004627471135339087,
  0.004612270021217279, 0.004543705985323684, 0.004540921427567004,
  0.004227672273139906, 0.004350457771404118, 0.004631127009929319,
  0.004890203903949713, 0.004660468585386885, 0.0047151691515289135,
  0.004682980330456342, 0.004757391584395839, 0.004829391754699103,
  0.00464254613160312, 0.004569075952587465, 0.004426748828666407,
  0.004060630815325484, 0.004230743559262028, 0.004141654872296019,
  0.004098892348993333, 0.004142372703355423, 0.004073641795512117,
  0.0042156399105943015, 0.004333689186239173, 0.0041741562305338395,
  0.004231720297067347, 0.004295621567411819, 0.004716586162021442,
  0.004919452724526632, 0.0048135089244820805, 0.004993736165306835,
  0.005048357186422887, 0.005053877094754424, 0.0049168316425917714,
  0.0050351865068060594, 0.004953084105394359, 0.004793234548646342,
  0.004720350232023404, 0.00455562796229782, 0.004584131881401991,
  0.0046402733891946725, 0.00459334880426628, 0.0048332596717545005,
  0.0047105898668329624, 0.00478397225216954, 0.005038422992369589,
  0.0049144643319501205, 0.005014569631996762, 0.004925142104469781,
  0.00502154899771953, 0.004859692830504735, 0.0050743152630772425,
  0.0052054777640333375, 0.0053644276081484775, 0.00555652376036284,
  0.005563968171847237, 0.005688785558115478, 0.005598768917778799,
  0.005697230647243259, 0.0054269985071085085, 0.005258915358054089,
  0.005331329262375707, 0.005409208480180701, 0.005354923306198168,
  0.005289158746687922, 0.005152561078241704, 0.005351799594566983,
  0.005131127996604594, 0.005075428612220348, 0.0049855907171799385,
  0.0049338001147145785, 0.004995414464148431, 0.005033929871491432,
  0.005146006025935739, 0.005117094686593955, 0.005304940762748256,
  0.004976997896327942, 0.004912898788990005, 0.005022745264416447,
  0.005066515023684214, 0.005128275217489126, 0.005195340841779979,
  0.005598615913593606, 0.005647915267705151, 0.005755661940108284,
  0.005535042385310947, 0.00560278839960197, 0.005381091124956291,
  0.005354598965615988, 0.00565915680333803, 0.005632254898310553,
  0.0054954256890368805, 0.00548833317481551, 0.005766599546663124,
  0.005824428764367992, 0.005764036576806067, 0.00582545930932509,
  0.00573608067588511, 0.005837502417332718, 0.005719456615279005,
  0.005684707180443692, 0.005500587254130457, 0.005296041995217308,
  0.005256334654855243, 0.005227608396361211, 0.005401988335968982,
  0.005327836627896942, 0.0054870781280976335, 0.005480650181828369,
  0.005462833674617015, 0.005293341179863465, 0.005335634014024228,
  0.005182706122592371, 0.005192087421296056, 0.005139327290506258,
  0.005189677546861835, 0.005169502643221495, 0.00516030812551369,
  0.005105130093416566, 0.005287859867855349, 0.005054776092728117,
  0.004886704032939864, 0.005028931504307673, 0.005121689808180128,
  0.0052529243086633405, 0.005000264217503184, 0.005110144410082502,
  0.005249111418731503, 0.005442651276636176, 0.005476016887699727,
  0.005613844342801491, 0.005640204823982067, 0.005654591425004116,
  0.0058511232402942166, 0.005729170953667955, 0.005795618297072548,
  0.005936506204933763, 0.005872800563690752, 0.005681177963509292,
  0.005490476341876926, 0.005355985013948338, 0.005377310633914889,
  0.005447993565188033, 0.005229336606672655, 0.005249448788079021,
  0.0051671938466325545, 0.0051646159254319975, 0.0050118510373188385,
  0.004905581975739335, 0.0050423814127566035, 0.004988829508024717,
  0.004888233325617339, 0.004738547160153623, 0.004608335822748474,
  0.004545410869984094, 0.004724726315165798, 0.004843727063849456,
  0.004897317479233209, 0.004928437501105944, 0.0049855854149278645,
  0.004778393394575062, 0.004784012846354512, 0.00493935954018988,
  0.005027148205318724, 0.0049562187244899325, 0.005129991077834339,
  0.005317648364756618, 0.005255119774573316, 0.005154186002538661,
  0.005206063576852603, 0.00529622756356758, 0.005298698528107494,
  0.005260075468479221, 0.00525567628548501, 0.005512099755132333,
  0.005718902892062141, 0.005857714805421448, 0.005878733260403612,
  0.005981731497217476, 0.005981992028037869, 0.006099773747437028,
  0.0061418378857149855, 0.005885708983468868, 0.005907907977974831,
  0.005855110103045158, 0.005924027358527133, 0.0060433519081947475,
  0.006025829585673141, 0.005936325377461256, 0.006041857640697018,
  0.005868191247952008, 0.005931798275387363, 0.006025892996654386,
  0.006136433793461377, 0.006126051014503162, 0.006134174766655718,
  0.006194891496841106, 0.006301700566842887, 0.006436417412385972,
  0.006662741976540155, 0.006316700617923081, 0.0063759208398316055,
  0.006492995289613268, 0.006231737264130432, 0.00589060360572494,
  0.00585419849243139, 0.0057318134873681715, 0.00572156770012769,
  0.005628139955289251, 0.005704302548962761, 0.005624665789390709,
  0.005724623580914407, 0.005528025808667871, 0.005517762321263374,
  0.005655586072148896, 0.005877835964354862, 0.0057432073424839416,
  0.005614729637559776, 0.0056739787372760446, 0.005708719083877429,
  0.005639985902825386, 0.00554425562761275, 0.0057909362341858365,
  0.00578633026153403, 0.005943030577487792, 0.0058499216802473724,
  0.005946999420821227, 0.0058327870190010515, 0.005930586539496935,
  0.005956129726831765, 0.00585733323779612, 0.006027919286577459,
  0.00614507349565784, 0.006378355570986324, 0.006335708609233555,
  0.006498717696617067, 0.006662092280616465, 0.006531727340324369,
  0.0068256297192025835, 0.006677500899234795, 0.006455414291898222,
  0.006483717928477758, 0.006341089469126095, 0.006432624712859758,
  0.006531637234720991, 0.006650919062717323, 0.006836453209111665,
  0.006726475552623481, 0.006663056751101657, 0.006345310709468293,
  0.006504566767976837, 0.006553446142834495, 0.006405517559151964,
  0.0063859536128497195, 0.006262188582359581, 0.0062741365018338,
  0.00626731444267041, 0.006280232693511662, 0.006114825151901141,
  0.006231646627892215, 0.005957307765366297, 0.0056521239168502895,
  0.005687314493072884, 0.005585548908552461, 0.005655166346663867,
  0.005632915378336113, 0.005488296849745835, 0.005214582277876367,
  0.005223024342199493, 0.0051765043622773324, 0.005228355323121043,
  0.005148494880504432, 0.00492616416446553, 0.004915298501635528,
  0.00506625269251498, 0.004964025903690913, 0.004999032915057782,
  0.00507006176759256, 0.00504553205048459, 0.005325269220932427,
  0.005238169223382308, 0.0052288049920742995, 0.005273797420946042,
  0.005164389441082115, 0.005208810800789537, 0.00536560869608978,
  0.005501129653365865, 0.0054374338726423015, 0.005464278462566796,
  0.005375132046038384, 0.00547653466014076, 0.0054970443550528434,
  0.00543698707990461, 0.0053918608493081985, 0.0050983038385143675,
  0.005148029992801516, 0.005246721183805135, 0.005332115368747807,
  0.005178515400641948, 0.005032486855401408, 0.0048997143879976075,
  0.00501205818605007, 0.0051711655999785375, 0.005013315995572082,
  0.005247159669125708, 0.00524905102321307, 0.005237034525021497,
  0.005245123958622699, 0.0050314983224876144, 0.004929510130094627,
  0.00482448014146493, 0.004971179724371935, 0.004965183237375915,
  0.005015598693780977, 0.005020701993213722, 0.004823646700406268,
  0.004537793846251558, 0.004216590776925531, 0.004166198801165753,
  0.004134071027799563, 0.004188513290414986, 0.004085260966870014,
  0.004126288846980017, 0.004152746776641968, 0.0039738908208458105,
  0.0038808535674726345, 0.003928253311581861, 0.003615746517906098,
  0.003776502452443737, 0.003459182562014349, 0.0032708683769421248,
  0.0033607416824502257, 0.0032162257503245786, 0.0032356804464880517,
  0.003246632463180278, 0.003102734819568025, 0.0031670200480102347,
  0.0033431835986109, 0.0032290523696187666, 0.0030981415587126595,
  0.003084131467616663, 0.0029905183522508016, 0.0030473323851484217,
  0.0029228732545127137, 0.0029103531362609956, 0.0030860897405908783,
  0.003076414069863095, 0.0033017741319694128, 0.0033396897274570077,
  0.003354022279132753, 0.003280519604442587, 0.0034017066663133647,
  0.003433956730904041, 0.0033106313666012837, 0.003318828958198641,
  0.0033281226449286406, 0.003464988782605759, 0.0033970860790784917,
  0.0034895987655832717, 0.0037119213513134176, 0.00359699876482989,
  0.003673797078514951, 0.003701608234783817, 0.0038224341304953554,
  0.0037087603513218156, 0.0037779188134385226, 0.0037647867056783964,
  0.0037973853659741103, 0.003853259135263343, 0.003975815437664132,
  0.004018084567589627, 0.004021736708729851, 0.004080244006117242,
  0.003982874045440626, 0.0038906265653980933, 0.0039412621570832085,
  0.004003079498198796, 0.00378279346453205, 0.003718099051440391,
  0.0035898588094198, 0.0036515164203529183, 0.003568865636618273,
  0.003418813290857667, 0.003652117998093669, 0.0036700736231466725,
  0.003792950804524683, 0.0036639365399238947, 0.0038161464988727663,
  0.003559218946787715, 0.003599000260523173, 0.003691636345615731,
  0.0036521689410542557, 0.0036545383934339713, 0.003594309040233563,
  0.003379358246338006, 0.003329065222356394, 0.003497254446192028,
  0.0035934682598581878, 0.0036090256967923028, 0.0036737624017289675,
  0.003715586699666979, 0.003589425321617073, 0.0036496301498735915,
  0.003478470214957699, 0.0035306926687359735, 0.003604990121412662,
  0.0038823479000518447, 0.004073923117369274, 0.004112325102823063,
  0.004253856168814891, 0.004182629477247977, 0.004047789570013405,
  0.003936562606685187, 0.003905309005080244, 0.004042501082810732,
  0.00409817862686026, 0.00411298577431088, 0.00395939980019844,
  0.00389473234496587, 0.004024426075285417, 0.004047666754195458,
  0.004207466549032325, 0.004209323672689017, 0.00394153948019454,
  0.0038796917385327486, 0.003998240846169632, 0.004129608934752297,
  0.004144100818637298, 0.004295638017978941, 0.004240341169103101,
  0.004258550649941545, 0.004464456981444909, 0.004413993919543248,
  0.004384819489845717, 0.004267887995754418, 0.004287016498446759,
  0.004210295981409682, 0.0041525525924272465, 0.004124750295735735,
  0.003854797845169718, 0.003760738690748045, 0.003461624117269936,
  0.0035127279567787073, 0.0035891039297181715, 0.003883754583360525,
  0.00387669326217851, 0.003825913011342505, 0.003922332477910717,
  0.003931046247907314, 0.0038625427610573357, 0.0038586771206678906,
  0.004026154079567352, 0.004025282914082124, 0.003992541932274084,
  0.003872249736097818, 0.0036002056480555593, 0.00362500562796917,
  0.003433599472579668, 0.003378672653915891, 0.0033782867806508043,
  0.003329631502411515, 0.0033804275179437015, 0.003333585791946479,
  0.003591684131932012, 0.003613268308327138, 0.00355070838177012,
  0.0036396873871985223, 0.0035596722659738227, 0.00352153924434156,
  0.0034526524481360197, 0.003506221632065217, 0.003264136782997207,
  0.0032187788348829872, 0.0034218800397939966, 0.003508676970457245,
  0.0038596881915421623, 0.00399907452009064, 0.0037877011182798387,
  0.0036995908664526596, 0.003660866477690821, 0.003522036170186274,
  0.0035944389676258437, 0.003817692415211091, 0.0036356252516998437,
  0.003500161583970108, 0.0037074169958603714, 0.0037254616549124747,
  0.0036864459668338816, 0.003856569096242427, 0.0036368490428720086,
  0.0036828933407272715, 0.003873818164977085, 0.0036885688108469583,
  0.0037280072653301907, 0.0036523025456363786, 0.003858495400480746,
  0.0038940683225655217, 0.003949394348497123, 0.0037984503190470788,
  0.004071932664314899, 0.0038996718128332516, 0.003968277572501065,
  0.004082470862784597, 0.004018703878372744, 0.003800794569453574,
  0.003938414678255037, 0.004053836954560107, 0.004208681977949458,
  0.0040945570698483, 0.004233794106622364, 0.004349781770230496,
  0.004475331710575194, 0.004437597775929996, 0.004436401750730176,
  0.00437625166593491, 0.004366715981336262, 0.00424170217459838,
  0.0043239654945253685, 0.0045834231444152485, 0.004965119605873698,
  0.004872333386645947, 0.004897251142666132, 0.004930503916693102,
  0.004836962370561485, 0.004954390065249338, 0.004898661684773586,
  0.004933099990040572, 0.004700757373289466, 0.004603758045891209,
  0.004349754199996928, 0.004178510479575206, 0.004006000809307355,
  0.00408907637805229, 0.004351597538813947, 0.004401158149813613,
  0.004228697654830339, 0.004316861774614768, 0.004289394347737693,
  0.004208405103990764, 0.004077626314815211, 0.0038800369893778972,
  0.0037869615862059776, 0.003800946973172741, 0.004082332364948314,
  0.004101602160172245, 0.0041541639778373265, 0.004190875604244998,
  0.00418540770710548, 0.004171971732878201, 0.004151542085019271,
  0.0041227625164828125, 0.00423442874597623, 0.004070529552495412,
  0.003992388987697786, 0.004044009220958758, 0.003952322396706027,
  0.003827111973823951, 0.0040091825330294175, 0.0041247015959345575,
  0.004032439129679028, 0.004038195506025972, 0.00402178458487229,
  0.004049136550079401, 0.00419569542194113, 0.004288862565700559,
  0.004288502472307381, 0.0043748656933878215, 0.0041964625187535355,
  0.004116193115910157, 0.004172806200586258, 0.004345348021085335,
  0.0044550831198729305, 0.004719659278519524, 0.0047085320894854335,
  0.004521812030865141, 0.004534321989981201, 0.004653121011315956,
  0.004861281915994832, 0.004406999419830977, 0.0043041552944532045,
  0.004515858412741828, 0.004420244237505785, 0.00458072543049781,
  0.004723853946782646, 0.0046894156618404095, 0.0045940228381074096,
  0.004445819427367852, 0.004275377807526622, 0.004302509425914594,
  0.0043931950369970815, 0.004353815062692664, 0.004544525260610805,
  0.004605844192061765, 0.004585473145876663, 0.004718369521614048,
  0.004773134527398558, 0.004699319124946169, 0.004618862865536617,
  0.004679755071245688, 0.004659185903134465, 0.004818317532377789,
  0.004683862745449009, 0.00453382328321453, 0.004717993710214293,
  0.00442111371199791, 0.00443006862728806, 0.004580697090028619,
  0.004617974945132229, 0.004527886931419026, 0.004696153828033907,
  0.004724959000586627, 0.004633998770140094, 0.004550945007551762,
  0.004699227924350744, 0.004540469431721774, 0.004675855286646483,
  0.004512437451930212, 0.00456220413687064, 0.004432566517424678,
  0.004336564982118091, 0.004553669831886143, 0.004621275930317312,
  0.00457982436720006, 0.00461899068447168, 0.004629876865370079,
  0.00468597283571628, 0.0047725131034214285, 0.004645722730828624,
  0.004622849661086154, 0.00462321685598123, 0.004488438608044268,
  0.004422990659535717, 0.004542323485023581, 0.004478694232644327,
  0.0044114962988350825, 0.004289036821318026, 0.004212813644073512,
  0.0043011285383628, 0.004389233258307547, 0.004348998370808671,
  0.004466016237631067, 0.004407232837684894, 0.0042231123812113205,
  0.004272485186182687, 0.004360816712726652, 0.004256169459083326,
  0.004049145444940717, 0.004048277668102528, 0.0040697652218341105,
  0.003677877850171209, 0.0036807432596948843, 0.0037463119070033084,
  0.0038538053762727053, 0.003960213427911001, 0.003922290147119714,
  0.004034176856509566, 0.0038546458779329545, 0.003977462351768653,
  0.004219271182270129, 0.004085208351663236, 0.004183844480810601,
  0.004024575598607927, 0.004310041974912724, 0.004252818285980534,
  0.004180320536722366, 0.004280407305524347, 0.004370800694982267,
  0.0045742389246684004, 0.004590951611038347, 0.004459203520817611,
  0.0042294990702283035, 0.004183543911113766, 0.004356432351290152,
  0.004112372455347597, 0.0041898942808718245, 0.004141087864506503,
  0.004051206451700105, 0.00400450686719787, 0.0041021882785804995,
  0.0038627871501274317, 0.003957224596518009, 0.003901106901255524,
  0.0038592703575043817, 0.00390321655355914, 0.004067832554059964,
  0.003893817022164972, 0.0037896023962405846, 0.0037064305191763154,
  0.003911515073459626, 0.003727918002612592, 0.0037100161119268176,
  0.003775655242353397, 0.003610804291461326, 0.0035009326619997415,
  0.0034739791800589824, 0.0035280504726929295, 0.003220585290109259,
  0.003314234113838717, 0.0033204926040023997, 0.0033117323345268463,
  0.0032935396236951813, 0.003159283481348034, 0.003068270481008221,
  0.0029863616086312633, 0.0027924820666088614, 0.0028409156030795384,
  0.002909061576956887, 0.002816554942159941, 0.0026707564358936123,
  0.0026888506159242443, 0.002780265611064666, 0.0028029318455029943,
  0.0029617352784824993, 0.00287242563276683, 0.002916187415177197,
  0.0027108992869150227, 0.002659442374364956, 0.002694749344107025,
  0.0027659106301878528, 0.0025580252471031667, 0.0025097633205736344,
  0.0023131521103582063, 0.0020622821563016877, 0.002043844437508148,
  0.0021292318685309404, 0.0022042900790497574, 0.0022092609625202165,
  0.0023662937233527065, 0.002312380093058969, 0.0022506340278687495,
  0.002343091461646982, 0.002318987255837021, 0.0022844815501108905,
  0.002251923438741, 0.002285239219201719, 0.002170528822435737,
  0.002184703729921564, 0.002320957253014983, 0.0024199996088938003,
  0.002428258279288757, 0.0023184101810464833, 0.002211833142172105,
  0.002256937295189497, 0.0025159324997072684, 0.0025097981437094763,
  0.002130985946340903, 0.0022659234342935954, 0.0026043861505411774,
  0.0026637788988359457, 0.002908379293899491, 0.00304131433331129,
  0.003117268233322763, 0.0031487649914409394, 0.0032630549174475507,
  0.00304835935954123, 0.003409371513014588, 0.0033653371306680787,
  0.0032452360226514895, 0.003246430557197472, 0.003398189706834363,
  0.0035786696928864917, 0.003427851073673759, 0.0034033239198339644,
  0.0035085933625074364, 0.0035144701724779412, 0.0034791616500863217,
  0.0036429115537737373, 0.003749490297260615, 0.0039699959931448064,
  0.003964810629109716, 0.004012821843528933, 0.003908645969566547,
  0.003894660906808279, 0.004025135983002208, 0.004175570026717261,
  0.004350315921404297, 0.004348198444701198, 0.0043777217396248,
  0.004187328640000201, 0.004045786591502977, 0.0039894204417025535,
  0.003730145410556093, 0.0037868944346864187, 0.0038330265752689035,
  0.0038979154000023464, 0.0038357769817592203, 0.0038348142892759415,
  0.0037620467037957914, 0.0037699605933022504, 0.0038283079373258497,
  0.0038510289005371694, 0.003637173674911212, 0.0036535636723169585,
  0.0036291572458826845, 0.003911611912607378, 0.00382853715642878,
  0.003951158613262872, 0.003941746438602523, 0.003999927498278947,
  0.003905317306360948, 0.003973641903277651, 0.004031854442453737,
  0.004063730051555795, 0.0038702210724035594, 0.0036901773699133415,
  0.003647094911590498, 0.0034922744843909645, 0.0034193434098292895,
  0.003242809956784929, 0.0031287726489126633, 0.0029400588244260623,
  0.0029953258984886243, 0.0031935805345891534, 0.003218198307993369,
  0.0031542114908919804, 0.0031434959561295584, 0.003247889518492344,
  0.0031469727436985724, 0.0031894724330034097, 0.003111468671183176,
  0.0032547823336752063, 0.0032320051007478988, 0.003154116828422584,
  0.003206703095223863, 0.0034262819295906408, 0.003459224032591492,
  0.0035649984335936334, 0.0035659774347674617, 0.00351441539071897,
  0.003593767037478746, 0.0036503995475567733, 0.0034286442918871145,
  0.003492339667177438, 0.003551548499032014, 0.003744666042271645,
  0.0036627024343978453, 0.003800874230154355, 0.003918752506867072,
  0.0038151553379568806, 0.003694405102240276, 0.003786291883851649,
  0.003775246540597874, 0.004167262772727478, 0.00409997086286492,
  0.00431659630911823, 0.004282053546847682, 0.004121833451876518,
  0.004219438487247003, 0.00426645714930929, 0.0043201477021051335,
  0.004508739895462582, 0.004673562447766397, 0.004443233397164947,
  0.004512882841341952, 0.004505489391344637, 0.0045553765927871005,
  0.004602226461636327, 0.004588298354131481, 0.004491926315823448,
  0.004505826937583017, 0.004576447207701003, 0.0046129374610229795,
  0.004753720354885702, 0.0047974187727099875, 0.004882633844619277,
  0.004800593623795851, 0.004500863024898685, 0.004492366144967261,
  0.004626406806309676, 0.004776982114130964, 0.004900942113870275,
  0.004838493769585789, 0.0048664090564577555, 0.004836171245620015,
  0.004639611722435871, 0.004702667872003578, 0.004708923715905879,
  0.004673616373101681, 0.0045126909818903925, 0.0044353448043714305,
  0.004305950403517426, 0.004182386087460237, 0.004132827585541311,
  0.00414039173183193, 0.0040143408379421965, 0.003933481597889384,
  0.0037084659472846697, 0.0034945564635319743, 0.0035628673343331145,
  0.0037418444513660164, 0.0039637993802900074, 0.003996049676955255,
  0.0038954321378904533, 0.003870017507869973, 0.0038968446828925264,
  0.00390148076621002, 0.004036231522455752, 0.0042574936028339574,
  0.004298722783834731, 0.004445719627030483, 0.004507016985524015,
  0.00444279593205138, 0.0044537362303742585, 0.0042698938748630165,
  0.004472444390460882, 0.0043172155789353295, 0.004237737749796681,
  0.004298600456908719, 0.004228628392337235, 0.0039432939927086675,
  0.003948573785705205, 0.0038559770592724056, 0.003755257997957781,
  0.003992372304665307, 0.004080086233249131, 0.004324638791006552,
  0.004194088408901296, 0.003892322857546092, 0.004139110097578047,
  0.004213032393699264, 0.004321094782997566, 0.00417452595139086,
  0.004174648930997611, 0.0042580398797665295, 0.004361975544775948,
  0.0043641687717176055, 0.004406718174924658, 0.004515649340396788,
  0.004409247779083738, 0.004593494108327905, 0.0044472513222398894,
  0.004206243512033258, 0.004339269006428419, 0.004541298927821112,
  0.004563040808431399, 0.004632523441052752, 0.004529759411136426,
  0.00466518112952233, 0.004767434713455454, 0.004646405751780087,
  0.0045441716876194575, 0.004653005728622104, 0.004816212052402316,
  0.004635624258367993, 0.004684960741963885, 0.004649841157898262,
  0.0045604670286415, 0.0047838221439011385, 0.00505724796102455,
  0.004863590640097418, 0.004919756440638239, 0.004785144035259865,
  0.004983312309697801, 0.0051071223670390515, 0.004994679678859316,
  0.00488912488101364, 0.0049361621059600715, 0.0050285380732946385,
  0.005060311060478361, 0.0049769735030864935, 0.004991866240180496,
  0.004952966558614764, 0.005017974208727212, 0.004936559231280343,
  0.0048803095610617285, 0.0049335071373012365, 0.004858536444581589,
  0.004858664333842192, 0.004934821468355256, 0.004956818333773794,
  0.005038261291197409, 0.005175912151632095, 0.005097934770937668,
  0.005192304199729896, 0.00527261569639335, 0.0053327945927095196,
  0.005288378053601809, 0.005309129775748119, 0.005407669728053761,
  0.005499558254451382, 0.005466508135113993, 0.005362271659144589,
  0.005482316439490744, 0.005267625398750032, 0.005277853782813915,
  0.005421538802305912, 0.00536103021219775, 0.005211625434562954,
  0.005096453896253846, 0.005347730668680931, 0.005151657116366559,
  0.0049153266019329196, 0.0048565189166443435, 0.005091755393282496,
  0.005030052325696764, 0.005116641027304472, 0.0050830518524231025,
  0.005157306734348615, 0.005039859604626343, 0.005319672108556419,
  0.00530455812845778, 0.005247163906204554, 0.005543485508353464,
  0.0057527296900574355, 0.00587378495509882, 0.005982775511243836,
  0.006241185908463888, 0.006198896628218978, 0.006204003422560922,
  0.0061738654528188976, 0.0060902646630221265, 0.005893893380496647,
  0.005762580010686897, 0.005747860382606959, 0.005916256015545353,
  0.005832975506494796, 0.005815452158286822, 0.0059062334922068095,
  0.006221031620237664, 0.006273155219375354, 0.006120988783819625,
  0.006013866863817935, 0.006181453923429544, 0.006387845926775146,
  0.006280370210601251, 0.006208117601613528, 0.006011188031598451,
  0.00601799878999338, 0.005995250878292095, 0.006050890965082914,
  0.006056709415768552, 0.0059225257309106745, 0.005772877709946786,
  0.005746655896579344, 0.005951775962814164, 0.005766269277875907,
  0.006001353786719623, 0.005779453300536998, 0.005780174153223158,
  0.005746760931311025, 0.005784618230096728, 0.005587458707310223,
  0.005394429372775731, 0.005434734021668785, 0.0054683206639141516,
  0.005484806747188926, 0.005380797208092581, 0.005470195610203939,
  0.0054988283048632855, 0.005590221920152311, 0.005587758723200313,
  0.005860425746063916, 0.005833170586754868, 0.006013071010886474,
  0.0062411540322225645, 0.006303950241932071, 0.006366983943741188,
  0.006331876349625373, 0.006267642534905573, 0.00640211535820612,
  0.0062614664005700435, 0.006256541936497025, 0.0062046460630555585,
  0.006379538963306016, 0.006284880384259955, 0.00617896506555557,
  0.006416362552721554, 0.006521717753853486, 0.006816235228413389,
  0.00677802955629243, 0.006937676920610341, 0.007004183615458845,
  0.006929075545934355, 0.006891552835482955, 0.006880260469197852,
  0.006967567268745875, 0.006748928761842638, 0.0069013130986723134,
  0.006949898153302157, 0.00690187685553785, 0.006911620561020878,
  0.007162917385035443, 0.0073311121242857465, 0.007438624144618262,
  0.0072611404032612075, 0.007277213733526752, 0.0074217085637272905,
  0.007390570431798793, 0.007403462343013798, 0.007541295163699256,
  0.007478075084845979, 0.007308456404097272, 0.007406180813113393,
  0.007338666942318841, 0.007303049839615167, 0.007140270882380553,
  0.007255066326650966, 0.006980408696573154, 0.007095782732123888,
  0.006921490677146616, 0.0068338360290700565, 0.0067794967592383956,
  0.0067303258511697715, 0.006481145226673074, 0.006391247417065599,
  0.006528367335137298, 0.006238092686647707, 0.0061658102653214765,
  0.006223279648802667, 0.006234757733665705, 0.006239920195857332,
  0.006241346614002456, 0.006272038253829835, 0.006198732103081048,
  0.006399056038312034, 0.0064522167166660375, 0.006510653259158767,
  0.006552430906864501, 0.006626129386789661, 0.006658299769919863,
  0.00685340962114112, 0.006873267467841278, 0.007059356045381842,
  0.00702323492148113, 0.007234001960287714, 0.007022981232841673,
  0.006911080619228788, 0.0068637601433180605, 0.0068369975640713045,
  0.006843059797105517, 0.006713003774602797, 0.0065838986812040085,
  0.0065548163021436675, 0.006667222782618616, 0.006592617980147586,
  0.0066474549100924854, 0.006647418040380133, 0.006632485663870987,
  0.006750042722740341, 0.006747188084706827, 0.006604646962125587,
  0.006702882796758188, 0.006912318699533841, 0.0068625965933521,
  0.006794024722233389, 0.006607751313477769, 0.006555116895005963,
  0.006585485939611886, 0.0063649234661997565, 0.006268833687161934,
  0.006081679203721118, 0.006066411192641588, 0.005912961737347365,
  0.005934215878605465, 0.005906324315485602, 0.006030986822197094,
  0.0060182050629244066, 0.006129116077045592, 0.006165213665206655,
  0.006083994893409544, 0.006263750612138419, 0.006290001390885304,
  0.006193623148385972, 0.006238229148684351, 0.006289992201598492,
  0.006265498763638681, 0.00643279882034261, 0.006383743986944006,
  0.0063294454356137885, 0.006174866398113941, 0.006127207030876925,
  0.0062052553862100256, 0.006053485965378613, 0.006220489641731271,
  0.00635121946715152, 0.006339236884240204, 0.006408651661026333,
  0.006655072848375764, 0.006662571279695814, 0.00647423301638354,
  0.006222099182890338, 0.0060303378263742435, 0.0058661491853974675,
  0.0056890376346567136, 0.005592689207963958, 0.005585122482635612,
  0.005525360049106712, 0.005516419676866438, 0.0053839394488038,
  0.005467596016166265, 0.0054161490325998094, 0.005485187548361841,
  0.005481665356556692, 0.005466206203566453, 0.0056607280354621075,
  0.005472912622951066, 0.005351242433130484, 0.005314076366144318,
  0.005371322452240516, 0.005434722487224265, 0.005556223369948204,
  0.005719291806343795, 0.0055574367111329995, 0.00570216767334206,
  0.005608821415579429, 0.005577689647356802, 0.005773551130922306,
  0.005728118535194159, 0.005688297255088297, 0.005749297004283878,
  0.0055248505933142966, 0.00570125285841155, 0.005666060032358395,
  0.005613481446018673, 0.005445770050035774, 0.0053967551906316455,
  0.005546667350128616, 0.005344565567213657, 0.005186401587669221,
  0.0053685762341546175, 0.005261150205485873, 0.005383076781264494,
  0.005581826890670224, 0.005649689682421178, 0.005601394488818485,
  0.0056405870895869055, 0.005565739284726274, 0.005485673720482496,
  0.005394028600709596, 0.005410248908261504, 0.005341679085052427,
  0.005406771486823374, 0.005158200937577362, 0.005248577751201725,
  0.005312997081648785, 0.005379699024404515, 0.005356142176880168,
  0.005432084214763581, 0.005498361597631174, 0.0054040168637429714,
  0.005639297436529935, 0.005555519733202568, 0.0054947042647621805,
  0.005450855266420444, 0.0056255713403469175, 0.005609521607063979,
  0.005630136194250645, 0.005734876091961541, 0.0058081662346794105,
  0.005646037924531154, 0.005546589707156936, 0.0055375120437820245,
  0.005689561774138354, 0.005782558519877447, 0.005824096781601868,
  0.005706737427942041, 0.00570744259016356, 0.005772700319872008,
  0.005671739868038649, 0.005945939425596779, 0.006051092867237223,
  0.006245532783340306, 0.006300811058278869, 0.006266453998203718,
  0.006100364758214113, 0.006041424861856372, 0.00599501884495031,
  0.00598701456616632, 0.005869855545540584, 0.0060243107595640425,
  0.005913585260720255, 0.005939888613519833, 0.006122799340508425,
  0.006335204682752242, 0.006297933760460724, 0.006043569044811541,
  0.005861263522679967, 0.005654434848797392, 0.005550079823817886,
  0.005527041592062781, 0.005443113814508683, 0.005759669240313352,
  0.005898388231276472, 0.0060573737800484785, 0.006080025014691918,
  0.006041724670935318, 0.005848116028854567, 0.005751549545827331,
  0.005626168351456069, 0.005532513728759154, 0.005314747568489597,
  0.005326842780480278, 0.005335509951949782, 0.0054317006599455845,
  0.005578558245779973, 0.0056668183972507615, 0.005538009473543294,
  0.005510549464802216, 0.005456777913999277, 0.00546695233473907,
  0.0053774952685360705, 0.005493359995745819, 0.005448061050954784,
  0.005572076035820796, 0.005529649804707533, 0.005618069055486546,
  0.005559933685686239, 0.005350974992256039, 0.005338110784297244,
  0.005211967931700291, 0.0053579482750262175, 0.005189688911611063,
  0.005150895008214675, 0.005065005640987125, 0.005165073424284111,
  0.005145727536083476, 0.005088353572746542, 0.004982343936736045,
  0.0048665642227386135, 0.004560159059259131, 0.004580277677907155,
  0.0047436470407144415, 0.004752608657697235, 0.004870079308908034,
  0.004990672940912766, 0.005009624151397092, 0.005048969581900784,
  0.004919608788469581, 0.004908669368499896, 0.00489915451631542,
  0.004773047626414814, 0.0049919390623654825, 0.004859177925423296,
  0.005086835418663851, 0.005005659867117206, 0.004985502308953155,
  0.005357089146710162, 0.005347936541096882, 0.005509520587918651,
  0.005466343261074239, 0.005536417282248562, 0.005486095865055391,
  0.005480460714380769, 0.0054580975357825905, 0.005638056075033302,
  0.005554100096906858, 0.0054044279718299375, 0.005170282866391625,
  0.004913143146265835, 0.004873183723728622, 0.004944808772849239,
  0.004913494000679962, 0.0047070402886285135, 0.0048870630167547596,
  0.005059241501662614, 0.004913397450584334, 0.0049859728705615594,
  0.0048350299115617204, 0.004930680136064121, 0.004869363297941616,
  0.0048803910169740545, 0.004945835637620674, 0.004823458378771001,
  0.004789209149378344, 0.004855614028616161, 0.004828350045874654,
  0.004911047346768758, 0.004867921011114642, 0.004991518413570935,
  0.004937034126702065, 0.004800591181600635, 0.004744289011923421,
  0.004837290667622516, 0.004616616199577043, 0.004586114513462412,
  0.004543415984176223, 0.00455466149783867, 0.004518873353181069,
  0.004643440994342982, 0.004702504712561365, 0.004652270281785163,
  0.004633287445545985, 0.004699598284096295, 0.0046269033495298624,
  0.004514521561435609, 0.004429026625270196, 0.004198611684363958,
  0.004218269851553071, 0.004268760201357799, 0.004464770678111224,
  0.004330084328412742, 0.004410298730514229, 0.00442511345479598,
  0.004460693670071247, 0.004378432930077121, 0.004506463430299444,
  0.004500417686761507, 0.004596066463023075, 0.004581658707917377,
  0.004470038549206875, 0.0044983087147647495, 0.004421089509076157,
  0.004598027444929571, 0.004733682040774258, 0.004995248546940447,
  0.005133165907726043, 0.005053493400904362, 0.004877374824271843,
  0.004869260001023756, 0.004972067431384431, 0.004942522753413127,
  0.004909753694119014, 0.0049021634209869, 0.004948555603399051,
  0.004735088771481436, 0.0046492944660827115, 0.0047158882467569115,
  0.004925735492772155, 0.005059012747212875, 0.005006409230856308,
  0.005053323602621404, 0.004965868265109713, 0.0049862736389660505,
  0.004811523624925175, 0.004602191300060878, 0.004587727940869203,
  0.004703067142974376, 0.004693450393033926, 0.004699034234711831,
  0.004602601027851307, 0.00484619221460237, 0.005046413620407515,
  0.0048938885601765535, 0.004959898689535248, 0.0052057201560827635,
  0.005209048906505113, 0.005256417011208145, 0.005415534831088845,
  0.005356033113756747, 0.0054580121673053995, 0.005535256879322094,
  0.005510193545655989, 0.005465787722539397, 0.005443727100829128,
  0.005533482661185478, 0.005310763026724628, 0.005288340532720046,
  0.005273856382495603, 0.005388358600816698, 0.005627273411038987,
  0.005592212556193747, 0.005540003530472087, 0.00556562682282823,
  0.005506748111835816, 0.005534408729528128, 0.0054679384319745485,
  0.005311024787485246, 0.005302804470248806, 0.005388804998657462,
  0.00551484037311303, 0.005409416439845733, 0.005498506979979709,
  0.005562918640304632, 0.005675673299879845, 0.00570531321732621,
  0.0056383318177752415, 0.005531145658327435, 0.005297645989002524,
  0.005147391383883239, 0.004943336133325737, 0.00490399097808419,
  0.004946517053110075, 0.005174894950632032, 0.005234683710190092,
  0.005156895448255767, 0.00512250020100566, 0.0053045025140358885,
  0.0054266535918513084, 0.005244187974429902, 0.00527639381057624,
  0.00531935304656195, 0.005275043103033924, 0.005277351965214584,
  0.005075183540095343, 0.005168322451255731, 0.005043458149001551,
  0.004883579421896482, 0.0049084802868274045, 0.005014417069492687,
  0.005045541229027919, 0.005057221156342289, 0.005094942789509169,
  0.005116823822832372, 0.0051086553516381155, 0.005132986569451248,
  0.005061902705313379, 0.004989593961331766, 0.004913825394867584,
  0.0051715794790807065, 0.005058351859565702, 0.004842977059875919,
  0.004894233354775726, 0.004883934601798164, 0.004809014530974139,
  0.004814303311492046, 0.004894501373910386, 0.005023492838313111,
  0.005049914397585919, 0.005010087261560912, 0.005005871470152353,
  0.00518143148352831, 0.005167594382944072, 0.005257063640904499,
  0.005244791612778643, 0.0052468264686707095, 0.005163921968190767,
  0.005262838316278402, 0.005452205795079926, 0.00532231695519772,
  0.005209443994430645, 0.005103243594644059, 0.0054575637068507345,
  0.005623960243876087, 0.005648817349075286, 0.0056759011802318595,
  0.005688768523293831, 0.005840505271103448, 0.005882115991724271,
  0.005955120090286666, 0.006196387416532374, 0.006458036138391804,
  0.006402561155224994, 0.006461187550884333, 0.006414463076210619,
  0.006462330563167082, 0.006324457626616101, 0.006368457711013597,
  0.006209365384373975, 0.006166357739010325, 0.006243161030656283,
  0.006323889431460552, 0.006309282534535403, 0.0062746011070321005,
  0.006241548022847002, 0.006312845820555161, 0.006285061405351002,
  0.006258832672869827, 0.00617557554814452, 0.00591852194734787,
  0.0058949796283365805, 0.005917642289181185, 0.0060586413796129,
  0.005900834270929438, 0.005955061915648376, 0.005883373394873696,
  0.005726323911425388, 0.0057512714728338785, 0.005650899392848143,
  0.005566359124288675, 0.005573749978639174, 0.005562482934648483,
  0.005596022599029829, 0.005876544666312517, 0.005900552508162313,
  0.005853995598039938, 0.006021092688140886, 0.005945864545122754,
  0.005905703052639666, 0.00591622877001702, 0.005948973714309821,
  0.006119537290006914, 0.006201626845593984, 0.006107600884628506,
  0.006153460522711223, 0.0060259585852554115, 0.006123248284653938,
  0.006099436813343316, 0.006136604281548634, 0.006016963324478108,
  0.006054274274055003, 0.0061256763488286925, 0.006026214087761515,
  0.006009973061395608, 0.005939342099607832, 0.0059517296430774335,
  0.006083115285677117, 0.006140854263577974, 0.0060900133663517085,
  0.006132616596488675, 0.0060290944175886065, 0.005763448092369164,
  0.005819281431993648, 0.005804754983204904, 0.005588211504080834,
  0.005718210047062469, 0.005674043148924378, 0.0056990162225846845,
  0.0057998489131459, 0.005724682149670791, 0.005535611633631856,
  0.005618743427438829, 0.005706648890426065, 0.005787339502885151,
  0.005848297720632379, 0.00565888686535878, 0.005753883648426065,
  0.005723723988948514, 0.005656057659062813, 0.005664271481905355,
  0.005581015444021681, 0.005540683975421128, 0.005504515133157902,
  0.005374236710079352, 0.005585390992481819, 0.005508654729091669,
  0.005474788979005292, 0.005454009175218415, 0.005422910115602071,
  0.005372175982543989, 0.005211791784761519, 0.005099819907540768,
  0.005080679994608247, 0.005105991905690935, 0.00495212987397276,
  0.005035118054711663, 0.005006064572748571, 0.005063968390004406,
  0.004907911984004451, 0.004960846591560386, 0.005077720781867949,
  0.005029181031227536, 0.005185420739515217, 0.005254799946701968,
  0.005219556471749375, 0.005311773499434781, 0.005300848665440624,
  0.0052458571644481335, 0.005143589088449745, 0.00509576459584365,
  0.004855762425535148, 0.005050624470216774, 0.005048715804064079,
  0.005044951112567357, 0.005192747036841818, 0.005228493840865135,
  0.005142455821058049, 0.005452808349927811, 0.0054962217884177685,
  0.005152615594027466, 0.005269394841449129, 0.005467614649972316,
  0.00562963229137725, 0.0057048431192084186, 0.005844006745685133,
  0.005797129106506509, 0.005845432340800197, 0.0058610495536743894,
  0.005887476164707872, 0.005736918651678433, 0.005771532350935157,
  0.005833336620102796, 0.006046786643239481, 0.005896366780881542,
  0.0057992640119955904, 0.005789698918906553, 0.005871784453594219,
  0.005896287615075596, 0.005818407126375885, 0.005643125822849721,
  0.005626488377006913, 0.005636435296760577, 0.005839456611720863,
  0.005741305176188182, 0.006080474884991841, 0.006259800181114361,
  0.006225347493352009, 0.006299048406149078, 0.006262299724474638,
  0.00619153831368384, 0.006169187981712672, 0.006178676584454419,
  0.006095616262986916, 0.005910594595902752, 0.006102455254794153,
  0.00603257055072352, 0.006150852963620263, 0.006124836551983151,
  0.00613709798886205, 0.006219586899714197, 0.006219640343552932,
  0.006264737767327308, 0.006263330361009256, 0.006375264577592973,
  0.0062162958529773105, 0.006109725235616427, 0.005834399585161361,
  0.005841341684388319, 0.0056431238601044, 0.005578089473932165,
  0.005629796629650825, 0.00536953525278728, 0.0052456945521789115,
  0.005256954414105279, 0.005056148011862285, 0.0048868316431451295,
  0.004752889161220882, 0.004812830741692029, 0.004903522718720474,
  0.005011591004890424, 0.0047580239336612465, 0.004655827060421476,
  0.004760388994139938, 0.004741544166501357, 0.004742364850941469,
  0.004704854530030581, 0.004684233990169664, 0.004542289530985543,
  0.004499531833097932, 0.004590360538631758, 0.00461318664911671,
  0.004667336316654308, 0.004671640719769298, 0.0048362494830696675,
  0.004774334369462786, 0.004627388396270601, 0.004545726972703937,
  0.00460150446155332, 0.004595115243693526, 0.004407097851371564,
  0.004317724600689004, 0.004154697547569154, 0.004101938606894294,
  0.004014400881869561, 0.004075244166612037, 0.003928100677839442,
  0.003840302953728053, 0.003693758896256827, 0.003645750322494469,
  0.003369770254452782, 0.0032504491512950567, 0.0032460347975761137,
  0.003102993061947352, 0.002993370773590594, 0.0030543023532737007,
  0.0029565135748651434, 0.002958782953142434, 0.0031763362070620946,
  0.003100961964758447, 0.003300131605704247, 0.003263427610207355,
  0.0032084104332330927, 0.0031490026848760453, 0.00312016432573862,
  0.0032621400339291192, 0.0030402699547152105, 0.003121401357855352,
  0.003079618380246939, 0.003121136851028725, 0.002957925410577551,
  0.0030752619029550386, 0.0030272220991950072, 0.0030040577583933586,
  0.0032224238598117, 0.003222254212732208, 0.00308161158609737,
  0.0031045968929892267, 0.003202945463227636, 0.0030498868633839988,
  0.003029819192632915, 0.00298364182965843, 0.003139118259575544,
  0.003210914275056507, 0.003233541456985094, 0.003412376328948635,
  0.003509756949255772, 0.003511490323636849, 0.0037549335728383477,
  0.0037175480276192287, 0.0038315813785936297, 0.0036815394986174396,
  0.00362349053262059, 0.0037456024860876565, 0.003942258179301595,
  0.003958060232715291, 0.004143402891177291, 0.0044172849857064675,
  0.004388687881439889, 0.0044439028898247196, 0.00461716161166867,
  0.004735932638741645, 0.004809623320489946, 0.004723071283592488,
  0.004664806499415139, 0.00457216007800659, 0.004649637326860785,
  0.004711402723737033, 0.00477892479603749, 0.004811726266841816,
  0.004732406261364735, 0.004726364843817794, 0.004718644075911914,
  0.004811433829339959, 0.0046242511048355354, 0.004581980632128983,
  0.004576963547863952, 0.004551504618835845, 0.004797092477989985,
  0.004798802152297813, 0.004665861346582747, 0.004806645278773331,
  0.004602159105294792, 0.004619533400963212, 0.0046667579145911985,
  0.004572305728901114, 0.004403563749788777, 0.004408744284688038,
  0.004293053123495626, 0.0043407628493696065, 0.004243542923487877,
  0.004003456665726185, 0.004105015442202106, 0.004079993816870511,
  0.0040889533649639985, 0.004068169436046726, 0.003948931910422019,
  0.0039343716751176795, 0.00382218194878062, 0.0038513820422569206,
  0.004006784121987883, 0.0037770419617930823, 0.003844777396503441,
  0.003895072627343317, 0.00385794560987936, 0.0038612221709008283,
  0.0039016788937912454, 0.0037692037412674407, 0.003761803208781611,
  0.003984859421047771, 0.0040129638757121075, 0.0037675514122374426,
  0.0039412209759643905, 0.004173475107391887, 0.004310199093625655,
  0.004359792267009239, 0.004133995986471456, 0.0038803674727850117,
  0.004021130334470791, 0.003957503243154124, 0.0037823041664122215,
  0.0037614438015353027, 0.0035183748865865935, 0.0037203885506044755,
  0.0038554941083796864, 0.0038714511400537253, 0.004137023194855674,
  0.003953195531684098, 0.004097960954515456, 0.004148265470747712,
  0.004070763450699645, 0.004064487690733764, 0.0040557532025709215,
  0.004154854502252298, 0.004214272871001242, 0.004299692853043421,
  0.004393666592295344, 0.00421525036950427, 0.00415128231139797,
  0.004090775371180289, 0.004082504966051034, 0.0041267415109292075,
  0.003892983311766877, 0.0038157500380859395, 0.003865854727822171,
  0.0038229595178936697, 0.003904170073427044, 0.0038508078212642805,
  0.003680959600416276, 0.003649114443185159, 0.003579959907321878,
  0.0036703460900286253, 0.003855036235376161, 0.003858914304327792,
  0.00391329629206165, 0.0036425032004009966, 0.0036625916015086604,
  0.0035219500427194385, 0.003453917671940926, 0.0034674713804199644,
  0.00343774000289011, 0.0035498157117006255, 0.003379142674174101,
  0.003398336008220668, 0.0032228149418082773, 0.0031310594288610745,
  0.0031494434636381123, 0.003176173893101604, 0.00310033060111226,
  0.003029869526538771, 0.0030568062561194655, 0.0028623063466189816,
  0.0025323820251234, 0.002394721977806432, 0.0023945780803146877,
  0.002196809382430133, 0.0021743255507376924, 0.002243902449805503,
  0.00216819601622294, 0.0020276974076966436, 0.0020532769469751373,
  0.0022242992993206662, 0.002256470097256426, 0.002251440486786638,
  0.0024569281048395573, 0.002553343733721928, 0.0025371019481003035,
  0.0025108359781826806, 0.002624872181261069, 0.0025735818029446944,
  0.0024756816109452184, 0.002570983514901957, 0.0024601221715929277,
  0.002412712498295406, 0.002282248483001064, 0.002329895145477643,
  0.002322874777219355, 0.0021570702572651194, 0.002021126531789256,
  0.0019746732231642585, 0.0019291903206425085, 0.001932686831045284,
  0.0018825136387149407, 0.0018467769715294945, 0.0018787488380621069,
  0.001861407306504548, 0.0019616362622720093, 0.0020092281403784485,
  0.0020899347795949887, 0.002076072678216907, 0.0021194072002093576,
  0.0019701109841991043, 0.0019186882761128593, 0.0017236580674826563,
  0.0019345729678393174, 0.0019251701543390508, 0.0019528059679634632,
  0.0017940281534546449, 0.002002642075281569, 0.002070392676265857,
  0.0020912790902289534, 0.0019270984456744846, 0.0021374559521003702,
  0.002290718850023385, 0.002039787863318368, 0.0021815643671716086,
  0.002242452883619862, 0.0020099237552488464, 0.0019479921998090937,
  0.001957664955421055, 0.0019657266551293545, 0.0021668102386038523,
  0.0023757587056216934, 0.002338899407509124, 0.0023064836531648055,
  0.0023190415489910392, 0.002451049674179685, 0.0024909925531629348,
  0.0022882482390123478, 0.0023036788983263803, 0.002370096628952791,
  0.0024434472118444184, 0.0023249093500608683, 0.0023718507328442735,
  0.0024578661760362407, 0.0024603892726432737, 0.0025181953126310434,
  0.0025731440505239725, 0.0026516370489359496, 0.0026984342348489315,
  0.0024545877174189093, 0.0023885371570936953, 0.0023394921993084264,
  0.00215765586658893, 0.002154324764151405, 0.002102617663153154,
  0.0020020341538646547, 0.001985891090676751, 0.0019548320919417685,
  0.0019262597831140487, 0.0018545145286218538, 0.0017893610575221415,
  0.0017529214257777583, 0.0018290689823307485, 0.0017036275003179343,
  0.0017205495153688778, 0.0017957428146110393, 0.0018484292575766616,
  0.0018301542156026456, 0.0017412424761019975, 0.001748377023658562,
  0.0015753263667820203, 0.001447850698661005, 0.0014530073628045675,
  0.0015630840710775388, 0.001665018508073434, 0.0015426754174568016,
  0.0013628568721183413, 0.0015243672947472067, 0.00161667974070417,
  0.0016152338674874783, 0.001770413919829949, 0.0018724022964002572,
  0.0019191642388838553, 0.0019973713406198383, 0.0018834244403601423,
  0.002087181497468618, 0.002125573066352673, 0.001986557875579623,
  0.001964851966417361, 0.0020255039756458602, 0.0019802713034168485,
  0.0021226262983843544, 0.0023228776658673726, 0.002453122876530595,
  0.002339489648252941, 0.00241736259261797, 0.0027005224893049593,
  0.002703049133165819, 0.0026889271452344063, 0.0027582413918197436,
  0.002724810240368522, 0.002800335728138715, 0.0027246370399186307,
  0.002660068780280458, 0.0026734816164259885, 0.0026487043205101965,
  0.002590091654315362, 0.002526701556360363, 0.002542384853480945,
  0.002565498903923735, 0.002688329827722642, 0.0026337098482956843,
  0.00245379047104614, 0.0025551048883324288, 0.0025788111141398086,
  0.002696383249149429, 0.0026793959190333262, 0.002873406278815599,
  0.0029868543932218294, 0.003032757977228274, 0.00304355482376396,
  0.00293196976642743, 0.002999103860331121, 0.002975775022276948,
  0.0029963063944078764, 0.0029626917927061663, 0.0027902886434876596,
  0.003018881248731677, 0.0031516882861047438, 0.003081877297629582,
  0.0029677262122170048, 0.0028807304489590147, 0.002872586510329284,
  0.0029343457072384797, 0.002789891317642524, 0.0029536143724067884,
  0.0030799870731460805, 0.003114996896755567, 0.0031740715650593596,
  0.003104405343805837, 0.0030863198592290435, 0.0031170409581553053,
  0.002978785364460992, 0.0029021599143936655, 0.0029956560801804285,
  0.002984060160145379, 0.0031852288068383913, 0.003251012842902793,
  0.0033274183897753417, 0.0032837568578187237, 0.003267396887403016,
  0.003164002540788415, 0.00312215185801436, 0.0030471608308178405,
  0.002834998738843253, 0.002819845209223254, 0.0028912989546166,
  0.002908369584254254, 0.002802184769297085, 0.002511183267836285,
  0.002508562584283724, 0.0024339095527429002, 0.0022505506482438545,
  0.0023973308637897507, 0.0025825401068782137, 0.002531586992143374,
  0.002390781021718062, 0.0023855900079675627, 0.002402412296779778,
  0.002449281984216491, 0.002441420726414836, 0.00237063738877332,
  0.0025175912104785127, 0.0024438995295581376, 0.002563517292118323,
  0.0025029906437520804, 0.0024466816101717925, 0.002735564378270852,
  0.00262203929556155, 0.0028735054996600973, 0.0027796465893202534,
  0.0028134104560923194, 0.0026813835591780574, 0.0025713685672245257,
  0.002741312711075563, 0.002690085294161149, 0.0025963078062116026,
  0.002459763976417682, 0.00237958393297439, 0.0022858483195134383,
  0.0023273385128090712, 0.0022821588980022575, 0.002598086901601468,
  0.002445035319953737, 0.0024241991577887666, 0.0023256174744889523,
  0.002393368206663448, 0.0023039569203049475, 0.0025045882397524053,
  0.0024216611432623207, 0.0027376721308745872, 0.0028342333944020403,
  0.0028346422351972047, 0.002906060984349401, 0.003060673419884425,
  0.0028361341387996325, 0.0027186488587697863, 0.002695619507452674,
  0.002812571947545388, 0.0025880880657305275, 0.0024515083001968403,
  0.0025985108434835257, 0.0023707252609806756, 0.0022810557126294703,
  0.0022827635769455275, 0.002469599381098293, 0.0024371609873368393,
  0.002411093678440808, 0.0026374794629701733, 0.0027343841269132584,
  0.0026831249415177386, 0.00269932260475362, 0.0027569845807176793,
  0.002884961295162621, 0.0028155201111880472, 0.002819537453511113,
  0.0027523295844596334, 0.002821182999058825, 0.0027179542007150894,
  0.0025986401425752365, 0.002668323287749554, 0.002517584048123911,
  0.002459944561721902, 0.00238397214259326, 0.002409031041468442,
  0.002556574039345921, 0.0025149612161036, 0.0024844786029360644,
  0.00238674244757871, 0.0023691585621002987, 0.002412879483966222,
  0.0022268692797408943, 0.0023445305151389914, 0.0020932927556043105,
  0.002153246781870647, 0.0020985747204134786, 0.0021701412081623923,
  0.0022535832937327837, 0.0022433838076823588, 0.0022115878686899734,
  0.0021920022859712846, 0.0022048665231615854, 0.0023606222229384066,
  0.0025030877012439854, 0.0025271687742698455, 0.0024955566494374534,
  0.0025657240681996873, 0.0024458708369459055, 0.002362472773661215,
  0.0025702498172198497, 0.0025802531726670753, 0.0027339828506279303,
  0.002670625255788942, 0.002722706473255472, 0.0028601258682219737,
  0.002919586264938852, 0.0027985721785513265, 0.0027165456901297783,
  0.002723973349580466, 0.002689046691195594, 0.0026548248430784353,
  0.0027930581649208783, 0.002815323980690449, 0.002826582234192563,
  0.002668403001369561, 0.002489422145096517, 0.0025427769783822147,
  0.002402209150129195, 0.0024352170627695083, 0.0023594205209736254,
  0.002203671765515499, 0.002404810441110377, 0.0022263004986778122,
  0.002222855964691894, 0.002029988116611071, 0.0021087116710944037,
  0.0019332763201325644, 0.0017415326351958487, 0.0017710842788444697,
  0.0016458172152060083, 0.0015569339885208708, 0.0014399325020302309,
  0.0014427056079961524, 0.0012666131406636183, 0.0012161764735664567,
  0.0012437994935454344, 0.0010716861522725599, 0.001094793829087208,
  0.0010913116378739158, 0.0009033935570802002, 0.000791746526555567,
  0.000691440032283018, 0.0007053313115609988, 0.0007342930718463514,
  0.0008058174018773754, 0.0008342223476253272, 0.0008805419623345308,
  0.000809629766760583, 0.0007666069213513942, 0.0006598814048937051,
  0.0007727792205427721, 0.0008272813364446429, 0.0005959660874487417,
  0.0005421532120766067, 0.0005913952314233412, 0.0004337672856785632,
  0.0003083132464821801, 0.00026569477799817806, 0.00030104256109728935,
  0.00046299720981246817, 0.0003885343794230072, 0.0005789465293396604,
  0.0007140548893728931, 0.0006565745750919807, 0.0005087309600097822,
  0.000469928533407667, 0.00033197745020998074, 0.0003402485594607888,
  0.0004616129288372075, 0.00040174396433338526, 0.000557809955774302,
  0.0006634713340553708, 0.0005375833257234446, 0.0005549647085588004,
  0.0005254154211265091, 0.0006596648608728777, 0.0005552026077337257,
  0.0008595919373925728, 0.0008704538142124258, 0.0009212398205237873,
  0.000827314108501661, 0.0008796535140368829, 0.0009066448313169359,
  0.0010328184827651436, 0.0008810141826267762, 0.0008933105765309341,
  0.0008840854134277231, 0.0009735252184742666, 0.0010794508960604722,
  0.001056394921240293, 0.0009424578329323113, 0.0007526677727590328,
  0.00091851354136905, 0.000807491068425441, 0.000734813814821169,
  0.0008102364148015596, 0.0008025917618141333, 0.0008433617060716971,
  0.0009098228323483697, 0.0010241138811721076, 0.001152091060049684,
  0.0013593434933158665, 0.0011942131561002384, 0.0011295628562882519,
  0.0013364364017004074, 0.0012003499728298111, 0.0013060426317107398,
  0.001433420089831286, 0.0013661309905294654, 0.0013933260707048995,
  0.0014991331935609465, 0.0016085399837714029, 0.0016517409683749706,
  0.0015237255337956224, 0.0014879341141165571, 0.0015839214478410783,
  0.001601289637099751, 0.0013624324757455278, 0.0012300207531406179,
  0.001090346603112112, 0.0009616429258011612, 0.0010208153462469072,
  0.001048844155090055, 0.001037681880792828, 0.0009455114191056264,
  0.0009872470256863655, 0.0009180641205953748, 0.0007885724908740505,
  0.0008440457360123346, 0.0009180906239838197, 0.0009041530825426145,
  0.0009291603897122679, 0.0008893079555013384, 0.0008231747398769555,
  0.0008090889548744568, 0.0009107887843090455, 0.0010057266949594923,
  0.0009167720135946716, 0.0007119102734874363, 0.0007156738009603771,
  0.0005758706116936517, 0.0005872067829121976, 0.0005262076608510657,
  0.000541635321462131, 0.00048400445785008034, 0.0003381026431223899,
  0.0002070647246474803, 0.00038312499973672275, 0.0005180281239327484,
  0.0005456773325399777, 0.0007325861673972696, 0.0007034033240915972,
  0.0007301852898054124, 0.0006208217346990058, 0.0005421898541751074,
  0.000736803788906597, 0.0007320391742118556, 0.0007562347905500586,
  0.0007295392647984739, 0.0006945979618500657, 0.000682416103807882,
  0.0006392645682138987, 0.000722445355690212, 0.0007054006439387688,
  0.0009160224653270689, 0.0008280787055063153, 0.0007224064858572041,
  0.0007316890740061735, 0.0006784123975275543, 0.0005948606795582814,
  0.0006399611552043902, 0.0005513528167984178, 0.0004097207559765218,
  0.0007369974421053973, 0.0007554196339451829, 0.0007388271916188038,
  0.0007972524078795776, 0.0006949757041029103, 0.000757111245894863,
  0.000762887382835292, 0.0007718238131933652, 0.0006468599952957017,
  0.0006231548405443643, 0.0006522261736021688, 0.0007107849575149213,
  0.000687002770725389, 0.0006563654245701536, 0.00047663709354838087,
  0.0005224740644475931, 0.0003025458249116594, 0.0005192183582927301,
  0.00043124867566083665, 0.00026931318848202656, 0.0002765236811439616,
  0.00019210456356919003, 0.0003239756205049446, 0.0003668263330344136,
  0.00022533569133419249, 3.3260114642809714e-5, -0.0001024456081224015,
  -7.302743980288223e-5, -0.00013261239031818632, -0.00011971549517219408,
  -0.00024614563703633603, -0.00025120652611280476, -0.00037225753316279795,
  -0.0003944099580989968, -0.00036532661346415525, -0.00021159310435538573,
  -0.00017419231430858257, -7.493734191205367e-5, 2.910913188588918e-5,
  0.00012792393876908542, 0.00018418896449341467, 3.493689869386431e-5,
  -1.775530785506823e-5, 1.0650018850004812e-5, -5.53662528101207e-5,
  3.9445894202537455e-5, 0.00017801099487738779, 0.00018986226284579746,
  0.0001952504837013978, 0.000328112268974353, 0.00029438386423299306,
  0.0004108857036502592, 0.00036875585555514394, 0.00036253388668217246,
  0.0002641364368614274, 0.00024482127143816057, 0.000313501748374648,
  8.156582081571707e-5, 3.6641298308997455e-5, 6.404985991416099e-5,
  0.00031779530866941295, 0.00040972900035043876, 0.0006009149146602918,
  0.0004961698244134317, 0.0004883684769883463, 0.0004481852318072224,
  0.0003922578592646988, 0.0002749878200745043, 0.0003763155695605902,
  0.0004536462860014076, 0.000566400549066775, 0.00048565645910875665,
  0.0004050408208600977, 0.0003746992351190424, 0.0004550602283485066,
  0.00040312618776461155, 0.00046888092563556887, 0.0005548971851673649,
  0.0005476035851738378, 0.0005500945145082934, 0.0005160975671117053,
  0.00035011412917824867, 0.00041677798524624295, 0.0005987970146505047,
  0.0006398654687092385, 0.0005878510023927634, 0.0006171883694617224,
  0.000525984239066422, 0.00043912846443238404, 0.0003562395270977725,
  0.0003534409728837575, 0.0003720543030074634, 0.00031963090481971443,
  2.634527834060256e-6, 7.837773801960768e-5, 0.0002275620438135133,
  0.00019047750789483544, 2.9231722635752054e-5, 0.00016983946944213943,
  0.00015877217806360062, 9.521321892001212e-5, -2.689450560204701e-5,
  3.650244124622219e-5, -5.016081150827667e-5, -0.00019790749171773704,
  -0.00047625311891276104, -0.00045167921510086325, -0.00042512119307569203,
  -0.00036505729273099397, -0.00037529124665749666, -0.00039828520832927566,
  -0.0003757518159195739, -0.00046117549833750513, -0.0005024091714997083,
  -0.00043064097265742805, -0.0004905780704380272, -0.00048483905736249435,
  -0.0004664325243045283, -0.0006377717710212607, -0.0006528045810805639,
  -0.0006737747345123201, -0.0007429318629502804, -0.0008839825266619303,
  -0.0009582062751049528, -0.0009401398465451063, -0.0010085144680986766,
  -0.001072229993806171, -0.0011307794326902826, -0.0009562250835941428,
  -0.001037494779377935, -0.0009321949896135397, -0.0010429586014080896,
  -0.0009079636692363999, -0.0007945321558610528, -0.0008173239089961822,
  -0.0008739142381623331, -0.0008035007865341777, -0.0009421212024009974,
  -0.0009970310115726857, -0.0011805024616791684, -0.0014263119932309458,
  -0.0012839203412311321, -0.0013169130288673279, -0.0013746286427963068,
  -0.0013682775220406173, -0.0013588360222986325, -0.0013620376882092517,
  -0.0013273767398884035, -0.0013583158156024147, -0.0012969886848897682,
  -0.0013859085666686327, -0.001492770533558825, -0.001425265616319024,
  -0.0012721366384651016, -0.0011473109715422955, -0.0012068637451582403,
  -0.001123323265808147, -0.0010195361780100242, -0.0010350189063295717,
  -0.0011570339811046914, -0.00119962503355412, -0.001098009099300176,
  -0.0011246195669671554, -0.001022028435282571, -0.001107245540392133,
  -0.0010434074337045977, -0.0009840322105984923, -0.0010067962982660747,
  -0.0009566595653041717, -0.0009091573970749495, -0.0010285012523955082,
  -0.0011851110601735835, -0.0010217579606939357, -0.0009998218144811105,
  -0.0010165344324506412, -0.0010892025001403166, -0.001188642710267308,
  -0.0010948623725342557, -0.001127355562592333, -0.0010408671533753862,
  -0.0010584518529748355, -0.0010571697976236149, -0.0009571255886811945,
  -0.0007701887644919718, -0.0005199669182994965, -0.0004728401128384369,
  -0.00021583996382603133, -0.00025497087939372324, -0.00038571732598348483,
  -0.0005420847465079285, -0.000618744105208138, -0.0006568541149378383,
  -0.00047327546145448167, -0.0005593601052643955, -0.0005813920748865809,
  -0.0005575176303938669, -0.0003461122763865745, -0.0001894578737800845,
  -0.00024084539492600228, -0.00032893868645791035, -0.0003177304738787882,
  -0.0004011864593347654, -0.0003403793747044526, -0.0002991608512314513,
  -0.00024086404300918508, -0.00016676085343448512, -0.0003209633835945017,
  -0.00038591882382266174, -0.0002599322444861424, -0.0003465514004854095,
  -0.00025199356686734, -0.0002772531075578454, -0.00027911990660235334,
  -0.00024790946344337763, -0.0002654137476980304, -0.00011013970808752225,
  -8.965080817441771e-5, -0.00019034748938305155, -0.0002206203059849284,
  -0.0004188478902223918, -0.0005108718110281074, -0.00048521363071758083,
  -0.0004429346567716755, -0.0003827048473632468, -0.00041970757070770235,
  -0.0004782397364941821, -0.00031799601526761776, -0.00022836326872651246,
  -0.00024382231417984407, -0.000276024672092922, -0.00025803917993651664,
  -0.00035242268854509385, -0.0004096346764362469, -0.00048231727675634593,
  -0.0003221301978286187, -0.00031725336852883357, -0.00030461403002390847,
  -0.00027689220586450783, -7.723919898049583e-5, -8.552587985286985e-5,
  0.00010835983239065986, 0.00014414875043900464, 0.000157454837696543,
  0.00034432482306214575, 0.000324133605513808, 0.0003717660858594353,
  0.0001769953340982951, 0.00023541514802412567, 0.0002754835677980883,
  0.00033002617257117754, 0.0004330988713125965, 0.0006328495547135939,
  0.0005669453623664867, 0.0006703053966040798, 0.0005393101066008591,
  0.000511642416641239, 0.0007058463511832046, 0.0007552053907699101,
  0.0007492654819174637, 0.0006254499416676969, 0.000560768346881065,
  0.0004542534562876852, 0.0005353764474536867, 0.0005285644485424802,
  0.0007333865473827493, 0.0005616162435328756, 0.0007202247231701407,
  0.0009094479963046848, 0.0008336418152209379, 0.0008430454981148805,
  0.0009557728577657831, 0.000851115639495444, 0.0008567206245573291,
  0.0007686494532629356, 0.0007269988673117019, 0.0008025600055347145,
  0.0008168393812999277, 0.0008829434649205644, 0.0009187636826060501,
  0.0009728009671800218, 0.0010184374676152261, 0.001213133647428063,
  0.0012192508593287935, 0.001337097872174493, 0.001573220069930754,
  0.0016248785217086723, 0.0015550321918038012, 0.001631261820954716,
  0.0016562119396374378, 0.0017469325484921634, 0.0015856645202932034,
  0.0017660282339676324, 0.0016188822971318535, 0.0014349232799038209,
  0.0015280588885721066, 0.0014297092362827327, 0.0014906251136555476,
  0.0013172810515862286, 0.0014042916491975818, 0.001285252333255232,
  0.0012406890784367978, 0.0010829997844567032, 0.0009454295878050128,
  0.0011037072329627784, 0.0012896826572616144, 0.001183699575366306,
  0.0012455194183163155, 0.0012226467512324226, 0.001297653233566446,
  0.0012966660698088702, 0.001322914998505444, 0.0011585908779187099,
  0.0011057912251815868, 0.0009545295937176338, 0.0009743569555771066,
  0.0008167516969757468, 0.0007451303488617726, 0.0007235681647027103,
  0.0007923205453143689, 0.0006438246689655624, 0.0006036947834721156,
  0.000630849645030235, 0.0005078575772116185, 0.0005891874820295914,
  0.0004812204510607361, 0.00042683191156571204, 0.0003522481858429021,
  0.00035354382474899566, 0.00032569586836672974, 0.0004037187542917148,
  0.0005115756465387119, 0.0004905634682954477, 0.0004952964683954898,
  0.0004437701845750711, 0.0003115606365857966, 0.00026197972453283235,
  0.0002681835990532223, 0.00036721004910572294, 0.00020333127540517,
  0.0001373901246349864, 3.934863361353764e-5, 7.721532016818057e-5,
  0.00024137704592883588, 0.00029835638982989243, 0.00020401298084819484,
  0.00014505240286226436, 0.00015359103502060107, 4.428863789559712e-5,
  0.00014726967875882787, 0.00022116411840819111, 0.00016022765672118875,
  0.00038785518640725215, 0.00040333982113877303, 0.00025259710370388177,
  0.00026240364591514865, 0.00014943985537811018, 7.731224636478829e-5,
  7.072582176322599e-5, 0.0003779858394295951, 0.0003664529567958152,
  0.00026965534955314293, 0.00013343780240989602, 3.10417719769852e-5,
  0.00010041084584853493, 0.00018574682774004082, 0.00015198501838656662,
  6.40974200145077e-5, -8.524616974791916e-6, -7.943496215824098e-5,
  -1.7959705807897606e-5, 5.62913802498183e-5, -0.00012386551226849286,
  -0.00022486000317581696, -0.0001740709851496977, -0.0002630508324099335,
  -0.000410246047529764, -0.0002891301647399392, -0.00014725131788520757,
  -0.00024963702580896346, -0.00014190628506236252, -0.00018959207099739634,
  -0.00011805225120978194, -1.7486802014490727e-5, -8.174926312517396e-5,
  -7.234705792065268e-6, 0.00017045271719536926, 0.0001529404030192913,
  0.00013546062562843993, 0.00011314353283888099, 0.00013181714983598577,
  2.59475399944302e-5, -0.000213773343818667, -0.0003995661958484489,
  -0.0004133634353876253, -0.00044140930684874544, -0.00043083751600500326,
  -0.00035089227304999207, -0.00035269857759638205, -0.00040392696017035347,
  -0.000491698791912649, -0.0003353154688649551, -0.0003772193506160543,
  -0.00044224432520532394, -0.00035297649337408395, -0.00037902247164460354,
  -0.0005370181146678723, -0.0002833278286376066, -0.00023921592098607148,
  -0.00024388172067360032, -0.00019562269871877894, -0.00023492585746081156,
  -0.0001320059187595253, -0.00017381591126165935, -0.000301697434311612,
  -0.00032738138809659464, -0.00036227258121600595, -0.00046752534167901147,
  -0.0006546537874089705, -0.0006291887739073281, -0.0007634625535051768,
  -0.0008846455535535033, -0.0008325141559371845, -0.000818168427514744,
  -0.0009032518858358944, -0.0008026054334044977, -0.0008546396314598537,
  -0.0009953414398616045, -0.0010767862526619117, -0.0009848666438818858,
  -0.0010596941628629127, -0.000979466810295046, -0.0008472212325348921,
  -0.000642197025918143, -0.0007533064261615635, -0.0006911863584223617,
  -0.0006829984451036129, -0.0007002892820867513, -0.0006921040903611829,
  -0.0007063352713001016, -0.0005967505373227042, -0.0004945104599498984,
  -0.0007099883870281063, -0.0006203091241588921, -0.0005472196168673856,
  -0.00056556300148703, -0.00048204840323799514, -0.00056080761664657,
  -0.0004256939456566105, -0.0003977694422235303, -0.00042192180921220073,
  -0.0005019746554055611, -0.0005131202407459037, -0.0004904198386071914,
  -0.0003364759667400868, -0.0005596341035337137, -0.0005991628444190596,
  -0.0007019973181350219, -0.0008486737211343865, -0.0008178356662090037,
  -0.0008594773799983465, -0.0009057046763481253, -0.0009344730793551403,
  -0.0007658842844564135, -0.0007659874792148721, -0.0007618227071006483,
  -0.0008352255097978146, -0.00078387699317734, -0.0008573844302784974,
  -0.0009059976053026645, -0.0009246723063726941, -0.0008823115018557211,
  -0.0008046412110893124, -0.0007656662467656348, -0.0007321977061623012,
  -0.0007421146895612797, -0.0007551557478647707, -0.0009382881229692324,
  -0.0009287670326508019, -0.0009457175875802146, -0.000983255057853945,
  -0.000996308045047978, -0.0008655160595204058, -0.0009714100732859411,
  -0.0010364581340636784, -0.0010719771709335284, -0.0010042778037177291,
  -0.0009331343245390296, -0.0009374298604237454, -0.0007374473247580322,
  -0.0007409999056405268, -0.0007088868622604095, -0.0008181459373367993,
  -0.0007906046845096585, -0.0007553508954340055, -0.0008781227833306348,
  -0.0006147617380916462, -0.0006584467209393304, -0.0006004688793594859,
  -0.0004870720407482967, -0.0004159602662706344, -0.00043568617453661884,
  -0.00039262016723526746, -0.0004473310831236067, -0.0004716404423842098,
  -0.0005756487855284911, -0.00036322566624259316, -0.00043299443845301513,
  -0.00028519625933305426, -0.0001919464916808377, -7.934835311484876e-5,
  -0.00023731937516345997, -0.00021056979855753257, -0.0001435802652188756,
  -0.00019102619596021923, -8.615418297008634e-5, -0.00030299448970162675,
  -0.0003974984685621108, -0.000280016190148576, -0.0004963630021764026,
  -0.0003878228497404456, -0.0002755204907820972, -0.0003391995468818178,
  -0.00019303398357261407, -0.00014813076360513917, -0.00037880566712234154,
  -0.00044399562939504144, -0.0004726993268924229, -0.0006027437143666944,
  -0.0006489558012834198, -0.000632420287891729, -0.0005841355117012217,
  -0.0005688329479365824, -0.0005844939450314142, -0.0005070489247365917,
  -0.00016508138838661787, -0.00010184719182090404, -0.00010553460991622096,
  -4.7105886768088264e-5, 6.43659008036868e-5, 7.415917821566321e-5,
  1.131454549938446e-5, 4.907346168266879e-5, 0.00017112678341992186,
  1.4552195393497335e-5, 0.0001591664870962505, 1.3951272506364154e-5,
  0.00010817318340018325, 3.0218309462401716e-5, -5.8500765986033966e-5,
  -8.382399828477892e-5, -0.00022829248802012448, -0.00019049426444905308,
  -8.391017148640114e-5, -0.00015045900834860846, -0.00018750602388297268,
  -0.00020696186904387034, -0.0002488962088263644, -0.0002893352703740674,
  -0.0003259581137800157, -0.0001607181473885603, -0.00016848599984140258,
  -0.00016232259488469642, -0.0001207787780546528, -0.00010563079110240443,
  -0.00012078289506509924, -6.568291278108208e-5, -0.00021543719646982285,
  -0.00016263397970975142, -0.00010886312165647976, -0.00012840963843306223,
  2.757705354917721e-5, 6.115132072105208e-5, 0.00015156206152826886,
  0.0002442254785071545, 0.0002528846176845097, 0.0002722145240368226,
  0.0002923876619086832, 0.0003094516985728666, 0.000329380016385671,
  0.0003556683232523555, 0.0004363803414377548, 0.0004505433950921979,
  0.00043424978286201046, 0.0005179562417484698, 0.0004615706920537471,
  0.0005356103370081938, 0.0004123816891271251, 0.0005897034691693825,
  0.0006231030947194146, 0.0007218734003575178, 0.0008138989615483795,
  0.0008856624306665072, 0.0007846493653461712, 0.0009425062633401783,
  0.000895561302375642, 0.000914133899821722, 0.0010510466182047447,
  0.0010829000898461645, 0.001005650620617386, 0.0009683494044296114,
  0.0010131399243435964, 0.0011132133268030888, 0.0010127738482197132,
  0.0009848557415838043, 0.0011805729943127853, 0.0011058194249174442,
  0.0010236768972020965, 0.0008666089677032184, 0.0007143663350651108,
  0.0004106107223535058, 0.0005288223606881741, 0.0006545103540160293,
  0.0006434510797182925, 0.0005877899572958912, 0.0005154284294419765,
  0.0004847801519025256, 0.00042636527685874145, 0.00023656299942479854,
  0.0002868774524678165, 0.00027169988418184127, 0.00036058630607883657,
  0.00025337490750671106, 0.0003623274945589198, 0.00030560979828246973,
  0.0004018408786522308, 0.0005198172336349751, 0.00046379880482401187,
  0.00042657355014044993,
];
